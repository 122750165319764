<mat-form-field class="mat-input-container">
  <div class="mat-input-wrapper">
    <div class="mat-input-table">
      <div class="mat-input-infix">
        <input matInput onkeydown="return false" [(ngModel)]="options.value" disabled="false" [matDatepicker]="picker"  [matDatepickerFilter]="validateDate"
          (ngModelChange)="dateChange()" (click)="picker.open()">
          <mat-datepicker-toggle matSuffix class="calenderBtn" [for]="picker"></mat-datepicker-toggle>
        <span class="datePickerIconSpan">
          <svg class="svgIm calenderIcon greyColor1" (click)="picker.open()" version="1.1"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22px" height="1px"
            viewBox="0 0 22 1" xml:space="preserve">
            <g transform="scale(0.9)">
              <g transform="translate(-305,-265.6)" xmlns="http://www.w3.org/2000/svg" id="calendar_2_">
                <path d="M300,259v16c0,0.55,0.45,1,1,1h21c0.55,0,1-0.45,1-1v-16H300z M305,274h-3v-3h3V274z M305,270h-3v-3h3V270z M305,266h-3
                    v-3h3V266z M309,274h-3v-3h3V274z M309,270h-3v-3h3V270z M309,266h-3v-3h3V266z M313,274h-3v-3h3V274z M313,270h-3v-3h3V270z
                      M313,266h-3v-3h3V266z M317,274h-3v-3h3V274z M317,270h-3v-3h3V270z M317,266h-3v-3h3V266z M321,274h-3v-3h3V274z M321,270h-3v-3
                    h3V270z M321,266h-3v-3h3V266z M323,255v3h-23v-3c0-0.55,0.45-1,1-1h2v-1c0-0.55,0.45-1,1-1h1c0.55,0,1,0.45,1,1v1h11v-1
                    c0-0.55,0.45-1,1-1h1c0.55,0,1,0.45,1,1v1h2C322.55,254,323,254.45,323,255z" />
              </g>
            </g>
          </svg>
        </span>
<!--     
        <span class="mat-input-placeholder-wrapper">
        </span> -->
      </div>
    </div>
    <!-- <div class="mat-input-underline">
      <span class="mat-input-ripple"></span>
    </div>
    <div class="mat-input-subscript-wrapper">
      <div class="mat-input-hint-wrapper ng-trigger ng-trigger-transitionMessages"
        style="opacity: 1; transform: translateY(0%);">
        <div class="mat-input-hint-spacer"></div>
      </div>
    </div> -->
  </div>
</mat-form-field>  
   <mat-datepicker #picker></mat-datepicker>