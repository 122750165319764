import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'alex-content-area-container',
  template: `
  <div  class="d-none d-lg-block pt-0 mt-0">
  <ng-content select=[contextual-bar]>
  </ng-content>
</div>
<div class="set-margin">
  <ng-content select=[content-area]>
  </ng-content>
</div>
  `,
  styleUrls: ['./content-area-container.component.scss']
})
export class AlexContentAreaContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
