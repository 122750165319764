import { Injectable } from "@angular/core";
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { asyncScheduler, Observable, of, scheduled } from "rxjs";
import { AlexPages } from "../components/help/help.enum";
import { CurrentPageService } from "../services/current-page.service";

@Injectable({
  providedIn: "root",
})
export class CurrentPageResolver implements Resolve<boolean> {
  constructor(private currentPageService: CurrentPageService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const { pageType } = route.data;
    this.currentPageService.setCurrentPage(pageType as AlexPages);
    return scheduled(of(true), asyncScheduler);
  }
}
