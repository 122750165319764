import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SubscriptionService {
  subscriptionPopupVisible: BehaviorSubject<boolean>
  sessionKey="AlexSessionId"+window.location.host;
  constructor() {
    this.subscriptionPopupVisible = new BehaviorSubject<boolean>(false);
  }

  setSessionID(sessionId: string) {
    localStorage.setItem(this.sessionKey, sessionId);
  }

  setFirstPage(sessionId: string, flag: string) {
    if (localStorage.getItem(this.sessionKey) == sessionId)
      localStorage.setItem("isFirstPage", flag);
  }

  IsFirstPage(sessionId: string): boolean {
    if (localStorage.getItem(this.sessionKey) == sessionId) {
      if (!localStorage.getItem("isFirstPage")
        || localStorage.getItem("isFirstPage") == "true") {
        return true;
      }
    }
    return false;
  }

  getSessionObject(sessionId: string) {
    let localStorageObj = JSON.parse(localStorage.getItem("AlexSessionObject"));
    if (localStorageObj.sessionId == sessionId) {
      return localStorageObj.isFirstPage;
    }
    return "";
  }
}
