<ng-template #addCountryModal>
  <alex-modal
    (OnCancelClicked)="reset()"
    (OnOkClicked)="updateCountrySelection($event)"
    [modalId]="'addCountryModal'"
    [headerText]="'Country/Region/Jurisdiction Selection'"
    [footerOkText]="'Apply'"
    dismitModal="t"
    rue
    [disableSubmit]="disableSub"
    [applyDisabledCSS]="disableSub"
  >
    <div class="countryListData searchScroll" modalBodyContent>
      <div class="dragText">Drag to reorder tabs on your Home page</div>
      <!-- <kendo-sortable [navigatable]="true" [animation]="true" [data]="countryList" class="row"
                itemClass="countryInactive sortable-row" activeItemClass="countryActive sortable-row" disabledItemClass="countryInactive sortable-row"> -->

      <kendo-sortable
        [kendoSortableBinding]="countryList"
        [navigable]="true"
        [animation]="true"
        class="row"
        itemClass="countryInactive sortable-row"
        activeItemClass="countryActive sortable-row"
        disabledItemClass="countryInactive sortable-row"
      >
        <ng-template let-item="item">
          <label class="countryName">
            <input
              type="checkbox"
              class="checkboxInput"
              [checked]="item.isSelected"
              (change)="
                item.isSelected = !item.isSelected; onCheckboxChanged($event)
              "
            />
            <span class="checkboxspan"> </span>
            <span class="checkboxTxt">{{ item.country }} </span>
          </label>
          <span class="burgerIcon">
            <svg
              class="svgIm greyColor2"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="22px"
              height="1px"
              viewBox="0 0 28 1"
              xml:space="preserve"
            >
              <g transform="scale(2.9)">
                <g
                  transform="translate(-245,-346.6)"
                  xmlns="http://www.w3.org/2000/svg"
                  id="sortable"
                >
                  <path
                    id="menu-hamburger_1_"
                    style="fill-rule: evenodd; clip-rule: evenodd"
                    d="M247,345.75c0,0.138,0.112,0.25,0.25,0.25h9.5
                    c0.138,0,0.25-0.112,0.25-0.25v-1.5c0-0.138-0.112-0.25-0.25-0.25h-9.5c-0.138,0-0.25,0.112-0.25,0.25V345.75z M247,348.75
                    c0,0.138,0.112,0.25,0.25,0.25h9.5c0.138,0,0.25-0.112,0.25-0.25v-1.5c0-0.138-0.112-0.25-0.25-0.25h-9.5
                    c-0.138,0-0.25,0.112-0.25,0.25V348.75z M247,351.75c0,0.138,0.112,0.25,0.25,0.25h9.5c0.138,0,0.25-0.112,0.25-0.25v-1.5
                    c0-0.138-0.112-0.25-0.25-0.25h-9.5c-0.138,0-0.25,0.112-0.25,0.25V351.75z"
                  />
                </g>
              </g>
            </svg>
          </span>
        </ng-template>
      </kendo-sortable>
    </div>
  </alex-modal>
</ng-template>
