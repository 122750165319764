import { SafeHtml, DomSanitizer } from "@angular/platform-browser";
import { SvgIconList } from "./icon-list.model"
import { Injectable } from "@angular/core";
import { AlexMimeTypeMap } from "./icon-mime-type-map.model";

@Injectable()
export class AlexIconFinder {
  constructor(private sanitizer: DomSanitizer) {
  }
  find(iconName: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(SvgIconList.get(iconName));
  }
  findByMimeType(mimeType: string): SafeHtml {
    mimeType = mimeType || '';
    let iconName = AlexMimeTypeMap.get(mimeType);
    if (!iconName)
      iconName = 'file';

    return this.find(iconName);
  }
}