import { Injectable } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

import { TemplateRef } from "@angular/core";
import { ModalConfig } from "app/feature/search/model/modal-config.model";

@Injectable({
  providedIn: "root",
})
export class ModalContentService {
  modalRef: BsModalRef;
  nestedModalRef: BsModalRef;
  config: ModalConfig;

  constructor(private modalService: BsModalService) {
    this.config = new ModalConfig();
  }

  openModal(templateRef: TemplateRef<any>, styleClass?: string) {
    if (styleClass) {
      this.config.class = [this.config.class, styleClass].join(" ");
    }
    this.modalRef = this.modalService.show(templateRef, this.config);
    this.config = new ModalConfig();
  }

  openNestedModal(template: TemplateRef<any>, styleClass?: string) {
    if (styleClass) {
      this.config.class = [this.config.class, styleClass].join(" ");
    }
    this.nestedModalRef = this.modalService.show(template, this.config);
    this.config = new ModalConfig();
  }

  openModalFromComponent(component: any, styleClass?: string) {
    if (styleClass) {
      this.config.class = [this.config.class, styleClass].join(" ");
    }
    const modalRef = this.modalService.show(component, this.config);
    this.config = new ModalConfig();

    return modalRef;
  }

  resetModal() {
    this.modalRef.hide();
  }

  resetNestedModal() {
    this.nestedModalRef.hide();
  }
}
