import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlexModalComponent } from "app/shared/components/alex-modal/alex-modal.component";
import { SubscriptionService } from "app/shared/components/subscription/subscription.service";
import { SubscriptionConstant } from "app/shared/components/subscription/subcription.constant";
import { AlexUserService, AlexUrlResolverService } from "app/core/services";
import { UserLicense } from "app/core/services/user/user-license.model";
import { LicenseType } from "app/core/services/user/license-type.enum";
import { AlexUserGuideService } from "app/shared/components/userGuide/user-guide.service";


@Component({
  selector: 'alex-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  @ViewChild(AlexModalComponent, { static: true }) modal;
  licenseInfo: UserLicense;
  @ViewChild('btnSubscriptionPopup', { static: true }) btnSubscriptionModal: ElementRef;
  message: string;
  SubscriptionConstant = SubscriptionConstant;
  subscriptionLoaded: boolean = false;
  warningMessages:any;
  constructor(private subscriptService: SubscriptionService,
    private userService: AlexUserService,
    private urlResolverService: AlexUrlResolverService,
    private userGuideService: AlexUserGuideService ) {
    this.licenseInfo = new UserLicense();
  }

  ngOnInit() {
    this.warningMessages=this.urlResolverService.getResourcesForPageByKey("subscription_popup");
  }

  ngAfterViewInit() {
    this.togglePopup();
  }

  subscriptionSubmit() {

  }

  togglePopup() {
    this.subscriptService.subscriptionPopupVisible.subscribe(flag => {
      if(flag){
        this.licenseInfo = this.userService.user.userLicenseModel;
        let isFirstPage = this.subscriptService.IsFirstPage(this.licenseInfo.sessionID);
        
        if ( isFirstPage &&
            this.licenseInfo &&
            this.licenseInfo.popupType != this.SubscriptionConstant.popupType.NO_POPUP) {
          this.subscriptService.setFirstPage(this.licenseInfo.sessionID, "false");
          this.bindPopupMessage();
          this.btnSubscriptionModal.nativeElement.click();
        } else
          this.showUserOnboarding();

      }
    })
  }

  showUserOnboarding() {
    this.userService.isAuthorizedUser.subscribe(isAuth=>{
      if(isAuth){
        // this.userService.isNewUser.next(this.userService.user.isNewUser);
        if (this.userService.user.isNewUser) {
            const userGuideServieSubscription = this.userGuideService.fetchUserGuideContent(this.userService.user.isExternalUser).subscribe(() => {
            this.userService.showUserGuide$.next(true);
            userGuideServieSubscription && userGuideServieSubscription.unsubscribe();
          });
        }
      }
    })
  }

  bindPopupMessage() {
    if (!this.licenseInfo || this.licenseInfo.noOfDays == undefined) {
      return
    }
    this.message = this.urlResolverService.getResourcesForPageByKey("subscription_popup","SUBSCRIPTION_EXPIARY_MSG")
      .replace("@noOfDays", this.licenseInfo.noOfDays.toString());

    if (this.licenseInfo.subscriptionType == LicenseType.Trial) {
      this.message = this.urlResolverService.getResourcesForPageByKey("subscription_popup","TRIAL_EXPIARY_MSG")
        .replace("@noOfDays", this.licenseInfo.noOfDays.toString());
    }

    if (this.licenseInfo.subscriptionType == LicenseType.Subscription) {
      this.message = this.urlResolverService.getResourcesForPageByKey("subscription_popup","SUBSCRIPTION_EXPIARY_MSG")
        .replace("@noOfDays", this.licenseInfo.noOfDays.toString());
    }
  }

  renewLicense() {
    let url = this.urlResolverService.getRenewLicenseUrl();
    window.location.href = url + "?Tab=License&UserSession=" + this.licenseInfo.sessionID;
  }

  subscribeNow() {
    let url = this.urlResolverService.getExtManagAccUrl();
    window.location.href = url + "?Tab=License&UserSession=" + this.licenseInfo.sessionID;
  }

  hideRenewButton(){
    return (this.licenseInfo.noOfDays <= this.warningMessages.RENEW_BUTTON_TIME_BOUNDARY);
  }

  ngOnDestroy() {
    this.subscriptService.subscriptionPopupVisible.unsubscribe();
  }
}
