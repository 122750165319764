import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AlexLocationModel } from "app/shared/components/location/alex-location.model";
import { Router } from "@angular/router";
import { AlexUserService } from '../../../core/services';


@Component({
    selector: 'alex-location-tree-item',
    templateUrl: './alex-location-tree-item.component.html',
    styleUrls: ['./alex-location-tree-item.component.scss']

})
export class AlexLocationTeeItemComponent implements OnInit {
    @Input() locationDetail: AlexLocationModel;
    @Input() isRootNode : boolean;
    constructor(private router: Router, private userService: AlexUserService) {

    }

    ngOnInit() {
    }

    navigetToBridgePage(node: AlexLocationModel) {
        if (node.id != null) {
            //this.router.navigate(["/bridge", node.id, node.id, { name: node.name }]);
            this.router.navigate(["/bridge", node.id, node.id], { queryParams: { d: this.userService.getActiveDomain().join(',') } });

        }
    }
}