import { AlexSourceFilterModel } from "app/feature/search/model/filters/source-filter.model";
import { AlexCheckBoxModel } from "app/feature/search/model/filters/checkbox.model";
import { AlexDateFilterModel } from "app/feature/search/model/filters/date-filter.model";
import { AlexTaxonomyNodeModel } from "app/feature/search/model/filters/taxonomy-node.model";
import { AlexSourceFilterViewModel } from "app/feature/search/model/filters/source-filter.viewmodel";


export class AlexAdvanceFilteViewModel {
  isRequired = true;
  q: string;
  sources: Array<AlexTaxonomyNodeModel>;
  topics: Array<AlexTaxonomyNodeModel>;
  //Add for Other Category
  otherCategories: Array<AlexTaxonomyNodeModel>;
  //Add for Other Category
  //Add for Country
  countries: Array<AlexTaxonomyNodeModel>;
  fileTypes: Array<AlexCheckBoxModel>;
  languages: Array<AlexCheckBoxModel>;
  date: AlexDateFilterModel;
  sort: string;
  //Added for Combined Search
  applicableCountries: Array<AlexCheckBoxModel>; 
  workflowType: Array<AlexCheckBoxModel>;
  version:Array<AlexCheckBoxModel>;
}