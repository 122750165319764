import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { AllSourcesTaxonomy } from "app/feature/header/services/all-sources.model";
import { AllSourcesService } from "app/feature/header/services/all-sources.service";
import { AlexAppService } from "app/core/services";
import { CkdSearchCountryModel } from "../ckd-search/models/ckd-search-country.model";
import { CkdSearchWorkflowModel } from "../ckd-search/models/ckd-search-workflow.model";
import { CkdSearchVersionModel } from "../ckd-search/models/ckd-search-version.model";
import { combineLatest, Observable, Subject } from "rxjs";
import { IManualsFiltersResponse } from "./interfaces";
import { CombinedSearchFiltersService } from "./services";
import { first, takeUntil, tap } from "rxjs/operators";
import { SearchParamService } from "../search/services/search-param.service";
import { VERSION_FILTER_PARAM } from "../ckd-search/constants/ckd-search-url.constants";
import {
  APPLICABLE_COUNTRY_FILTER_PARAM,
  AUDIT_STANDARD_FILTER_PARAM,
} from "../search/constants/search-url.constants";
import { Router } from "@angular/router";
import { KcwSearchPageService } from "../kcw-search/services/kcw-search-page.service";

@Component({
  selector: "alex-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class AlexHeaderComponent implements OnInit, OnDestroy {
  allSources: {
    allChecked: boolean;
    sources: AllSourcesTaxonomy[];
  };

  ckdSearchCountryData: {
    allChecked: boolean;
    sources: CkdSearchCountryModel[];
  };
  ckdSearchWorkflowData: {
    allChecked: boolean;
    sources: CkdSearchWorkflowModel[];
  };
  ckdSearchVersionData: {
    //allChecked: boolean;
    sources: CkdSearchVersionModel[];
  };
  isVisible: boolean;
  popupStatus: boolean;
  sourcesText = "";
  status = "";
  deviceType = "";

  /**
   * parameter to track whether kcw search box should be enabled
   */
  readonly enableKcwSearch$: Observable<boolean>;

  // combined search
  private countries$: Observable<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  >;
  private workflowTypes$: Observable<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  >;
  private versions$: Observable<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  >;
  private sources$: Observable<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  >;
  isManualsSelected$: Observable<boolean>;

  sources: {
    label: string;
    value: string;
    isChecked: boolean;
  }[] = [];
  countries: {
    label: string;
    value: string;
    isChecked: boolean;
  }[] = [];
  workflowTypes: {
    label: string;
    value: string;
    isChecked: boolean;
  }[] = [];
  versions: {
    label: string;
    value: string;
    isChecked: boolean;
  }[] = [];

  showDropdown = false;
  isAllSourcesSelected = true;

  private readonly manualsFilters$: Observable<IManualsFiltersResponse>;
  private readonly destroy$: Subject<any> = new Subject<any>();
  private combinedSearchEvent: Event;

  constructor(
    private allSourcesService: AllSourcesService,
    public appService: AlexAppService,
    private filtersService: CombinedSearchFiltersService,
    private searchParamService: SearchParamService,
    private router: Router,
    private readonly kcwSearchPageService: KcwSearchPageService
  ) {
    // combined search
    this.countries$ = this.filtersService.getCountries();
    this.workflowTypes$ = this.filtersService.getWorkFlowTypes();
    this.versions$ = this.filtersService.getVersions();
    this.sources$ = this.filtersService.getSources();
    this.isManualsSelected$ = this.filtersService.getIsManualsSelected();
    this.manualsFilters$ = this.filtersService.fetchKaegFilters();

    // set kcw search box activation state
    this.enableKcwSearch$ = this.kcwSearchPageService.getIsValid();
  }

  ngOnInit() {
    //this.getAllTaxonomySources();
    this.deviceType = this.appService.getDeviceType();

    this.allSourcesService.getAllSourcesTaxonomy$
      .pipe(takeUntil(this.destroy$))
      .subscribe((allSources) => {
        this.allSources = allSources;
        const sources = allSources.sources.map((s) => ({
          label: s.name,
          value: s.id.toString(),
          isChecked: allSources.allChecked,
        }));
        this.filtersService.setSources(sources);
        this.filtersService.handleSourcesSelectionChange(["All"]);
      });

    // combined search
    this.manualsFilters$.pipe(takeUntil(this.destroy$)).subscribe();
    this.sources$
      .pipe(
        tap((sources) => (this.sources = sources)),
        tap((sources) => {
          if (Array.isArray(sources) && sources.length > 0) {
            this.isAllSourcesSelected = sources.every((s) => s.isChecked);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.countries$
      .pipe(
        tap((countries) => (this.countries = countries)),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.workflowTypes$
      .pipe(
        tap((workflowTypes) => (this.workflowTypes = workflowTypes)),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.versions$
      .pipe(
        tap((versions) => {
          this.versions = versions;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // combined search
  onSourcesChange(sources: string[]): void {
    this.filtersService.handleSourcesSelectionChange(sources);
  }

  onCountryChange(countries: string[]): void {
    this.filtersService.handleCountriesSelectionChange(countries, true);
  }

  onWorkflowTypesChange(workflowTypes: string[]): void {
    this.filtersService.handleWorkflowTypesSelectionChange(workflowTypes);
  }

  onVersionsChange(versions: string[]): void {
    this.filtersService.handleVersionsSelectionChange(versions);
  }

  onSearchClick(): void {
    this.showDropdown = false;
    this.handleSearchClick();
  }

  //for kcw search
  onKcwSearchClick(searchTerm: string) {
    this.handleKcwSearchClick(searchTerm);
  }

  onResetClick(): void {
    this.filtersService.handleResetClick();
  }

  allSourceDClick(allSourceEvent) {
    this.trackEvent(allSourceEvent);
    this.toggleDropdown();
  }

  deliverSourceTitle($event) {
    this.sourcesText = $event.text;
    this.status = $event.state;
  }

  handleFiltersOutsideClick(): void {
    if (this.showDropdown) {
      this.toggleDropdown();
    }
  }

  trackEvent(newHostEvent) {
    this.combinedSearchEvent = newHostEvent;
  }

  @HostListener("document:click", ["$event"])
  compareEvent(globalEvent) {
    if (!this.showDropdown) {
      return;
    }
    if (this.combinedSearchEvent !== globalEvent) {
      this.toggleDropdown();
    }
  }

  private toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  private handleSearchClick() {
    const searchText = this.searchParamService.filterParam.q;

    const selectedCountries = this.filtersService.getSelectedCountries();
    const selectedWorkflowTypes = this.filtersService.getSelectedWorkflowTypes();
    const selectedVersions = this.filtersService.getSelectedVersions();
    const selectedSources = this.filtersService.getSelectedSources();
    const isAllSourcesSelected =
      selectedSources.length === 1 && selectedSources[0] === "All";
    const domains = this.filtersService.getDomains().join(",");
    let matrixParams = this.searchParamService.getRouteParam();
    let queryParams: {
      q: string;
      d: string;
    } = {
      q: searchText,
      d: domains,
    };

    if (isAllSourcesSelected) {
      matrixParams.als = 1;
      delete matrixParams.sources;
    } else {
      matrixParams.als = 0;
      if(selectedSources.length > 0){
        matrixParams.sources = JSON.stringify(
          selectedSources.map((s, i) => ({ k: s, o: i + 1 }))
        );
      }else{
        matrixParams.als = 1;
        delete matrixParams.sources;
      } 
    }

    const selectedSourcesSet = new Set<string>(selectedSources);
    const isManuals = isAllSourcesSelected || selectedSourcesSet.has("0");
    if (isManuals) {
      if (
        Array.isArray(selectedCountries) &&
        !(selectedCountries.length === 1 && selectedCountries[0] === "All")
      ) {
        const countries = JSON.stringify(
          selectedCountries.map((s, i) => ({ k: s, o: i + 1 }))
        );
        matrixParams = {
          ...matrixParams,
          [APPLICABLE_COUNTRY_FILTER_PARAM]: countries,
        };
      } else {
        delete matrixParams[APPLICABLE_COUNTRY_FILTER_PARAM];
      }

      if (
        Array.isArray(selectedWorkflowTypes) &&
        !(
          selectedWorkflowTypes.length === 1 &&
          selectedWorkflowTypes[0] === "All"
        )
      ) {
        const workflowTypes = JSON.stringify(
          selectedWorkflowTypes.map((s, i) => ({ k: s, o: i + 1 }))
        );
        matrixParams = {
          ...matrixParams,
          [AUDIT_STANDARD_FILTER_PARAM]: workflowTypes,
        };
      } else {
        delete matrixParams[AUDIT_STANDARD_FILTER_PARAM];
      }

      if (
        Array.isArray(selectedVersions) &&
        !(selectedVersions.length === 1 && selectedVersions[0] === "All")
      ) {
        const versions = JSON.stringify(
          selectedVersions.map((s, i) => ({ k: s, o: i + 1 }))
        );
        matrixParams = {
          ...matrixParams,
          [VERSION_FILTER_PARAM]: versions,
        };
      } else {
        delete matrixParams[VERSION_FILTER_PARAM];
      }
    } else {
      delete matrixParams[APPLICABLE_COUNTRY_FILTER_PARAM];
      delete matrixParams[AUDIT_STANDARD_FILTER_PARAM];
      delete matrixParams[VERSION_FILTER_PARAM];
    }

    this.router.navigate(["/search", matrixParams], {
      queryParams,
    });
    this.searchParamService.clearAllFilter();
  }

  //for kcw search
  handleKcwSearchClick(searchTerm: string): void {
    combineLatest([
      this.kcwSearchPageService.getRuleSetId(),
      this.kcwSearchPageService.getScreenName(),
    ])
      .pipe(
        tap(([ruleSetId, screenName]) => {
          if (!ruleSetId) {
            return;
          }
          this.router.navigate([`/kcwsearch/${ruleSetId}/search`], {
            queryParams: {
              q: searchTerm || "",
              screenName,
            },
          });
        }),
        first()
      )
      .subscribe();
  }
}
