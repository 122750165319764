import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  onTokenUpdate$ = new Subject<void>();
  constructor() { }

  getTokenKey(tokenName:string) {
    let tokenKey = null;
    let itemArr =[];
    let sessionStorageItems = {...sessionStorage};
    Object.keys(sessionStorageItems).forEach(item => {
       itemArr = item.split('-');
      if(itemArr.includes(tokenName)){
        tokenKey = item
      }
    })
    return tokenKey;
    
  }

  getTokenValue(tokenName:string) {
    let tokenKey = this.getTokenKey(tokenName);
    return JSON.parse(sessionStorage.getItem(tokenKey));
  }

  
}
