export class AlexPaginationModel {
  isRequired: boolean;
  startPage: number;
  itemsPerPage: number;
  recordCount: number;
  endPage: number;
  currentPage: number;
  lastVisitedPage: number;
  pageNumberDisplayList: Array<number>;
  constructor() {
    this.startPage = 1;
    this.currentPage = 1;
    this.lastVisitedPage = 1;
    this.pageNumberDisplayList = new Array<number>();
    this.isRequired = false;
    this.itemsPerPage = 25;
  }
}
