
import {of as observableOf,  Observable } from 'rxjs';

import {first, filter,  switchAll } from 'rxjs/operators';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute, CanActivateChild } from "@angular/router";
import { AlexDocumentService } from "app/feature/long-form-content/document/document.service";
import { AlexDocumentModel } from "app/feature/long-form-content/document/document.model";
import { Injectable, NgZone } from "@angular/core";
import { SearchParamService } from "app/feature/search/services/search-param.service";
import { RecentDocument } from "app/feature/header/recent-search/recent-document.model";
import { AlexUrlResolverService, AlexNavigationService, Status } from "app/core/services";
import { AlexFileService } from "app/core/services/file/alex-file.service";
import { SpinnerService } from "../../../shared/components/spinner/spinner.service";
import { AlexNotificationService } from "../../../shared/components/notification/notification.service";
import { HttpClient } from "@angular/common/http";


@Injectable()
export class BinaryDownloadGuard implements Resolve<AlexDocumentModel>, CanActivate, CanActivateChild {
    processingStatus = {
        docId: "",
        url: "",
        type: "",
        isBinary: false,
        doc: null,
        done: false,
        error: false,
        navigationId: 0
    }

    constructor(private documentService: AlexDocumentService
        , private router: Router,
        private searchParam: SearchParamService,
        private route: ActivatedRoute,
        private urlResolver: AlexUrlResolverService,
        private fileService: AlexFileService,
        private spinnerService: SpinnerService,
        private navigationService: AlexNavigationService,
        private zone: NgZone,
        private notification: AlexNotificationService,
        private http: HttpClient) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return new Observable<boolean>((observer) => {
            this.spinnerService.showSpinnerAsync().then(spinnerId => {
                this.processingStatus.navigationId = this.navigationService.getNavigationId();
                //If we are processing same request consecutively  then we can use reuse data of last request
                if (this.processConsecutiveRequest(observer, spinnerId)) {
                    return true;
                }
                this.processingStatus.error = false;
                this.extractUrlParts(state.url);
                //For top no need to go further. just continue with the route
                if (this.processingStatus.type == "top") {
                    observer.next(true);
                    observer.complete();
                    return this.spinnerService.hideSpinner(spinnerId);
                }
                this.processNewRequest(observer, state, spinnerId);
            })
        })
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (!this.isProcessingRequiredForChild(state.url) || this.processingStatus.navigationId == this.navigationService.getNavigationId()) {
            return true;
        } else {
            console.log('handled by child');
            this.processingStatus.navigationId = this.navigationService.getNavigationId();
            return new Observable<boolean>((observer) => {
                this.spinnerService.showSpinnerAsync().then(spinnerId => {
                    //If we are processing same request consecutively  then we can use reuse data of last request
                    if (this.processConsecutiveRequest(observer, spinnerId)) {
                        return true;
                    }
                    this.processingStatus.error = false;
                    this.extractUrlParts(state.url);
                    this.processNewRequest(observer, state, spinnerId);
                });

            });
        }
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AlexDocumentModel> {
        return observableOf(this.processingStatus.doc);
    }

    isProcessingRequired(state: RouterStateSnapshot) {
        if (state.url == this.processingStatus.url && !this.processingStatus.error) {
            return false;
        }
        return true;
    }
    extractUrlParts(url: string) {
        try {
            this.processingStatus.url = url;
            this.processingStatus.type =
                /document\/lfc\/find\//i.test(url) ? 'lfcfind' : /document\/(.*?)\//i.exec(url)[1];
            if (/\/tocsearch\//.exec(url)) {
                this.processingStatus.docId = /.*\/(.*?)\/tocsearch/i.exec(url)[1];
            } else {
                this.processingStatus.docId = this.processingStatus.type == 'lfcfind' ?
                    /find\/(.*?)(?:\/|\?|$)/i.exec(url)[1]
                    : /.*\/(.+?)(?:\/|\?|$)/i.exec(url)[1];
            }
        } catch (error) {
            this.processingStatus.error = true;
        }
    }
    isBinaryDocument(doc: any) {
        return doc.binaryPath;
    }

    processConsecutiveRequest(observer, spinnerId) {
        if (!this.isProcessingRequired && this.processingStatus.isBinary) {
            this.fileService.downloadOrOpenFile(
                this.processingStatus.doc.fileName,
                this.processingStatus.doc.binaryContent,
                this.processingStatus.doc.mimeType);
            observer.next(false);
            observer.complete();
            this.spinnerService.hideSpinner(spinnerId);
            return true;
        }
        return false;
    }

    processNewRequest(observer, state, spinnerId) {
        if (!this.processingStatus.error) {
            //patch for ie memory issue
            const subscription = this.zone.onError.pipe(
                filter(({ message }) => /^Not enough storage/i.test(message)),
                first(),)
                .subscribe(error => {
                    this.processingStatus.error = true;
                    observer.next(false);
                    observer.complete();
                    //this.notification.show(Status.Failure, error, true);
                    this.notification.showApiError(Status.Failure, 'Oops! something went wrong');
                    this.spinnerService.hideSpinner(0, true);
                });
            const fetchDocument =
                (this.processingStatus.type == "lfc"
                    ? this.documentService.fetchSingleDocument
                    : this.documentService.fetchSingleDocumentWithSecOrPara).bind(this.documentService);

            fetchDocument(this.processingStatus.docId)
                .pipe(first()).subscribe(doc => {
                    this.processingStatus.doc = doc;
                    let continueWithRoute = false;
                    try {
                        if (continueWithRoute = !this.isBinaryDocument(doc)) {
                            //add to recent document
                            this.addRecentDocument(doc.documentName, state.url);
                        } else {
                            this.processingStatus.isBinary = true;
                             // EXCEL MACRO ISSUE FIX START
                if (doc.mimeType === "application/vnd.ms-excel") {
                    // memory stream download
                  
                    const sub = this.documentService
                      .fetchMemStream(doc)
                      .subscribe((res) => {
                        this.fileService.downloadOrOpenFile(
                          doc.fileName,
                          (<any>res)._buffer,
                          doc.mimeType
                        );
                        sub.unsubscribe();
                      });
                  } else {
                    this.fileService.downloadOrOpenFile(
                      doc.fileName,
                      doc.binaryContent,
                      doc.mimeType
                    );
                  }
                //   // EXCEL MACRO ISSUE FIX END
                            //this.fileService.downloadOrOpenFile(doc.fileName, doc.binaryContent, doc.mimeType);
                            if (this.navigationService.isFirstNavigation()) {
                                setTimeout(_ => this.router.navigateByUrl('home'), 0);
                            }
                        }
                    } catch (error) {
                        continueWithRoute = false;
                        console.log('Error occurred in document resolve guard');
                        console.error(error);
                    }
                    observer.next(continueWithRoute);
                    observer.complete();
                    this.spinnerService.hideSpinner(spinnerId);
                    subscription && subscription.unsubscribe();
                }, error => {
                    this.processingStatus.error = true;
                    observer.next(false);
                    observer.complete();
                    this.spinnerService.hideSpinner(0, true);
                    subscription && subscription.unsubscribe();
                }, () => {
                    subscription && subscription.unsubscribe();
                    observer.complete();
                    this.spinnerService.hideSpinner(spinnerId);
                })
        } else {
            observer.next(false);
            observer.complete();
            this.spinnerService.hideSpinner(spinnerId);
        }
    }

    addRecentDocument(title: string, url: string) {
        const recentDoc = new RecentDocument();
        recentDoc.documentName = title;
        recentDoc.reference = url;
        this.http
            .post(this.urlResolver.resolveUrlApiV1(`user/AddRecentDocument`), recentDoc).pipe(
            first()).subscribe();
    }

    isProcessingRequiredForChild(url) {
        return !/tocsearch\//i.exec(url);
    }

}