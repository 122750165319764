
import {tap, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { AlexUrlResolverService } from "app/core/services";
import { Subject } from "rxjs";
import { CkdSearchCountryModel } from "../models/ckd-search-country.model";
import { CkdSearchCountryWorkflowModel } from "../models/ckd-search-country-workflow.model";
import { CkdSearchWorkflowModel } from "../models/ckd-search-workflow.model";
import { CKDSearchParamService } from "./ckd-search-param.service";
import { CkdSearchVersionModel } from "../models/ckd-search-version.model";

// import "rxjs/add/operator/do";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class CkdSearchCountryWorkflowSelectorService {
  selectedCountry: string;
  selectedWorkflow: Array<string>;
  selectedVersions: Array<string>;
  ckdSearchcountryData: CkdSearchCountryModel[];
  ckdSearchWorkflowData: CkdSearchWorkflowModel[];
  ckdSearchversionData: CkdSearchVersionModel[];
  ckdSearchcountryStatus$: Subject<boolean>;
  ckdSearchWorkflowStatus$: Subject<boolean>;
  ckdSearchVersionStatus$: Subject<boolean>;
  getCkdSearchcountry$: Subject<{
    allChecked: boolean;
    sources: CkdSearchCountryModel[];
  }>;
  getCkdSearchWorkflow$: Subject<{
    allChecked: boolean;
    sources: CkdSearchWorkflowModel[];
  }>;
  getCkdSearchversion$: Subject<{
    sources: CkdSearchVersionModel[];
  }>;
  fetched = false;
  versionFetched = false;
  allSourceChecked = false;
  countryPlaceholderTxt: string;
  workflowPlaceholderTxt: string;
  versionPlaceholderTxt: string;
  searchConfig: any;
  defaultVersion: number;

  private defaultWorkflowType: string;
  private countryValid: boolean;
  private workFlowTypesValid: boolean;
  private versionValid: boolean;

  private countrySet: Set<string>;
  private workflowsSet: Set<String>;
  private versionSet: Set<String>;

  public get areParametersValid(): boolean {
    return this.countryValid && this.workFlowTypesValid && this.versionValid;
  }

  constructor(
    private http: HttpClient,
    private urlResolver: AlexUrlResolverService,
    private searchParam: CKDSearchParamService
  ) {
    this.searchConfig = this.urlResolver.getManualSearchConfig() || {};
    this.ckdSearchcountryStatus$ = new Subject<boolean>();
    this.ckdSearchWorkflowStatus$ = new Subject<boolean>();
    this.ckdSearchVersionStatus$ = new Subject<boolean>();

    this.getCkdSearchcountry$ = new Subject<{
      allChecked: boolean;
      sources: CkdSearchCountryModel[];
    }>();
    this.getCkdSearchWorkflow$ = new Subject<{
      allChecked: boolean;
      sources: CkdSearchWorkflowModel[];
    }>();
    this.getCkdSearchversion$ = new Subject<{
      sources: CkdSearchVersionModel[];
    }>();
    this.ckdSearchcountryStatus$.next(false);
    this.ckdSearchWorkflowStatus$.next(false);
    this.ckdSearchVersionStatus$.next(false);
    this.getCkdSearchcountry$.next({ allChecked: false, sources: [] });
    this.getCkdSearchWorkflow$.next({ allChecked: false, sources: [] });
    this.getCkdSearchversion$.next({ sources: [] });
    this.selectedWorkflow = [];
    this.selectedWorkflow = [];
    this.selectedVersions = [];
    this.defaultWorkflowType = this.searchConfig.defaultWorkflow;
  }

  getckdSearchcountry() {
    return this.http
      .get(
        this.urlResolver.resolveUrlApiV1(`CKDSearch/CountriesAndWorkflowtypes`)
      ).pipe(
      map((response) => {
        return response as CkdSearchCountryWorkflowModel;
      }),
      tap((data) => {
        this.ckdSearchcountryData = data.manualsCountryModelList.map((item) =>
          Object.assign(item, {
            checked: this.selectedCountry === item.name,
          })
        );

        // create country values set
        this.countrySet = new Set(
          data.manualsCountryModelList.map((item) => item.name)
        );
        // add default country to country set
        this.countrySet.add(this.searchConfig.defaultCountryDisplayTxt);

        this.ckdSearchWorkflowData = data.manualsWorkFlowTypeModelList.map(
          (item) =>
            Object.assign(item, {
              checked: this.selectedWorkflow.includes(item.name),
            })
        );

        // create workflow types set
        this.workflowsSet = new Set(
          data.manualsWorkFlowTypeModelList.map((item) => item.name)
        );
        // add default workflow to workflow types set
        this.workflowsSet.add(this.defaultWorkflowType);

        this.getCkdSearchcountry$.next({
          allChecked:
            this.ckdSearchcountryData.findIndex(
              (item) => item.checked === true
            ) === -1,
          sources: this.ckdSearchcountryData,
        });
        this.getCkdSearchWorkflow$.next({
          allChecked: this.ckdSearchWorkflowData.every((item) => !item.checked),
          sources: this.ckdSearchWorkflowData,
        });
        this.fetched = true;
        this.selectedCountry = "";
        this.selectedWorkflow = [];
      }),);
  }

  getManualVersions() {
    return this.http
      .get(this.urlResolver.resolveUrlApiV1(`CKDSearch/ActiveVersions`)).pipe(
      map((response) => {
        return response as string[];
      }),
      tap((data) => {
        this.ckdSearchversionData = data.map((item) =>
          Object.assign(item, {
            id: item,
            name: item,
            checked: false,
          })
        );

        // create versions set
        this.versionSet = new Set(
          data.filter((version) => !Number.isNaN(+version))
        );

        // set default version
        this.defaultVersion = Math.max(
          ...data
            .map((versionStr) => +versionStr)
            .filter((version) => !Number.isNaN(version))
        );

        this.getCkdSearchversion$.next({ sources: this.ckdSearchversionData });
        this.versionFetched = true;
      }),);
  }

  updateCountrySelection(selectedCountry: string) {
    this.countryValid = false;

    if (this.ckdSearchcountryData) {
      const ckdSearchCountryModel = new CkdSearchCountryModel();

      // check if country is valid
      if (this.isValidCountry(selectedCountry)) {
        this.countryValid = true;
        this.selectedCountry = selectedCountry;
        this.countryPlaceholderTxt = selectedCountry;
        ckdSearchCountryModel.name = selectedCountry;
      }
      // country is invalid
      else {
        this.countryPlaceholderTxt = this.searchConfig.invalidCountryDisplayTxt;
        ckdSearchCountryModel.name = this.searchConfig.invalidCountryDisplayTxt;
      }
      this.updateCountryListPanel(ckdSearchCountryModel);
    }
  }

  private isValidCountry(selectedCountry: string): boolean {
    return selectedCountry && this.countrySet.has(selectedCountry);
  }

  updateWorkflowSelection(selectedWorkflow: Array<string>) {
    this.workFlowTypesValid = false;

    if (selectedWorkflow) {
      this.selectedWorkflow = selectedWorkflow;

      // check if workflow types are valid
      if (this.isVaidWorkflow(selectedWorkflow)) {
        this.workFlowTypesValid = true;
        // check if workflow types have default workflow
        if (this.isDefaultWorkFlow(selectedWorkflow)) {
          this.workflowPlaceholderTxt = this.searchConfig.defaultWorkflowDisplayTxt;
        }
        // workflow types contain selected workflows
        else {
          this.workflowPlaceholderTxt = this.searchConfig.selectedWorkflowDisplayTxt;
        }

        // update valid workflow types search param field
        this.searchParam.setValidWorkflowTypesFilter(
          this.getValidWorkflowTypes(selectedWorkflow)
        );
      }
      // workflow types are invalid
      else {
        this.workflowPlaceholderTxt = this.searchConfig.invalidWorkflowDisplayTxt;
      }
      this.updateWorkflowListPanel(selectedWorkflow);
    }
  }
  private isDefaultWorkFlow(selectedWorkflow: Array<string>): boolean {
    return (
      Array.isArray(selectedWorkflow) &&
      selectedWorkflow.includes(this.defaultWorkflowType)
    );
  }
  private isVaidWorkflow(selectedWorkflow: Array<string>): boolean {
    return (
      Array.isArray(selectedWorkflow) &&
      selectedWorkflow.some((userWorkFlowType) =>
        this.workflowsSet.has(userWorkFlowType)
      )
    );
  }
  private getValidWorkflowTypes(
    selectedWorkflow: Array<string>
  ): Array<string> {
    return Array.isArray(selectedWorkflow)
      ? this.selectedWorkflow.filter((workFlowType) =>
          this.workflowsSet.has(workFlowType)
        )
      : [];
  }

  updateVersionSelection(selectedVersion: Array<string>) {
    this.versionValid = false;
    this.selectedVersions = selectedVersion;

    // check if versions are valid
    if (this.isVaidVersion(selectedVersion)) {
      this.versionValid = true;
      this.versionPlaceholderTxt = this.searchConfig.selectedVersionDisplayTxt;
      this.searchParam.setValidVersions(this.getValidVersions(selectedVersion));
    }
    // versions are invalid
    else {
      this.versionPlaceholderTxt = this.searchConfig.invalidVersionDisplayTxt;
    }
    this.updateVersionListPanel(this.selectedVersions);
  }

  private isVaidVersion(selectedVersion: Array<string>): boolean {
    return (
      Array.isArray(selectedVersion) &&
      selectedVersion.some((userVersion) => this.versionSet.has(userVersion))
    );
  }

  private getValidVersions(selectedVersions: Array<string>): Array<string> {
    return Array.isArray(selectedVersions)
      ? selectedVersions.filter((userVersion) =>
          this.versionSet.has(userVersion)
        )
      : [];
  }
  updateCountryListPanel(selectedCountry: CkdSearchCountryModel) {
    selectedCountry.checked = true;
    if (this.ckdSearchcountryData) {
      let sources = this.ckdSearchcountryData.map((item) => {
        item.checked = item.name == selectedCountry.name;
        return item;
      });
      this.getCkdSearchcountry$.next({
        allChecked: this.countryValid && !sources.some((item) => item.checked),
        sources: sources,
      });
    }
  }
  updateWorkflowListPanel(selectedItems: Array<string>) {
    if (this.ckdSearchWorkflowData) {
      let selectedWorkflow = this.ckdSearchWorkflowData.map((item) => {
        if (selectedItems.includes(item.name)) {
          item.checked = true;
        }
        return item;
      });
      this.getCkdSearchWorkflow$.next({
        allChecked:
          this.workFlowTypesValid &&
          !selectedWorkflow.some((item) => item.checked),
        sources: selectedWorkflow,
      });
    }
  }
  updateVersionListPanel(selectedItems: Array<string>) {
    if (this.ckdSearchversionData) {
      const selectedVersion = this.ckdSearchversionData.map((item) => {
        if (selectedItems.includes(item.name)) {
          item.checked = true;
        }
        return item;
      });
      this.getCkdSearchversion$.next({
        //allChecked: !selectedVersion.some(item=> item.checked),
        sources: selectedVersion,
      });
    }
  }
}
