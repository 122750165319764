import { Subject } from "rxjs";
import { AlexTaskTrackerModel } from "app/core/services/task-tracker/task-tracker.model";
import { Injectable } from "@angular/core";

@Injectable()
export class AlexTaskTrackerService {
    private taskTrackers: Array<AlexTaskTrackerModel>;

    constructor() {
        this.taskTrackers = new Array<AlexTaskTrackerModel>();
    }

    setTaskQueue(context: string, tasks: Array<string>, removeExistingSubscription = true): Subject<string> {
        let taskTracker = this.taskTrackers.find(taskTracker => taskTracker.context == context)
        if (!taskTracker) {
            taskTracker = new AlexTaskTrackerModel();
            taskTracker.id = +new Date;
            taskTracker.context = context;
            taskTracker.allTaskCompletionNotification$ = new Subject<string>()
            this.taskTrackers.push(taskTracker);
        }
        if (removeExistingSubscription) {
            taskTracker.allTaskCompletionNotification$.observers = [];
        }
        taskTracker.tasks = tasks;
        return taskTracker.allTaskCompletionNotification$;
    }
    clearTaskQueue(context: string) {
        const taskTracker = this.taskTrackers.find(taskTracker => taskTracker.context == context);
        if (taskTracker && taskTracker.tasks) {
            taskTracker.tasks = new Array<string>();
        }
    }
    getPendingTaskQueue(context: string) {
        const taskTracker = this.taskTrackers.find(taskTracker => taskTracker.context == context);
        if (taskTracker) {
            return taskTracker.tasks || new Array<string>();
        }
        return new Array<string>();
    }
    addTasks(context: string, tasks: Array<string>) {
        const taskTracker = this.taskTrackers.find(taskTracker => taskTracker.context == context);
        if (taskTracker) {
            taskTracker.tasks = taskTracker.tasks || new Array<string>();
            taskTracker.tasks.push(...tasks);
        }
    }
    markTaskAsComplete(contexts: Array<string> | string, taskName: string) {
        if (!contexts)
            return;

        contexts = Array.isArray(contexts) ? contexts : [contexts];
        for (let context of contexts) {
            const taskTracker = this.taskTrackers.find(taskTracker => taskTracker.context == context);

            if (taskTracker && taskTracker.tasks && taskTracker.tasks.length && !!~taskTracker.tasks.indexOf(taskName)) {
                const completedTaskName = taskTracker.tasks.splice(taskTracker.tasks.indexOf(taskName), 1);
                if (!taskTracker.tasks.length) {
                    taskTracker.allTaskCompletionNotification$.next(context);
                }
            }
        }
    }
    removeContext(context: string) {
        const index = this.taskTrackers.findIndex(task => task.context == context);
        if (!!~index) {
            this.taskTrackers[index].allTaskCompletionNotification$.observers = [];
            this.taskTrackers.splice(index, 1);
        }
    }
    contextExists(context: string) {
        return this.taskTrackers.find(task => task.context == context);
    }



}