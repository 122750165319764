import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AlexPages } from "../components/help/help.enum";

@Injectable({
  providedIn: "root",
})
export class CurrentPageService {
  private currentPage$: BehaviorSubject<AlexPages> = new BehaviorSubject<AlexPages>(
    AlexPages.home
  );

  getCurrentPage(): Observable<AlexPages> {
    return this.currentPage$.asObservable();
  }

  setCurrentPage(page: AlexPages): void {
    this.currentPage$.next(page);
  }
}
