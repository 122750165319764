<div class="modal-header">
  <h2 class="modal-title modalHdrTxt abs-pos">Welcome Tour</h2>

  <button
    type="button"
    class="close modalClose"
    data-dismiss="modal"
    (click)="close()"
  >
    <span>
      <svg
        class="svgIm ModalcloseIcon CloseIcon greyColor2"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="30px"
        height="1px"
        viewBox="0 0 50 1"
        xml:space="preserve"
      >
        <g transform="scale(1.7)">
          <g
            transform="translate(-155,-980.5)"
            xmlns="http://www.w3.org/2000/svg"
            id="remove-sign_2_"
          >
            <path
              d="M176.662,975.338c-4.784-4.784-12.54-4.784-17.324,0c-4.784,4.784-4.784,12.54,0,17.324c4.784,4.784,12.54,4.784,17.324,0
                    C181.445,987.878,181.446,980.122,176.662,975.338z M171.535,989.656c-0.194,0.195-0.513,0.195-0.707,0L168,986.828l-2.828,2.828
                    c-0.194,0.195-0.513,0.195-0.707,0l-2.121-2.121c-0.195-0.193-0.195-0.512,0-0.707l2.828-2.828l-2.828-2.828
                    c-0.195-0.195-0.195-0.514,0-0.707l2.121-2.121c0.194-0.195,0.512-0.195,0.707,0l2.828,2.828l2.828-2.828
                    c0.195-0.195,0.513-0.195,0.707,0l2.121,2.121c0.195,0.193,0.195,0.512,0,0.707L170.828,984l2.828,2.828
                    c0.195,0.195,0.195,0.514,0,0.707L171.535,989.656z"
            />
          </g>
        </g>
      </svg>
    </span>
  </button>
</div>
<div class="modal-body">
  <div class="onboarding">
    <div class="onboardingContainer clearfix">
      <div
        class="contentAreamain clearfix"
        id="one"
        #test
        [@visibilityChanged]="transitionState"
      >
        <h2 class="mobileHeader">{{ currentTitle }}</h2>
        <div class="onboardingImgLeft">
          <img class="img-fluid" src="{{ currentImagePath }}" />
        </div>
        <div class="onboardingText">
          <h2 class="desktopHeader">{{ currentTitle }}</h2>
          <div lass="spotellipsis" [innerHTML]="currentDesc"></div>
          <!--<p class="spotellipsis">
            {{currentDesc}}
          </p>-->
        </div>
      </div>
      <div class="float-right">
        <button
          type="button"
          #backBtn
          class="btn onBoardingBtn back"
          [disabled]="!isBackValid"
          (click)="back()"
        >
          Back
        </button>
        <button
          type="button"
          #nextBtn
          class="btn onBoardingBtn next"
          (click)="next()"
          *ngIf="!changeEvent"
        >
          Next
        </button>
        <button
          type="button"
          class="btn onBoardingBtn next"
          data-dismiss="modal"
          (click)="close()"
          *ngIf="changeEvent"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
