import { Component, OnInit, Input } from '@angular/core';
import { AlexDocumentType } from "app/core/services/app/app.constant";
import { SvgIconList } from "app/core/services/icon-finder/icon-list.model";
import { DocumentSpaceHeaderService } from "app/shared/components/document-space-header/document-space-header.service";

@Component({
  selector: 'alex-document-space-header',
  templateUrl: './document-space-header.component.html',
  styleUrls: ['./document-space-header.component.scss'],
  providers: [DocumentSpaceHeaderService]
})
export class DocumentSpaceHeaderComponent implements OnInit {
  @Input() type: string;
  @Input() mimeType: string;
  iconType: string;

  constructor(private documentSpaceHeaderService: DocumentSpaceHeaderService) { }

  ngOnInit() {
    this.iconType = this.documentSpaceHeaderService.chooseIcon(this.type, this.mimeType);
  }
}
