import { Component, OnInit, TemplateRef } from "@angular/core";
import { HelpService } from "app/shared/components/help/help.service";
import { Router } from "@angular/router";
import { CurrentUsrStatus } from "app/core/services/app/app.constant";
import { SubscriptionConstant } from "app/shared/components/subscription/subcription.constant";
import { SubscriptionService } from "app/shared/components/subscription/subscription.service";
import {
  AlexUserService,
  AlexUrlResolverService,
  AlexNavigationService,
} from "app/core/services";
import { UserLicense } from "app/core/services/user/user-license.model";
import { AlexPages } from "app/shared/components/help/help.enum";
import { ModalContentService } from "app/shared/modal-content.service";

@Component({
  selector: "alex-unauthorized",
  templateUrl: "./unauthorized.component.html",
  styleUrls: ["./unauthorized.component.scss"],
})
export class UnauthorizedComponent implements OnInit {
  breadCrumb;
  unauthorizedError: string = "Unauthorized";
  userLicence: UserLicense;
  isExternalUser: boolean = true;
  CurrentUsrStatus = CurrentUsrStatus;
  SubscriptionConstant = SubscriptionConstant;
  extUserLoginUrl: string;
  currentPage: AlexPages = AlexPages.home;
  siteAdmintUrl: string;
  intWarningHeader: string;
  extWarningHeader: string;
  extWarningPara: string;
  warningMsg: any;

  constructor(
    private helpService: HelpService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private userService: AlexUserService,
    private urlResolverService: AlexUrlResolverService,
    private navigationService: AlexNavigationService,
    private modalContentService: ModalContentService
  ) {
    this.extUserLoginUrl = this.urlResolverService.getExtUserLoginUrl();
    this.siteAdmintUrl = this.urlResolverService.getSitAdminUrl();
    this.warningMsg = this.urlResolverService.getResourcesForPageByKey(
      "unauthorized"
    );
    this.navigationService.resetRouteCollection();
  }

  ngOnInit() {
    this.breadCrumb = "";
    this.userService.user$.subscribe((user) => {
      this.userLicence = user.userLicenseModel;
      this.isExternalUser = user.isExternalUser;
    });
  }

  showHelpModal(templateRef: TemplateRef<any>) {
    this.helpService.lastRouteUrl = this.router.url;
    this.modalContentService.openModal(templateRef, "helpModal");
  }

  goToLogin() {
    window.location.href = this.extUserLoginUrl;
  }

  reset() {
    this.modalContentService.resetModal();
  }
}
