import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { DatePickerOptions } from "app/shared/components/datePicker/datePicker.options.model";
import * as moment from "moment";
import { AlexCalendarModel } from "app/shared/components/datePicker/calendar.model";
import { FormControl } from '@angular/forms';

@Component({
  selector: 'alex-datepicker',
  templateUrl: 'datePicker.component.html',
  styleUrls: ['datePicker.component.scss']
})
export class DatePickerComponent implements OnInit {

  @Input() options: DatePickerOptions;
  @Output() onDateChange = new EventEmitter<AlexCalendarModel>();
  constructor() {
    this.options = this.options || new DatePickerOptions();
  }

  ngOnInit(): void {
    this.options.name = this.options.name || '';
    this.options.format = this.options.format || 'DD MMM YYYY';
    this.options.value = this.options.value || moment().format(this.options.format);
    this.options.value = moment(this.options.value).toISOString();

  }

  dateChange() {
    this.onDateChange.emit({
      name: this.options.name,
      date: moment(this.options.value).format(this.options.format)
    });
  }

  validateDate = (date) => {
    if (this.options.maxValue && moment(date).startOf('date').isAfter(moment(this.options.maxValue).startOf('date')))
      return false;

    if (this.options.minValue && moment(date).startOf('date').isBefore(moment(this.options.minValue).startOf('date')))
      return false;

    return true;
  }

}