import {
  throwError as observableThrowError,
  Observable,
  Subject,
  AsyncSubject,
  BehaviorSubject,
} from "rxjs";

import { map } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { Space } from "./model/space.model";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";

import { AlexUrlResolverService } from "app/core/services";
import { SpaceDocument } from "app/feature/spaces/model/space-document.model";
import { OperationModel } from "app/feature/spaces/model/OperationModel";
import { SpaceDocModel } from "app/feature/spaces/model/space-doc-param.model";
import { UpdateDocumentModel } from "app/feature/spaces/model/update-document.model";
import { SearchItem } from "app/feature/search/model/search-item.model";
import { AlexNotificationService } from "app/shared/components/notification/notification.service";
import { Status } from "app/core/services";
import { SpacesContentModel } from "app/feature/spaces/model/spaces-content.model";
import { SpacesDocContentModel } from "app/feature/spaces/model/space-doc-content.model";
import { AlexTaskTrackerService } from "app/core/services/task-tracker/task-tracker.service";
import {
  SPACES_PAGE,
  TASK_FETCH_SPACES_DATA,
  SAVED_SEARCH_PAGE,
  TASK_FETCH_SAVED_SEARCH_DATA,
  SPACES_DOCUMENT_PAGE,
  TASK_FETCH_SPACES_DOCUMENTS_DATA,
} from "app/feature/spaces/space.constant";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AlexSearchViewModelService } from "../search/services/search-view-model.service";
import { AlexSearchResult } from "../search/model/content/search-result.model";
import { SearchParamService } from "../search/services/search-param.service";
import { CkdDocumentModel } from "../ckd-lfc/document/ckd-document.model";

@Injectable()
export class SpacesService {
  getSpaceListUrl: string = "space/GetUserSpaceList";
  getDocumentListUrl: string = "space/GetDocumentList";
  addNewSpaceUrl: string = "space/InsertNewSpace";
  addDocumentToSpacesUrl = "space/AddDocumentToSpace";
  addDocumentToMasterSpacesUrl = "space/InsertDoc";
  addQueryToMasterSpacesUrl = "space/InsertQuery";
  deleteSpaceUrl = "space/DeleteSpace";
  editSpaceTitleUrl: string = "space/UpdateSpaceName";
  savedSearchUrl: string = "space/GetQueryList";
  deleteSavedDocumentUrl: string = "space/DeleteDoc";
  moveDocBetweenSpaceUrl = "space/MoveDocumentBetweenSpace";
  editUserDocumentUrl = "space/UpdateDocName";
  editSpaceDocumentUrl = "space/UpdateSpaceDocName";
  editSavedSearchUrl = "space/UpdateQueryName";
  getCompleteSpaceInfoURL: string = "space/GetCompleteSpaceInfo";
  getCompleteSpaceDocInfoURL: string = "space/GetCompleteSpaceDocInfo";
  spaceList$: Subject<Array<Space>>;
  documentList$: Subject<Array<SpaceDocument>>;
  savedSearchList$: Subject<Array<SpaceDocument>>;
  spaceDocTitle$ = new Subject<string>();
  shareURL: BehaviorSubject<string> = new BehaviorSubject<string>("");
  spacesContentModel$: Subject<SpacesContentModel>;
  SpacesDocContentModel$: Subject<SpacesDocContentModel>;
  private spacesDocumentModelList: Array<SpaceDocument> = new Array<SpaceDocument>();
  constructor(
    private http: HttpClient,
    private urlResolverService: AlexUrlResolverService,
    private notificationService: AlexNotificationService,
    private taskTracker: AlexTaskTrackerService,
    private alexSearchViewModelService: AlexSearchViewModelService,
    private searchParamService: SearchParamService
  ) {
    this.spaceList$ = new Subject<Array<Space>>();
    this.spaceList$.next(null);
    this.documentList$ = new Subject<Array<SpaceDocument>>();
    this.documentList$.next(null);
    this.savedSearchList$ = new Subject<Array<SpaceDocument>>();
    this.savedSearchList$.next(null);
    this.spacesContentModel$ = new Subject<SpacesContentModel>();
    this.spacesContentModel$.next(null);
    this.SpacesDocContentModel$ = new Subject<SpacesDocContentModel>();
    this.SpacesDocContentModel$.next(null);
  }

  fetchCompleteSpaceInfo() {
    // let requestHeaders = new Headers({ 'Content-Type': 'application/json' });
    // let requestOptions = new RequestOptions({ headers: requestHeaders });
    // requestOptions["noNotification"] = [204];

    const header = new HttpHeaders({
      "Content-Type": "application/json",
      noNotification: "204",
    });

    return this.http
      .get(
        //  this.urlResolverService.resolveUrlApiV1(this.getCompleteSpaceInfoURL), requestOptions)
        this.urlResolverService.resolveUrlApiV1(this.getCompleteSpaceInfoURL),
        { headers: header }
      )
      .pipe(
        map((response) => {
          return (<any>response).error
            ? new SpacesContentModel()
            : (response as SpacesContentModel);
        })
      )
      .subscribe(
        (result) => {
          this.setDocumentModelList(result.documentModelList);
          this.spacesContentModel$.next(result);
        },
        (error) => {
          this.taskTracker.markTaskAsComplete(
            SPACES_PAGE,
            TASK_FETCH_SPACES_DATA
          );
        }
      );
  }
  setDocumentModelList(documentModelList: Array<SpaceDocument>) {
    this.spacesDocumentModelList = documentModelList;
  }
  getDocumentModelList(id) {
    const documentType = this.spacesDocumentModelList?.find(
      (item) => item.id == id
    )?.documentType;
    return (
      documentType?.toLowerCase() == "manualsfeature" ||
      documentType?.toLowerCase() == "manualsearch"
    );
  }
  fetchCompleteSpaceDocInfo(spaceID) {
    // let requestHeaders = new Headers({ 'Content-Type': 'application/json' });
    // let requestOptions = new RequestOptions({ headers: requestHeaders });
    // requestOptions["noNotification"] = [204];

    const header = new HttpHeaders({
      "Content-Type": "application/json",
      noNotification: "204",
    });

    return this.http
      .get(
        //  this.urlResolverService.resolveUrlApiV1(this.getCompleteSpaceDocInfoURL + "/" + spaceID),
        //         requestOptions)
        this.urlResolverService.resolveUrlApiV1(
          this.getCompleteSpaceDocInfoURL + "/" + spaceID
        ),
        { headers: header }
      )
      .pipe(
        map((response) => {
          return (<any>response).error
            ? new SpacesDocContentModel()
            : (response as SpacesDocContentModel);
        })
      )
      .subscribe(
        (result) => {
          this.SpacesDocContentModel$.next(result);
        },
        (error) => {
          this.taskTracker.markTaskAsComplete(
            SPACES_DOCUMENT_PAGE,
            TASK_FETCH_SPACES_DOCUMENTS_DATA
          );
        }
      );
  }

  fetchSpaces() {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });

    // options["noNotification"] = [204];

    const header = new HttpHeaders({
      "Content-Type": "application/json",
      noNotification: "204",
    });

    // return this.http.get(this.urlResolverService.resolveUrlApiV1(this.getSpaceListUrl), options)
    return this.http
      .get<any>(this.urlResolverService.resolveUrlApiV1(this.getSpaceListUrl), {
        headers: header,
      })
      .pipe(
        map((response) => {
          return (<any>response).error
            ? new Array<Space>()
            : (response.spaceModelList as Array<Space>);
        })
      )
      .subscribe((result) => {
        this.spaceList$.next(result);
      });
  }

  fetchSavedSearch(notification: boolean) {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });

    // options["noNotification"] = [204];

    const header = new HttpHeaders({
      "Content-Type": "application/json",
      noNotification: "204",
    });

    // return this.http.get(this.urlResolvrService.resolveUrlApiV1(this.savedSearchUrl), options)
    return this.http
      .get<any>(this.urlResolverService.resolveUrlApiV1(this.savedSearchUrl), {
        headers: header,
      })
      .pipe(
        map((response) => {
          return (<any>response).error
            ? new Array<SpaceDocument>()
            : (response.documentModelList as Array<SpaceDocument>);
        })
      )
      .subscribe(
        (result) => {
          if (notification && (result.length === 0 || result == null)) {
            this.notificationService.show(
              Status.Failure,
              "No content found",
              true
            );
          }
          this.savedSearchList$.next(result);
        },
        (error) => {
          this.taskTracker.markTaskAsComplete(
            SAVED_SEARCH_PAGE,
            TASK_FETCH_SAVED_SEARCH_DATA
          );
        }
      );
  }

  getSavedDocument(spaceId = null, notification: boolean) {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    // options["noNotification"] = [204];

    const header = new HttpHeaders({
      "Content-Type": "application/json",
      noNotification: "204",
    });

    let url = "";
    if (spaceId && spaceId != 0)
      url = this.urlResolverService.resolveUrlApiV1(
        this.getDocumentListUrl + "/" + spaceId
      );
    else url = this.urlResolverService.resolveUrlApiV1(this.getDocumentListUrl);
    return this.http
      .get<any>(url, { headers: header })
      .pipe(
        map(
          (response) => {
            return (<any>response).error
              ? Array<SpaceDocument>()
              : (response.documentModelList as Array<SpaceDocument>);
          },
          (error) => {
            console.log(error);
          }
        )
      )
      .subscribe((result) => {
        this.documentList$.next(result);
        if (notification && (result.length == 0 || result == null)) {
          this.notificationService.show(
            Status.Failure,
            "No content found",
            true
          );
        }
      });
  }

  handledError(error: Response): any {
    console.log(error);
    return observableThrowError(error || "Server error");
  }

  addDocumentToSpace(
    documentId: string,
    spaceId: string
  ): Observable<OperationModel> {
    // let index = this.customSpaces.findIndex(e=>e.id==spaceId);
    //this.customSpaces[index].documentsCount.push(documentId);

    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    const header = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http
      .post(
        this.urlResolverService.resolveUrlApiV1(this.addDocumentToSpacesUrl),
        {
          docID: documentId,
          spaceID: spaceId,
          DestinationSpaceID: 0,
        },
        { headers: header }
      )
      .pipe(
        map(
          (response) => {
            return response as OperationModel;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  addDocumentToMasterSpace(
    searchItem: SearchItem,
    modifiedTitle: string,
    isKillDocId: boolean = true
  ): Observable<OperationModel> {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });

    const header = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const isManual =
      searchItem.type?.toLowerCase() == "manualsfeature" ||
      searchItem.type?.toLowerCase() == "manualsearch";

    return this.http
      .post(
        this.urlResolverService.resolveUrlApiV1(
          this.addDocumentToMasterSpacesUrl
        ),
        {
          idolInfo: searchItem.docId,
          idolCreatedDate: searchItem.createdDate,
          mimeType: searchItem.mimeType,
          title: modifiedTitle,
          idolTitle: searchItem.title,
          documentType: searchItem.docType,
          documentParentID: searchItem.parentId,
          taxanomyIDList:
            searchItem.docType?.toLowerCase() === "top" ||
            searchItem.docType?.toLowerCase() === "topic" ||
            searchItem.docType?.toLowerCase() === "topics"
              ? searchItem.topicTaxonomyIds
              : searchItem.docType == "Content Page" ||
                searchItem.docType == "contentPageQuery"
              ? searchItem.taxonomyIds
              : searchItem.sourceTaxonomyIds,
          // topicTaxonomyIds:searchItem.topicTaxonomyIds,
          // sourceTaxonomyIds:searchItem.sourceTaxonomyIds,
          isKillDocId: isKillDocId,
          tocRef: searchItem.tocRef,
          isManual: isManual,
          country: [searchItem.tocCountry],
        },
        { headers: header }
      )
      .pipe(
        map(
          (response) => {
            return response as OperationModel;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  addKaegDocumentToMasterSpace(
    ckdDocument: CkdDocumentModel,
    modifiedTitle: string,
    tocCountry: string,
    tocRef: string
  ): Observable<OperationModel> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http.post<OperationModel>(
      this.urlResolverService.resolveUrlApiV1(
        this.addDocumentToMasterSpacesUrl
      ),
      {
        idolInfo: ckdDocument.referenceId,
        idolCreatedDate: ckdDocument.createdDate,
        mimeType: ckdDocument.mimeType,
        title: modifiedTitle,
        idolTitle: ckdDocument.documentName,
        documentType: "manualdocument",
        documentParentID: ckdDocument.parentId,
        taxanomyIDList: [],
        isKillDocId: true,
        tocRef: tocRef,
        isManual: true,
        country: [tocCountry],
      },
      { headers }
    );
  }

  addQueryToMasterSpace(
    searchItem: SearchItem,
    newName: string
  ): Observable<OperationModel> {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });

    const header = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http
      .post(
        this.urlResolverService.resolveUrlApiV1(this.addQueryToMasterSpacesUrl),
        {
          idolInfo: searchItem.docId,
          createdDate: searchItem.createdDate,
          mimeType: searchItem.mimeType,
          title: newName,
          documentType: "Query",
        },
        { headers: header }
      )
      .pipe(
        map(
          (response) => {
            return response as OperationModel;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  moveDocBetweenSpace(spaceDocModel: SpaceDocModel) {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });

    const header = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http
      .post(
        this.urlResolverService.resolveUrlApiV1(this.moveDocBetweenSpaceUrl),
        spaceDocModel,
        { headers: header }
      )
      .pipe(
        map(
          (response) => {
            return response as OperationModel;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  addNewSpace(spaceName: string): Observable<OperationModel> {
    let space = new Space();
    space.title = spaceName;

    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    const header = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http
      .post(
        this.urlResolverService.resolveUrlApiV1(this.addNewSpaceUrl),
        space,
        { headers: header }
      )
      .pipe(
        map(
          (response) => {
            return response as OperationModel;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  editSpaceTitle(space: Space): Observable<OperationModel> {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    const header = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http
      .post(
        this.urlResolverService.resolveUrlApiV1(this.editSpaceTitleUrl),
        space,
        { headers: header }
      )
      .pipe(
        map(
          (response) => {
            return response as OperationModel;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  deleteSavedDocument(docID: string): Observable<OperationModel> {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    const header = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http
      .post(
        this.urlResolverService.resolveUrlApiV1(
          this.deleteSavedDocumentUrl + "/" + docID
        ),
        { headers: header }
      )
      .pipe(
        map(
          (response) => {
            return response as OperationModel;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  deleteSpace(spaceId: string): Observable<OperationModel> {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });

    const header = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http
      .post(
        this.urlResolverService.resolveUrlApiV1(
          this.deleteSpaceUrl + "/" + spaceId
        ),
        { headers: header }
      )
      .pipe(
        map(
          (response) => {
            return response as OperationModel;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  editSavedQuery(newDocument: UpdateDocumentModel): Observable<OperationModel> {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });

    const header = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http
      .post(
        this.urlResolverService.resolveUrlApiV1(this.editSavedSearchUrl),
        newDocument,
        { headers: header }
      )
      .pipe(
        map(
          (response) => {
            return response as OperationModel;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  editUserDocument(
    newDocument: UpdateDocumentModel
  ): Observable<OperationModel> {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    const header = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http
      .post(
        this.urlResolverService.resolveUrlApiV1(this.editUserDocumentUrl),
        newDocument,
        { headers: header }
      )
      .pipe(
        map(
          (response) => {
            return response as OperationModel;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }

  editSpaceDocument(
    newDocument: UpdateDocumentModel
  ): Observable<OperationModel> {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    const header = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http
      .post(
        this.urlResolverService.resolveUrlApiV1(this.editSpaceDocumentUrl),
        newDocument,
        { headers: header }
      )
      .pipe(
        map(
          (response) => {
            return response as OperationModel;
          },
          (error) => {
            console.log(error);
          }
        )
      );
  }
  getFileType(mimeType: string): string {
    let iconType = "file";
    mimeType = mimeType ? mimeType.toLowerCase() : mimeType;
    switch (mimeType) {
      case "application/vnd.ms-excel":
        iconType = "excel";
        break;
      case "application/msword":
        iconType = "word";
        break;
      case "text/plain":
        iconType = "word";
        break;
      case "text/rtf":
        iconType = "word";
        break;
      case "application/zip":
        iconType = "zip";
        break;
      case "application/xml":
        iconType = "file";
        break;
      case "application/vnd.ms-powerpoint":
        iconType = "ppt";
        break;
      case "application/pdf":
        iconType = "pdf";
        break;
      case "text/vnd.wap.wml":
        iconType = "msg";
        break;
      case "application/outlook":
        iconType = "msg";
        break;
      case "video/mpeg":
        iconType = "video";
        break;
      case "audio/mpeg":
        iconType = "video";
        break;
      case "audio/x-ms-wmv":
        iconType = "video";
        break;
      case "application/x-shockwave-flash":
        iconType = "video";
        break;
      case "video":
        iconType = "video";
        break;
      case "video/mp4":
        iconType = "video";
        break;
      case "web":
        iconType = "web";
        break;
      case "text/html" || "html/xml" || "application/xml" || "xml" || "html":
        iconType = "file";
        break;
      default:
        iconType = "other";
    }
    return iconType;
  }

  assignKillDocID(document: SearchItem) {
    let killDocIdTaken = false;
    let spaceDocInput = document;
    //killdocid will only be considered for feature and lfc,manuals
    if (
      document.docType.toLowerCase() == "manualsfeature" ||
      document.docType.toLowerCase() == "manualsearch"
    )
      killDocIdTaken = true;
    else if (document.docType.toLowerCase() == "feature") killDocIdTaken = true;
    //for search page
    else if (document.docType.toLowerCase() == "search") {
      if (this.getFileType(document.mimeType) == "file") killDocIdTaken = true;
    } else {
      if (document.docType.toLocaleLowerCase() == "documents")
        killDocIdTaken = true;
    }

    if (killDocIdTaken) {
      spaceDocInput = Object.assign({}, document, {
        docId: document.killDocId,
      });
    }

    return spaceDocInput;
  }
}
