

import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { TypeAhead } from "./type-ahead.model";
import { AlexUrlResolverService, AlexAppService } from "app/core/services";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";
import { AlexNotificationService } from "app/shared/components/notification/notification.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { finalize, map } from 'rxjs/operators';

@Injectable()
export class TypeAheadService {
  url: string = null;

  constructor(private http: HttpClient, private urlResolver: AlexUrlResolverService, private spinner: SpinnerService,
    private notificationService: AlexNotificationService,
    private alexappservice: AlexAppService
  ) { }

  typeAheadUrl(framework: string, keyword: string): string {
    if (framework == "CKD") {
      return `CKDsearch/CKDTypeAhead/${" " + encodeURIComponent(keyword.trim())}`;
    }
    return `Search/TypeAhead/${" " + encodeURIComponent(keyword.trim())}`;
  }
  
  findTypeAheadText(text: string): Observable<TypeAhead> {

    // let requestOptions = new RequestOptions();
    // requestOptions["noSpinner"] = true;

  const headers = new HttpHeaders({"noSpinner":"true"});

    return this.http.get<any>(this.urlResolver.resolveUrlApiV1(this.typeAheadUrl(this.alexappservice.frameworkType, text)), 
    {headers:headers}).pipe(
      map(response => {
        let typeAhead = new TypeAhead();
        typeAhead.searchText = text;
        typeAhead.suggestions = response.suggestions as string[];
        return typeAhead;
      }),finalize(() => {
        this.spinner.noSpinner(false);
        this.notificationService.noNotification(true);
      }));

  }

}
