<ng-template #helpFromUnAuth>
  <alex-help (OnCancelClicked)="reset()" [modalId]="'helpFromUnAuth'">
  </alex-help>
</ng-template>
<alex-content-area-container>
  <alex-contextual-bar [breadCrumb]="breadCrumb" contextual-bar>
    <div icons>
      <a (click)="showHelpModal(helpFromUnAuth)">
        <alex-svg-icon
          cssClass="colorNone"
          icon="contextualBar_Help"
          title="Help"
        ></alex-svg-icon>
      </a>

      <alex-feedback-link></alex-feedback-link>

    </div>
  </alex-contextual-bar>

  <div class="subscribeContainer clearfix" content-area>
    <div class="imageContent">
      <svg
        class="svgIm alertIcon greyColor4"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="130px"
        height="1px"
        viewBox="0 0 38 1"
        xml:space="preserve"
      >
        <g transform="scale(1.2)">
          <g
            transform="translate(-348,-985.9)"
            xmlns="http://www.w3.org/2000/svg"
            id="exclamation-sign_2_"
          >
            <path
              d="M360,971.75c-6.765,0-12.25,5.484-12.25,12.25s5.485,12.25,12.25,12.25c6.765,0,12.25-5.484,12.25-12.25
                      S366.765,971.75,360,971.75z M362,991.5c0,0.275-0.225,0.5-0.5,0.5h-3c-0.275,0-0.5-0.225-0.5-0.5v-3c0-0.275,0.225-0.5,0.5-0.5h3
                      c0.275,0,0.5,0.225,0.5,0.5V991.5z M362,980.5c0,0.275-0.044,0.721-0.098,0.99l-0.9,4.77c-0.053,0.269-0.322,0.74-0.598,0.74
                      h-0.785c-0.275,0-0.545-0.471-0.6-0.74l-0.92-4.645c-0.055-0.269-0.1-0.84-0.1-1.115v-4c0-0.275,0.225-0.5,0.5-0.5h3
                      c0.275,0,0.5,0.225,0.5,0.5V980.5z"
            ></path>
          </g>
        </g>
      </svg>
    </div>
    <div class="fontBold warningTxt">
      <h1>{{ warningMsg?.WARNING_HEADER_INTERNAL }}</h1>
    </div>
    <!-- ARO EXTERNAL LOGIC -->
    <!-- 
    <div class="subscribeTxt">

    </div>

    <div>
      <div *ngSwitchCase="CurrentUsrStatus?.CONCURRENTUSER">
        <div class="fontBold warningTxt">
          <h1>{{warningMsg?.CONCURRENT_USER}}</h1>
        </div>
        <br/><br/>
        <p class="footerTxt fontBold">{{warningMsg?.USER_ALREADY_LOGGEDIN}}</p>
      </div>
      <div *ngSwitchCase="CurrentUsrStatus?.IDLETIMEOUT">
        <div class="warningTxt fontBold">
          <h1> {{warningMsg?.SESSION_TIMEOUT}}</h1>
        </div><br/><br/>
        <p class="footerTxt fontBold"> your current session has timed out. Please <a (click)="goToLogin()">re-login.</a></p>
      </div>
      <div *ngSwitchCase="CurrentUsrStatus?.DUPLICATEUSER">
        <div class="warningTxt fontBold">
          <h1>{{warningMsg?.DUPLICATE_USER}}</h1>
        </div>
        <br/><br/>
        <p class="footerTxt fontBold">Please log out from all other session and <a (click)="goToLogin()">re-login</a> here.</p>
      </div>
      <div *ngSwitchCase="CurrentUsrStatus?.USERLOGOUT">
        <div class="warningTxt fontBold">
          <h1>{{warningMsg?.CONCURRENT_USER}}</h1>
        </div>
      </div>
      <div *ngSwitchCase="CurrentUsrStatus?.PREVIOUSSESSIONIDLE">
        <div class="warningTxt fontBold">
          <h1>{{warningMsg?.NOT_LOGGED_OUT}}</h1>
        </div>
        <br/><br/>
        <p class="footerTxt fontBold"> Please <a (click)="goToLogin()">re-login</a> here.</p>
      </div>
      <div *ngSwitchDefault>
        <div class="warningTxt fontBold">
          <h1 *ngIf="isExternalUser;else internalWarning">
            {{warningMsg?.WARNING_HEADER_EXTERNAL}}

          </h1><br/><br/>
          <p class="footerTxt fontBold" *ngIf="isExternalUser">
            {{warningMsg?.WARNING_PARAGRAPH_EXTERNAL}}
            <a href="mailto:{{siteAdmintUrl}}" target="_top">{{siteAdmintUrl}}</a>
          </p>
        </div>
      </div>
    </div> -->
  </div>

  <!-- <ng-template #internalWarning>
    <h1>{{ warningMsg?.WARNING_HEADER_INTERNAL }}</h1>
  </ng-template> -->
</alex-content-area-container>
