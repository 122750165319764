export type CombinedSearchQueryType =
  | "topic"
  | "featured"
  | "kaeg"
  | "alex"
  | null;

export const combinedSearchQueryCategories: {
  [key: string]: CombinedSearchQueryType;
} = {
  topic: "topic",
  featured: "featured",
  kaeg: "kaeg",
  alex: "alex",
};

export const combinedSearchQueryList: CombinedSearchQueryType[] = [
  "topic",
  "featured",
  "kaeg",
  "alex",
];
