import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

import { AppConfig } from "app/app.config";
import { UrlConfig } from "./url-config.model";

// import "rxjs/add/operator/toPromise";
import { StyleSheetUrls } from "app/core/services/url-resolver/stylesheet-url.model";
import { AppInsightConfig } from "app/core/services/url-resolver/appInsight-config.model";
// phase 2 tracking code
import { ITrackingConfig } from "./tracking-config.model";
import { ALEX_EDITION_IDENTIFIER } from "app/shared/constants";
import { ICombinedSearchConfig } from "app/feature/search/interfaces";
import { combinedSearchDefaultConfig } from "app/feature/search/constants";
import { KcwSearchConfig } from "./kcw-search-config.interface";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class AlexUrlResolverService {
  lastChar: string;
  private loaded = false;
  private loadInProgress = false;
  private urlConfig: UrlConfig;
  private styleSheetUrls: StyleSheetUrls;
  private configLoader: Observable<any>;
  private helpPages: any;
  private userGuideUrl: any;
  private websiteRoot: string;
  private appInsight: AppInsightConfig;
  private resources: Array<any>;
  private appConfigInstance: any;
  private environmentInstance: any;
  private routeRedirect: any;
  private CKDManualConfig: any;
  private KCWManualConfig: any;
  private CKDManualSearchConfig: any;
  private staticArrayConfig: any;
  // phase 2 tracking code
  private trackingConfig: ITrackingConfig; // config for ADS specific endpoints
  /**
   * combined search related configs
   */
  private combinedSearchConfig: Readonly<ICombinedSearchConfig> =
    combinedSearchDefaultConfig;
  private kcwSearchConfig: Readonly<KcwSearchConfig> = {
    welcomeMessage: {
      header: "",
      subHeader: "",
      body: [],
      footer: "",
    },
    invalidRuleSetIdMessage: "",
    breadCrumbText: {
      activityFlyout: "",
      guidancePanel: "",
    },
    browserTitle: "",
    searchFields: [],
  };

  private KCWAchdConfig: any;
  readonly lfcFocusTimeout: number = 0;
  private alexBrowserTitle: string;
  
  specialCharacterAsciiList = new Array<string>();
  langWithoutWordBoundaryUnicodes = new Array<string>();
  
  

  constructor(private appConfig: AppConfig) {
    //this.appConfigInstance =AppConfig.getInstance();

    this.environmentInstance = environment;

    this.loadConfig(this.environmentInstance);
    this.loadStyleSheetUrls(this.environmentInstance);
    this.loadHelpPageConfig(this.environmentInstance);
    this.loadUserGuideUrl(this.environmentInstance);
    this.loadAppInisightConfig(this.environmentInstance);
    this.loadResources(this.environmentInstance);
    this.loadCKDManualConfig(this.environmentInstance);
    this.loadCKDManualSearchConfig(this.environmentInstance);
    this.loadKCWManualConfig(this.environmentInstance);
    this.loadStaticArray(this.environmentInstance);
    // phase 2 tracking code
    this.loadTrackingConfig(this.environmentInstance);

    // load combined search related config
    this.loadCombinedSearchConfig(this.environmentInstance);

    // load Kcw Search related config.
    this.loadKcwSearchConfig(this.environmentInstance);

    //load kcw achd configs
    this.loadKCWAchdConfig(this.environmentInstance);

    this.loaded = true;
    this.specialCharacterAsciiList =
    this.environmentInstance.specialCharacterList;
    this.langWithoutWordBoundaryUnicodes =
    this.environmentInstance.langWithoutWordBoundaryUnicodes;
    this.lfcFocusTimeout = this.environmentInstance.lfcFocusTimeout;
    this.alexBrowserTitle = this.environmentInstance.alexBrowserTitle;
  }
  setFilePathForArchive() {
    const editionId = sessionStorage.getItem(ALEX_EDITION_IDENTIFIER);
    const archiveFilePathFromConfig =
      this.environmentInstance.api.v1.archiveFilePath;
    if (editionId) {
      const archiveFilePath = `${archiveFilePathFromConfig}/${editionId}`;
      this.urlConfig.baseArchiveFilePath = this.addUrlPart(archiveFilePath);
    }
  }

  getSessionConfig() {
    return environment.session;
  }

  resolveUrlApiV1(urlPart: string): string {
    return this.urlConfig.baseUrlApiV1 + this.removeUrlPart(urlPart);
  }

  resolveFilePath(fileName: string, withoutEdition = false): any {
    if (!fileName) return fileName;
    const editionId = sessionStorage.getItem(ALEX_EDITION_IDENTIFIER);
    if (!editionId || withoutEdition) {
      return this.urlConfig.baseFilePath + this.removeUrlPart(fileName);
    } else {
      return this.urlConfig.baseArchiveFilePath + this.removeUrlPart(fileName);
    }
  }

  resolveAlexFriendlyUrl(urlPart: string): any {
    this.lastChar =
      this.urlConfig.baseAlexFriendlyUrl[
        this.urlConfig.baseAlexFriendlyUrl.length - 1
      ];
    if (this.lastChar != "/") {
      this.urlConfig.baseAlexFriendlyUrl =
        this.urlConfig.baseAlexFriendlyUrl.substring(
          0,
          this.urlConfig.baseAlexFriendlyUrl.length - 1
        ); //this.urlConfig.baseAlexFriendlyUrl.slice(-1)
    }
    return this.urlConfig.baseAlexFriendlyUrl + this.removeUrlPart(urlPart);
  }

  resolveStyleSheetUrl(name: string) {
    return this.styleSheetUrls[name];
  }

  /**
   * Format content according to edition id present or not
   * @param contentRef contentRef to replace
   */
  replaceBinaryPathInContent(contentRef: string): string {
    if (!contentRef) {
      return contentRef;
    }
    const editionId = sessionStorage.getItem(ALEX_EDITION_IDENTIFIER);
    if (!editionId) {
      return contentRef;
    } else {
      const nonArchiveBinariesRegEx = new RegExp(
        this.urlConfig.binariesPath,
        "gi"
      );
      const archiveBinariesPath = `${this.urlConfig.archiveBinariesPath}/${editionId}`;
      const newContentRef = contentRef.replace(
        nonArchiveBinariesRegEx,
        archiveBinariesPath
      );
      return newContentRef;
    }
  }

  getHelpPageList() {
    return this.helpPages;
  }

  getUserGuideUrl(urlPart: string): string {
    return this.userGuideUrl + urlPart;
  }

  getImageUrl(): string {
    return this.replaceBinaryPathInContent(this.urlConfig.websiteRoot);
  }

  getAppInsightConfig(): AppInsightConfig {
    return this.appInsight;
  }

  getLogoutUrl(): string {
    return this.urlConfig.baseAlexLogoutUrl;
  }

  getExtUserLoginUrl() {
    return this.urlConfig.extUserLoginUrl;
  }

  getRenewLicenseUrl() {
    return this.urlConfig.renewLicenseUrl;
  }

  getExtUserProfileUrl() {
    return this.urlConfig.extUserProfileUrl;
  }

  getExtManagAccUrl() {
    return this.urlConfig.extManageAccUrl;
  }

  getSitAdminUrl() {
    return this.urlConfig.siteAdminEmail;
  }

  getResourcesForPageByKey(pageName: string = "", key: string = "") {
    let currentPage =
      this.resources.find((page) => page.pageName == pageName) || {};
    if (pageName && !key) return currentPage.resources;
    else if (pageName && key) {
      return currentPage.resources ? currentPage.resources[key] : {};
    } else {
      return "dummy";
    }
  }
  getRouteRedirectMapping() {
    return this.routeRedirect;
  }

  getCKDManualsConfig() {
    return this.CKDManualConfig;
  }

  getKCWManualConfig() {
    return this.KCWManualConfig;
  }
  getManualSearchConfig() {
    return this.CKDManualSearchConfig;
  }

  getKCWAchdConfig() {
    return this.KCWAchdConfig;
  }

  getAlexBrowserTitle(){
    return this.alexBrowserTitle;
  }


  // phase 2 tracking code
  /**
   * Get tracking related config
   */
  getTrackingConfig() {
    return this.trackingConfig;
  }
  
  getStaticArrayConfig() {
    return this.staticArrayConfig;
  }

  /**
   * Get combined search related config
   */
  getCombinedSearchConfig(): Readonly<ICombinedSearchConfig> {
    return this.combinedSearchConfig;
  }

  /**
   * Get kcw search related config
   */
  getKcwSearchConfig(): Readonly<KcwSearchConfig> {
    return this.kcwSearchConfig;
  }

  getBinariesPath() {
    return this.urlConfig.binariesPath;
  }

  getArchiveBinariesPath() {
    return this.urlConfig.archiveBinariesPath;
  }

  private loadAppInisightConfig(response: any) {
    this.appInsight = new AppInsightConfig();
    this.appInsight = response.appInsight;
  }

  private loadHelpPageConfig(response: any) {
    this.helpPages = response.helpPages;
  }

  private loadUserGuideUrl(response: any) {
    this.userGuideUrl = response.userGuideUrl.base;
  }

  private loadStyleSheetUrls(response: any) {
    this.styleSheetUrls = new StyleSheetUrls();
    this.styleSheetUrls.aroGlobal = response.styleSheets.aroGlobal;
    this.styleSheetUrls.featured = response.styleSheets.featured;
  }

  private loadConfig(response: any): void {
    this.urlConfig = new UrlConfig();
    this.urlConfig.baseUrlApiV1 = this.addUrlPart(response.api.v1.base);
    this.urlConfig.baseFilePath = this.addUrlPart(response.api.v1.filePath);
    this.urlConfig.binariesPath = response.api.v1.binariesPath;
    this.urlConfig.archiveBinariesPath = response.api.v1.archiveBinariesPath;
    this.urlConfig.baseAlexFriendlyUrl = this.addUrlPart(
      response.api.v1.alexFriendlyUrl
    );
    this.urlConfig.websiteRoot = this.addUrlPart(response.api.v1.websiteRoot);
    this.urlConfig.baseAlexLogoutUrl = response.api.v1.alexLogOutURL;
    this.urlConfig.extUserLoginUrl = response.api.v1.extUserLoginUrl;
    this.urlConfig.renewLicenseUrl = this.addUrlPart(
      response.api.v1.renewLicenseUrl
    );
    this.urlConfig.extUserProfileUrl = this.addUrlPart(
      response.api.v1.extUserProfileUrl
    );
    this.urlConfig.extManageAccUrl = this.addUrlPart(
      response.api.v1.extManageAccUrl
    );
    this.urlConfig.siteAdminEmail = response.api.v1.siteAdminEmail;

    this.routeRedirect = response.routeRedirect;
  }

  private loadResources(response: any) {
    this.resources = response.resources;
  }
  private loadCKDManualConfig(response: any) {
    this.CKDManualConfig = response.CKDManual;
  }

  private loadStaticArray(response: any){
    this.staticArrayConfig = response.staticArray;
  }
  private loadKCWManualConfig(response: any) {
    this.KCWManualConfig = response.KCWManual;
  }
  private loadCKDManualSearchConfig(response: any) {
    this.CKDManualSearchConfig = response.CKDManualSearch;
  }
  private loadKCWAchdConfig(response: any) {
    this.KCWAchdConfig = response.KCWAchdManual;
  }
  // phase 2 tracking code
  /**
   * Extract and save ADS specific tracking configurations
   * @param config configuration json
   */
  private loadTrackingConfig(config: any) {
    this.trackingConfig = config.tracking;
  }

  /**
   * Extract and save combined search specific configurations
   * @param config configuration json
   */
  private loadCombinedSearchConfig(config: any): void {
    this.combinedSearchConfig =
      config?.combinedSearch || combinedSearchDefaultConfig;
  }

  /**
   * Extract and save KCW search specific configurations
   * @param config configuration json
   */
  private loadKcwSearchConfig(config: any): void {
    this.kcwSearchConfig = config?.kcwSearch || {
      welcomeMessage: {
        header: "",
        subHeader: "",
        body: [],
        footer: "",
      },
      browserTitle: "",
    };
  }

  private addUrlPart(urlPart: string): string {
    if (
      urlPart &&
      urlPart.length &&
      urlPart.substring(urlPart.length - 1, 1) != "/"
    ) {
      urlPart += "/";
    }
    return urlPart;
  }
  private removeUrlPart(urlPart: string): string {
    if (urlPart && urlPart.indexOf("/") == 0) {
      urlPart = urlPart.substring(1);
    }
    return urlPart;
  }
}
