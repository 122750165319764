import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  AlexUserResolver,
  AlexAllSourcesTaxonomyResolver,
} from "app/core/resolver";
import { AlexAppRouteRedirect } from "app/feature/app-route-redirect.component";
import { AlexUserGuideComponent } from "app/shared/components/userGuide/user-guide.component";
import { UnauthorizedComponent } from "app/shared/components/unauthorized/unauthorized.component";
import { UserAuthorizationGuard } from "./core/guards";
import { BinaryDownloadGuard } from "./feature/long-form-content/guard/binary-download.guard";
import { SpecialCharacterResolver } from "./core/factories/special-chaeacters/special-character.resolver";
import { AlexPages } from "./shared/components/help/help.enum";
import { CurrentPageResolver } from "./shared/resolvers/current-page.resolver";
import { KaegFiltersResolver } from "./feature/search/resolvers/kaeg-filters.resolver";
import { MsalGuard } from "@azure/msal-angular";

const appRoutes: Routes = [
  {path: "", canActivate: [MsalGuard], children:[
    { path: "", redirectTo: "home", pathMatch: "full"},
    { path: "aroweb", redirectTo: "home", pathMatch: "full"},
    {
      path: "manualsearch",
      redirectTo: "home",
      pathMatch: "full"
    },
    {
      path: "kcwsearch",
      loadChildren: () =>
        import("app/feature/kcw-search/kcw-search.module").then(
          (m) => m.KcwSearchModule
        ),
      data: { pageType: AlexPages.kcwSearch },
      resolve: {
        user: AlexUserResolver,
        currentPage: CurrentPageResolver,
      },
      canActivate: [UserAuthorizationGuard],
    },
    {
      path: "document/kcwmanuals",
      loadChildren: () =>
        import("app/feature/kcw-lfc/kcw-lfc/kcw-lfc.module").then(
          (m) => m.KCWLfcModule
        ),
      data: { pageType: AlexPages.kcwLfc },
      resolve: {
        user: AlexUserResolver,
        currentPage: CurrentPageResolver,
      },
      canActivate: [UserAuthorizationGuard],
    },
    {
      path: "manualsearch/more/:id",
      redirectTo: "home",
      pathMatch: "full"
    },
    {
      path: "aroweb/documentwindow.aspx",
      component: AlexAppRouteRedirect,
      pathMatch: "prefix",
      data: { page: "documentwindow" }
    },
    {
      path: "documentwindow.aspx",
      component: AlexAppRouteRedirect,
      pathMatch: "prefix",
      data: { page: "documentwindow" }
    },
    {
      path: "aroweb/home.aspx",
      component: AlexAppRouteRedirect,
      pathMatch: "prefix",
      data: { page: "homewindow" }
    },
    {
      path: "home.aspx",
      component: AlexAppRouteRedirect,
      pathMatch: "prefix",
      data: { page: "homewindow" }
    },
    {
      path: "Home/ManageAccount",
      component: AlexAppRouteRedirect,
      pathMatch: "prefix",
      resolve: {
        user: AlexUserResolver,
        allSourcesTaxonomy: AlexAllSourcesTaxonomyResolver,
      },
      data: { page: "manageaccount" },
      canActivate: [UserAuthorizationGuard],
    },
    {
      path: "unauthorized",
      component: UnauthorizedComponent,
    },
    {
      path: "home",
      loadChildren: () =>
        import("app/feature/home/home.module").then((m) => m.AlexHomeModule),
      data: { preload: true, page: "HOME", pageType: AlexPages.home },
      
      resolve: {
        user: AlexUserResolver,
        allSourcesTaxonomy: AlexAllSourcesTaxonomyResolver,
        currentPage: CurrentPageResolver,
      },
      canActivate: [UserAuthorizationGuard],
    },
    {
      path: "search",
      loadChildren: () =>
        import("app/feature/search/search.module").then(
          (m) => m.AlexSearchModule
        ),
      data: { preload: true, pageType: AlexPages.search },
      resolve: {
        user: AlexUserResolver,
        allSourcesTaxonomy: AlexAllSourcesTaxonomyResolver,
        currentPage: CurrentPageResolver,
        kaegFilters: KaegFiltersResolver,
      },
      canActivate: [UserAuthorizationGuard],
      runGuardsAndResolvers: "paramsOrQueryParamsChange",
    },
    {
      path: "document",
      loadChildren: () =>
        import("app/feature/long-form-content/lfc.module").then(
          (m) => m.AlexLfcModule
        ),
      data: { preload: false, page: "document", pageType: AlexPages.lfc },
      resolve: {
        user: AlexUserResolver,
        lfcDocument: BinaryDownloadGuard,
        specialCharacters: SpecialCharacterResolver,
        allSourcesTaxonomy: AlexAllSourcesTaxonomyResolver,
        currentPage: CurrentPageResolver,
      },
      canActivate: [UserAuthorizationGuard],
    },
    {
      path: "bridge/:menuTaxonomyId/:contentTaxonomyId",
      loadChildren: () =>
        import("app/feature/bridge-page/bridge-page.module").then(
          (m) => m.BridgePageModule
        ),
      data: { preload: true, page: "bridge", pageType: AlexPages.bridge },
      resolve: {
        user: AlexUserResolver,
        allSourcesTaxonomy: AlexAllSourcesTaxonomyResolver,
        currentPage: CurrentPageResolver,
      },
      canActivate: [UserAuthorizationGuard],
    },
    {
      path: "spaces",
      loadChildren: () =>
        import("app/feature/spaces/spaces.module").then(
          (m) => m.AlexSpacesModule
        ),
      data: { preload: false, page: "spaces", pageType: AlexPages.spaces },
      resolve: {
        user: AlexUserResolver,
        allSourcesTaxonomy: AlexAllSourcesTaxonomyResolver,
        currentPage: CurrentPageResolver,
      },
      canActivate: [UserAuthorizationGuard],
    },
    {
      path: "userGuide",
      component: AlexUserGuideComponent,
      data: { preload: false, page: "userGuide" },
      canActivate: [UserAuthorizationGuard],
    },
    //ckd to alex routing start
    {
      path: "document/manuals",
      loadChildren: () =>
        import("app/feature/ckd-lfc/ckd-lfc.module").then((m) => m.CkdLfcModule),
      data: { preload: false, page: "document", pageType: AlexPages.manualsLfc },
      canActivate: [UserAuthorizationGuard],
      resolve: {
        specialCharacters: SpecialCharacterResolver,
        currentPage: CurrentPageResolver,
      },
    },
    {
      path: "**",
      redirectTo : 'home'
    },
  ]}
  
  //ckd to alex code end
];

const isIframe = window !== window.parent && !window.opener;
//const isIframe = window !== window.top && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      relativeLinkResolution: "legacy",
      onSameUrlNavigation: "reload",
      initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
    }),
  ],
  exports: [RouterModule],
})
export class AlexAppRoutingModule {}
