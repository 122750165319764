import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from "@angular/router";
import { Injectable } from "@angular/core";

//Beta version.
@Injectable()
export class AlexRouteReuseStrategy implements RouteReuseStrategy {
    shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }
    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void { }
    shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle { return null!; }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        let reuse = false;

        try {
            const currentUrl = (<any>curr)._routerState.url;
            const futureUrl = (<any>future)._routerState.url;

            if (currentUrl && futureUrl) {
                reuse = this.isHashChange(currentUrl, futureUrl) ||
                        this.isTOCSearch(currentUrl, futureUrl);
            }

        } catch (error) {

        }
        return reuse;
    }

    private isTOCSearch(currentUrl: string, futureUrl: string): boolean {
        let isTOCSearch = false;
        const currentUrlSplit = currentUrl.split('/');
        const futureUrlSplit = futureUrl.split('/');
        if (currentUrlSplit[6] == "tocsearch" && futureUrlSplit[6] == "tocsearch") {
            isTOCSearch = true;
        }
        return isTOCSearch;
    }

    private isHashChange(currentUrl: string, futureUrl: string): boolean {
        let isHashChange = false;
        if (currentUrl.includes('#') || futureUrl.includes('#')) {
            const currentUrlSplit = currentUrl.split('#');
            const futureUrlSplit = futureUrl.split('#');
            if (currentUrlSplit.length <= 2 && futureUrlSplit.length <= 2
                && currentUrlSplit[0] == futureUrlSplit[0])
                isHashChange = true;
        }

        return isHashChange;
    }

    private isSearch(currentUrl: string, futureUrl: string): boolean {
        return false && currentUrl && currentUrl.toLowerCase().indexOf('/search') > -1
            && futureUrl && futureUrl.toLowerCase().indexOf('/search') > -1;
    }
}