import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouteReuseStrategy, UrlSerializer } from "@angular/router";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";

// import application routing module
import { AlexAppRoutingModule } from "app/app.routing.module";

// bootstrap component
import { AppComponent } from "app/app.component";

// core module. Don't import the module anywhere else. It should be imported here only.
import { AlexCoreModule } from "app/core";

import { AlexSharedModule } from "app/shared";

import { AlexFooterComponent } from "app/feature/footer";
import { HeaderTabsService } from "app/feature/header/tabs/header-tabs.service";
import { SearchParamService } from "app/feature/search/services/search-param.service";
import { AllSourcesService } from "app/feature/header/services/all-sources.service";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";
import { AlexUrlResolverService, AlexAppService } from "app/core/services";
import { AlexHeaderModule } from "app/feature/header/header.module";
import { AlexUserResolver } from "app/core/resolver";
import { AddToAlexService } from "app/shared/add-to-alex.service";
import { AlexContextualBarService } from "app/shared/components/contextual-bar/contextul-bar.service";
import { AlexSearchCacheService } from "app/feature/search/services/search-cache.service";
import { AlexDocumentService } from "app/feature/long-form-content/document/document.service";
import { AlexLfcDocumentResolveGuard } from "app/feature/long-form-content/guard/lfc-document.resolve.guard";
import { CountrySelectionService } from "app/feature/country-selection/country-selection.service";
//Kendo
import { SortableModule } from "@progress/kendo-angular-sortable";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AlexRouteReuseStrategy } from "app/core/route-reuse-strategy/alex-route-reuse-strategy";
import { AlexNotificationService } from "app/shared/components/notification/notification.service";
import { AlexAppCacheService } from "app/core/services/cache/cache.service";
import { AlexAppRouteRedirect } from "app/feature/app-route-redirect.component";
import { AlexLowerCaseUrlSerializer } from "app/core/url-serializers/lower-case-url-serializer.provider";
import { AlexSearchViewModelService } from "app/feature/search/services/search-view-model.service";
import { HelpService } from "app/shared/components/help/help.service";

import { AlexPageLayoutService } from "app/shared/components/page-layout/page-layout.service";
import { AlexSkinnyHeaderService } from "app/core/services/skinny-header/skinny-header.service";
import { AppInsightService } from "app/core/logging/app-insight-service.service";
import { AlexSessionManager } from "app/core/services/session/session-manager.service";
import { SubscriptionService } from "app/shared/components/subscription/subscription.service";
import { UserAuthorizationGuard } from "app/core/guards/user-authorization.guard";
import { SpaceDocument } from "app/feature/spaces/model/space-document.model";
import { AlexFileService } from "app/core/services/file/alex-file.service";
import { AlexUserGuideService } from "app/shared/components/userGuide/user-guide.service";
import { DocumentProcessorService } from "app/shared/components/document-viewer/document-processor.service";
import { TocSectionSearchDataService } from "app/feature/long-form-content/toc-section-search/toc-section-search.data-service";
import { TocSearchDocumentSyncService } from "app/feature/long-form-content/toc-section-search/toc-search-document-sync.service";
import { TocSectionSearchStateManagerService } from "app/feature/long-form-content/toc-section-search/toc-section-search-state-manager.service";
import { DocumentViewerService } from "app/shared/components/document-viewer/document-viewer.service";
import { BinaryDownloadGuard } from "./feature/long-form-content/guard/binary-download.guard";
import { SpecialCharacterResolver } from "./core/factories/special-chaeacters/special-character.resolver";
import { SessionConfigInterceptor } from "./core/interceptor/session-http-config.interceptor";
import { SpinnerHttpConfigInterceptor } from "./core/interceptor/spinner-http-config.interceptor";
import { NotificationHttpConfigInterceptor } from "./core/interceptor/notification-http-config.interceptor";
import { TokenHttpConfigInterceptor } from "./core/interceptor/token-http-config.interceptor";
import { EditionHttpConfigInterceptor } from "./core/interceptor/edition-http-config.interceptor";
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { AuthInterceptor } from "./auth.interceptor";
import { AppConfig } from "./app.config";
import { ModalModule } from "ngx-bootstrap/modal";
import { TokenService } from "./token.service";
import { environment } from "environments/environment";


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


// export function getAppConfig() {
//   return () => AppConfig.load();
// }

export function setToken(tokenService: TokenService) {
  return () => new Promise<void>(resolve => {
    navigator.serviceWorker.getRegistration('/sw.js').then(registration => {
      // this.urlResolverService.setIsToken(false);
  let isSWInstalled;
      const authSWChannel = new BroadcastChannel("auth");

      authSWChannel.onmessage = event => {
        if(event.data.type === 'SW_INSTALLED'){
          isSWInstalled = true;
        }
        if (event.data.type === 'AUTH_TOKEN_REQUEST') {
          authSWChannel.postMessage({
            type: 'AUTH_TOKEN_RESPONSE',
            tokens: {
              accessToken: tokenService.getTokenValue('accesstoken').secret,
              idToken: tokenService.getTokenValue('idtoken').secret
            }
          })
        }
      }
     
      tokenService.onTokenUpdate$.subscribe(() => {
        console.log('Posting updated token');
        authSWChannel.postMessage({
          type: 'AUTH_TOKEN_UPDATE',
          tokens: {
            accessToken: tokenService.getTokenValue('accesstoken').secret,
            idToken: tokenService.getTokenValue('idtoken').secret
          }
        })
      })

      const interval = setInterval(() => {
        if (tokenService.getTokenValue('accesstoken').secret && tokenService.getTokenValue('idtoken').secret 
        && registration?.active.state === "activated") {
          
          resolve();
          clearInterval(interval);
        }
      }, 1000);

    });
  });
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.L72AAD.clientId,
      authority: environment.L72AAD.provider + environment.L72AAD.tenantId,
      redirectUri: environment.L72AAD.redirectUri,
      postLogoutRedirectUri: environment.L72AAD.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: environment.L72AAD.cacheLocation,
      storeAuthStateInCookie: isIE,
    },
    system: {
      loggerOptions: {
      },
      allowRedirectInIframe: true,
    }
  })
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('', [environment.L72AAD.apiScope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.L72AAD.apiScope]
    },
    loginFailedRoute: '/'
  }
}

// TODO: remove import statement
@NgModule({
  declarations: [AppComponent, AlexAppRouteRedirect, AlexFooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AlexAppRoutingModule,
    AlexCoreModule.forRoot(),
    AlexSharedModule,
    AlexHeaderModule,
    SortableModule,
    NgIdleKeepaliveModule.forRoot(),
    MsalModule,
    ModalModule
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: setToken,
      deps: [TokenService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },

    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    HeaderTabsService,
    SearchParamService,
    AllSourcesService,
    AlexUserResolver,
    AlexContextualBarService,
    SpinnerService,
    AlexAppService,
    AlexUrlResolverService,
    AlexDocumentService,
    AlexSearchCacheService,
    DocumentProcessorService,
    AlexSearchViewModelService,
    AddToAlexService,
    HelpService,
    AlexFileService,
    AlexSessionManager,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionConfigInterceptor,
      multi: true,
      deps: [AlexSessionManager, AlexUrlResolverService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerHttpConfigInterceptor,
      multi: true,
      deps: [SpinnerService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationHttpConfigInterceptor,
      multi: true,
      deps: [AlexNotificationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenHttpConfigInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EditionHttpConfigInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: AlexRouteReuseStrategy,
    },
    {
      provide: UrlSerializer,
      useClass: AlexLowerCaseUrlSerializer,
    },
    AlexLfcDocumentResolveGuard,
    BinaryDownloadGuard,
    CountrySelectionService,
    AlexNotificationService,
    AlexAppCacheService,
    AlexPageLayoutService,
    AlexSkinnyHeaderService,
    AppInsightService,
    UserAuthorizationGuard,
    SubscriptionService,
    SpaceDocument,
    AlexUserGuideService,
    TocSectionSearchDataService,
    TocSearchDocumentSyncService,
    TocSectionSearchStateManagerService,
    DocumentViewerService,
    SpecialCharacterResolver,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
