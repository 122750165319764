
import {map} from 'rxjs/operators';
import { Injectable, ViewChild } from "@angular/core";
import { AlexUrlResolverService } from "app/core/services";
import { AlexLocationModel } from "app/shared/components/location/alex-location.model";
import { AlexLocationQueryModel } from "app/shared/components/location/alex-location-query.model";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";


@Injectable()
export class AlexLocationService {
    locationDetail: AlexLocationModel;
    locationDetail$: Subject<AlexLocationModel>;
    locationDetailTop$: Subject<AlexLocationModel>;
    constructor(private http: HttpClient, private urlResolver: AlexUrlResolverService) {

        this.locationDetail$ = new Subject<AlexLocationModel>();
        this.locationDetailTop$ = new Subject<AlexLocationModel>();
    }

    fetchLocationDetails(locationQueryDetails: AlexLocationQueryModel, type: string) {

        return this.http.post(this.urlResolver.resolveUrlApiV1('/common/LocationDetails'), locationQueryDetails).pipe(
            map(response => {
                return response as AlexLocationModel;
            })).subscribe(locationData => {
                this.locationDetail = locationData;
                if (type == "Top")
                    this.locationDetailTop$.next(this.locationDetail);
                else
                    this.locationDetail$.next(this.locationDetail);
            });
    }

    fetchLocationTop(locationQueryDetails: AlexLocationQueryModel) {
        this.fetchLocationDetails(locationQueryDetails, "Top");

    }
    fetchLocationMid(locationQueryDetails: AlexLocationQueryModel) {
        this.fetchLocationDetails(locationQueryDetails, "Mid");
        //this.locationDetail$.next(this.locationDetail );
    }
}