import { Subject } from "rxjs";
import { SafeHtml } from "@angular/platform-browser";
import { AlexLocationQueryModel } from "app/shared/components/location/alex-location-query.model";
import { Injectable } from "@angular/core";

@Injectable()
export class CKDSearchContextualBarService {

    breadCrumbText$: Subject<string>;
    breadCrumbHtml$: Subject<SafeHtml>;
    breadCrumbTooltip$: Subject<string>;
    locationQueryDetail$:Subject<AlexLocationQueryModel>;
    locationQuery: AlexLocationQueryModel;
    constructor() {
        this.breadCrumbText$ = new Subject<string>();
        this.breadCrumbHtml$ = new Subject<SafeHtml>();
          this.breadCrumbTooltip$ = new Subject<string>();
          this.locationQueryDetail$ = new Subject<AlexLocationQueryModel>();
    }
    updateBreadCrumbText(text: string): void {
        this.breadCrumbText$.next(text);
    }
    updateTooltip(text: string): void {
        this.breadCrumbTooltip$.next(text);
    }
    updateBreadCrumbHtml(htmlContent: SafeHtml): void {
        this.breadCrumbHtml$.next(htmlContent);
    }

    updateLocationQueryDetails(locationQuery: AlexLocationQueryModel): void {
        this.locationQueryDetail$.next(locationQuery);
        //for mobile
        this.locationQuery = locationQuery;
    }
}