<div
  #cnb
  [@slideContextualBar]="slideContextualBar"
  class="col-sm-12 col-12 blackbar affix d-none d-lg-block"
>
  <div class="col-lg-9 col-8 iconPart">
    <ul>
      <alex-svg-icon
        cssClass="prevArrow deselectArw"
        [attr.disabled]="!alexRouteStatus?.canGoBack"
        [ngClass]="{ navigationActive: alexRouteStatus?.canGoBack }"
        icon="back-icon"
        (click)="navigateBack()"
        title="Back"
      ></alex-svg-icon>
      <alex-svg-icon
        cssClass="nextArrow deselectArw"
        icon="forward-icon"
        [attr.disabled]="!alexRouteStatus?.canGoForward"
        [ngClass]="{ navigationActive: alexRouteStatus?.canGoForward }"
        (click)="navigateFwd()"
        title="Forward"
      ></alex-svg-icon>
      <alex-svg-icon
        cssClass="locationHover"
        icon="location-icon"
        (click)="clickLocation()"
        [ngClass]="{ treemenuSelect: showPopOver, locationCursor: showPointer }"
      ></alex-svg-icon>
      <alex-location-top [showPopOver]="showPopOver"></alex-location-top>
      <!--
                [ngClass]="{'locationCursor':showPointer}"
                <li class="home">{{breadCrumb}}</li>-->
      <!--<li class="breadCrumbText">
                <div class="breadText breadCrumbEllipsis">
                    {{breadCrumb}}
                </div>
            </li>-->
      <li class="breadCrumbText">
        <span *ngIf="!isSafeHtml" [title]="breadCrumb">{{ breadCrumb }}</span>
        <span
          *ngIf="isSafeHtml"
          [innerHtml]="breadCrumbHtml"
          [title]="tooltip"
        ></span>
      </li>
    </ul>
  </div>
  <div class="col-lg-3 col-4 iconPartRt">
    <ul>
      <ng-content select="[icons]"></ng-content>
    </ul>
  </div>
</div>
