export class OwlCarouselOptions {
    navContainer;
    dotsContainer: any;
    animateIn: any;
    animateOut: any;
    videoWidth: any;
    videoHeight: any;
    video: any;
    items: any;
    margin: any;
    nav: any;
    navText: any;
    // navElement : any;
    responsiveRefreshRate: any;
    slideBy: any;
    dots: any;
    dotsEach: any;
    dotData: any;
    loop: any;
    center: any;
    rewind: any;
    mouseDrag: any;
    touchDrag: any;
    pullDrag: any;
    freeDrag: any;
    stagePadding: any;
    merge: any;
    mergeFit: any;
    autoWidth: any;
    startPosition: any;
    rtl: any;
    smartSpeed: any;
    fluidSpeed: any;
    dragEndSpeed: any;
    responsive: any;
    URLhashListener: any;
    responsiveRefshRate: any;
    responsiveBaseElement: any;
    fallbackEasing: any;
    info: any;
    nestedItemSelector: any;
    itemElement: any;
    stageElement: any;
    refreshClass: any;
    loadedClass: any;
    loadingClass: any;
    rtlClass: any;
    responsiveClass: any;
    dragClass: any;
    itemClass: any;
    stageClass: any;
    stageOuterClass: any;
    grabClass: any;
    autoHeigh: any;
    autoPlay: any;
    autoplayTimeout: any;
    autoplayHoverPause: any;
    lazyLoad: any;
    mouseScroll: any;
    preload: any;
    preloadCount: any;
    maxItemCount: any;
    autoHeight: any;

    constructor() {
        this.navContainer = false;
        this.dotsContainer = false;
        this.animateIn = false;
        this.animateOut = false;
        this.videoWidth = false;
        this.videoHeight = false;
        this.video = false;
        this.items = 5;
        this.margin = 5;
        this.nav = true;
        this.navText = [`<div>
           <span>
           <img src ="./assets/fonts/leftArrow.svg">
                                </span>
                                </div>`,
            `<div>
            <span>
            <img src ="./assets/fonts/rightArrow.svg">
                           </span>
                           </div>`];
        //this.navElement = "div";
        this.responsiveRefreshRate = 200;
        this.slideBy = 1;
        this.dots = false;
        this.dotsEach = false;
        this.dotData = false;
        this.loop = false;
        this.center = false;
        this.rewind = false;
        this.mouseDrag = true;
        this.touchDrag = true;
        this.pullDrag = true;
        this.freeDrag = false;
        this.stagePadding = 0;
        this.merge = false;
        this.mergeFit = true;
        this.autoWidth = false;
        this.startPosition = 0;
        this.rtl = false;
        this.smartSpeed = 250;
        this.fluidSpeed = false;
        this.dragEndSpeed = false;
        this.responsive = {
            400: {
                items: 1
            },
            768: {
                items: 3
            },
            1024: {
                items: 5
            }
        };
        this.URLhashListener = false;
        this.responsiveRefshRate = 200;
        this.responsiveBaseElement = window;
        this.fallbackEasing = "swing";
        this.info = false;
        this.nestedItemSelector = false;
        this.itemElement = "div";
        this.stageElement = "div";
        this.refreshClass = "owl-refresh";
        this.loadedClass = "owl-loaded";
        this.loadingClass = "owl-loading";
        this.rtlClass = "owl-rtl";
        this.responsiveClass = false;
        this.dragClass = "owl-drag";
        this.itemClass = "owl-item";
        this.stageClass = "owl-stage";
        this.stageOuterClass = "owl-stage-outer";
        this.grabClass = "owl-grab";
        this.autoHeight = true;
        this.autoPlay = false;
        this.autoplayTimeout = 1000;
        this.autoplayHoverPause = false;
        this.lazyLoad = true;
        this.mouseScroll = false;
        this.preload = false;
        this.preloadCount = 0;
        this.maxItemCount = 10000;
    }

}