import { Injectable } from "@angular/core";
import { ALLOWED_TAGS } from './document.constant'
import { TOCSearchSectionModel } from "app/feature/long-form-content/toc-section-search/toc-search-section.model";
import { ProcessedNode } from "app/shared/components/document-viewer/document-processor.model";
@Injectable()
export class DocumentProcessorService {
    constructor() {
        //   const worker = new Worker('/worker/worker-lfc.js');
    }
    tocSectionSearchList: Array<TOCSearchSectionModel>;
    killDocId: string;
    docString: any;
    updateSearchList(searchList: Array<TOCSearchSectionModel>, killDocId: string) {
        this.tocSectionSearchList = searchList;
        this.killDocId = killDocId;
    }

    highlightDocument(doc: string): Document {

        const parser = new DOMParser();
        const html = parser.parseFromString(doc, 'text/html');
        if (!this.tocSectionSearchList || !this.killDocId)
            return html;

        const model = this.tocSectionSearchList.find(tocSectionSearch => tocSectionSearch.docId === this.killDocId);
        const nodeList = html.querySelectorAll('.tocsearchhit');

        model.searchExcerptModels = model.searchExcerptModels.map(searchExcerptModel => {
            let excerptText = <any>searchExcerptModel.text;
            excerptText = excerptText.replace(new RegExp("<span class='tocsearchhit'>", "g"), "")
                .replace(new RegExp("</span>", "g"), "").trim().toLowerCase();
            if (this.findTextInNodeCollection(nodeList, excerptText, searchExcerptModel.excerptId)) {
                searchExcerptModel.matchFound = true;
            }
            return searchExcerptModel;
        });

        return html;
    }

    findTextInNodeCollection(nodeList: NodeList, searchtext: string, excerptId: string): boolean {
        const processedNodeCollection = new Array<ProcessedNode>();
        for (let i = 0; i < nodeList.length; i++) {
            const processedNode = new ProcessedNode();
            processedNode.currentNode = processedNode.startNode = nodeList[i];
            processedNode.searchText = searchtext;
            processedNode.trimmedSearchText = this.replaceSpace(searchtext)
                .toLowerCase();
            processedNodeCollection.push(processedNode);
            if (!this.isAlreadyProcessed(<any>processedNode.currentNode)) {
                this.findTextInNode(processedNode);
            }
        }
        try {
            const sortedNode = processedNodeCollection
                .filter(processedNode => processedNode.matchFound && !this.isAlreadyProcessed(<any>processedNode.currentNode))
                .sort((nodeA, nodeB) => {
                    if (nodeA.stepCount < nodeB.stepCount)
                        return -1;
                    if (nodeA.stepCount > nodeB.stepCount)
                        return 1;
                    return 0;
                });
            const nearestParentNode = sortedNode.shift();

            if (nearestParentNode) {
                nearestParentNode.matchExcerptCollection.push(excerptId);
                (<Element>nearestParentNode.currentNode).classList.add('matchedNode');
                (<Element>nearestParentNode.startNode).classList.add('matchedNode');
                this.updateExcerptIdInContentRef(nearestParentNode);
                return true;
            }
        } catch (error) {
            console.log('Node not found for excerpt #' + excerptId);
        }

        return false;
    }

    isAlreadyProcessed(element: Element): boolean {
        return element.classList &&
            element.classList.contains("matchedNode");
    }

    findTextInNode(node: ProcessedNode): ProcessedNode {

        if (node.currentNode.nodeName.toUpperCase() == 'BODY') {
            node.messages.push("Reached body tag");
            return node;
        }

        let matchIndex = this.replaceSpace(node.currentNode.textContent)
            .toLowerCase().indexOf(node.trimmedSearchText);

        if (matchIndex == -1) {
            if (node.currentNode.parentElement) {
                node.currentNode = node.currentNode.parentElement;
                node.stepCount++;
                this.findTextInNode(node);
            } else {
                node.messages.push('Parent Node not Found!');
            }
        } else {
            node.matchFound = true;
            node.matchStartIndex = matchIndex;
            node.matchEndIndex = matchIndex + node.trimmedSearchText.length;
        }
        return node;
    }

    updateExcerptIdInContentRef(node: ProcessedNode) {
        if (node.matchExcerptCollection && node.matchExcerptCollection.length) {
            (<Element>node.currentNode).classList.add('matchParent');
            (<Element>node.currentNode).id = node.matchExcerptCollection[0];
        }
    }

    isTextOnlyNode(node: Node): boolean {
        return (node.nodeType === node.TEXT_NODE) ||
            (node.textContent === (<any>node).innerHTML);
    }

    replaceSpace(text: string): string {
        return text.replace(new RegExp(" ", "g"), "")
            .replace(new RegExp(String.fromCharCode(160), "g"), "")
            .replace(new RegExp(String.fromCharCode(9), "g"), "")
            .replace(new RegExp(String.fromCharCode(10), "g"), "")
            .replace(new RegExp(String.fromCharCode(11), "g"), "")
            .replace(new RegExp(String.fromCharCode(12), "g"), "")
            .replace(new RegExp(String.fromCharCode(13), "g"), "");
    }
}
