export const SPACES_PAGE = 'Spaces';
export const SAVED_SEARCH_PAGE = 'SavedSearch';
export const SPACES_DOCUMENT_PAGE = 'SpacesDocument';

export const TASK_FETCH_SPACES_DATA = 'FETCH_SPACES_DATA';
export const TASK_FETCH_SAVED_SEARCH_DATA = 'FETCH_SAVED_SEARCH_DATA';
export const TASK_FETCH_SPACES_DOCUMENTS_DATA = 'FETCH_SPACES_DOCUMENTS_DATA';
export const TASK_RENDER_VIEW = 'RENDER_VIEW';


