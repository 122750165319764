import { Component } from "@angular/core";
import { KcwSearchBoxComponent } from "../kcw-search-box/kcw-search-box.component";

@Component({
  selector: "alex-kcw-search-box-mobile",
  templateUrl: "./kcw-search-box-mobile.component.html",
  styleUrls: ["./kcw-search-box-mobile.component.scss"],
  host: {
    "(document:click)": "onClick($event)",
  },
})
export class KcwSearchBoxMobileComponent extends KcwSearchBoxComponent {
  isVisible = false;
  clickHistory() {
    this.isVisible = true;
    event.stopPropagation();
  }
  hideDiv() {
    this.isVisible = false;
  }
}
