import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { DateSelectionType } from "app/feature/search/model/filters/date-selction-type.enums";
import { AlexDropdownItemModel } from "app/shared/components/dropdown/dropdown-item.model";
import { SearchResultViewMode } from "app/feature/search/enums/alex-search-enums";
import { CKDSearchFilterModel } from "../models/ckd-search-filter.model";
import { AlexUrlResolverService } from "app/core/services";
import { CKDSeeAllResultFilterModel } from "../models/ckd-see-all-result-fiter.model";
import {
  COUNTRY_FILTER_PARAM,
  WORKFLOW_FILTER_PARAM,
  MAX_FILTER_ORDER_PARAM,
  VIEW_MODE_PARAM,
  SORT_ORDER_PARAM,
  VERSION_FILTER_PARAM,
} from "../constants/ckd-search-url.constants";

@Injectable()
export class CKDSearchParamService {
  tocTitleFromShowmore: string;
  filterParam: CKDSearchFilterModel;
  private _uniqueUrlKey = 1;
  get UniqueUrlKey() {
    this._uniqueUrlKey++;
    return this._uniqueUrlKey % 2;
  }
  public recentSearch = false;
  public shouldLoadAllFilter = true;
  recentClick = false;
  filterOrderMaxValue = 0;
  reloadAllFilters = true;
  dateSelectionType: DateSelectionType;
  allSourcesChecked: boolean;
  taxonomyModelArray: any;
  searchTerm: string;
  searchFor: string;
  typedTerm: string;
  suggestionLength: number;
  refreshSourceFilter: boolean;
  ckdSearchQueryParam$ = new Subject<string>();

  ///
  toDate: string;
  fromDate: string;
  config: any;
  constructor(private urlResolver: AlexUrlResolverService) {
    this.config = this.urlResolver.getManualSearchConfig() || {};
    this.filterParam = new CKDSearchFilterModel();
    this.filterParam.mxo = 0;
    this.filterParam.sort = this.getSortDropdownPristineValue()[0].value;
    this.filterParam.viewMode = SearchResultViewMode.Default;
    this.filterParam.country = this.config.defaultCountry;
    this.filterParam.workflow = this.config.defaultWorkflow;
    this.filterParam.versions = new Array<string>();
  }

  getSortDropdownPristineValue(): Array<AlexDropdownItemModel> {
    return this.config.sortDropdownPristineValue;
  }

  setUniqueKey(key: number): void {
    this._uniqueUrlKey = key;
  }
  setFilterParamMaxOrder(order: number) {
    this.filterParam.mxo = order;
  }
  getFilterParamMaxOrder() {
    return this.filterParam.mxo;
  }

  getFilterData(): CKDSearchFilterModel {
    return this.filterParam;
  }

  clearAllFilter() {
    this.clearAdvanceFilter();
    this.reloadAllFilters = true;
    this.filterParam.mxo = 0;
    //this.allSourcesService.updateTaxonomySelection();
  }

  clearAdvanceFilter(advance = false) {
    this.filterParam.sort = "";
  }
  getRouteParam(): any {
    const routeParam = {};
    if (this.filterParam.country) {
      routeParam[COUNTRY_FILTER_PARAM] = this.filterParam.country;
    } else {
      routeParam[COUNTRY_FILTER_PARAM] = this.config.defaultCountry;
    }
    if (this.filterParam.workflow) {
      routeParam[WORKFLOW_FILTER_PARAM] = this.filterParam.workflow;
    } else {
      routeParam[WORKFLOW_FILTER_PARAM] = this.config.defaultWorkflow;
    }
    if (this.filterParam.versions) {
      routeParam[VERSION_FILTER_PARAM] = this.filterParam.versions;
    }
    if (this.filterParam.sort) {
      routeParam[SORT_ORDER_PARAM] = this.filterParam.sort;
    }
    if (this.filterParam.mxo) {
      routeParam[MAX_FILTER_ORDER_PARAM] = this.filterParam.mxo;
    }
    routeParam[VIEW_MODE_PARAM] = this.filterParam.viewMode;

    return routeParam;
  }
  updateSearchTerm(term: string) {
    this.filterParam.q = term || "";
    this.ckdSearchQueryParam$.next(term);
  }
  setCountryFilter(country: string) {
    this.filterParam.country = country || this.config.defaultCountry;
  }
  setWorkflowFilter(workflow: Array<string>) {
    this.filterParam.workflow = workflow || [this.config.defaultWorkflow];
  }
  setValidWorkflowTypesFilter(validWorkflowTypes: Array<string>) {
    this.filterParam.validWorkFlowTypes = validWorkflowTypes || [];
  }
  setVersionFilter(version: Array<string>) {
    this.filterParam.versions = version || [];
  }
  setValidVersions(version: Array<string>) {
    this.filterParam.validVersions = version || [];
  }
  setSortFilter(key: string) {
    let selectedIndex = this.getSortDropdownPristineValue().findIndex(
      (item) => item.value == key
    );
    if (selectedIndex == -1) {
      selectedIndex = 0;
      console.log("invalid sort order. using default sort order");
    }
    this.filterParam.sort = this.getSortDropdownPristineValue()[
      selectedIndex
    ].value;
  }
  setViewMode(viewMode: SearchResultViewMode) {
    this.filterParam.viewMode = viewMode;
  }
  setSeeAllResultFilter(filter: CKDSeeAllResultFilterModel) {
    this.filterParam.seeAllResult = filter;
  }
}
