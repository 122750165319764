export const SOURCE_FILTER_PARAM = 'sources';
export const TOPIC_FILTER_PARAM = 'topics';
export const OTHERCATEGORY_FILTER_PARAM = 'othercategory';
export const COUNTRY_FILTER_PARAM = 'country';
export const DATE_FILTER_PARAM = 'date';
export const DOMAIN_FILTER_PARAM = 'domain';
export const FILE_FILTER_PARAM = 'filetypes';
export const LANGUAGE_FILTER_PARAM = 'langs';
export const SORT_ORDER_PARAM = 'sort';
export const PARENT_DOC_ID_FILTER_PARAM = 'parentDocId';
export const DOC_ID_FILTER_PARAM = 'docId';
export const MAX_FILTER_ORDER_PARAM = 'mxo';
export const VIEW_MODE_PARAM = 'vm';
export const UNIQUE_KEY_PARAM = 'x';
export const IS_ALL_SOURCE_SELECTED = 'als';

export const SEARCH_TEXT_QUERY_PARAM = 'q';

export const SHOW_MORE_TEXT_URL_IDENTIFIER = 'more';
export const WORKFLOW_FILTER_PARAM = 'wflow';
export const TOC_REF_FILTER_PARAM = 'tocref';
export const VERSION_FILTER_PARAM = 'version';
