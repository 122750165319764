import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { CarouselItem } from "app/shared/components/carousel/model/carousel-item.model";
import { Space } from "app/feature/spaces/model/space.model";
import { CarouselAction } from "app/shared/components/carousel/model/carousel.model";

@Component({
  selector: 'alex-carousel-item-type2',
  templateUrl: './carousel-item-type2.component.html',
  styleUrls: ['./carousel-item-type2.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class AlexCarouselItemType2Component implements OnInit {
  @Input() item: Space;
  @Input() showMoreAction: boolean;
  @Output() onTitleClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() editTileTitle: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteTile: EventEmitter<any> = new EventEmitter<any>();
  private showTooltip: boolean = false;
  carouselAction: CarouselAction = null;
  constructor(private eref: ElementRef) {

  }

  ngOnInit() {

  }

  onTileClick($event: Event, data: any) {
    if (!this.carouselAction)
      this.onTitleClicked.emit(data);
    this.carouselAction =null;
  }

  editItemTitle(id: string) {
    this.carouselAction = CarouselAction.EDITCAROUSEL;
    this.editTileTitle.emit(id);
  }

  deleteItem(id) {
    this.carouselAction = CarouselAction.DELETECAROUSEL;
    this.deleteTile.emit(id)
  }

  setshowTooltip($event) {
    this.showTooltip = !this.showTooltip;
    this.carouselAction =CarouselAction.MOREACTION;
  }

  onClick(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.showTooltip = false;
    }
  }
}
