/**
 * Model for tracking properties
 */
export class TrackingPropertiesModel {
    TRACE_TYPE: string;
    SEARCH_KEY: string;
    constructor(
        public SESSION_ID: string,
        public USER_ID: string,
        public DOMAIN: string,
        public MEMBERSHIP_DOMAIN: string='',
        public ISAUTHENTICATED: boolean = false,
        public ISEXTERNALUSER: boolean = false,
        public GROUPS: string
    ) { }
}