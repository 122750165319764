
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AlexUrlResolverService } from "app/core/services";



import { HttpClient } from '@angular/common/http';


@Injectable()
export class FooterService {
    footer:string;
    constructor(private http: HttpClient, private urlResolver: AlexUrlResolverService) {

    }

    getFooterText(): Observable<string> {
        if(this.footer){
            return observableOf(this.footer);
        }
        return this.http.get<any>(this.urlResolver.resolveUrlApiV1('/common/footer')).pipe(
            map(response => {
                this.footer =<string>response.text;
                return this.footer;
            }))
    }

}
