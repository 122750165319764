import { Injectable } from "@angular/core";
import { DeviceType } from "./app.enums";
import { AppSettings } from "./app.settings";
import { AppConfig } from "app/app.config";
import { Subject, Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AlexAppService {
  mode: string;
  mode$: Subject<string>;
  frameworkType: string;
  appSettings: AppSettings;
  appHeaderHeight$: Subject<number>;
  cnbHeight$: Subject<number>;
  constructor(private appConfig: AppConfig) {
    this.appHeaderHeight$ = new BehaviorSubject<number>(0);
    this.cnbHeight$ = new BehaviorSubject<number>(0);

    this.mode$ = new Subject<string>();
    try {
      if (!this.appSettings) {
        this.appSettings = (<any>AppConfig.getInstance()).settings as AppSettings;
      }
      this.appSettings.deviceType = this.detectDevice();

      let initialLoadCount = <any>(
        this.appSettings.homePage.carousel.initialLoad
      );
      // let tileCount = <any>this.appSettings.homePage.carousel.tileCount;
      switch (this.appSettings.deviceType) {
        case DeviceType.Mobile:
          this.appSettings.homePage.carousel.initialLoad =
            initialLoadCount["mobile"];
          //this.appSettings.homePage.carousel.tileCount = tileCount["mobile"];
          break;
        case DeviceType.Tablet:
          this.appSettings.homePage.carousel.initialLoad =
            initialLoadCount["tablet"];
          //this.appSettings.homePage.carousel.tileCount = tileCount["tablet"];
          break;
        case DeviceType.Desktop:
          this.appSettings.homePage.carousel.initialLoad =
            initialLoadCount["desktop"];
          //this.appSettings.homePage.carousel.tileCount = tileCount["desktop"];
          break;
      }
      if (!this.appSettings.homePage.carousel.lazyLoad) {
        this.appSettings.homePage.carousel.initialLoad = 0;
      }
    } catch (error) {
      console.error("config parse error; using default settings");
      this.appSettings = {
        deviceType: this.detectDevice(),
        homePage: {
          carousel: {
            lazyLoad: false,
            initialLoad: 0,
            tileCount: 5,
          },
        },
      };
    }
  }
  getAppSettings(): AppSettings {
    return this.appSettings;
  }
  detectDeviceType(): DeviceType {
    return this.appSettings.deviceType;
  }
  getDeviceType() {
    if (window.innerWidth <= 320) {
      return "mobile";
    } else if (window.innerWidth > 320 && window.innerWidth <= 768) {
      return "tablet";
    } else {
      return "desktop";
    }
  }
  getMode(mode: string) {
    if (this.mode != "popOut") {
      this.mode = mode;
    }
    this.mode$.next(this.mode);
  }

  private detectDevice(): DeviceType {
    if (window.innerWidth < 320) {
      return DeviceType.Mobile;
    } else if (window.innerWidth > 320 && window.innerWidth < 768) {
      return DeviceType.Tablet;
    } else {
      return DeviceType.Desktop;
    }
  }

  setHeaderHeight(height) {
    this.appHeaderHeight$.next(height);
  }

  setCNBHeight(height) {
    this.cnbHeight$.next(height);
  }

  removeFavicon() {
    var links = document.getElementsByTagName("link");
    var head = document.getElementsByTagName("head")[0];
    for (var i = 0; i < links.length; i++) {
      if (links[i].getAttribute("rel") === "icon") {
        head.removeChild(links[i]);
      }
    }
  }

  setFavicon() {
    let url = "favicon.ico";
    this.removeFavicon();
    var link = document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "icon";
    link.href = url;
    document.getElementsByTagName("head")[0].appendChild(link);
    if (window.console)
      console.log("Set FavIcon URL to " + this.getFavicon().href);
  }

  getFavicon() {
    var links = document.getElementsByTagName("link");
    for (var i = 0; i < links.length; i++) {
      if (links[i].getAttribute("rel") === "icon") {
        return links[i];
      }
    }
    return undefined;
  }

  isScrollReachedToBottm(element: HTMLElement) {
    if (element) {
      const diff = element.scrollHeight - element.scrollTop;
      const hasScrollReachedBottom =
        Math.round(diff) <= Math.round(element.clientHeight);
      return hasScrollReachedBottom;
    }
    return false;
  }

  hasScrollBar(element, IEScrollHeightOffset = 0) {
    return element.scrollHeight > element.clientHeight + IEScrollHeightOffset;
  }

  lastY;
  touchDirection($event) {
    let direction = "";
    let currentY = $event.touches[0].clientY;
    if (currentY > this.lastY) {
      direction = "down";
    } else if (currentY < this.lastY) {
      direction = "up";
    }
    this.lastY = currentY;
    return direction;
  }

  detectMouseWheelDirection(e) {
    var delta = null,
      direction = "";
    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }
    if (e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox
      delta = -e.detail / 2;
    }
    if (delta !== null) {
      direction = delta > 0 ? "up" : "down";
    }
    return direction;
  }

  lastScrollTop = 0;
  getScrollDirection(element) {
    let direction = "";
    let st = element.scrollTop;
    if (st > this.lastScrollTop) {
      direction = "down";
    } else if (st == this.lastScrollTop) {
      //do nothing
      //In IE this is an important condition because there seems to be some instances where the last scrollTop is equal to the new one
    } else {
      direction = "up";
    }
    this.lastScrollTop = st <= 0 ? 0 : st;
    return direction;
  }
}
