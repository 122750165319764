
 <div class="treeStructure" [ngClass]="{'displayFlyout':showPopOver}" >    
            <ng-container *ngIf="locationDetail!=null && locationDetail?.id!=null">
             <svg class="svgIm oceanBlue treeLocationIcon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22px" height="1px" viewBox="0 0 22 1" xml:space="preserve">
                        <g transform="scale(1.9)">
                        <g transform="translate(-55 -108)" xmlns="http://www.w3.org/2000/svg" id="map-marker_2_">
                            <path d="M63.972,106.585c0,0.881-0.183,1.838-0.727,2.701c-1.638,2.544-3.711,4.878-3.711,4.878s-2.383-2.253-3.906-4.924
                        c-0.382-0.834-0.631-1.716-0.631-2.6c0-2.483,2.008-4.64,4.488-4.64C61.963,102,63.972,104.102,63.972,106.585z M62.208,106.404
                        c0-1.484-1.202-2.683-2.683-2.683c-1.481,0-2.683,1.2-2.683,2.683c0,1.483,1.202,2.682,2.683,2.682
                        C61.006,109.086,62.208,107.886,62.208,106.404z"></path>
                                </g>
                            </g>	
         </svg>
         <alex-location-tree-item isRootNode=true [locationDetail]="locationDetail"></alex-location-tree-item>
             
              


                </ng-container>
            </div> 
