import { Injectable } from "@angular/core";

/**
 * A service to parse parametered message strings.
 *
 * Following format messages are parsed:
 *
 * Example: `A string with ${param.someParameter} parameter`.
 * Here the parameter is `someParameter`.
 *
 */
@Injectable({
  providedIn: "root",
})
export class ParameteredMessageParseService {
  /**
   * A method to parse parametered message with provided parameters.
   * @param parameteredMessage Parametered message to be parsed.
   * @param params A key-value pair object specifying the keys to be parsed with values.
   * @returns Parsed message after replacing parameteres in given message string with parameter values.
   */
  parseMessage(
    parameteredMessage: string,
    params: Readonly<{ [key: string]: string }> = {}
  ): string {
    const parsedMessage = this.formatMessage(parameteredMessage, params);
    return parsedMessage;
  }

  /**
   * A method for formatting parametered messages with provided parameter values.
   * @param parameterdMessage Parametered Message to be parsed.
   * @param params A key-value pair object specifying the keys to be parsed with values.
   * @returns Parsed message after replacing parameteres in given message string with parameter values.
   */
  private formatMessage(
    parameterdMessage: string,
    params: Readonly<{ [key: string]: string }> = {}
  ): string {
    let formattedMessage = parameterdMessage;
    if (typeof params === "object") {
      Object.keys(params).forEach((key) => {
        formattedMessage = formattedMessage.replace(
          "${param." + key + "}",
          params[key]
        );
      });
    }
    return formattedMessage;
  }
}
