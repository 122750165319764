<alex-carousel-item-header-left [docType]="docType" [mimeType]="mimeType">

</alex-carousel-item-header-left>
<div class="flyouts flySrch" (click)="flyoutClicked()" title="More Actions">
    <svg class="svgIm carouselSvgTopRight" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        width="34px" height="1px" viewBox="0 0 28 1" style="fill: #929292" xml:space="preserve">
        <g transform="scale(1.8)">
            <g transform="translate(-340.5,-299.1)" xmlns="http://www.w3.org/2000/svg" id="option-horizontal">
                <path id="divFlyOut"  style="fill-rule:evenodd;clip-rule:evenodd;" d="M344.5,298c0.828,0,1.5,0.672,1.5,1.5s-0.672,1.5-1.5,1.5
                                                        s-1.5-0.672-1.5-1.5S343.672,298,344.5,298z M347,299.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5
                                                        S347,298.672,347,299.5z M351,299.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5S351,298.672,351,299.5z"
                />
            </g>
        </g>
    </svg>

    <!--tooltip second section start-->
    <div class="Alextooltiptext flyLayoutTxt flyoutScoll" [ngClass]="{'displayFlyout':clicked}">
        <ul   >
            <li  (click)="clickAddToSpace($event)" title="Add to Spaces">
                <span>
                            <svg class="svgIm steelgray" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="30px" height="1px" viewBox="0 0 49 1" xml:space="preserve">
                                <g transform="scale(1.9)">
                                    <g transform="translate(-476,-371.6)" xmlns="http://www.w3.org/2000/svg" id="cube-black">
                                        <path d="M494,370.5v7c0,0.276-0.224,0.5-0.5,0.5h-7c-0.276,0-0.5-0.224-0.5-0.5v-7c0-0.276,0.224-0.5,0.5-0.5h7
			                C493.776,370,494,370.224,494,370.5z M496.25,366h-6.344c-0.276,0-0.668,0.148-0.875,0.33l-2.656,2.339
			                c-0.207,0.183-0.151,0.331,0.125,0.331h7.25c0.276,0,0.643-0.172,0.82-0.384l1.86-2.232C496.607,366.172,496.526,366,496.25,366z
			                 M497.675,366.88l-2.349,2.741c-0.18,0.21-0.325,0.604-0.325,0.88v7c0,0.276,0.146,0.33,0.325,0.12l0.837-0.977l1.512-1.764
			                c0.18-0.21,0.325-0.604,0.325-0.88v-7C498,366.724,497.854,366.67,497.675,366.88z" />
                                    </g>
                                </g>
                            </svg>
                                </span>
                <div class="toolTiopListText"  >Add to Spaces</div>
                <!--/*flyout sub list section*/-->
                <ul class="flyoutSubList float-left" *ngIf="ifAddToAlex">


                </ul>
            </li>
            <!-- <li  data-toggle="modal" data-target='#shareModalToolTip' title="Share"> -->
                <li  title="Share">
                <span>
                                    
                            <svg class="svgIm  greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30px"
                                    height="1px" viewBox="0 0 38 1"  xml:space="preserve">
                            <g transform="scale(1)">
                                <g transform="translate(-145,-1128)" xmlns="http://www.w3.org/2000/svg" id="share_2_">
                                    <path d="M179.098,1128.293l-10.678,7.91c-0.222,0.164-0.402,0.072-0.402-0.203l-0.006-5.002 c-3.017-0.023-9.574-0.105-11.512,4.505c0.139-9.503,9.264-10.8,11.505-10.976l-0.005-4.514c0-0.275,0.182-0.367,0.402-0.203			l10.695,7.889C179.318,1127.862,179.319,1128.129,179.098,1128.293z"></path>
                                </g>
                            </g>
                        </svg>
                                </span>
                <div class="toolTiopListText" (click)="clickShare()">Share</div>
                <!---swarnava-->
                <!--<div *ngIf="showShareDiv">
                    <input style="color:red" #clipboardInput type="text" [(ngModel)]="path" readonly/>
                    <button type="button" (click)="copyContent(clipboardInput)">Copy To Clipboard</button>
                </div>-->
                <!---swarnava-->
            </li>
            <li title="Preview" class="mobile-preview item-group-b" *ngIf="domainService.checkIsPreviewAllowed(mimeType)!='other'" (click)="clicktoView()"> 
                <span>
                                            <svg class="svgIm greyColor1" xmlns="http://www.w3.org/2000/svg" width="21" height="16"  viewBox="0 0 16 16" xml:space="preserve" style="
                                            padding-left: 4px;
                                            padding-right: 1px;
                                            margin-right: 0px;
                                            margin-left: 5px;
                                        ">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" ></path></svg>
                                            </span>
                <div class="toolTiopListText" style="padding-left: 11px;">Preview</div> 
            </li>
        </ul>
    </div>
    <!--tooltip section end-->

</div>