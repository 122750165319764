import { ICombinedSearchConfig } from "../interfaces";

export const combinedSearchDefaultConfig: Readonly<ICombinedSearchConfig> = {
  categoryTypes: {
    alex: "ALEX",
    manuals: "KAEG",
    topics: "TOPIC",
    featured: {
      alex: "ALEXFEATURED",
      manuals: "KAEGFEATURED",
      both: "ALEXKAEGFEATURED",
    },
  },
  api: {
    endpoints: {
      get: {
      },
      post: {
        countryWorkflowVersions: "",
      },
    },
  },
};
