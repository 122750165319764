import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router/router";
import { AlexUrlResolverService } from "app/core/services";
import { RecentSearch } from "app/feature/header/recent-search/recent-search.model";
import { Subject } from "rxjs";
import { RecentDocument } from "app/feature/header/recent-search/recent-document.model";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable()
export class RecentSearchService {
recenSearches$:Subject<Array<RecentSearch>>
recenSearches:Array<RecentSearch>
recentDocuments$:Subject<Array<RecentDocument>>
recentDocuments:Array<RecentDocument>
 constructor(private http: HttpClient, private urlResolver: AlexUrlResolverService) {
    this.recenSearches$ = new Subject<Array<RecentSearch>>();
    this.recenSearches$.next(null);
    this.recentDocuments$ = new Subject<Array<RecentDocument>>();
    this.recentDocuments$.next(null);
  }
    
  fetchRecentSearch(){
    this.http.get(this.urlResolver.resolveUrlApiV1(`user/GetRecentSearches`)).
      pipe(map(response => {
        return response as Array<RecentSearch>;
      }))
      .subscribe(recenSearches => {
        this.recenSearches = recenSearches;
        this.recenSearches$.next(recenSearches);
      });
  }
    fetchRecentDocument(){
    this.http.get(this.urlResolver.resolveUrlApiV1(`user/GetRecentDocuments`)).
      pipe(map(response => {
        return response as Array<RecentDocument>;
      }))
      .subscribe(recentDocuments => {
        this.recentDocuments = recentDocuments;
        this.recentDocuments$.next(recentDocuments);
      });
  }

}
