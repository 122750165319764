import { Injectable } from '@angular/core';
import { Status } from "app/core/services";
import { AlexNotificationModel } from "app/shared/components/notification/notification.model";
import { Subject ,  Observable, BehaviorSubject } from "rxjs";
import { Download } from 'app/shared/download';
@Injectable()
export class AlexNotificationService {
  private timeOut: string = "2000";
  private notification: AlexNotificationModel;
  public notification$: Subject<AlexNotificationModel>;
  private _noNotification: boolean = false;
  private _hideNotification: boolean = true;
  get isVisible() {
    return !this._noNotification;
  }
  public onVisibilityChange$: Subject<boolean>;

  private _data: BehaviorSubject<number> = new BehaviorSubject(0);
  private _last_fileName: BehaviorSubject<string> = new BehaviorSubject('');

  doc_download_arr:any;
  isFileDownloading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {
    this.notification = new AlexNotificationModel();
    this.notification$ = new Subject<AlexNotificationModel>();
    this.notification$.next(null);
    this.onVisibilityChange$ = new Subject<boolean>();
    // this.notification$.subscribe(item=>{
    //   alert(JSON.stringify(item) );
    // })
  }

  noNotification(status: boolean) {
    this._noNotification = status;
    this.onVisibilityChange$.next(this.isVisible);
  }

  hide() {
    if (this._hideNotification) {
      this.onVisibilityChange$.next(this.isVisible);
      this.notification.displayNotification = "hide";
      this.notification$.next(this.notification);
    }
    else {
      this._hideNotification = true;
    }

  }

  public show(status: Status, msg: string, autoHide: boolean = false, progress: boolean=false) {
    this.onVisibilityChange$.next(this.isVisible);
    this.notification = new AlexNotificationModel();
    this.notification.message = msg;
    this.notification.status = status;
    this.notification.displayNotification = "show";
    this.notification.showProgress = progress;
    this.notification$.next(this.notification);
    this._hideNotification = false;
    if (autoHide) {
     // console.log("autoHide" + autoHide);
      this._hideNotification = false;
      setTimeout(() => {
        this._hideNotification = true;
        this.hide();
      }, +this.timeOut);
    }

  }

  public showApiError(status: Status, msg: string, autoHide: boolean = false) {
    this.onVisibilityChange$.next(this.isVisible);
    this.notification = new AlexNotificationModel();
    this.notification.message = msg;
    this.notification.status = status;
    this.notification.displayNotification = "show";
    this.notification$.next(this.notification);
    this._hideNotification = true;
    if (autoHide) {
    //  console.log("autoHideapi" + autoHide);
      this._hideNotification = false;
      setTimeout(() => {
        this._hideNotification = true;
        this.hide();
      }, +this.timeOut);
    }
  }

  public get Data(): Observable<number>{
    return this._data.asObservable();
  }

  public get DownloadStatus(): Observable<boolean>{
    return this.isFileDownloading.asObservable();
  }

  SetDownloadProgressVal(progress_obj: any,downloadComplete:boolean=false){
    // setTimeout(() => {
      if((progress_obj.state="IN_PROGRESS" || progress_obj.state=="PENDING") && !downloadComplete)
      {
        this.isFileDownloading.next(true);
      }
      else{
        this.isFileDownloading.next(false);
      }
      this._data.next(progress_obj.progress);
    // }, 2000);
  }

  UpdateDownloadStatus(){
    this.isFileDownloading.next(false);
  }


}
