import { Component, OnInit } from '@angular/core';
import { AlexUserService, User, AlexAppService } from "app/core/services";

@Component({
  selector: 'alex-header-container',
  templateUrl: './header-container.component.html',
  styleUrls: ['./header-container.component.scss']
})
export class AlexHeaderContainerComponent implements OnInit {
    isAuthorizedUser:boolean;
    user:User;
    constructor(private userService:AlexUserService, public appService:AlexAppService) { 
      this.userService.isAuthorizedUser.subscribe(uAuth=>{
        this.isAuthorizedUser = uAuth;
      })
    }

  ngOnInit() {

    this.userService.getUserData().subscribe(user=>{
      this.user = user;
    })
  }

  
}
