import { filter } from "rxjs/operators";
import { AlexRoute } from "./route.model";
import { RouteScope } from "./route.enums";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AlexRouteStatus } from "./route-status.model";
import {
  NavigationEnd,
  ROUTES,
  UrlTree,
  Router,
  NavigationStart,
  ActivatedRoute,
} from "@angular/router";

@Injectable()
export class AlexNavigationService {
  private alexRouteCollection: Array<AlexRoute>;
  alexRouteStatus$: Subject<AlexRouteStatus>;
  ignoreRouteEvent: boolean;
  isRedirect: boolean;
  private _cancelCurrentRoute: boolean;
  private alexRouterStatus: AlexRouteStatus;
  store: Storage;
  ROUTE_STATE_KEY = new Date().valueOf().toString();
  private _navigationCount = 0;
  private _navigationId = 0;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.alexRouteCollection = new Array<AlexRoute>();
    this.ignoreRouteEvent = false;
    this.alexRouteStatus$ = new Subject<AlexRouteStatus>();
    this.alexRouterStatus = new AlexRouteStatus();

    this.store = window.sessionStorage || window.localStorage;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((navigationEvent) => {
        this._navigationId = (<any>navigationEvent).id;
        if (this._cancelCurrentRoute) {
        }
        this._cancelCurrentRoute = true;
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (!this.isRedirect) {
          this._navigationCount++;
        }
        this.isRedirect = false;

        if (this.ignoreRouteEvent) {
          this.ignoreRouteEvent = false;
        } else {
          let lastActiveIndex = this.alexRouteCollection.findIndex(
            (route) => route.active
          );
          const spliceLength =
            lastActiveIndex >= 0
              ? this.alexRouteCollection.length - lastActiveIndex
              : 0;
          this.alexRouteCollection = this.alexRouteCollection.map((alexRoute) =>
            Object.assign(alexRoute, { active: false })
          );
          this.alexRouteCollection.splice(
            lastActiveIndex + 1,
            spliceLength,
            new AlexRoute(this.router.url, true)
          );
        }
        this.alexRouterStatus.routeUrl = this.router.url;
        this.alexRouterStatus.canGoBack =
          this.alexRouteCollection.length &&
          !this.alexRouteCollection[0].active;
        this.alexRouterStatus.canGoForward =
          this.alexRouteCollection.length > 1 &&
          !this.alexRouteCollection[this.alexRouteCollection.length - 1].active;

        this.alexRouteStatus$.next(this.alexRouterStatus);
      });
  }

  cancelCurrentRoute() {
    this._cancelCurrentRoute = true;
  }

  navigateBack() {
    const activeRouteIndex = this.alexRouteCollection.findIndex(
      (item) => item.active == true
    );
    if (activeRouteIndex - 1 < 0) return;

    this.alexRouteCollection = this.alexRouteCollection.map((alexRoute) =>
      Object.assign(alexRoute, { active: false })
    );
    this.alexRouteCollection[activeRouteIndex - 1].active = true;
    this.router.navigateByUrl(
      this.alexRouteCollection[activeRouteIndex - 1].url
    );
    this.ignoreRouteEvent = true;
  }

  getActiveRoute() {
    this.alexRouteStatus$.next(this.alexRouterStatus);
    return this.alexRouterStatus;
  }

  navigateForward() {
    const activeRouteIndex = this.alexRouteCollection.findIndex(
      (item) => item.active == true
    );
    if (activeRouteIndex + 1 == this.alexRouteCollection.length) return;

    this.alexRouteCollection = this.alexRouteCollection.map((alexRoute) =>
      Object.assign(alexRoute, { active: false })
    );
    this.alexRouteCollection[activeRouteIndex + 1].active = true;
    this.router.navigateByUrl(
      this.alexRouteCollection[activeRouteIndex + 1].url
    );
    this.ignoreRouteEvent = true;
  }

  exportRouterState(): string {
    this.store.setItem(
      this.ROUTE_STATE_KEY,
      JSON.stringify(this.alexRouteCollection)
    );
    return this.ROUTE_STATE_KEY;
  }

  importRouterState(routeStateKey = this.ROUTE_STATE_KEY) {
    this.alexRouteCollection.unshift(
      ...JSON.parse(this.store.getItem(this.ROUTE_STATE_KEY))
    );
  }

  resetRouteCollection() {
    this.alexRouteCollection = new Array<AlexRoute>();
    this.alexRouterStatus = new AlexRouteStatus();
    this.alexRouteStatus$.next(this.alexRouterStatus);
  }
  isFirstNavigation() {
    return !this._navigationCount;
  }
  getNavigationId() {
    return this._navigationId;
  }
}
