import { NgModule } from "@angular/core";
import { AlexHeaderContainerComponent } from "app/feature/header/container/header-container.component";
import { AlexHeaderComponent } from "app/feature/header/header.component";
import { AlexHeaderSearchBoxComponent } from "app/feature/header/search-box/header-search-box.component";
import { AlexHeaderTabsComponent } from "app/feature/header/tabs/header-tabs.component";
import { AlexHeaderTaxonomyTreeComponent } from "app/feature/header/taxonomy-tree/header-taxonomy-tree.component";
import { AlexHeaderUserProfileMenuComponent } from "app/feature/header/user-profile-menu/header-user-profile-menu.component";
import { AlexHeaderSearchbarMobileComponent } from "app/feature/header/search-bar-mobile/search-bar-mobile.component";
import { AlexSharedModule } from "app/shared";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SearchParamService } from "app/feature/search/services/search-param.service";
import { TypeAheadService } from "app/feature/header/services";
import { AllSourcesComponent } from "app/feature/header";
import { AddToAlexService } from "app/shared/add-to-alex.service";
import { ParentTaxonomyIds } from "app/feature/search/model/taxonomy-model";
import { HeaderTaxonomyTreeService } from "app/feature/header/services/header-taxonomy-tree.service";
import { SpacesService } from "app/feature/spaces/spaces.service";
import { SearchItem } from "app/feature/search/model/search-item.model";
import { RecentSearchComponent } from "app/feature/header/recent-search/recent-search.component";
import { RecentSearchService } from "app/feature/header/recent-search/recent-search.service";
import { CountrySelectionComponent } from "app/feature/country-selection/country-selection.component";
import { SortableModule } from "@progress/kendo-angular-sortable";
import { CkdSearchCountrySelectorComponent } from "../ckd-search/ckd-search-country-selector/ckd-search-country-selector.component";
import { CKDHeaderSearchBoxComponent } from "../ckd-search/ckd-search-box/ckd-header-search-box.component";
import { CkdSearchWorkflowSelectorComponent } from "../ckd-search/ckd-search-workflow-selector/ckd-search-workflow-selector.component";
import { CkdSearchVersionSelectorComponent } from "../ckd-search/ckd-search-version-selector/ckd-search-version-selector.component";

import { CkdSearchCountryWorkflowSelectorService } from "../ckd-search/services/ckd-search-country-workflow-selector.service";
import { CKDHeaderSearchbarMobileComponent } from "../ckd-search/ckd-search-bar-mobile/ckd-search-bar-mobile.component";
import { CKDSearchHeaderContainerComponent } from "./ckd-search-container/ckd-search-header-container.component";
import { CKDSearchViewModelService } from "../ckd-search/services/ckd-search-view-model.service";
import { CKDSearchParamService } from "../ckd-search/services/ckd-search-param.service";
import { CombinedSearchFiltersComponent } from "./components";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { KcwSearchBoxMobileComponent } from "../kcw-search/components/kcw-search-box-mobile/kcw-search-box-mobile.component";
import { KcwSearchBoxComponent } from "../kcw-search/components";

@NgModule({
  imports: [
    AlexSharedModule,
    FormsModule,
    RouterModule,
    CommonModule,
    SortableModule,
    BsDropdownModule,
    BsDropdownModule.forRoot()
  ],
  declarations: [
    AlexHeaderContainerComponent,
    AlexHeaderComponent,
    AlexHeaderSearchBoxComponent,
    KcwSearchBoxComponent,
    AlexHeaderTabsComponent,
    AlexHeaderTaxonomyTreeComponent,
    AlexHeaderUserProfileMenuComponent,
    AlexHeaderSearchbarMobileComponent,
    KcwSearchBoxMobileComponent,
    AllSourcesComponent,
    RecentSearchComponent,
    CountrySelectionComponent,
    CkdSearchCountrySelectorComponent,
    CkdSearchWorkflowSelectorComponent,
    CkdSearchVersionSelectorComponent,
    CKDHeaderSearchBoxComponent,
    CKDHeaderSearchbarMobileComponent,
    CKDSearchHeaderContainerComponent,
    CombinedSearchFiltersComponent,
  ],
  exports: [
    AlexHeaderContainerComponent,
    AlexHeaderComponent,
    AlexHeaderSearchBoxComponent,
    KcwSearchBoxComponent,
    AlexHeaderTabsComponent,
    AlexHeaderTaxonomyTreeComponent,
    AlexHeaderUserProfileMenuComponent,
    AlexHeaderSearchbarMobileComponent,
    KcwSearchBoxMobileComponent,
    AllSourcesComponent,
    CountrySelectionComponent,
    CkdSearchCountrySelectorComponent,
    CkdSearchWorkflowSelectorComponent,
    CkdSearchVersionSelectorComponent,
    CKDHeaderSearchBoxComponent,
    CKDHeaderSearchbarMobileComponent,
    CKDSearchHeaderContainerComponent,
  ],
  providers: [
    TypeAheadService,
    ParentTaxonomyIds,
    TypeAheadService,
    HeaderTaxonomyTreeService,
    SpacesService,
    SearchItem,
    RecentSearchService,
    CkdSearchCountryWorkflowSelectorService,
    CKDSearchViewModelService,
    CKDSearchParamService,
  ],
})
export class AlexHeaderModule {}
