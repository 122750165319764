  <h1 class="carouselheading">{{carousel.title}}</h1>
<ul class="allCarousel" [ngClass]="{'carousel':carousel.displayOption== pageLayOut?.tile,
                'owl-carousel':carousel.displayOption== pageLayOut?.tile,
                'carouselWidth':carousel.displayOption== pageLayOut?.tile}">
                <ng-container *ngIf="carousel.displayOption== pageLayOut.list">
                  <ng-container *ngFor="let carouselData of carousel.curatedItems">
                    <alex-home-listview [carouselItem]="carouselData" [columnCount]="carousel.columnCount"
                      (addDocumentToSpace)="sendItemtoParent($event)" (sendItem)="sendItem($event)"></alex-home-listview>
                  </ng-container>
                </ng-container>
  <!--<ul class="listSection homeListing">-->
  <!-- <ng-content></ng-content> -->
  <owl-carousel-o [options]="carouselOptions" id="{{carousel.id}}">
    <!-- <ng-container *ngIf="carousel.displayOption== pageLayOut.list">
      <ng-container *ngFor="let carouselData of carousel.curatedItems">
        <alex-home-listview [carouselItem]="carouselData" [columnCount]="carousel.columnCount"
          (addDocumentToSpace)="sendItemtoParent($event)"></alex-home-listview>
      </ng-container>
    </ng-container> -->
    <ng-container *ngIf="carousel.displayOption==pageLayOut.tile">
      <ng-container *ngFor="let carouselItem of carousel.curatedItems">
        <ng-template carouselSlide>
          <alex-carousel-item-type1  [item]="carouselItem" [document]="carouselItem"
            (sendItemtoParent)="sendItemtoParent($event)"></alex-carousel-item-type1>
        </ng-template>
      </ng-container>
    </ng-container>
  </owl-carousel-o>
</ul>
<!--<md-spinner *ngIf="carousel.isLoading"></md-spinner>-->


