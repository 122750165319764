import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { HeaderTabs } from "app/feature/header/tabs/header-tabs.model";

@Injectable()
export class HeaderTabsService {

    tabSource: Subject<HeaderTabs>;
    tabs: HeaderTabs;
    searchQueryParams= {q:"audit"};
    constructor() {
        this.tabSource = new Subject<HeaderTabs>();
        this.tabs = new HeaderTabs();
        this.tabs.showSearch = false;
        this.tabSource.next(this.tabs);
    }

    showTab(tabName: string,queryParams : any) {
        this.tabs.showSearch = true;
        this.tabs.searchQueryParams = queryParams;
        this.tabSource.next(this.tabs);
    }
    hideTab(tabName: string) {
        this.tabs.showSearch = false;
        this.tabSource.next(this.tabs);
    }


}
