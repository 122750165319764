import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AlexUserService, User } from "app/core/services";
import { Observable } from "rxjs";

@Injectable()
export class AlexUserResolver implements Resolve<User> {

  constructor(private userService: AlexUserService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    return this.userService.getUserDataAsObservable();
  }

}
