export class CKDSearchResultClickTrackingData {
    constructor(
        public docId: string,
        public killDocId: string,
        public title: string,
        public url: string,
        public tocRef: string,
        public displayText: string,
        public tocTitle: string,
        public countries: string,
        public workFlowTypes: string,
        public weightage: string,
        public type: string
    ) { }
}