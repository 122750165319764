import { TrackingMessageModel } from "../tracking-message.model";

import { ExternalUrlClickTrackingData } from "./data";
import { EXTERNAL_URL_CLICK_TRACKING_IDENTIFIER } from "app/shared/constants/tracking/external-url-click";

/**
 * Model for tracking External URL click
 */
export class ExternalUrlClickTrackingModel extends TrackingMessageModel {
    /**
     * constructor
     * @param data Payload data
     */
    constructor(public data: ExternalUrlClickTrackingData, public searchKey: string) {
        super(EXTERNAL_URL_CLICK_TRACKING_IDENTIFIER, searchKey);
    }
}