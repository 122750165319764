import { Component, OnInit, Input,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'alex-carousel-item-header-left',
  templateUrl: './carousel-item-header-left.component.html',
  styleUrls: ['./carousel-item-header-left.component.scss']
})
export class CarouselItemHeaderLeftComponent implements OnInit {
  @Input() docType: string = "";
  @Input() mimeType: string = "";
  alexDocumentType: string;
  constructor() {
    
  }

  ngOnInit() {
    switch (this.mimeType) {
      case "application/vnd.ms-excel":
        this.alexDocumentType = "excel";
        break;
      case "application/msword":
        this.alexDocumentType = "word";
        break;
      case "text/plain":
        this.alexDocumentType = "word";
        break;
      case "text/rtf":
        this.alexDocumentType = "word";
        break;
      case "application/zip":
        this.alexDocumentType = "zip";
        break;
      case "application/xml":
        this.alexDocumentType = "file";
        break;
      case "application/vnd.ms-powerpoint":
        this.alexDocumentType = "ppt";
        break;
      case "application/pdf":
        this.alexDocumentType = "pdf";
        break;
      case "text/vnd.wap.wml":
        this.alexDocumentType = "msg";
        break;
      case "application/outlook":
        this.alexDocumentType = "msg";
        break;
      case "video/mpeg":
        this.alexDocumentType = "video";
        break;
      case "audio/mpeg":
        this.alexDocumentType = "video";
        break;
      case "audio/x-ms-wmv":
        this.alexDocumentType = "video";
        break;
      case "application/x-shockwave-flash":
        this.alexDocumentType = "video";
        break;
      case "video":
        this.alexDocumentType = "video";
        break;
      case "video/mp4":
        this.alexDocumentType = "video";
        break;
      case "web":
        this.alexDocumentType = "web";
        break;
      case "text/html":
        this.alexDocumentType = "file";
        break;
      default:
        this.alexDocumentType = "file";


    }
  }
}
