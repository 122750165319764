
import {
    of as observableOf, Observable,
    throwError
} from 'rxjs';
import { Injectable } from "@angular/core";
import { AlexUrlResolverService } from "app/core/services";
import { DomSanitizer } from "@angular/platform-browser";
import { AlexAppCacheService } from "app/core/services/cache/cache.service";
import { AlexDocumentModel } from "app/feature/long-form-content/document/document.model";
import { CarouselQuery } from "app/shared/components/carousel/model/carousel-query.model";
import { QueryCriteria } from "app/shared/components/carousel/model";
import { TOCSearchModel } from "app/feature/long-form-content/toc-section-search/toc-search.model";
import { ALEX_EDITION_IDENTIFIER } from "app/shared/constants";
import { replaceQuestionMark } from "app/shared/utils";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Router } from '@angular/router';
import { TocSearchDocumentSyncService } from './toc-search-document-sync.service';
import { TOCSearchExcerptModel } from './toc-search-excerpt.model';
import { AppConfig } from 'app/app.config';
@Injectable()
export class TocSectionSearchDataService {
    private documentCache = new Array<AlexDocumentModel>();
    binariesPath: string;
    archiveBinariesPath: string;
    theLastCharacter: string;
    newTOCModel = new TOCSearchModel;
    constructor(private http: HttpClient,
        private urlResolver: AlexUrlResolverService,
        private sanitizer: DomSanitizer,
        private tocSearchDocSyncService: TocSearchDocumentSyncService,
        private appCacheSerivce: AlexAppCacheService, private router: Router) {
        this.binariesPath = this.urlResolver.getBinariesPath();
        this.archiveBinariesPath = this.urlResolver.getArchiveBinariesPath();
    }

    /**
     * Format content according to edition id present or not
     * @param contentRef contentRef to replace
     */
    replaceBinaryPathInContent(contentRef: string): string {
        if (!contentRef) {
            return contentRef;
        }
        const editionId = sessionStorage.getItem(ALEX_EDITION_IDENTIFIER);
        if (!editionId) {
            return contentRef;
        } else {
            const nonArchiveBinariesRegEx = new RegExp(this.binariesPath, 'gi');
            const archiveBinariesPath = `${this.archiveBinariesPath}/${editionId}`;
            const newContentRef = contentRef.replace(nonArchiveBinariesRegEx, archiveBinariesPath);
            return newContentRef;
        }
    }

    fetchDocument(referenceId: string): Observable<AlexDocumentModel> {

        const cacheItem = this.documentCache.find(doc => doc.referenceId == referenceId);
        if (cacheItem) {
            return observableOf(cacheItem);
        }
       return this.http.post(this.urlResolver.resolveUrlApiV1('/search/searchLfcData'), {
            referenceId: referenceId
        }).pipe(map(response => {
            if ((<any>response).error) {
                return;
            }
            const docModel = response as AlexDocumentModel;
            const contentRef = this.replaceBinaryPathInContent((<any>docModel).contentRef);
            docModel.contentRef = contentRef;
            docModel.htmlContentRef = this.sanitizer.bypassSecurityTrustHtml(docModel.contentRef);
            this.documentCache.push(docModel);
            return docModel;
        }),
            catchError((err) => {
                if (err.status == 404) {
                    setTimeout(() => {
                        localStorage.setItem('referenceID', (new Date().getTime()).toString());
                        this.router.navigateByUrl('home');
                    }, 5);
                }
                return throwError(err);
            }));
    }

    fetchTocSearchResult(killDocId: string, originalSearchTerm: string, tocRef: string = '') {
        const appConfigInstance = AppConfig.getInstance();
        let totalWord = appConfigInstance["totalSearchWordLimit"];
        const modifiedSearchTerm = replaceQuestionMark(originalSearchTerm);
        let isQuotesPresent = this.hasAnyQuotes(modifiedSearchTerm);
        let newModifiedTerm: string;
        let noOfQuotes = this.countQuotes(modifiedSearchTerm);
        let searchterm: string;
        let wordCount = modifiedSearchTerm.split(' ').length;
        if(!isQuotesPresent){
            searchterm = wordCount >= totalWord ? `"${modifiedSearchTerm}"` : modifiedSearchTerm;
        }else{
            if(noOfQuotes === 1){
                newModifiedTerm = modifiedSearchTerm.replace(/["]/g, '');
                searchterm = wordCount >= totalWord ? `"${newModifiedTerm}"` : modifiedSearchTerm;
            }else{
                searchterm = modifiedSearchTerm;
            }
        }
        // let requestOptions = new RequestOptions();
        // requestOptions["noNotification"] = [204];
        const header = new HttpHeaders({
            "Content-Type": "application/json",
            "noNotification": "204"
        });

        let tocsearchQuery = new CarouselQuery();
        tocsearchQuery.criteria = new QueryCriteria("", "", "", searchterm, "", "", [killDocId], "",
            "", "", [], [], "", null, tocRef, killDocId);
        return this.http.post(this.urlResolver.resolveUrlApiV1(`search/TOCSearchData`), tocsearchQuery,
            { headers: header }).pipe(map((response) => {
                let allTOCResponse = response as TOCSearchModel;

                let excerptModel: Array<TOCSearchExcerptModel>[] = [];
                allTOCResponse.searchSectionModels.map((val) => {
                    excerptModel.push(val.searchExcerptModels)
                });

                let newExcerptModel = this.getSpanText(excerptModel, searchterm);

                allTOCResponse.searchSectionModels.map((val, index) => {
                    val.searchExcerptModels = newExcerptModel[index];
                });

                return allTOCResponse as TOCSearchModel;
            }));
        //return this.http.get('assets/searchLFCResponse.json').pipe(map(response => response as TOCSearchModel));
    }

    getSpanText(searchModel: Array<TOCSearchExcerptModel[]>, term: string): Array<TOCSearchExcerptModel[]> {
        let newStaticArr = this.checkForLastElement(term);
        searchModel = searchModel.map(i => 
            i.map((j)=>{
                const spanTags = j?.text?.split("</span>");
                const modifiedTerms = spanTags.map(val => {
                    if (val.includes("<span class='tocsearchhit'>")) {
                           const lastCh = val.charAt(val.length - 1);
                            if (newStaticArr.find(i=> i.includes(lastCh))) {
                                return val.substring(0, val.length - 1) + "</span>" + lastCh;
                            }
                        return val + "</span>";
                    } else {
                        return val;
                    }
                });
                return {...j,text: modifiedTerms.join("")};
            })
            );
        return searchModel;
    }

    checkForLastElement(term:string){
        let commonElement:Array<string> = [];
        let isQuotedText = this.hasQuotesBothEnds(term);
        if(isQuotedText){
            term = term.replace(/^"|"$/g, '');
        }
        let res = term.match(/\S/g);
        let lastElementofTerm = res[res.length - 1];

        let staticArr = this.urlResolver.getStaticArrayConfig();
        let originalStaticArr = staticArr.split(',');

        if(originalStaticArr.includes(lastElementofTerm)) {
            commonElement.push(lastElementofTerm);
            let newStaticArr = originalStaticArr.filter((f) =>!commonElement.includes(f));
            return newStaticArr;
        }else{
            return originalStaticArr;
        }
    }

    hasAnyQuotes(term: string): boolean{
        if(term.includes('"')){
            return true;
        }else{
            return false;
        }
    }

    hasQuotesBothEnds(term: string): boolean{
        return term.startsWith('"') && term.endsWith('"');
    }

    countQuotes(term: string){
        let totalDoubleQuotes = term.match(/"/g) || [];
        return totalDoubleQuotes.length;
    }
   
}

