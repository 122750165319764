export class ExternalUrlClickTrackingData {
    searchKey: string;
    menuTaxonomyId: string;
    contentTaxonomyId: string;
    constructor(
        public url: string,
        public documentId: string,
        public documentTitle: string,
        public pageInfo: string,
        searchKey?: string,
        menuTaxonomyId?: string,
        contentTaxonomyId?: string
    ) {
        this.searchKey = searchKey || '';
        this.menuTaxonomyId = menuTaxonomyId || '';
        this.contentTaxonomyId = contentTaxonomyId || '';
    }
}