export class ProcessedNode {
    startNode: Node;
    currentNode: Node;
    searchText: string;
    trimmedSearchText: string;
    matchStartIndex: number;
    matchEndIndex: number;
    parsedTextLength: number;
    matchExcerptCollection: Array<string>;
    html: string;
    modifiedHtml: number;
    text: string;
    matchFound: boolean;
    stepCount: number;
    highlightStarted: boolean;
    highlightComplete: boolean;
    highlightedLength: number;
    parsedLength: number;
    messages: Array<string>;
    constructor() {
        this.matchExcerptCollection = new Array<string>();
        this.messages = new Array<string>();
        this.parsedTextLength = 0;
        this.stepCount = 0;
        this.highlightedLength = 0;
        this.parsedLength = 0;
    }
}