import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { AlexLocationModel } from "app/shared/components/location/alex-location.model";
import { AlexLocationService } from "app/shared/components/location/alex-location.service";
import { AlexLocationQueryModel } from "app/shared/components/location/alex-location-query.model";


@Component({
    selector: "alex-location-top",
    templateUrl: "./alex-location-top.component.html",
    styleUrls: ['./alex-location-top.component.scss']
})

export class AlexLocationTopComponent {
    locationDetail: AlexLocationModel;
    @Input() showPopOver: boolean = false;
    locationSubscription: any;
    constructor(private locationService: AlexLocationService, private cdref: ChangeDetectorRef) {
      this.locationSubscription =  this.locationService.locationDetailTop$.subscribe(location => {

            this.locationDetail = location;
            this.cdref.detectChanges();
        });
    }

    ngOnInit() {

    }
    ngOnDestroy(){
        this.locationSubscription.unsubscribe();
    }
    GetDetails(locationQueryDetails: AlexLocationQueryModel) {
        if (locationQueryDetails != null  ) {           
            this.locationService.fetchLocationTop(locationQueryDetails);

        }
    }
}