import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BsLegacyModalService {
  private renderer: Renderer2;
  private _bodyRef = document.querySelector("body");
  private _backdropElement: HTMLElement;
  private _unlistenBackdropClickHandler;
  private _unlistenBackdropEscapeHandler;
  private _unlistenModalContentClickHandler;
  private _unlistenCloseButtonClickHandler;
  private _modalRef: Element;
  private _registeredTriggers = new Array();

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this._insertStyles();
    this._backdropElement = this._createBackdropElement();
  }

  registerModals(triggers: NodeListOf<Element>) {
    triggers?.forEach((trigger) => {
      this._registeredTriggers.push(
        this.renderer.listen(trigger, "click", (event) =>
          this._showModal(event)
        )
      );
    });
  }

  unregisterAllModals(): BsLegacyModalService {
    if (
      Array.isArray(this._registeredTriggers) &&
      this._registeredTriggers.length
    ) {
      for (let i = 0; i < this._registeredTriggers.length; i++) {
        this._registeredTriggers[i]();
      }
    }
    this._registeredTriggers = new Array();
    return this;
  }
  private _showModal(event: MouseEvent) {
    const targetId = ((event.target as any) as HTMLElement).getAttribute(
      "data-target"
    );
    if (!targetId) {
      return console.log("data-target attribute missing");
    }
    const target = document.querySelector(targetId);
    if (!target) {
      return console.log("invalid value in data-target attribute");
    }
    this._modalRef = target;
    this._showBackdrop();
    this._modalRef.classList.add("--lb-show");
    this._modalRef.classList.add("--lb-in");
    this._listenDismissEvents(target);
  }
  private _hideModal() {
    this._modalRef.classList.add("--lb-hide");
    setTimeout(() => {
      this._modalRef.classList.remove("--lb-show");
      this._modalRef.classList.remove("--lb-in");
      this._modalRef.classList.remove("--lb-hide");
      this._hideBackdrop();
      this._unlistenDismissEvents();
    }, 300);
  }
  private _showBackdrop() {
    this.renderer.appendChild(this._bodyRef, this._backdropElement);
  }
  private _listenDismissEvents(target: Element) {
    const closeButton = target.querySelector("[data-dismiss='modal']");
    if (closeButton) {
      this._unlistenCloseButtonClickHandler = this.renderer.listen(
        closeButton,
        "click",
        (event: Event) => {
          event.preventDefault();
          event.stopPropagation();
          this._hideModal();
        }
      );
    }
    const modalContent = target.querySelector(".modal-content");
    this._unlistenModalContentClickHandler = this.renderer.listen(
      modalContent,
      "click",
      (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
      }
    );
    this._unlistenBackdropClickHandler = this.renderer.listen(
      target,
      "click",
      (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        this._hideModal();
      }
    );

    this._unlistenBackdropEscapeHandler = this.renderer.listen(
      document,
      "keyup",
      (event: KeyboardEvent) => event.key === "Escape" && this._hideModal()
    );
  }
  private _hideBackdrop() {
    this.renderer.removeChild(this._bodyRef, this._backdropElement);
  }
  private _unlistenDismissEvents() {
    function unlisten(listnerRefProp: string) {
      if (typeof this[listnerRefProp] == "function") {
        this[listnerRefProp]();
        this[listnerRefProp] = null;
      }
    }
    unlisten("_unlistenBackdropClickHandler");
    unlisten("_unlistenBackdropEscapeHandler");
    unlisten("_unlistenCloseButtonClickHandler");
    unlisten("_unlistenModalContentClickHandler");
  }
  private _insertStyles() {
    const style = this.renderer.createElement("style");
    const styleText = this.renderer.createText(`
      #legacy-bootstrap-backdrop{
          background-color: black;
          opacity: 0.5;
          z-index:99;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          position: fixed;
      }
      .--lb-show{
          display:block;
      }
      .fade.--lb-in{
          opacity:1;
      }
      .--lb-show .modal-header{
        flex-direction:row-reverse;
      }
      .modal.--lb-show .modal-dialog{
          max-width:none;
          animation:slide-down;
          animation-duration: .03s;
          animation-timing-function:ease-in-out;
      }
      .modal.--lb-in.--lb-hide .modal-dialog{
        animation:slide-up !importaint;
        animation-duration: .03s;
        animation-timing-function:ease-in-out;
    }
      @keyframes slide-down{
            0% {
                transform:translateY(-120px);
            }
            100% {
                transform:translateY(-50px);
            }
      }
      @keyframes slide-up{
        0% {
            transform:translateY(-50px);
        }
        100% {
            transform:translateY(-120px);
        }
  }
    `);
    this.renderer.appendChild(style, styleText);
    this.renderer.appendChild(document.head, style);
  }
  private _createBackdropElement(): HTMLElement {
    const element = this.renderer.createElement("div");
    this.renderer.setAttribute(element, "id", "legacy-bootstrap-backdrop");
    this.renderer.addClass(element, "--lb-backdrop");
    return element;
  }
}
