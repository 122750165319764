import { DateSelectionType } from "./date-selction-type.enums";
import { AlexFilterBaseModel } from "app/feature/search/model/filters/filter-base.model";

export class AlexDateFilterModel extends AlexFilterBaseModel {
    to: string;
    from: string;
    selection: DateSelectionType;
    constructor(){
        super();
    }
}