import {
  Component,
  ElementRef,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TypeAheadService } from "app/feature/header/services/type-ahead.service";
import { TypeAhead } from "app/feature/header/services";
import { AllSourcesService } from "app/feature/header/services/all-sources.service";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";
import { AutoUnsubscribeObservables } from "app/core/decorators/auto-unsubscribe-observables";
import { AlexSearchViewModelService } from "app/feature/search/services/search-view-model.service";
import { SearchParamService } from "app/feature/search/services/search-param.service";
import { AlexUserService, User } from "app/core/services";

@Component({
  selector: "alex-header-search-box",
  templateUrl: "./header-search-box.component.html",
  styleUrls: [
    "./header-search-box.component.scss",
    "./header-search-box.component.responsive.scss",
  ],
  host: {
    "(document:click)": "onClick($event)",
  },
})
@AutoUnsubscribeObservables()
export class AlexHeaderSearchBoxComponent implements OnInit, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {}

  @Input() status;
  @Input() filterLabel: string = "Sources";
  input: TypeAhead = new TypeAhead();
  timer: any;
  //typeAhead response comes after 1 second delay time
  delayTime: number = 1000;
  searchClicked: boolean = false;
  showInputTextResult: boolean;
  showRemoveTextBtn: boolean = false;
  //minimum number of characters to get type ahead response
  minCharacterforTypeAhead: number = 3;

  typeAheadCallExecuted: boolean = false;
  typeAheadCallMatched: boolean = false;
  mainSearchClicked = false;
  httpSubscription: any;
  alexSearchQueryParamSubscription: any;
  activeSuggestionIndex = -1;
  textBoxOriginalValue = "";
  triggeredByArrowKey = false;

  user: User;
  isAuthorized: boolean;
  @Output() allSourceClick = new EventEmitter<Event>();
  @Output() searchClick = new EventEmitter<void>();

  constructor(
    private searchService: TypeAheadService,
    private eref: ElementRef,
    private router: Router,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private searchViewModelService: AlexSearchViewModelService,
    public searchParam: SearchParamService,
    public allSource: AllSourcesService,
    public userService: AlexUserService,
    private allSourceService: AllSourcesService
  ) {}

  ngOnInit() {
    this.allSource.allSourcesTxt = "Sources";
    this.input.searchText = "";
    this.input.suggestions = [];

    this.alexSearchQueryParamSubscription = this.searchParam.alexSearchQueryParam$.subscribe(
      (param) => {
        this.input.searchText = param || "";
      }
    );

    this.userService.getUserData().subscribe((model) => {
      this.user = model;
    });

    this.userService.isAuthorizedUser.subscribe((isAuth) => {
      this.isAuthorized = isAuth;
    });
  }

  //called on click on outside element
  onClick(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      //this.allSourceService.allSourceStatus$.next(false);
      this.showInputTextResult = false;
      this.showRemoveTextBtn = false;
    } else {
      if (this.input.searchText != "") {
        this.showRemoveTextBtn = true;
      } else {
        this.showRemoveTextBtn = false;
      }
      if (this.mainSearchClicked) {
        this.showRemoveTextBtn = false;
      }
    }
  }

  mobileRightMenuClicked() {
    this.mainSearchClicked = true;
  }

  // call 'Type-Ahead' service based on the user input search text
  searchText() {
    if (!this.triggeredByArrowKey) {
      this.textBoxOriginalValue = this.input.searchText;
      this.activeSuggestionIndex = -1;
    }

    this.searchParam.updateSearchTerm(this.input.searchText);
    this.searchParam.setSortFilter("Relevance");
    this.mainSearchClicked = false;

    if (this.searchClicked) {
      this.searchClicked = false;
    }
    if (this.input.searchText != "") {
      this.showRemoveTextBtn = true;
      this.typeAheadCallExecuted = false;
      this.typeAheadCallMatched = false;

      if (!this.triggeredByArrowKey) {
        if (
          this.input.searchText.length >= this.minCharacterforTypeAhead &&
          !this.input.searchText.startsWith('"')
        ) {
          this.httpSubscription = this.searchService
            .findTypeAheadText(this.input.searchText)
            .subscribe((result) => {
              if (!this.searchClicked) {
                this.showInputTextResult = true;
                this.typeAheadCallExecuted = true;
                this.input.isTypeAheadListMached = result.isTypeAheadListMached;
                this.input.isTypeAheadResponseReceived =
                  result.isTypeAheadResponseReceived;
                this.input.suggestions = result.suggestions;
              }
            });
        } else {
          this.showInputTextResult = false;
        }
      }
    } else {
      this.showInputTextResult = false;
      this.showRemoveTextBtn = false;
    }

    this.triggeredByArrowKey = false;
  }
  allSourceDropDownClick(clickEvent) {
    this.allSourceClick.emit(clickEvent);
  }

  //set flag Type Ahead Call excecuted and Type Ahead Call matched
  checkTypeAheadText(text: string) {
    if (
      this.input.suggestions &&
      this.input.suggestions.length &&
      this.input.suggestions.indexOf(text)
    ) {
      this.typeAheadCallMatched = true;
    } else {
      this.typeAheadCallMatched = false;
    }
  }

  //clears the 'Type Ahead' field if search clear button pressed
  searchClearPressed() {
    this.input.searchText = "";
    this.showRemoveTextBtn = false;
    document.getElementById("txtSearch").focus();

    this.typeAheadCallExecuted = false;
    this.typeAheadCallMatched = false;
  }

  //select the value on selecting the value from Type Ahead suggestions
  typeAheadRowClicked(item: string) {
    this.searchParam.typedTerm = this.input.searchText;
    this.searchParam.suggestionLength = this.input.suggestions.length;
    this.input.searchText = item;
    this.typeAheadServiceCall();
  }

  //fires on pressing the search button
  bigSearch() {
    this.searchParam.suggestionLength = this.input.suggestions.length;
    this.mainSearchClicked = true;
    this.typeAheadServiceCall();
  }

  //fires on pressing the enter button in keyboard
  textEnter() {
    this.searchParam.suggestionLength = this.input.suggestions.length;
    this.typeAheadServiceCall();
    this.searchParam.typedTerm = "";
  }

  typeAheadServiceCall() {
    this.showInputTextResult = false;
    this.searchClicked = true;
    this.showRemoveTextBtn = false;
    document.getElementById("txtSearch").blur();
    this.checkTypeAheadText(this.input.searchText);
    this.searchDoc();
  }

  //hit IDOL with the search request
  searchDoc() {
    const domains = (this.user.domains || []).join(","); // on search keyword change go to default domain

    this.spinnerService.noSpinner(false);
    this.searchParam.refreshSourceFilter = true;
    this.searchParam.searchTerm = this.input.searchText;
    this.input.isTypeAheadResponseReceived = this.typeAheadCallExecuted;
    this.input.isTypeAheadListMached = this.typeAheadCallMatched;
    this.input.suggestions = [];
    this.searchParam.reloadAllFilters = true;
    this.searchParam.loadAllFilter(true);
    // this.searchParam.clearAdvanceFilter();
    this.searchParam.updateSearchTerm(this.input.searchText);
    this.searchViewModelService.resetPaginationModel();

    this.searchClick.emit();
    // this.router.navigate(
    //   [
    //     "/search",
    //     Object.assign(this.searchParam.getRouteParam(), {
    //       x: this.searchParam.UniqueUrlKey,
    //     }),
    //   ],
    //   { queryParams: { q: this.input.searchText, d: domains } }
    // );
  }

  onUpArrowPressed() {
    if (this.showInputTextResult && this.activeSuggestionIndex > 0) {
      this.activeSuggestionIndex--;
      this.input.searchText = this.input.suggestions[
        this.activeSuggestionIndex
      ];
    } else {
      this.activeSuggestionIndex = -1;
      this.input.searchText = this.textBoxOriginalValue;
    }
    this.triggeredByArrowKey = true;
  }
  onDownArrowPressed() {
    if (
      this.input.suggestions &&
      this.showInputTextResult &&
      this.activeSuggestionIndex < this.input.suggestions.length - 1
    ) {
      this.activeSuggestionIndex++;
      this.input.searchText = this.input.suggestions[
        this.activeSuggestionIndex
      ];
    }
    this.triggeredByArrowKey = true;
  }

  onEscapeKeyPressed() {
    this.showInputTextResult = false;
  }
}
