import { AutoUnsubscribeObservables } from "app/core/decorators/auto-unsubscribe-observables";
import { ActivatedRoute, Router, ActivatedRouteSnapshot } from "@angular/router";
import { Subscription } from "rxjs";
import { Component } from "@angular/core";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";
import { SearchParamService } from "app/feature/search/services/search-param.service";
import { AlexDateFilterModel } from "app/feature/search/model/filters/date-filter.model";
import { DateSelectionType } from "app/feature/search/model/filters/date-selction-type.enums";
import { DatePipe } from '@angular/common';
import { AlexDocumentService } from "app/feature/long-form-content/document/document.service";
import { User, AlexUrlResolverService, AlexNavigationService } from "app/core/services";
@Component({
    providers: [DatePipe],
    template: ''
})
@AutoUnsubscribeObservables()
export class AlexAppRouteRedirect {
    private dataRouteSubscription: Subscription;
    private urlRouteSubscription: Subscription;
    page: string;
    user: User;
    dateModel: AlexDateFilterModel
    routeRedirect: any;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private spinner: SpinnerService,
        private searchParamService: SearchParamService,
        public datepipe: DatePipe,
        private navigationService: AlexNavigationService,
        private urlResover: AlexUrlResolverService) {
        this.routeRedirect = this.urlResover.getRouteRedirectMapping();
        this.dataRouteSubscription = this.route.data.subscribe(data => {
            this.page = data.page;
            this.user = data.user;
        });
        this.urlRouteSubscription = this.route.url.subscribe(oldUrl => {
            this.navigationService.isRedirect = true;
            if (this.page == 'documentwindow') {
                this.mapToNewRoute(this.route.snapshot);
            }
            else if (this.page == 'homewindow') {
                this.mapToSearchNewRoute(this.route.snapshot);
            }
            else if (this.page == 'manageaccount') {
                this.spinner.setMessage("You will be redirected momentarily.");
                this.spinner.showSpinnerAsync(true).then(spinnerId => {
                    if (this.user)
                        window.location.href = this.urlResover.getExtManagAccUrl()
                            + "?UserSession=" + this.user.userLicenseModel.sessionID;
                });
            }
        });
    }
    private mapToNewRoute(routeSnapShot: ActivatedRouteSnapshot) {
        this.spinner.setMessage("You will be redirected momentarily.");
        this.spinner.showSpinnerAsync().then(spinnerId => {
            const from = routeSnapShot.queryParams["from"];
            const ref = routeSnapShot.queryParams["ref"];
            const secOrParaId = routeSnapShot.queryParams["id"];
            const sec = routeSnapShot.queryParams["sec"];
            const fieldName = routeSnapShot.queryParams["fieldName"];

            const fragment = routeSnapShot.fragment;

            const routeCollection = [];

            if (ref) {
                routeCollection.push('/document/lfc');
                routeCollection.push(ref);
                if (sec) {
                    routeCollection.push('toc');
                    routeCollection.push('secordpos');
                    routeCollection.push(sec);
                } else {
                    routeCollection.push('toc');
                    routeCollection.push(ref);
                }
                if (fragment) {
                    this.router.navigate(routeCollection, { fragment: fragment });
                } else {
                    this.router.navigate(routeCollection);
                }
            }
            else if (secOrParaId) {
                routeCollection.push('/document/lfc/find');
                routeCollection.push(secOrParaId);
                this.router.navigate(routeCollection);
            }
            this.spinner.hideSpinnerAsync(spinnerId);
        });


    }
    private mapToSearchNewRoute(routeSnapShot: ActivatedRouteSnapshot) {
        this.spinner.setMessage("You will be redirected momentarily.");
        this.spinner.showSpinnerAsync().then(spinnerId => {
            const ref = routeSnapShot.queryParams["ref"];
            const link = routeSnapShot.queryParams["link"];
            let bridgeRoute;
            if (link && (bridgeRoute = this.findMatchingContentPageRedirect(link))) {
                this.router.navigateByUrl(bridgeRoute);
            }
            else if (ref) {
                const routeCollection = [];
                routeCollection.push('/document/top');
                routeCollection.push(ref);
                this.router.navigate(routeCollection);
            }
            else {
                const searchTerm = routeSnapShot.queryParams["q"];
                const domain = routeSnapShot.queryParams["qdom"];
                const date = routeSnapShot.queryParams["qdr"];
                const dateFrom = routeSnapShot.queryParams["qfrom"];
                const dateTo = routeSnapShot.queryParams["qto"];
                this.dateModel = new AlexDateFilterModel();
                if (date) {
                    if (date == 'alldates')
                        this.dateModel.selection = DateSelectionType.None;
                    else if (date == 'withindays7') {
                        this.dateModel.selection = DateSelectionType.Custom;
                        var dateTemp = new Date();
                        this.dateModel.to = this.datepipe.transform(dateTemp, 'MM/dd/yyyy');
                        var numberOfDays = 6;
                        dateTemp.setDate(dateTemp.getDate() - numberOfDays);
                        this.dateModel.from = this.datepipe.transform(dateTemp, 'MM/dd/yyyy');

                    }
                    else if (date == 'withindays30') {
                        this.dateModel.selection = DateSelectionType.Last30Days;
                        var dateTemp = new Date();
                        this.dateModel.to = this.datepipe.transform(dateTemp, 'MM/dd/yyyy');
                        var numberOfDays = 29;
                        dateTemp.setDate(dateTemp.getDate() - numberOfDays);
                        this.dateModel.from = this.datepipe.transform(dateTemp, 'MM/dd/yyyy');
                    }
                    else if (date == 'withindays365') {
                        this.dateModel.selection = DateSelectionType.Custom;
                        var dateTemp = new Date();
                        this.dateModel.to = this.datepipe.transform(dateTemp, 'MM/dd/yyyy');
                        var numberOfDays = 364;
                        dateTemp.setDate(dateTemp.getDate() - numberOfDays);
                        this.dateModel.from = this.datepipe.transform(dateTemp, 'MM/dd/yyyy');
                    }
                    else if (date == 'datedbetween') {
                        this.dateModel.selection = DateSelectionType.Custom;
                        this.dateModel.from = dateFrom;
                        this.dateModel.to = dateTo;
                    }

                    this.dateModel.o = this.searchParamService.getFilterParamMaxOrder() + 1;
                    this.searchParamService.setFilterParamMaxOrder(this.dateModel.o);
                    this.searchParamService.setDateFilter(this.dateModel);
                }
                let newRouteParam = Object.assign(this.searchParamService.getRouteParam());//, { date: JSON.stringify(this.dateModel) });
                if (this.dateModel.selection == DateSelectionType.None)
                    delete newRouteParam.date;
                this.router.navigate(['/search', newRouteParam], { queryParams: { q: searchTerm } });
                this.spinner.hideSpinnerAsync(spinnerId);
            }
        })
    }
    findMatchingContentPageRedirect(link) {
        const redirectMap = this.routeRedirect.contentPage;
        return (redirectMap.find(item => decodeURIComponent(item.path).toLowerCase() == link.toLowerCase()) || {}).redirectTo;
    }
    ngOnDestroy() {
        this.spinner.setMessage("");
        this.spinner.hideSpinner(0, true);
    }
}
