import { Injectable } from "@angular/core";
import { ALLOWED_TAGS } from './document.constant'
import { TOCSearchSectionModel } from "app/feature/long-form-content/toc-section-search/toc-search-section.model";
import { ProcessedNode } from "app/shared/components/document-viewer/document-processor.model";
import { BehaviorSubject } from "rxjs";
@Injectable()
export class DocumentViewerService {
    spinner$  = new BehaviorSubject<{ isVisible: boolean, message: string }>({ isVisible: false, message: '' });
    constructor() {
    }

}