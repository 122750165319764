import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { AlexIconFinder } from 'app/core/services';

@Component({
  selector: 'alex-svg-icon',
  template: `
  <li [class]="cssClass">
  <span [innerHtml]="iconSvg" [attr.title]="title">
  </span>
  </li>`,
  styles:[
        `
    li &.active{
        background:'#005EB8';
            svg{
                fill:#FFFFFF;
            }
          }
           `
  ]
})
export class AlexSvgIconComponent implements OnInit {
  @Input() title:string;
  @Input() icon: string;
  @Input() isMimeType: boolean;
  @Input() cssClass: string;
  iconSvg: SafeHtml;
  constructor(private iconFinder: AlexIconFinder,private elref: ElementRef) {   
  }

  ngOnInit() {
    if (!this.isMimeType) {
      this.iconSvg = this.iconFinder.find(this.icon);
    }
    else {
      this.iconSvg = this.iconFinder.findByMimeType(this.icon);
    }
  }


}
