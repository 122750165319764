import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable()
export class AppConfig {
  private static config = environment;
  //private static config = {};

  constructor() {}

  // public static load() {
  //   return new Promise((resolve, reject) => {
  //     var xobj = new XMLHttpRequest();
  //     xobj.overrideMimeType("application/json");
  //     xobj.open("GET", "config/config.json", true);
  //     xobj.onreadystatechange = () => {
  //       if (xobj.readyState == 4) {
  //         if (xobj.status == 200) {
  //           this.config = JSON.parse(xobj.responseText);
  //           resolve('');
  //         } else {
  //           reject(`Could not load config file`);
  //         }
  //       }
  //     };
  //     xobj.send(null);
  //   });
  // }

  public static getInstance() {
    return this.config;
  }
}
