import {
  Component,
  OnInit,
  OnChanges,
  Input,
  AfterViewChecked,
  Output,
  EventEmitter,
  SimpleChanges,
  NgZone,
  ElementRef,
} from "@angular/core";
import { Carousel } from "app/shared/components/carousel/model/carousel.model";
import { OwlCarouselOptions } from "app/shared/components/carousel/model/owl-carousel-options.model";
import { DomainService } from "app/feature/home/domain.service";
import { LayOut } from "app/core/services/app/app.constant";
import { CAROUSEL_ANIMATION } from "app/core/animations/carousel.animation";
import { CarouselItem } from "./model/carousel-item.model";

import { OnScreenCarouseService } from "app/shared/services/tracking/window/on-screen-carouse.service";

@Component({
  selector: "alex-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class AlexCarouselComponent
  implements OnInit, AfterViewChecked, OnChanges {
  hasChange = false;
  @Output() addDocumentToHomeSpace: EventEmitter<any> = new EventEmitter();
  @Output() fetchCarouselData: EventEmitter<Carousel>;
  @Output() sendItemtoHome: EventEmitter<any> = new EventEmitter();
  @Input() carousel: Carousel;
  @Input() carouselOptions: OwlCarouselOptions;
  isLoading = false;
  @Input() showLoader: boolean = false;
  pageLayOut = LayOut;
  constructor(
    private zone: NgZone,
    private elRef: ElementRef,
    private onScreenCarouseService: OnScreenCarouseService
  ) {
    this.carousel = new Carousel();
    this.fetchCarouselData = new EventEmitter<Carousel>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hasChange = true;
  }

  ngOnInit() {
    this.carousel.title = this.carousel.title;
    this.carouselOptions = this.carouselOptions || new OwlCarouselOptions();

    this.carouselOptions.responsive = {
      320: {
        items: 1,
        slideBy: 1,
      },
      768: {
        items: 3,
        slideBy: 2,
      },
      1024: {
        items: 5,
        slideBy: 4,
      },
      1440: {
        items: 7,
        slideBy: 6,
      },
      1920: {
        items: 10,
        slideBy: 9,
      },
    };
  }

  ngAfterViewChecked(): void {
    if (this.hasChange) {
      this.zone.runOutsideAngular(() => {
        // const $owl = $('.carousel', '#' + this.carousel.id);
        // $owl.owlCarousel(this.carouselOptions);
      });
      this.hasChange = false;
    }
  }

  sendItemtoParent(carouselItem: CarouselItem) {
    this.addDocumentToHomeSpace.emit(carouselItem);
  }

  sendItem(carouselItem: CarouselItem) {
    // console.log("SENDITEMTOPARENT",$event)
    this.sendItemtoHome.emit(carouselItem);
  }
  // onscroll(window: Window, event: Event) {
  //   if (!this.carousel.isCurated) {
  //     if (!this.carousel.isLoaded && !this.carousel.isLoading) {
  //       if ($('.allCarousel', '#' + this.carousel.id).isOnScreen()) {
  //         this.carousel.isLoading = true;
  //         this.fetchCarouselData.emit(this.carousel);

  //       }
  //     }
  //   }
  // }
  onscroll(window: Window, event: Event) {
    if (!this.carousel.isCurated) {
      if (!this.carousel.isLoaded && !this.carousel.isLoading) {
        if (this.onScreenCarouseService.isOnScreen(this.elRef)) {
          this.carousel.isLoading = true;
          this.fetchCarouselData.emit(this.carousel);
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.hasChange) {
      this.zone.runOutsideAngular(() => {
        // const $owl = $('.carousel', '#' + this.carousel.id);
        // $owl.owlCarousel(this.carouselOptions);
      });
      this.hasChange = false;
    }
  }
}
