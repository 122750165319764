
import {of as observableOf,  Observable } from 'rxjs';

import {first} from 'rxjs/operators';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from "@angular/router";
import { AlexDocumentService } from "app/feature/long-form-content/document/document.service";
import { AlexDocumentModel } from "app/feature/long-form-content/document/document.model";
import { Injectable } from "@angular/core";
import { SearchParamService } from "app/feature/search/services/search-param.service";
import { RecentDocument } from "app/feature/header/recent-search/recent-document.model";
import { AlexUrlResolverService, AlexNavigationService } from "app/core/services";
import { AlexFileService } from "app/core/services/file/alex-file.service";
import { SpinnerService } from "../../../shared/components/spinner/spinner.service";
import { HttpClient } from "@angular/common/http";


@Injectable()
export class AlexLfcDocumentResolveGuard implements Resolve<AlexDocumentModel>, CanActivate {

    tempDocModel: { id: string, type: string, data: AlexDocumentModel, message: string };
    recentDoc: RecentDocument;
    constructor(private documentService: AlexDocumentService
        , private router: Router,
        private searchParam: SearchParamService,
        private route: ActivatedRoute,
        private urlResolver: AlexUrlResolverService,
        private fileService: AlexFileService,
        private spinnerService: SpinnerService,
        private navigationService: AlexNavigationService,
        private http: HttpClient) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.spinnerService.showSpinnerAsync().then(spinnerId => {
                this.determineDocumentType(state.url);
                if (this.tempDocModel && this.tempDocModel.type == "lfc") {
                    return this.documentService.fetchSingleDocument(this.tempDocModel.id).pipe(
                        first()).subscribe(doc => {
                            if (!(<any>doc).binaryPath) {
                                this.tempDocModel.data = doc;
                                this.saveOpenDocument(doc.documentName, state.url).pipe(first()).subscribe();
                                observer.next(true);
                            } else {
                                this.fileService.downloadOrOpenFile(doc.fileName, doc.binaryContent, doc.mimeType);
                                if (this.navigationService.isFirstNavigation()) {
                                    setTimeout(_ => this.router.navigateByUrl('home'), 0);
                                }
                                observer.next(false);
                            }
                            observer.complete();
                            this.spinnerService.hideSpinner(spinnerId);
                        }, _ => {
                            this.tempDocModel.data = null;
                            observer.next(false);
                            observer.complete();
                            this.spinnerService.hideSpinner(spinnerId);
                        });
                }
                else if (this.tempDocModel && this.tempDocModel.type == "lfcfind") {
                    return this.documentService.fetchSingleDocumentWithSecOrPara(this.tempDocModel.id).pipe(
                        first()).subscribe(doc => {
                            this.tempDocModel.data = doc;
                            observer.next(true);
                            observer.complete();
                            this.spinnerService.hideSpinner(spinnerId);
                        }, _ => {
                            observer.next(false);
                            observer.complete();
                            this.spinnerService.hideSpinner(spinnerId);
                        });
                }
                else {
                    observer.next(true);
                    observer.complete();
                    this.spinnerService.hideSpinner(spinnerId);
                }
            })
        })
    }

    saveOpenDocument(title: string, url: string) {
        this.recentDoc = new RecentDocument();
        this.recentDoc.documentName = title;
        this.recentDoc.reference = url;
        return this.http.post(this.urlResolver.resolveUrlApiV1(`user/AddRecentDocument`), this.recentDoc);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AlexDocumentModel> {
        const docData = (<any>(this.tempDocModel || {})).data;
        return observableOf(docData);
    }

    determineDocumentType(url: string) {

        if (url.indexOf('/lfc/') >= 0) {
            const urlSplit = url.split('/lfc/')[1];
            const urlPart = urlSplit.split('/');

            try {

                this.tempDocModel = {
                    id: urlPart[0],
                    type: 'lfc',
                    data: null,
                    message: ''
                }
                if (urlPart[0] != 'find') {
                    if (urlPart.length > 2) {
                        if (urlPart[2].indexOf("?") >= 0) {
                            this.tempDocModel.id = urlPart[2].split('?')[0];
                            this.tempDocModel.type = 'lfc';
                        }
                        else {
                            this.tempDocModel.id = urlPart[2];
                            this.tempDocModel.type = 'lfc';
                        }

                    }
                    else {
                        if (urlPart[0].indexOf("?") >= 0) {
                            this.tempDocModel.id = urlPart[0].split('?')[0];
                            this.tempDocModel.type = 'lfc';
                        }
                        else {
                            this.tempDocModel.id = urlPart[0];
                            this.tempDocModel.type = 'lfc';
                        }
                    }
                }
                else {
                    this.tempDocModel.id = urlPart[1];
                    this.tempDocModel.type = 'lfcfind';
                }
            }
            catch (e) {
                this.tempDocModel = null;
            }


        }

        if (url.indexOf('/top/') >= 0) {
            const urlSplit = url.split('/top/')[1];
            const urlPart = urlSplit.split('/');

            try {
                this.tempDocModel = {
                    id: urlPart[0],
                    type: 'top',
                    data: null,
                    message: ''
                }
            }
            catch (e) {
                this.tempDocModel = null;
            }
        }
    }
}