import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AlexUrlResolverService } from "app/core/services";
import { ParameteredMessageParseService } from "app/core/services/parametered-message-parse.service";
import {
  KcwSearchConfig,
  KcwSearchSectionField,
} from "app/core/services/url-resolver/kcw-search-config.interface";
import { AlexPageLayoutModel } from "app/shared/components/page-layout/page-layout.model";
import { AlexPageLayoutService } from "app/shared/components/page-layout/page-layout.service";
import { AlexPaginationActions } from "app/shared/components/pagination/pagination.actions";
import { AlexPaginationEventModel } from "app/shared/components/pagination/pagination.event.model";
import { AlexPaginationModel } from "app/shared/components/pagination/pagination.model";
import { AlexPaginationService } from "app/shared/components/pagination/pagination.service";
import {
  asyncScheduler,
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  scheduled,
  throwError,
} from "rxjs";
import { catchError, first, map, switchMap, tap } from "rxjs/operators";
import { KcwSearchResponse, KcwSearchResultItem } from "../models";
import { KcwSearchActivityHeaderResponse } from "../models/kcw-search-activity-header-response.model";
import { KcwSearchEmptyResponse } from "../models/kcw-search-empty-response.model";
import { KcwSearchMoreRequest } from "../models/kcw-search-more.request.model";
import { KcwSearchRequest } from "../models/kcw-search-request.model";
import { KcwSearchResultSection } from "../models/kcw-search-result-section.model";

@Injectable({
  providedIn: "root",
})
export class KcwSearchPageService {
  private readonly searchResults$: BehaviorSubject<KcwSearchResponse | null> =
    new BehaviorSubject(null);
  private readonly loading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly error$: BehaviorSubject<string> =
    new BehaviorSubject<string>("");
  private readonly searchTerm$: BehaviorSubject<string> =
    new BehaviorSubject<string>("");
  private readonly sectionType$: BehaviorSubject<string> =
    new BehaviorSubject<string>("");
  private readonly fromSectionType$: BehaviorSubject<string> =
    new BehaviorSubject<string>("");
  private readonly currentPage$: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);
  private readonly ruleSetId$: BehaviorSubject<string> =
    new BehaviorSubject<string>("");

  private readonly screenName$: BehaviorSubject<string> =
    new BehaviorSubject<string>("");

  private readonly pagination$: BehaviorSubject<AlexPaginationModel> =
    new BehaviorSubject<AlexPaginationModel>(new AlexPaginationModel());

  private readonly sectionTitle$: BehaviorSubject<string> =
    new BehaviorSubject<string>("");

  private readonly isMore$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private readonly isValid$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private readonly activityId$: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  private readonly activityHeader$: BehaviorSubject<string> =
    new BehaviorSubject<string>("");

  private readonly currentActivityHeaderText$: BehaviorSubject<string> =
    new BehaviorSubject<string>("");

  private needToFetchData: boolean;

  private readonly config: Readonly<KcwSearchConfig>;
  private readonly activityHeaderResponseByRuleSetIdMap: Map<
    string,
    KcwSearchActivityHeaderResponse
  >;

  constructor(
    private readonly http: HttpClient,
    private readonly urlResolverService: AlexUrlResolverService,
    private readonly paginationService: AlexPaginationService,
    private readonly pageLayoutService: AlexPageLayoutService,
    private readonly messageParseService: ParameteredMessageParseService,
    private readonly titleService: Title
  ) {
    this.needToFetchData = true;
    this.config = this.urlResolverService.getKcwSearchConfig();
    this.activityHeaderResponseByRuleSetIdMap = new Map<
      string,
      KcwSearchActivityHeaderResponse
    >();
  }

  getSearchFields(): ReadonlyArray<KcwSearchSectionField> {
    return this.config.searchFields;
  }

  updateSearchPageLayout(): void {
    const pageLayoutModel = new AlexPageLayoutModel();
    this.pageLayoutService.pageLayoutChange$.next(pageLayoutModel);
  }

  getConfig(): Readonly<KcwSearchConfig> {
    return this.config;
  }

  getIsValid(): Observable<boolean> {
    return this.isValid$.asObservable();
  }

  setIsValid(isValid: boolean): void {
    this.isValid$.next(isValid);
  }

  getSearchResults(): Observable<KcwSearchResponse> {
    return this.searchResults$.asObservable();
  }

  setSearchResults(results: KcwSearchResponse): void {
    this.searchResults$.next(results);
  }

  getError(): Observable<string> {
    return this.error$.asObservable();
  }

  setError(error: string): void {
    this.error$.next(error);
  }

  getLoading(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  setLoading(isLoading: boolean): void {
    this.loading$.next(isLoading);
  }

  getSearchTerm(): Observable<string> {
    return this.searchTerm$.asObservable();
  }

  setSearchTerm(searchTerm: string): void {
    this.searchTerm$.next(searchTerm || "");
  }

  getSectionType(): Observable<string> {
    return this.sectionType$.asObservable();
  }

  setSectionType(sectionType: string): void {
    this.sectionType$.next(sectionType);
  }

  getFromSectionType(): Observable<string> {
    return this.fromSectionType$.asObservable();
  }

  setFromSectionType(sectionType: string): void {
    this.fromSectionType$.next(sectionType);
  }

  getCurrentPage(): Observable<number> {
    return this.currentPage$.asObservable();
  }

  setCurrentPage(page: number): void {
    this.currentPage$.next(page);
  }

  getRuleSetId(): Observable<string> {
    return this.ruleSetId$.asObservable();
  }

  setRuleSetId(ruleSetId: string): void {
    this.ruleSetId$.next(ruleSetId);
  }

  getScreenName(): Observable<string> {
    return this.screenName$.asObservable();
  }

  setScreenName(screenName: string): void {
    this.screenName$.next(screenName);
  }

  getPagination(): Observable<AlexPaginationModel> {
    return this.pagination$.asObservable();
  }

  setPagination(pagination: AlexPaginationModel): void {
    this.pagination$.next(pagination);
  }

  getSectionTitle(): Observable<string> {
    return this.sectionTitle$.asObservable();
  }

  setSectionTitle(sectionTitle: string): void {
    this.sectionTitle$.next(sectionTitle);
  }

  getIsMore(): Observable<boolean> {
    return this.isMore$.asObservable();
  }

  setIsMore(isMore: boolean): void {
    this.isMore$.next(isMore);
  }

  getActivityId(): Observable<string> {
    return this.activityId$.asObservable();
  }

  setActivityId(activityId: string): void {
    this.activityId$.next(activityId);
  }

  getActivityHeader(): Observable<string> {
    return this.activityHeader$.asObservable();
  }

  setActivityHeader(activityHeader: string): void {
    this.activityHeader$.next(activityHeader);
  }

  getCurrentActivityHeaderText(): Observable<string> {
    return this.currentActivityHeaderText$.asObservable();
  }

  setCurrentActivityHeaderText(activityHeaderText: string): void {
    this.currentActivityHeaderText$.next(activityHeaderText);
  }

  getNeedToFetchData(): boolean {
    return this.needToFetchData;
  }

  setNeedToFetchData(needToFetchData: boolean): void {
    this.needToFetchData = needToFetchData;
  }

  search(): Observable<KcwSearchResponse> {
    this.setLoading(true);
    this.setError(null);
    this.setSearchResults({
      perPageCount: 0,
      totalCount: 0,
      activityContent: { isLoadMore: false, items: [] },
      relActivityContent: { isLoadMore: false, items: [] },
      standardContent: { isLoadMore: false, items: [] },
      relStandardContent: { isLoadMore: false, items: [] },
    });

    return combineLatest([
      this.getSearchTerm(),
      this.getSectionType(),
      this.getCurrentPage(),
      this.getRuleSetId(),
    ]).pipe(
      map(([searchTerm, sectionType, currentPage, ruleSetId]) => {
        const searchType =
          sectionType ||
          ["activity", "standard", "relActivity", "relStandard"].join("&");
        const sortOrder = "Relevance";
        const startPage = currentPage;
        const endPage = currentPage;
        const payload = {
          searchType,
          sortOrder,
          criteria: {
            ruleSetId,
            searchTerm,
            startPage,
            endPage,
          },
        } as KcwSearchRequest;
        return payload;
      }),
      switchMap((payload: KcwSearchRequest) => {
        return this.http
          .post<KcwSearchResponse | KcwSearchEmptyResponse>(
            this.urlResolverService.resolveUrlApiV1(
              "/kcwSearch/kcwSearchContent"
            ),
            payload
          )
          .pipe(
            map((res) => {
              if (res && (res as KcwSearchEmptyResponse).Message) {
                const emptyResponse = {
                  totalCount: 0,
                  perPageCount: 0,
                  activityContent: {
                    isLoadMore: false,
                    items: [],
                  },
                  relActivityContent: {
                    isLoadMore: false,
                    items: [],
                  },
                  standardContent: {
                    isLoadMore: false,
                    items: [],
                  },
                  relStandardContent: {
                    isLoadMore: false,
                    items: [],
                  },
                } as KcwSearchResponse;
                return emptyResponse;
              }
              return res as KcwSearchResponse;
            }),
            catchError((error) => {
              this.setLoading(false);
              this.setError("Oops! something went wrong.");
              return throwError(error);
            }),
            first()
          );
      }),
      tap(() => this.setLoading(false)),
      tap((response) => this.setSearchResults(response)),
      tap(({ totalCount, perPageCount }) => {
        const sectionType = this.sectionType$.value;
        const currentPagination = this.pagination$.value;
        if (sectionType) {
          const pagination = this.paginationService.updatePaginationModel(
            currentPagination,
            Number.parseInt((perPageCount || 25).toString(), 10),
            Number.parseInt((totalCount || 0).toString(), 10)
          );
          this.setPagination(pagination);
        } else {
          const pagination = this.paginationService.updatePaginationModel(
            currentPagination,
            0,
            0
          );
          this.setPagination(pagination);
        }
      }),
      first()
    );
  }

  getSeeMore(): Observable<KcwSearchResponse> {
    this.setLoading(true);
    this.setError(null);
    this.setSearchResults({
      perPageCount: 0,
      totalCount: 0,
      activityContent: { isLoadMore: false, items: [] },
      relActivityContent: { isLoadMore: false, items: [] },
      standardContent: { isLoadMore: false, items: [] },
      relStandardContent: { isLoadMore: false, items: [] },
    });

    return combineLatest([
      this.getSearchTerm(),
      this.getSectionType(),
      this.getCurrentPage(),
      this.getRuleSetId(),
      this.getActivityId(),
    ]).pipe(
      map(
        ([searchTerm, sectionType, currentPage, ruleSetId, newActivityId]) => {
          const searchType = sectionType;
          const sortOrder = "Relevance";
          const startPage = currentPage;
          const endPage = currentPage;
          const payload = {
            searchType,
            sortOrder,
            ruleSetId,
            searchTerm,
            startPage,
            endPage,
            newActivityId,
          } as KcwSearchMoreRequest;
          return payload;
        }
      ),
      switchMap((payload: KcwSearchMoreRequest) => {
        return this.http
          .post<KcwSearchResponse | KcwSearchEmptyResponse>(
            this.urlResolverService.resolveUrlApiV1(
              "/kcwSearch/kcwShowMoreData"
            ),
            payload
          )
          .pipe(
            map((res) => {
              if (res && (res as KcwSearchEmptyResponse).Message) {
                const emptyResponse = {
                  totalCount: 0,
                  perPageCount: 0,
                  activityContent: {
                    isLoadMore: false,
                    items: [],
                  },
                  relActivityContent: {
                    isLoadMore: false,
                    items: [],
                  },
                  standardContent: {
                    isLoadMore: false,
                    items: [],
                  },
                  relStandardContent: {
                    isLoadMore: false,
                    items: [],
                  },
                } as KcwSearchResponse;
                return emptyResponse;
              }
              return res as KcwSearchResponse;
            }),
            catchError((error) => {
              this.setLoading(false);
              this.setError("Oops! something went wrong.");
              return throwError(error);
            }),
            first()
          );
      }),
      tap(() => this.setLoading(false)),
      // update activity header display text
      tap((response) => this.updateActivityHeaderDisplayText(response)),
      tap((response) => this.setSearchResults(response)),
      tap(({ totalCount, perPageCount }) => {
        const sectionType = this.sectionType$.value;
        const currentPagination = this.pagination$.value;
        if (sectionType) {
          const pagination = this.paginationService.updatePaginationModel(
            currentPagination,
            Number.parseInt((perPageCount || 25).toString(), 10),
            Number.parseInt((totalCount || 0).toString(), 10)
          );
          this.setPagination(pagination);
        } else {
          const pagination = this.paginationService.updatePaginationModel(
            currentPagination,
            0,
            0
          );
          this.setPagination(pagination);
        }
      }),
      first()
    );
  }

  handlePagination(
    paginationEvent: AlexPaginationEventModel
  ): AlexPaginationModel {
    const updatedPagination =
      this.paginationService.handlePaginationEvent(paginationEvent);
    this.setPagination(updatedPagination);
    return updatedPagination;
  }

  updatePaginationModel(currentPage: number): void {
    const model = new AlexPaginationEventModel();
    model.action = AlexPaginationActions.PAGE_CHANGE;
    model.data = this.pagination$.getValue();
    model.additionalData = currentPage;

    const updatedPaginationStartEnd =
      this.paginationService.handlePaginationEvent(model);
    const updatedPaginationModel = this.paginationService.updatePaginationModel(
      updatedPaginationStartEnd,
      updatedPaginationStartEnd.itemsPerPage,
      updatedPaginationStartEnd.recordCount
    );
    this.setPagination(updatedPaginationModel);
  }

  getPaginatedResult(
    results: KcwSearchResultItem[],
    paginationModel: AlexPaginationModel
  ): KcwSearchResultItem[] {
    const { itemsPerPage, currentPage, recordCount } = paginationModel;
    if (itemsPerPage <= 0) {
      return [];
    }
    const offset = (currentPage - 1) * itemsPerPage;
    const endIndex =
      offset + itemsPerPage > recordCount ? recordCount : offset + itemsPerPage;
    const slice = results.slice(offset, endIndex);
    return slice;
  }

  getBreadCrumbText(): Observable<string> {
    return combineLatest([this.getScreenName(), this.getActivityHeader()]).pipe(
      map(([screenName, activityHeader]) => {
        const { activityFlyout, guidancePanel } = this.config.breadCrumbText;
        const parameteredMessage = activityHeader
          ? activityFlyout
          : guidancePanel;
        const params = activityHeader
          ? { screenName, activityTitle: activityHeader }
          : { screenName };
        const breadCrumbText = this.getParsedMessage(
          parameteredMessage,
          params
        );
        return breadCrumbText;
      })
    );
  }

  fetchActivityHeader(
    ruleSetId: string
  ): Observable<KcwSearchActivityHeaderResponse> {
    const activityHeaderResForRulesetId =
      this.activityHeaderResponseByRuleSetIdMap.get(ruleSetId);
    if (activityHeaderResForRulesetId) {
      return scheduled(of(activityHeaderResForRulesetId), asyncScheduler);
    }
    const url = `kcwSearch/kcwGetActivityHeader/${ruleSetId}`;
    return this.http
      .get<KcwSearchActivityHeaderResponse>(
        this.urlResolverService.resolveUrlApiV1(url)
      )
      .pipe(
        tap((res) =>
          this.activityHeaderResponseByRuleSetIdMap.set(ruleSetId, res)
        ),
        first()
      );
  }

  updateBrowserTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  private updateActivityHeaderDisplayText(response: KcwSearchResponse) {
    const currentActivityHeaderText$ = this.getCurrentActivityHeaderText();
    const currentSectionType$ = this.getSectionType();

    combineLatest([currentActivityHeaderText$, currentSectionType$])
      .pipe(
        tap(([currentActivityHeaderText, sectionType]) => {
          if (currentActivityHeaderText) {
            return;
          }
          const { field } =
            this.config.searchFields.find((s) => s.id === sectionType) || {};

          if (!field) {
            return;
          }
          const { items } = response[field] as KcwSearchResultSection;

          if (Array.isArray(items) && items.length > 0) {
            const { activityHeaderDisplayText } = items[0];
            this.setCurrentActivityHeaderText(activityHeaderDisplayText);
          }
        }),
        first()
      )
      .subscribe();
  }

  /**
   * Method for replacing params in parametered string.
   * @param parameteredMessage Message with parameters.
   * @param params A key-value pair object specifying param keys to be replaced with values.
   * @returns Param replaced string.
   */
  private getParsedMessage(
    parameteredMessage: string,
    params: Readonly<{ [key: string]: string }>
  ): string {
    return this.messageParseService.parseMessage(parameteredMessage, params);
  }
}
