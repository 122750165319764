import { Injectable } from '@angular/core';
import { AlexDocumentType } from "app/core/services/app/app.constant";
import { SearchService } from "app/feature/search/services/search.service";

@Injectable()
export class DocumentSpaceHeaderService {
  TOPIC = "topic";
  BROWSE_PAGE = "Content Page";
  BROWSE_PAGE_QUERY = "Content Page";
  FEATURE = "feature";
  DEFAULT_ICON = "file";
  QUERY_ICON = "query";
  EXTERNAL_URL = "External URLs";
  icon:string;
  constructor(private searchService:SearchService) { }

  chooseIcon(docType:string, mimeType:string) {
    switch (docType.toLowerCase()) {
      case AlexDocumentType.Document.toLowerCase():
        this.icon = this.searchService.getFileType(mimeType);
        break;
      case "search":
        this.icon = this.searchService.getFileType(mimeType);
        break;
      case AlexDocumentType.Topic.toLowerCase():
        this.icon = this.TOPIC;
        break;
      case "top":
        this.icon = this.TOPIC;
        break;
      case AlexDocumentType.ExternalUrl.toLowerCase():
        this.icon = this.EXTERNAL_URL;
        break;
      case AlexDocumentType.BrowsePage.toLowerCase():
        this.icon = this.BROWSE_PAGE;
        break;
      case AlexDocumentType.BrowsePageQuery.toLowerCase():
        this.icon = this.BROWSE_PAGE_QUERY;
        break;
      case AlexDocumentType.Feature.toLowerCase():
        // this.icon = this.FEATURE;
        this.icon = this.searchService.getFileType(mimeType);
        break;
      case AlexDocumentType.Query.toLowerCase():
        this.icon = this.QUERY_ICON;
        break;
      default:
        this.icon = this.DEFAULT_ICON;
    }
    return this.icon;
  }
}
