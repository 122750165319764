export class Space {
    id: string;
    title: string;
    documentCount: number;
    createdDate: string;
    documentTitleList:Array<string>;
}



// export class SavedSearch {
//     QueryID: string
//     QueryName: string
//     QueryUrl: string
//     QueryCreatedDate: string
//     QueryUpdatedDate: string
// }


export enum Action {
    ADD,
    EDIT,
    DELETE
}

