export const SvgIconList = new Map();

SvgIconList.set("alexHome",
    `<svg class="logoWidth" xmlns="http://www.w3.org/2000/svg" width="77" height="35" viewBox="0 0 65 27" logo-top-left>
        <path fill="#00338D" fill-rule="evenodd" d="M50.47 0v12.956c-.455.357-.84.74-1.2 1.152V0H34.867v11.797h-1.193V0H19.27v11.816h-1.192V0H3.675v13.467L0 25.677h3.232l1.624-5.407h.466l2.681 5.408h3.906L9.308 20.27h5.901l-1.637 5.408h3.532l1.616-5.393h.775v-.015h7.864l-1.56 5.392h3.561l1.512-5.392h1.602l.043 5.392h2.988l3.428-5.392h2.245l-1.157 5.392h3.506l1.137-5.392h2.028c-.08 1.671.348 3.19 1.46 4.212 1.36 1.244 3.445 1.482 4.99 1.482 2.112 0 4.305-.302 6.512-.798l1.215-4.896h4.002V0H50.47zM17.56 12.503l-.21.692-1.914 6.347-.075.223h-6.3l-.49-1.034 6.79-6.915h-4.363l-5.31 5.7 1.72-5.7H4.19V.512H17.56v11.991zm3.719 5.367h-.005c-.129.007-.26.018-.409.018-.195 0-.348.007-.492.007l-.856-.007.396-1.459.187-.724.45-1.697c.199 0 .394-.007.58-.007h.663c1.131 0 1.847.064 2.108.427.2.272.174.742-.062 1.458-.404 1.233-.915 1.859-2.56 1.984zm9.737 1.895l1.409-4.984.05 4.984h-1.46zm2.14-7.968h-3.348l-2.296 7.968H23.96c1.81-.667 2.91-1.94 3.25-3.83.281-1.469.148-2.435-.453-3.159-.9-1.076-2.708-.984-4.314-.984l-2.656.005V.512h13.369v11.285zm8.124 7.968h-2.013l3.047-4.777-1.034 4.777zm7.471-7.744l-.006 2.76c-.933 1.284-1.513 2.687-1.783 3.816a7.68 7.68 0 0 0-.214 1.168h-1.966l1.681-7.95-5.658-.004-5.064 7.954h-.369V.512h13.38v11.51zm7.713 10.998c-.764.138-1.525.23-2.25.23-1.93 0-3.272-.894-3.3-2.98h6.235l-.685 2.75zm7.899-3.254h-3.37l.556-2.221h-6.753l-.555 2.22h-3.264v-.455c.05-.25.094-.513.157-.794.594-2.395 2.172-4.761 4.901-4.761 1.08 0 2.154.41 2.007 1.899h4.017c.159-.695.425-1.878-.333-2.966-.857-1.182-2.582-1.656-4.82-1.656-1.59 0-3.918.253-5.929 1.561V.512h13.386v19.253z" />
        </svg>`);
SvgIconList.set("alexLogo",
    `<svg class="alexLogo logoWidth" xmlns="http://www.w3.org/2000/svg" width="105" height="32" viewBox="0 0 105 32">
            <g fill="none" fill-rule="evenodd">
                <path fill="#6D2077" d="M21.616 26.323c-.488.246-.956.527-1.435.79-.063.035-.133.059-.237.104-.01-.122-.022-.205-.022-.288 0-6.5 0-13.002-.007-19.503 0-.266.126-.392.32-.524 1.708-1.16 3.585-1.965 5.558-2.561 1.658-.5 3.351-.849 5.081-.998.97-.083 1.944-.125 2.915-.198.27-.02.37.041.37.336-.01 4.025-.007 8.05-.007 12.074 0 2.563-.005 5.127.006 7.69.001.284-.064.382-.368.386-.875.01-1.752.02-2.621.101-.955.09-1.907.228-2.85.401-2.334.43-4.583 1.123-6.703 2.19zm-3.027.536v.395c-4.413-2.649-9.185-3.601-14.225-3.641v-.358c0-6.578.002-13.157-.007-19.736 0-.313.085-.402.4-.372 1.481.14 2.972.204 4.444.405 2.016.276 3.965.826 5.84 1.624 1.142.485 2.237 1.046 3.263 1.74.208.14.291.285.29.537-.007 6.468-.005 12.937-.005 19.406zM36.872 4.697v22.787H22.425c4.115-2.04 8.515-2.657 13.066-2.571v-.575c-.002-3.082-.005-6.165-.005-9.248 0-4.303 0-8.605.01-12.908 0-.264-.055-.37-.35-.356-.795.036-1.593.017-2.388.066-1.083.066-2.174.114-3.243.282-1.22.191-2.429.468-3.625.773-2.286.584-4.425 1.508-6.366 2.848-.2.137-.331.136-.529-.002-1.459-1.019-3.06-1.768-4.74-2.355-1.995-.698-4.043-1.18-6.151-1.366-1.428-.126-2.862-.172-4.294-.248-.253-.014-.508-.002-.768-.002v23.093c4.557-.092 8.951.538 13.064 2.576H1.672V4.68H0v24.74H15.74c.172 0 .321-.02.453.183.603.93 1.45 1.495 2.59 1.624 1.437.163 2.526-.405 3.344-1.52.162-.222.305-.291.56-.291 5.122.005 10.244.003 15.365.002h.355V4.697h-1.535z" />
                <path fill="#6D2077" d="M16.465 20.485c-2.968-2.016-6.27-2.63-9.673-3.128l-.141-.021-.107 1.139.126.018c3.226.473 6.354 1.05 9.156 2.887l.108.072.644-.89-.113-.077zM31.948 17.311l-.143.026c-.309.054-.615.104-.918.154-.7.116-1.361.224-2.032.372-.835.185-1.741.426-2.85.758a12.965 12.965 0 0 0-3.33 1.52c-.12.078-.23.164-.347.256-.053.042-.107.084-.163.126l-.104.079.618.844.108-.07c2.826-1.836 5.941-2.413 9.15-2.886l.127-.018-.116-1.16zM16.465 15.84c-2.968-2.017-6.27-2.63-9.673-3.129l-.141-.02-.107 1.138.126.018c3.226.473 6.354 1.05 9.156 2.888l.108.071.644-.89-.113-.077zM31.948 12.666l-.143.025c-.309.054-.615.104-.918.154-.7.116-1.361.225-2.032.373-.835.184-1.741.425-2.85.758a12.965 12.965 0 0 0-3.33 1.519c-.12.078-.23.165-.347.256-.053.042-.107.084-.163.126l-.104.079.618.845.108-.07c2.826-1.837 5.941-2.414 9.15-2.887l.127-.018-.116-1.16zM16.465 11.06c-2.968-2.015-6.27-2.63-9.673-3.128l-.141-.02-.107 1.139.126.018c3.226.473 6.354 1.05 9.156 2.887l.108.072.644-.89-.113-.077zM31.948 7.887l-.143.025c-.309.054-.615.105-.918.155-.7.115-1.361.224-2.032.372-.835.184-1.741.425-2.85.758a12.965 12.965 0 0 0-3.33 1.52c-.12.077-.23.164-.347.256-.053.041-.107.084-.163.126l-.104.078.618.845.108-.07c2.826-1.837 5.941-2.413 9.15-2.886l.127-.019-.116-1.16z" />
                <text fill="#00338D" stroke="#00338D" stroke-width="1.2" font-family="KPMG-Light, KPMG" font-size="32.3" font-weight="300" letter-spacing="5.015">
                    <tspan x="49.254" y="28">ALE</tspan> <tspan x="93.595" y="28">X</tspan>
                </text>
            </g>
         </svg>`);
SvgIconList.set("headerTab_Home",
    `<svg class="svgIm oceanBlue" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                width="18px" height="1px" viewBox="0 0 18 1" xml:space="preserve">
                <g transform="scale(1.7)">
                    <g transform="translate(-32,-62.6)" xmlns="http://www.w3.org/2000/svg" id="home">
                        <path id="path_26_" d="M41,60h1.75c0.138,0,0.17-0.08,0.073-0.177l-6.084-6.084c-0.097-0.097-0.257-0.098-0.355-0.002
			            l-6.206,6.087C30.08,59.921,30.112,60,30.25,60H32v5.75c0,0.138,0.112,0.25,0.25,0.25h2.5c0.138,0,0.25-0.112,0.25-0.25V62h3v3.75
			            c0,0.138,0.112,0.25,0.25,0.25h2.5c0.138,0,0.25-0.112,0.25-0.25V60z" />
                    </g>
                </g>
            </svg>`);
SvgIconList.set("headerTab_Spaces",
    `<svg class="svgIm GreyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="18px" height="1px" viewBox="0 0 18 1" xml:space="preserve">
                    <g transform="scale(1.7)">
                        <g transform="translate(-488,-373.6)" xmlns="http://www.w3.org/2000/svg" id="cube-black">
                            <path d="M494,370.5v7c0,0.276-0.224,0.5-0.5,0.5h-7c-0.276,0-0.5-0.224-0.5-0.5v-7c0-0.276,0.224-0.5,0.5-0.5h7
			                C493.776,370,494,370.224,494,370.5z M496.25,366h-6.344c-0.276,0-0.668,0.148-0.875,0.33l-2.656,2.339
			                c-0.207,0.183-0.151,0.331,0.125,0.331h7.25c0.276,0,0.643-0.172,0.82-0.384l1.86-2.232C496.607,366.172,496.526,366,496.25,366z
			                 M497.675,366.88l-2.349,2.741c-0.18,0.21-0.325,0.604-0.325,0.88v7c0,0.276,0.146,0.33,0.325,0.12l0.837-0.977l1.512-1.764
			                c0.18-0.21,0.325-0.604,0.325-0.88v-7C498,366.724,497.854,366.67,497.675,366.88z" />
                        </g>
                    </g>
                </svg>`);
SvgIconList.set("headerTab_History",
    `<svg class="svgIm GreyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                width="18px" height="1px" viewBox="0 0 18 1" xml:space="preserve">
                <g transform="scale(0.87)">
                    <g transform="translate(-398,-314.5)" xmlns="http://www.w3.org/2000/svg" id="history_1_">
                        <path d="M413.205,308.258l-3.268,3.27c0.037,0.153,0.062,0.314,0.062,0.479c0,1.105-0.895,2.006-2,2.006
			c-0.748,0-1.393-0.512-1.736-1.012H403.5c-0.275,0-0.5-0.249-0.5-0.523v-0.953c0-0.274,0.225-0.523,0.5-0.523h2.764
			c0.344-0.5,0.988-1.012,1.736-1.012c0.197,0,0.385,0.049,0.565,0.102l3.238-3.237c0.194-0.194,0.513-0.194,0.707,0l0.694,0.696
			C413.4,307.744,413.4,308.062,413.205,308.258z M419.791,314c0.115-1,0.197-1.334,0.197-2.018c0-6.618-5.383-12.01-12-12.01
			c-6.617,0-11.994,5.377-11.994,11.996c0,6.615,5.006,11.994,12.006,11.994v-0.002c2-0.006,4.509-0.643,6.527-1.961l0.057-0.039
			l1.476,2.046c0.162,0.224,0.361,0.189,0.442-0.073l2.205-6.439c0.082-0.263-0.077-0.48-0.353-0.486l-6.835,0.002
			c-0.276-0.006-0.37,0.174-0.21,0.396l1.515,2.12c-1.495,0.961-2.825,1.424-4.825,1.431v0.008c-5-0.001-9.008-4.03-9.006-9.001
			c0-4.971,4.027-9,8.998-9c4.97,0,8.998,4.048,8.998,9.02c0,0.688-0.092,1.018-0.24,2.018H419.791z" />
                    </g>
                </g>
            </svg>`);
SvgIconList.set("headerTab_Search",
    `<svg class="svgIm GreyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                width="18px" height="1px" viewBox="0 0 18 1" xml:space="preserve">
                <g transform="scale(0.87)">
                    <g transform="translate(-398,-314.5)" xmlns="http://www.w3.org/2000/svg" id="search_1_">
                        <path d="M413.205,308.258l-3.268,3.27c0.037,0.153,0.062,0.314,0.062,0.479c0,1.105-0.895,2.006-2,2.006
			c-0.748,0-1.393-0.512-1.736-1.012H403.5c-0.275,0-0.5-0.249-0.5-0.523v-0.953c0-0.274,0.225-0.523,0.5-0.523h2.764
			c0.344-0.5,0.988-1.012,1.736-1.012c0.197,0,0.385,0.049,0.565,0.102l3.238-3.237c0.194-0.194,0.513-0.194,0.707,0l0.694,0.696
			C413.4,307.744,413.4,308.062,413.205,308.258z M419.791,314c0.115-1,0.197-1.334,0.197-2.018c0-6.618-5.383-12.01-12-12.01
			c-6.617,0-11.994,5.377-11.994,11.996c0,6.615,5.006,11.994,12.006,11.994v-0.002c2-0.006,4.509-0.643,6.527-1.961l0.057-0.039
			l1.476,2.046c0.162,0.224,0.361,0.189,0.442-0.073l2.205-6.439c0.082-0.263-0.077-0.48-0.353-0.486l-6.835,0.002
			c-0.276-0.006-0.37,0.174-0.21,0.396l1.515,2.12c-1.495,0.961-2.825,1.424-4.825,1.431v0.008c-5-0.001-9.008-4.03-9.006-9.001
			c0-4.971,4.027-9,8.998-9c4.97,0,8.998,4.048,8.998,9.02c0,0.688-0.092,1.018-0.24,2.018H419.791z" />
                    </g>
                </g>
            </svg>`);
SvgIconList.set("contextualBar_Help",
    ` <svg class="svgIm helpIcon steelgray" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="38px" height="1px" viewBox="0 0 38 1" xml:space="preserve">
                    <g transform="scale(.89)">
                        <g transform="translate(-242,-984)" xmlns="http://www.w3.org/2000/svg" id="question-sign_2_">
                            <path d="M266.047,991.5c0,0.275-0.225,0.5-0.5,0.5H262.5c-0.275,0-0.5-0.225-0.5-0.5v-3c0-0.275,0.225-0.5,0.5-0.5h3.047
                        c0.275,0,0.5,0.225,0.5,0.5V991.5z M264,971.75c-6.765,0-12.25,5.484-12.25,12.25s5.485,12.25,12.25,12.25
                        s12.25-5.484,12.25-12.25S270.765,971.75,264,971.75z M266.85,983.832c-0.967,0.631-1.256,1.175-1.288,2.424
                        c-0.003,0.137-0.114,0.244-0.25,0.244h-2.561c-0.068,0-0.25-0.182-0.25-0.25v-1.019c0-1.069,0.576-2.01,1.766-2.877
                        c0.093-0.064,0.983-0.647,0.983-1.449c0-0.666-0.501-1.131-1.219-1.131c-1.023,0-1.593,0.513-1.648,1.491
                        c-0.008,0.132-0.117,0.235-0.25,0.235H262h-2.561c-0.068,0-0.135-0.028-0.182-0.078c-0.047-0.049-0.072-0.116-0.068-0.185
                        c0.14-2.716,1.904-4.273,4.844-4.273c2.228,0,4.629,1.209,4.629,3.864C268.662,982.607,268.256,982.943,266.85,983.832z"
                            />
                        </g>
                    </g>
                </svg>`);
SvgIconList.set('calander1',
    `<svg class="svgIm GreyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22px"
                                                height="1px" viewBox="0 0 22 1" xml:space="preserve">
                                                <g transform="scale(0.7)">
                                                    <g transform="translate(-298,-267.6)" xmlns="http://www.w3.org/2000/svg" id="calendar_2_">
                                                    <path d="M300,259v16c0,0.55,0.45,1,1,1h21c0.55,0,1-0.45,1-1v-16H300z M305,274h-3v-3h3V274z M305,270h-3v-3h3V270z M305,266h-3
			                                            v-3h3V266z M309,274h-3v-3h3V274z M309,270h-3v-3h3V270z M309,266h-3v-3h3V266z M313,274h-3v-3h3V274z M313,270h-3v-3h3V270z
			                                             M313,266h-3v-3h3V266z M317,274h-3v-3h3V274z M317,270h-3v-3h3V270z M317,266h-3v-3h3V266z M321,274h-3v-3h3V274z M321,270h-3v-3
			                                            h3V270z M321,266h-3v-3h3V266z M323,255v3h-23v-3c0-0.55,0.45-1,1-1h2v-1c0-0.55,0.45-1,1-1h1c0.55,0,1,0.45,1,1v1h11v-1
			                                            c0-0.55,0.45-1,1-1h1c0.55,0,1,0.45,1,1v1h2C322.55,254,323,254.45,323,255z" />
                                                    </g>
                                                </g>	
                </svg>`);
SvgIconList.set('feedbackForm',
    `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
    class="svgIm steelgray" version="1.1" id="Layer_1" 
    width="49" height="1" viewBox="0 -30 30 100" xml:space="preserve">
    <defs><style>.cls-1{fill-rule:evenodd;}</style></defs>
    <g xmlns="http://www.w3.org/2000/svg" transform="scale(0.35)">
    <g xmlns="http://www.w3.org/2000/svg" id="feedbackForm">
    <path class="cls-1" d="M55.05,97.68l-24.9,23.88a3.95,3.95,0,0,1-6.89-2.62V97.68H10.1A10.16,10.16,0,0,1,0,87.58V10.1A10.18,10.18,0,0,1,10.1,0H111.73a10.16,10.16,0,0,1,10.1,10.1V87.58a10.16,10.16,0,0,1-10.1,10.1ZM27.53,36.61a3.94,3.94,0,0,1,0-7.87H94.3a3.94,3.94,0,1,1,0,7.87Zm0,25.55a3.94,3.94,0,0,1,0-7.87H82a3.94,3.94,0,0,1,0,7.87Z"/>
    </g>
    </g>
    </svg>`);
SvgIconList.set('calander2',
    `<svg class="svgIm calenderIcon greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22px"
                        height="1px" viewBox="0 0 22 1"  xml:space="preserve">
                        <g transform="scale(0.7)">
                            <g transform="translate(-300,-265.6)" xmlns="http://www.w3.org/2000/svg" id="calendar_2_">
                            <path d="M300,259v16c0,0.55,0.45,1,1,1h21c0.55,0,1-0.45,1-1v-16H300z M305,274h-3v-3h3V274z M305,270h-3v-3h3V270z M305,266h-3
                                v-3h3V266z M309,274h-3v-3h3V274z M309,270h-3v-3h3V270z M309,266h-3v-3h3V266z M313,274h-3v-3h3V274z M313,270h-3v-3h3V270z
                                    M313,266h-3v-3h3V266z M317,274h-3v-3h3V274z M317,270h-3v-3h3V270z M317,266h-3v-3h3V266z M321,274h-3v-3h3V274z M321,270h-3v-3
                                h3V270z M321,266h-3v-3h3V266z M323,255v3h-23v-3c0-0.55,0.45-1,1-1h2v-1c0-0.55,0.45-1,1-1h1c0.55,0,1,0.45,1,1v1h11v-1
                                c0-0.55,0.45-1,1-1h1c0.55,0,1,0.45,1,1v1h2C322.55,254,323,254.45,323,255z" />
                            </g>
                        </g>	
            </svg>`);
SvgIconList.set('location1',
    `<svg class="svgIm GreyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      width="28px" height="1px" viewBox="0 0 22 1" xml:space="preserve">
      <g transform="scale(1.1)">
        <g transform="translate(-57 -110)" xmlns="http://www.w3.org/2000/svg" id="map-marker_2_">
          <path d="M63.972,106.585c0,0.881-0.183,1.838-0.727,2.701c-1.638,2.544-3.711,4.878-3.711,4.878s-2.383-2.253-3.906-4.924
                                        c-0.382-0.834-0.631-1.716-0.631-2.6c0-2.483,2.008-4.64,4.488-4.64C61.963,102,63.972,104.102,63.972,106.585z M62.208,106.404
                                        c0-1.484-1.202-2.683-2.683-2.683c-1.481,0-2.683,1.2-2.683,2.683c0,1.483,1.202,2.682,2.683,2.682
                                        C61.006,109.086,62.208,107.886,62.208,106.404z" />
        </g>
      </g>
    </svg>`);
SvgIconList.set('remove-sign',
    `<svg class="svgIm" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
                                 height="1px" viewBox="0 0 28 1" style="fill: #acacac" xml:space="preserve">
                            <g transform="scale(2.6)">
                            <g transform="translate(-80,-132.5)" xmlns="http://www.w3.org/2000/svg" id="remove-sign">
                                <path d="M84,126.229c-3.187,0-5.771,2.584-5.771,5.771s2.584,5.771,5.771,5.771s5.771-2.584,5.771-5.771
                            S87.187,126.229,84,126.229z M82.585,135.128c-0.08,0-0.155-0.031-0.212-0.088l-1.414-1.414c-0.117-0.117-0.117-0.307,0-0.424
                            L82.162,132l-1.202-1.202c-0.117-0.117-0.117-0.308,0-0.424l1.414-1.414c0.056-0.057,0.131-0.087,0.212-0.087
                            s0.156,0.03,0.212,0.087L84,130.162l1.202-1.202c0.056-0.057,0.131-0.088,0.212-0.088s0.156,0.031,0.212,0.087l1.414,1.415
                            c0.057,0.057,0.088,0.132,0.088,0.212s-0.031,0.155-0.087,0.212L85.838,132l1.202,1.202c0.057,0.057,0.088,0.132,0.088,0.212
                            s-0.031,0.155-0.087,0.212l-1.414,1.414c-0.056,0.057-0.131,0.087-0.212,0.087s-0.156-0.03-0.212-0.087L84,133.838l-1.202,1.202
                            C82.741,135.097,82.666,135.128,82.585,135.128z"/>
	                                </g>
	                            </g>	
                        </svg>`);
SvgIconList.set('search_1',
    `<svg class="svgIm" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
                                 height="1px" viewBox="0 0 28 1" style="fill: #fff" xml:space="preserve">
                            <g transform="scale(1.7)">
                            <g transform="translate(-399,-168.5)" xmlns="http://www.w3.org/2000/svg" id="search_1_">
                                <path d="M419.602,176.755l-5.878-5.879c0.956-1.482,1.52-3.24,1.52-5.132c0-5.235-4.262-9.494-9.496-9.494
			                        c-5.236,0-9.494,4.259-9.494,9.494c0,5.236,4.258,9.495,9.494,9.495c1.891,0,3.65-0.562,5.132-1.519l5.878,5.878
			                        c0.195,0.196,0.518,0.196,0.712,0l2.132-2.132C419.799,177.271,419.799,176.951,419.602,176.755z M399.27,165.744
			                        c0-3.571,2.906-6.479,6.478-6.479c3.573,0,6.479,2.907,6.479,6.479c0,3.573-2.905,6.48-6.479,6.48
			                        C402.176,172.224,399.27,169.317,399.27,165.744z" />
	                                </g>
	                            </g>	
                            </svg>`);
SvgIconList.set("list-plus",
    `<svg class="svgIm GreyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            width="22px" height="1px" viewBox="0 0 22 1" xml:space="preserve">
            <g transform="scale(1.9)">
                <g transform="translate(-437,-372)" xmlns="http://www.w3.org/2000/svg" id="list-plus">
                    <path d="M440,373.5v1c0,0.275-0.225,0.5-0.5,0.5h-1c-0.275,0-0.5-0.225-0.5-0.5v-1c0-0.275,0.225-0.5,0.5-0.5h1
			C439.775,373,440,373.225,440,373.5z M449.5,376h-8c-0.275,0-0.5,0.225-0.5,0.5v1c0,0.275,0.225,0.5,0.5,0.5h8
			c0.275,0,0.5-0.225,0.5-0.5v-1C450,376.225,449.775,376,449.5,376z M439.5,376h-1c-0.275,0-0.5,0.225-0.5,0.5v1
			c0,0.275,0.225,0.5,0.5,0.5h1c0.275,0,0.5-0.225,0.5-0.5v-1C440,376.225,439.775,376,439.5,376z M449.5,367h-6.592
			c0.056,0.157,0.092,0.324,0.092,0.5v1c0,0.176-0.036,0.343-0.092,0.5h6.592c0.275,0,0.5-0.225,0.5-0.5v-1
			C450,367.225,449.775,367,449.5,367z M449.5,370h-8c-0.275,0-0.5,0.225-0.5,0.5v1c0,0.275,0.225,0.5,0.5,0.5h8
			c0.275,0,0.5-0.225,0.5-0.5v-1C450,370.225,449.775,370,449.5,370z M449.5,373h-8c-0.275,0-0.5,0.225-0.5,0.5v1
			c0,0.275,0.225,0.5,0.5,0.5h8c0.275,0,0.5-0.225,0.5-0.5v-1C450,373.225,449.775,373,449.5,373z M441.5,367H440v-1.5
			c0-0.275-0.225-0.5-0.5-0.5h-1c-0.275,0-0.5,0.225-0.5,0.5v1.5h-1.5c-0.275,0-0.5,0.225-0.5,0.5v1c0,0.275,0.225,0.5,0.5,0.5h1.5
			v1.5c0,0.275,0.225,0.5,0.5,0.5h1c0.275,0,0.5-0.225,0.5-0.5V369h1.5c0.275,0,0.5-0.225,0.5-0.5v-1
			C442,367.225,441.775,367,441.5,367z" />
                </g>
            </g>
        </svg>`);
SvgIconList.set("back-icon",
    `<svg class="svgIm cnbArrow" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="40px" height="1px" viewBox="0 0 35 1" xml:space="preserve">
                    <g transform="scale(0.79)">
                        <g transform="translate(-242,-1127.5)" xmlns="http://www.w3.org/2000/svg" id="chevron-left_2_">
                            <polygon points="255.808,1128 266.301,1117.507 269.973,1121.179 263.161,1128 269.977,1134.816 266.302,1138.492 		" />
                        </g>
                    </g>
                </svg>`);
SvgIconList.set("forward-icon",
    `<svg class="svgIm cnbArrow" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="40px" height="1px" viewBox="0 0 40 1" xml:space="preserve">
                    <g transform="scale(0.9)">
                        <g transform="translate(-194,-1127.5)" xmlns="http://www.w3.org/2000/svg" id="chevron-right_1_">
                            <polygon points="224.227,1128 213.734,1117.507 210.062,1121.179 216.874,1128 210.058,1134.816 213.733,1138.492 		" />
                        </g>
                    </g>
                </svg>`);
SvgIconList.set("location-icon",
    `<svg class="svgIm  whiteColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="40px" height="1px" viewBox="0 0 22 1" xml:space="preserve">
                    <g transform="scale(1.02)">
                        <g transform="translate(-49,-107.5)" xmlns="http://www.w3.org/2000/svg" id="map-marker_2_">
                            <path d="M63.972,106.585c0,0.881-0.183,1.838-0.727,2.701c-1.638,2.544-3.711,4.878-3.711,4.878s-2.383-2.253-3.906-4.924
                    c-0.382-0.834-0.631-1.716-0.631-2.6c0-2.483,2.008-4.64,4.488-4.64C61.963,102,63.972,104.102,63.972,106.585z M62.208,106.404
                    c0-1.484-1.202-2.683-2.683-2.683c-1.481,0-2.683,1.2-2.683,2.683c0,1.483,1.202,2.682,2.683,2.682
                    C61.006,109.086,62.208,107.886,62.208,106.404z" />
                        </g>
                    </g>
                </svg>`);
SvgIconList.set("pagination_left_arrow",
    `<svg class="svgIm filterNav greyColor4 filterleftIcon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
              height="1px" viewBox="0 0 22 1" xml:space="preserve">
          <g transform="scale(0.6)">
          <g transform="translate(-245,-1127)" xmlns="http://www.w3.org/2000/svg" id="chevron-left_2_">
              <polygon points="255.808,1128 266.301,1117.507 269.973,1121.179 263.161,1128 269.977,1134.816 266.302,1138.492 		" />
              </g>
          </g>	
          </svg>`);
SvgIconList.set("pagination_right_arrow",
    `<svg class="svgIm greyColor4 filterNav filterRightIcon " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
                height="1px" viewBox="0 0 22 1"  xml:space="preserve">
                    <g transform="scale(0.6)">
                    <g transform="translate(-198,-1127.5)" xmlns="http://www.w3.org/2000/svg" id="chevron-right_1_">
                    <polygon points="224.227,1128 213.734,1117.507 210.062,1121.179 216.874,1128 210.058,1134.816 213.733,1138.492 		" />
                    </g>
                </g>	
            </svg>`);
SvgIconList.set("filter_cross_icon",
    `<svg class="svgIm filterCrossIcon " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22px"
                                        height="1px" viewBox="0 0 28 1" xml:space="preserve">
                        <g transform="scale(2.5)">
                        <g transform="translate(-405,-1035.5)" xmlns="http://www.w3.org/2000/svg" id="remove_2_">
                            <path d="M411.195,1031.996l5.648-5.648c0.221-0.222,0.221-0.585,0-0.806l-2.383-2.383c-0.221-0.221-0.583-0.221-0.805,0
                                l-5.65,5.649l-5.653-5.652c-0.222-0.221-0.584-0.221-0.805,0l-2.383,2.384c-0.222,0.22-0.222,0.582,0,0.806l5.652,5.651
                                l-5.65,5.65c-0.22,0.22-0.22,0.583,0,0.805l2.383,2.383c0.222,0.22,0.586,0.22,0.806,0l5.65-5.649l5.646,5.646
                                c0.221,0.221,0.585,0.221,0.806,0l2.384-2.384c0.22-0.22,0.22-0.583,0-0.806L411.195,1031.996z"/>
                                    </g>
                                </g>	
                            </svg>`);
SvgIconList.set("fly",
    `<svg class="svgIm OptionHorizontal greyColor3 " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="38px"
            height="1px" viewBox="0 0 38 1"  xml:space="preserve">
                  <g transform="scale(1.5)">
                        <g transform="translate(-335,-300.5)" xmlns="http://www.w3.org/2000/svg" id="option-horizontal">
                        <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M344.5,298c0.828,0,1.5,0.672,1.5,1.5s-0.672,1.5-1.5,1.5
                                                s-1.5-0.672-1.5-1.5S343.672,298,344.5,298z M347,299.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5
                                                S347,298.672,347,299.5z M351,299.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5S351,298.672,351,299.5z" />
                        </g>
                  </g>           	
            </svg>`);
SvgIconList.set("flyOut",
    `<svg class="svgIm  greyColor1 " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30px" height="1px" viewBox="0 0 38 1" xml:space="preserve">
                                                    <g transform="scale(1.9)">
                                                    <g transform="translate(-457,-180.9)" xmlns="http://www.w3.org/2000/svg" id="briefcase">

                                                    <rect x="467" y="180" style="opacity:0.5;" width="2" height="1"></rect>
                                                    <rect x="462" y="181" style="opacity:0.3;" width="12" height="1"></rect>
                                                    <g>
                                                    <path d="M467,182h-5v2c0,0.55,0.45,1,1,1h10c0.55,0,1-0.45,1-1v-2h-5H467z"></path>
                                                    <path d="M473,176h-3v-1c0-0.551-0.448-1-1-1h-2c-0.552,0-1,0.449-1,1v1h-3c-0.55,0-1,0.45-1,1v4h5v-1h2v1h5v-4
                                                    C474,176.45,473.55,176,473,176z M467,175h2v1h-2V175z"></path>
                                                    </g>

                                                    </g>
                                                    </g>	
                                                    </svg>`);
SvgIconList.set("flyIn",
    `<svg class="svgIm  greyColor1 " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30px" height="1px" viewBox="0 0 38 1" xml:space="preserve">
                                                    <g transform="scale(1.9)">
                                                    <g transform="translate(-457,-180.9)" xmlns="http://www.w3.org/2000/svg" id="briefcase">

                                                    <rect x="467" y="180" style="opacity:0.5;" width="2" height="1"></rect>
                                                    <rect x="462" y="181" style="opacity:0.3;" width="12" height="1"></rect>
                                                    <g>
                                                    <path d="M467,182h-5v2c0,0.55,0.45,1,1,1h10c0.55,0,1-0.45,1-1v-2h-5H467z"></path>
                                                    <path d="M473,176h-3v-1c0-0.551-0.448-1-1-1h-2c-0.552,0-1,0.449-1,1v1h-3c-0.55,0-1,0.45-1,1v4h5v-1h2v1h5v-4
                                                    C474,176.45,473.55,176,473,176z M467,175h2v1h-2V175z"></path>
                                                    </g>

                                                    </g>
                                                    </g>	
                                                    </svg>`);



SvgIconList.set("spaces",
    `<svg class="svgIm steelgray" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="49px" height="1px" viewBox="0 0 49 1" xml:space="preserve">
                                <g transform="scale(1.4)">
                                    <g transform="translate(-476,-371.6)" xmlns="http://www.w3.org/2000/svg" id="cube-black">
                                        <path d="M494,370.5v7c0,0.276-0.224,0.5-0.5,0.5h-7c-0.276,0-0.5-0.224-0.5-0.5v-7c0-0.276,0.224-0.5,0.5-0.5h7
			                C493.776,370,494,370.224,494,370.5z M496.25,366h-6.344c-0.276,0-0.668,0.148-0.875,0.33l-2.656,2.339
			                c-0.207,0.183-0.151,0.331,0.125,0.331h7.25c0.276,0,0.643-0.172,0.82-0.384l1.86-2.232C496.607,366.172,496.526,366,496.25,366z
			                 M497.675,366.88l-2.349,2.741c-0.18,0.21-0.325,0.604-0.325,0.88v7c0,0.276,0.146,0.33,0.325,0.12l0.837-0.977l1.512-1.764
			                c0.18-0.21,0.325-0.604,0.325-0.88v-7C498,366.724,497.854,366.67,497.675,366.88z" />
                                    </g>
                                </g>
                            </svg>`);
SvgIconList.set("pop_out",
    `<svg class="svgIm steelgray" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="49px" height="1px" viewBox="0 0 49 1" xml:space="preserve">
                                <g transform="scale(0.26)">
                                    <g transform="translate(50,-30.6)" xmlns="http://www.w3.org/2000/svg" id="cube-black">
                                        <g>
                                    <g>
                                        <g>
                                            <polygon points="53,56 8,56 8,11 30,11 30,3 0,3 0,64 61,64 61,34 53,34 			"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <polygon points="42,0 50,8 33,25 39,31 56,14 64,23 64,0 			"/>
                                        </g>
                                    </g>
                                </g>
                                    </g>
                                </g>
                            </svg>`);

SvgIconList.set('share',
    `<svg class="svgIm shareIcon steelgray " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49px" height="1px" viewBox="0 0 49 1" xml:space="preserve">
                            <g transform="scale(1)">
                                <g transform="translate(-145,-1128)" xmlns="http://www.w3.org/2000/svg" id="share_2_">
                                    <path d="M179.098,1128.293l-10.678,7.91c-0.222,0.164-0.402,0.072-0.402-0.203l-0.006-5.002 c-3.017-0.023-9.574-0.105-11.512,4.505c0.139-9.503,9.264-10.8,11.505-10.976l-0.005-4.514c0-0.275,0.182-0.367,0.402-0.203			l10.695,7.889C179.318,1127.862,179.319,1128.129,179.098,1128.293z"></path>
                                </g>
                            </g>
                        </svg>`);
SvgIconList.set('print',
    `<svg class="svgIm prinIcon steelgray " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49px" height="1px" viewBox="0 0 49 1" xml:space="preserve">
                            <g transform="scale(1.6)">
                                <g transform="translate(-118,-84)" xmlns="http://www.w3.org/2000/svg" id="print">
                                    <path id="path_60_" d="M135.328,81.261c0.04,0.131,0.011,0.333-0.065,0.447l-0.624,1.084C134.562,82.906,134.387,83,134.25,83
                    h-4.5c-0.137,0-0.312-0.094-0.389-0.208l-0.575-1.084c-0.076-0.114-0.106-0.316-0.065-0.447l0.779-3.013
                    c0.015-0.137,0.139-0.248,0.276-0.248h4.447c0.138,0,0.262,0.111,0.276,0.248L135.328,81.261z"></path>
                                    <path id="path_59_" d="M134.811,88.242C134.777,88.109,134.638,88,134.5,88h-5c-0.138,0-0.277,0.109-0.311,0.242l-0.379,1.516
                    C128.777,89.891,128.862,90,129,90h6c0.138,0,0.223-0.109,0.189-0.242L134.811,88.242z"></path>
                                    <path id="path_58_" d="M136.25,82c-0.137,0-0.3,0.101-0.362,0.224l-0.776,1.553C135.05,83.899,134.887,84,134.75,84h-5.5
                    c-0.137,0-0.3-0.101-0.362-0.224l-0.776-1.553C128.05,82.101,127.887,82,127.75,82h-1.5c-0.137,0-0.25,0.113-0.25,0.25v6.5
                    c0,0.137,0.113,0.25,0.25,0.25h1.5c0.138,0,0.25-0.113,0.25-0.25v-1.5c0-0.137,0.113-0.25,0.25-0.25h7.5
                    c0.137,0,0.25,0.113,0.25,0.25l0.002,1.5c0,0.137,0.113,0.25,0.25,0.25h1.497c0.137,0,0.25-0.113,0.25-0.25v-6.5
                    c0-0.137-0.113-0.25-0.25-0.25H136.25z"></path>
                                </g>
                            </g>
                        </svg>`);
SvgIconList.set('toc',
    `<svg class="svgIm whiteColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49px" height="1px" viewBox="0 0 49 1" xml:space="preserve">
                <g transform="scale(0.8)">
                <g transform="translate(-235,-600.5)" xmlns="http://www.w3.org/2000/svg" id="list_2_">
                 <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M276,604.5c0,0.275-0.225,0.5-0.5,0.5h-18c-0.275,0-0.5-0.225-0.5-0.5v-2
			c0-0.275,0.225-0.5,0.5-0.5h18c0.275,0,0.5,0.225,0.5,0.5V604.5z M255,602.5c0-0.275-0.225-0.5-0.5-0.5h-2
			c-0.275,0-0.5,0.225-0.5,0.5v2c0,0.275,0.225,0.5,0.5,0.5h2c0.275,0,0.5-0.225,0.5-0.5V602.5z M276,608.5
			c0-0.275-0.225-0.5-0.5-0.5h-18c-0.275,0-0.5,0.225-0.5,0.5v2c0,0.275,0.225,0.5,0.5,0.5h18c0.275,0,0.5-0.225,0.5-0.5V608.5z
			 M255,608.5c0-0.275-0.225-0.5-0.5-0.5h-2c-0.275,0-0.5,0.225-0.5,0.5v2c0,0.275,0.225,0.5,0.5,0.5h2c0.275,0,0.5-0.225,0.5-0.5
			V608.5z M276,596.5c0-0.275-0.225-0.5-0.5-0.5h-18c-0.275,0-0.5,0.225-0.5,0.5v2c0,0.275,0.225,0.5,0.5,0.5h18
			c0.275,0,0.5-0.225,0.5-0.5V596.5z M255,596.5c0-0.275-0.225-0.5-0.5-0.5h-2c-0.275,0-0.5,0.225-0.5,0.5v2
			c0,0.275,0.225,0.5,0.5,0.5h2c0.275,0,0.5-0.225,0.5-0.5V596.5z M276,590.5c0-0.275-0.225-0.5-0.5-0.5h-18
			c-0.275,0-0.5,0.225-0.5,0.5v2c0,0.275,0.225,0.5,0.5,0.5h18c0.275,0,0.5-0.225,0.5-0.5V590.5z M255,590.5
			c0-0.275-0.225-0.5-0.5-0.5h-2c-0.275,0-0.5,0.225-0.5,0.5v2c0,0.275,0.225,0.5,0.5,0.5h2c0.275,0,0.5-0.225,0.5-0.5V590.5z"></path>
                </g>
                </g>	
                </svg>`);
SvgIconList.set('bookmark',
    `<svg class="svgIm whiteColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49px" height="1px" viewBox="0 0 49 1" xml:space="preserve">
                <g transform="scale(0.8)">
                <g transform="translate(-139,-406)" xmlns="http://www.w3.org/2000/svg" id="bookmark_2_">
                    <path d="M175.5,396h-14c-0.275,0-0.5,0.225-0.5,0.5v22.875c0,0.274,0.28,0.353,0.475,0.158l7.086-6.283l6.908,6.172
			c0.194,0.194,0.531,0.156,0.531-0.297V396.5C176,396.225,175.775,396,175.5,396z"></path>
                </g>
                </g>	
                </svg>`);
SvgIconList.set('light',
    `<svg class="svgIm whiteColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49px" height="1px" viewBox="0 0 49 1" xml:space="preserve">
                <g transform="scale(1.11)">
                <g transform="translate(-433,-935.7)" xmlns="http://www.w3.org/2000/svg" id="brightness-reduce_2_">
                    <path d="M448.898,936c0-0.34,0.032-0.672,0.079-1h-2.904c-0.033,0.329-0.051,0.662-0.051,1s0.018,0.671,0.051,1h2.904
			C448.931,936.672,448.898,936.34,448.898,936z"></path>
		<path d="M457,945.927v-2.904c-0.328,0.047-0.66,0.079-1,0.079c-0.34,0-0.672-0.032-1-0.079v2.904c0.329,0.033,0.662,0.051,1,0.051
			C456.338,945.977,456.671,945.96,457,945.927z"></path>
		<path d="M461.671,931.743l2.052-2.052c-0.424-0.517-0.897-0.99-1.414-1.414l-2.052,2.052
			C460.792,930.732,461.268,931.208,461.671,931.743z"></path>
		<path d="M465.927,935h-2.904c0.047,0.328,0.079,0.66,0.079,1s-0.032,0.672-0.079,1h2.904c0.033-0.329,0.051-0.662,0.051-1
			S465.96,935.329,465.927,935z"></path>
		<path d="M456,928.898c0.34,0,0.672,0.032,1,0.079v-2.904c-0.329-0.033-0.662-0.051-1-0.051c-0.338,0-0.671,0.017-1,0.051v2.904
			C455.328,928.931,455.66,928.898,456,928.898z"></path>
		<path d="M461.671,940.257c-0.403,0.535-0.879,1.011-1.414,1.414l2.052,2.052c0.517-0.424,0.99-0.898,1.414-1.414L461.671,940.257z
			"></path>
		<path d="M450.329,940.257l-2.052,2.052c0.424,0.517,0.897,0.99,1.414,1.414l2.052-2.052
			C451.208,941.268,450.732,940.792,450.329,940.257z"></path>
		<path d="M450.329,931.743c0.403-0.535,0.879-1.011,1.414-1.414l-2.052-2.052c-0.517,0.424-0.99,0.898-1.414,1.414L450.329,931.743
			z"></path>
		<path d="M456,939.987c-2.199,0-3.987-1.788-3.987-3.987s1.788-3.987,3.987-3.987c2.199,0,3.987,1.788,3.987,3.987
			S458.199,939.987,456,939.987z M456,934.045c-1.077,0-1.954,0.877-1.954,1.955c0,1.077,0.877,1.954,1.954,1.954
			c1.078,0,1.954-0.877,1.954-1.954C457.954,934.922,457.078,934.045,456,934.045z"></path>
                </g>
                </g>	
                </svg>`);
SvgIconList.set('edit',
    `<svg class="svgIm whiteColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49px" height="1px" viewBox="0 0 49 1" xml:space="preserve">
                <g transform="scale(1.7)">
                <g transform="translate(-116,-107.5)" xmlns="http://www.w3.org/2000/svg" id="edit">
                    <path d="M131.406,108.394l3.553-3.552l1.131,1.131l-3.618,3.618l-1.61,0.499L131.406,108.394z M136,103.869l1.08-1.148
			c0,0,0.436-0.205,0.92,0.279s0.212,0.852,0.212,0.852L137.131,105L136,103.869z"></path>
		<path d="M135,109.185V111c0,0.55-0.45,1-1,1h-5c-0.55,0-1-0.45-1-1v-5c0-0.55,0.45-1,1-1h3.679l1.149-1.148l0.718-0.718
			c-0.325-0.079-0.668-0.134-1.046-0.134h-4c-2.2,0-3.5,1.3-3.5,3.5v4c0,2.2,1.3,3.5,3.5,3.5h4c2.156,0,3.5-1.281,3.5-3.5
			c0,0,0-2.039,0-3.315L135,109.185z"></path>
                </g>
                </g>	
                </svg>`);
SvgIconList.set('magnifying-glass',
    `<svg class="svgIm whiteColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49px" height="1px" viewBox="0 0 49 1" xml:space="preserve">
                <g transform="scale(0.77)">
                <g transform="translate(-330,-1176)" xmlns="http://www.w3.org/2000/svg" id="zoom-in_2_">
                   <path d="M373.469,1186.257l-2.213,2.212c-0.202,0.202-0.534,0.202-0.736,0l-6.098-6.099c-1.537,0.993-3.362,1.577-5.323,1.577
			c-5.431,0-9.849-4.418-9.849-9.849s4.418-9.849,9.849-9.849c5.431,0,9.849,4.418,9.849,9.849c0,1.961-0.584,3.786-1.576,5.323
			l6.098,6.098C373.672,1185.722,373.672,1186.054,373.469,1186.257z M365.818,1174.099c0-3.706-3.014-6.72-6.72-6.72
			c-3.706,0-6.72,3.014-6.72,6.72s3.014,6.72,6.72,6.72C362.805,1180.818,365.818,1177.805,365.818,1174.099z M362.5,1173H360v-2.5
			c0-0.275-0.225-0.5-0.5-0.5h-1c-0.275,0-0.5,0.225-0.5,0.5v2.5h-2.5c-0.275,0-0.5,0.225-0.5,0.5v1c0,0.275,0.225,0.5,0.5,0.5h2.5
			v2.5c0,0.275,0.225,0.5,0.5,0.5h1c0.275,0,0.5-0.225,0.5-0.5v-2.5h2.5c0.275,0,0.5-0.225,0.5-0.5v-1
			C363,1173.225,362.775,1173,362.5,1173z"></path>
                </g>
                </g>	
                </svg>`);
SvgIconList.set('filter',
    `<svg class="svgIm filterIcon whiteColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49px" height="1px" viewBox="0 0 49 1" xml:space="preserve">
                            <g transform="scale(1.9)">
                                <g transform="translate(-433,-178.5)" xmlns="http://www.w3.org/2000/svg" id="filter">
                                    <path d="M443,181v5l2-2v-3l3.5-5h-9L443,181z"></path>
                                    <path d="M439.5,174c-0.275,0-0.5,0.225-0.5,0.5l0,0c0,0.275,0.225,0.5,0.5,0.5h9c0.275,0,0.5-0.225,0.5-0.5l0,0
                c0-0.275-0.225-0.5-0.5-0.5H439.5z"></path>
                                </g>
                            </g>
                        </svg>`);
SvgIconList.set('pdf',
    `<svg class="SearchPdfIcon" width="18" height="20" viewBox="0 0 15 20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M0 18.965h14.604V0H0v18.965z"></path></defs>
                                        <g fill="none" fill-rule="evenodd"><g transform="translate(0 .345)"><mask id="b" class="whiteColor"><use xlink:href="#a"></use></mask><path d="M13.24 17.144a.44.44 0 0 1-.435.442H1.8a.44.44 0 0 1-.436-.442V1.821a.44.44 0 0 1 .436-.442h6.856l4.585 4.638v11.127zm1.015-12.052L9.57.353A1.18 1.18 0 0 0 8.726 0H1.8C.807 0 0 .817 0 1.82v15.324c0 1.004.807 1.822 1.8 1.822h11.005c.992 0 1.8-.818 1.8-1.822V5.946c0-.323-.125-.626-.35-.854z" class="deepRed" mask="url(#b)"></path></g>
                                        <path d="M6.623 6.551c-.282.306-.325 1.308-.077 1.792.092-.617.278-1.179.077-1.792m3.229 6.013c.564.342 1.29.499 1.765.392-.54-.477-1.16-.409-1.765-.392M3.978 14.17c-.593.327-1.012.82-1.312 1.425.704-.218 1.014-.816 1.312-1.425m2.62-3.754l-1.259 2.752 2.632-.753c-.234-.303-.499-.614-.729-.95-.236-.343-.438-.71-.644-1.049m.618-1.294c-.029.168-.034.37.03.522.337.818.765 1.575 1.492 2.125.307.233.618.28.981.23.625-.086 1.25-.076 1.852.14.62.222.768.758.352
                                         1.264-.226.274-.562.274-.865.267a5.72 5.72 0 0 1-2.203-.488.69.69 0 0 0-.354-.06c-1.012.135-2.033.236-2.993.617a.78.78 0 0 0-.31.256c-.455.61-.883 1.241-1.356 1.835-.159.2-.408.343-.642.458-.356.175-.821.071-1.032-.235-.197-.285-.135-.595.036-.89.352-.606.857-1.05 1.464-1.363.476-.245.79-.594 1.044-1.069a16.43 16.43 0 0 0 1.185-2.787c.08-.25.098-.47.008-.718a3.6 3.6 0 0 1-.04-2.391c.188-.585.65-.785 1.182-.5.301.162.373.473.412.776.083.643-.153 1.486-.243 2.01" class="deepRed"></path></g>
                                        </svg>`);
SvgIconList.set('excel',
    `<svg class="SearchExcelIcon" width="18" height="20" viewBox="0 0 15 20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M0 18.965h14.604V0H0v18.965z"></path></defs>
                                    <g fill="none" fill-rule="evenodd"><g transform="translate(0 .345)">
                                        <mask id="b" class="whiteColor"><use xlink:href="#a"></use></mask>
                                        <path d="M13.24 17.144a.44.44 0 0 1-.435.442H1.8a.44.44 0 0 1-.436-.442V1.821a.44.44 0 0 1 .436-.442h6.856l4.585 4.638v11.127zm1.015-12.052L9.57.353A1.18 1.18 0 0 0 8.726 0H1.8C.807 0 0 .817 0 1.82v15.324c0 1.004.807 1.822 1.8 1.822h11.005c.992 0 1.8-.818 1.8-1.822V5.946c0-.323-.125-.626-.35-.854z" class="deepgreen" mask="url(#b)"></path></g><path class="deepgreen" d="M5.197 15.032H3.409l2.367-3.793-2.252-3.653h1.838l1.312 2.425 1.35-2.425H9.8L7.55 11.178l2.392 3.854H8.073l-1.399-2.539z"></path>
                                    </g>
                                    </svg>`);
SvgIconList.set('word',
    `<svg class="SearchWordIcon" width="18" height="20" viewBox="0 0 15 20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs>
                                            <path id="a" d="M0 18.965h14.604V0H0v18.965z"></path></defs><g fill="none" fill-rule="evenodd"><g transform="translate(0 .345)"><mask id="b" class="whiteColor"><use xlink:href="#a"></use></mask><path d="M13.24 17.144a.44.44 0 0 1-.435.442H1.8a.44.44 0 0 1-.436-.442V1.821a.44.44 
                                            0 0 1 .436-.442h6.856l4.585 4.638v11.127zm1.015-12.052L9.57.353A1.18 1.18 0 0 0 8.726 0H1.8C.807 0 0 .817 0 1.82v15.324c0 1.004.807 1.822 1.8 1.822h11.005c.992 0 1.8-.818 1.8-1.822V5.946c0-.323-.125-.626-.35-.854z" class="lightblue" mask="url(#b)"></path></g><path class="lightblue" d="M3.678 7.586l.972 4.264.212 1.187.212-1.163.832-4.288h1.623l.874 4.264.224 1.187.228-1.142.98-4.309h1.567l-2.071
                                             7.446H7.858l-.888-4.355-.26-1.44-.26 1.44-.89 4.355H4.134L2.045 7.586z"></path></g></svg>`);
SvgIconList.set('ppt',
    `<svg class="SearchPowerPointIcon" width="18" height="20" viewBox="0 0 22 28" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M0 27.5h21.42V0H0v27.5z"></path></defs><g fill="none" fill-rule="evenodd"><g transform="translate(0 .5)"><mask id="b" class="whiteColor"><use xlink:href="#a"></use></mask><path d="M19.42 24.859a.641.641 0 0 1-.64.641H2.64a.641.641 0 0
                                         1-.64-.641V2.64A.64.64 0 0 1 2.64 2h10.055l6.725 6.725v16.134zm1.487-17.475L14.036.512A1.74 1.74 0 0 0 12.798 0H2.64A2.643 2.643 0 0 0 0 2.64v22.219A2.643 2.643 0 0 0 2.64 27.5h16.14a2.644 2.644 0 0 0 2.64-2.641V8.621c0-.467-.182-.907-.513-1.237z" class="lightorange" mask=""></path></g><path d="M11.475 13.234c-.286-.24-.686-.359-1.2-.359H8.241v3.179h2.034c.514 0 .914-.13 1.2-.388.286-.259.428-.669.428-1.231s-.142-.961-.428-1.201m1.722
                                          3.889c-.632.528-1.535.791-2.708.791H8.241v3.882H6V11h4.636c1.068 0 1.92.278 2.556.835.635.557.953
                                         1.419.953 2.586 0 1.274-.317 2.175-.948 2.702" class="lightorange"></path></g></svg>`);
SvgIconList.set('video',
    `<svg class="svgIm oceanBlue"  width="22"  height="24" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="-268 386 23 23" xml:space="preserve">
                                                    <g id="facetime-video_2_">
                                                    <path d="M-245,391v12c0,0.3-0.2,0.4-0.4,0.2l-6.6-5.3v5.6c0,0.8-0.7,1.5-1.5,1.5h-14c-0.8,0-1.5-0.7-1.5-1.5v-13
                                                    c0-0.8,0.7-1.5,1.5-1.5h14c0.8,0,1.5,0.7,1.5,1.5v5.6l6.6-5.3C-245.2,390.6-245,390.7-245,391z"/>
                                                    </g>
                                                    </svg>`);
SvgIconList.set('web',
    `<svg class="svgIm carouselSvgTopLeft oceanBlue worldSvg" x="0px" y="5px" width="25" height="25" version="1.1"
        id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-269 384 25 25"
        xml:space="preserve">
        <g id="global_2_">
            <path d="M-257,385c-6.6,0-12,5.4-12,12s5.4,12,12,12c6.6,0,12-5.4,12-12S-250.4,385-257,385z M-260.9,388.1
                                                    c-0.7,0.7-1.4,1.7-1.9,2.9h-1.8C-263.6,389.8-262.4,388.8-260.9,388.1z M-265.8,393h2.3c-0.3,0.9-0.4,2-0.5,3.1h-2.7
                                                    C-266.5,395-266.2,393.9-265.8,393z M-265.8,401.1c-0.4-1-0.7-2-0.9-3.1h2.7c0.1,1.1,0.2,2.2,0.5,3.1H-265.8z M-264.6,403h1.8
                                                    c0.5,1.2,1.2,2.1,1.9,2.9C-262.4,405.2-263.6,404.2-264.6,403z M-258,406.1c-0.5-0.3-1.8-1.3-2.8-3.1h2.8V406.1z M-258,401.1h-3.6
                                                    c-0.3-0.9-0.5-1.9-0.6-3.1h4.2V401.1z M-258,396.1h-4.2c0.1-1.2,0.3-2.2,0.6-3.1h3.6V396.1z M-258,391.1h-2.8
                                                    c1-1.8,2.3-2.8,2.8-3.1V391.1z M-248.2,393c0.4,1,0.7,2,0.8,3.1h-2.7c-0.1-1.1-0.2-2.2-0.5-3.1H-248.2z M-249.3,391.1h-1.9
                                                    c-0.6-1.3-1.3-2.2-2-3C-251.6,388.7-250.3,389.8-249.3,391.1z M-256,388c0.5,0.3,1.8,1.3,2.8,3.1h-2.8V388z M-256,393h3.6
                                                    c0.3,0.9,0.5,1.9,0.6,3.1h-4.2V393z M-256,398h4.2c-0.1,1.2-0.3,2.2-0.6,3.1h-3.6V398z M-256,406.1V403h2.8
                                                    C-254.2,404.8-255.5,405.7-256,406.1z M-253.1,405.9c0.7-0.7,1.3-1.7,1.9-2.9h1.8C-250.3,404.2-251.6,405.2-253.1,405.9z
                                                    M-248.2,401.1h-2.3c0.3-0.9,0.4-2,0.5-3.1h2.7C-247.4,399.1-247.7,400.1-248.2,401.1z"
            />
        </g>
    </svg>`);

SvgIconList.set('External URLs',
    `<svg class="svgIm carouselSvgTopLeft oceanBlue worldSvg" x="0px" y="5px" width="25" height="25" version="1.1"
        id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-269 384 25 25"
        xml:space="preserve">
        <g id="global_2_">
            <path d="M-257,385c-6.6,0-12,5.4-12,12s5.4,12,12,12c6.6,0,12-5.4,12-12S-250.4,385-257,385z M-260.9,388.1
                                                    c-0.7,0.7-1.4,1.7-1.9,2.9h-1.8C-263.6,389.8-262.4,388.8-260.9,388.1z M-265.8,393h2.3c-0.3,0.9-0.4,2-0.5,3.1h-2.7
                                                    C-266.5,395-266.2,393.9-265.8,393z M-265.8,401.1c-0.4-1-0.7-2-0.9-3.1h2.7c0.1,1.1,0.2,2.2,0.5,3.1H-265.8z M-264.6,403h1.8
                                                    c0.5,1.2,1.2,2.1,1.9,2.9C-262.4,405.2-263.6,404.2-264.6,403z M-258,406.1c-0.5-0.3-1.8-1.3-2.8-3.1h2.8V406.1z M-258,401.1h-3.6
                                                    c-0.3-0.9-0.5-1.9-0.6-3.1h4.2V401.1z M-258,396.1h-4.2c0.1-1.2,0.3-2.2,0.6-3.1h3.6V396.1z M-258,391.1h-2.8
                                                    c1-1.8,2.3-2.8,2.8-3.1V391.1z M-248.2,393c0.4,1,0.7,2,0.8,3.1h-2.7c-0.1-1.1-0.2-2.2-0.5-3.1H-248.2z M-249.3,391.1h-1.9
                                                    c-0.6-1.3-1.3-2.2-2-3C-251.6,388.7-250.3,389.8-249.3,391.1z M-256,388c0.5,0.3,1.8,1.3,2.8,3.1h-2.8V388z M-256,393h3.6
                                                    c0.3,0.9,0.5,1.9,0.6,3.1h-4.2V393z M-256,398h4.2c-0.1,1.2-0.3,2.2-0.6,3.1h-3.6V398z M-256,406.1V403h2.8
                                                    C-254.2,404.8-255.5,405.7-256,406.1z M-253.1,405.9c0.7-0.7,1.3-1.7,1.9-2.9h1.8C-250.3,404.2-251.6,405.2-253.1,405.9z
                                                    M-248.2,401.1h-2.3c0.3-0.9,0.4-2,0.5-3.1h2.7C-247.4,399.1-247.7,400.1-248.2,401.1z"
            />
        </g>
    </svg>`);


SvgIconList.set('defaultDoc',
    `<svg class="svgIm oceanBlue listDocIcon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-267 386 18 21" width="24" height="30" xml:space="preserve">
        <g>
            <rect x="-262.7" y="392.5" width="1.3" height="1.3" />
            <rect x="-260" y="400.5" width="6.7" height="1.3" />
            <rect x="-260" y="397.8" width="6.7" height="1.3" />
            <rect x="-262.7" y="395.2" width="1.3" height="1.3" />
            <polygon points="-253.3,395.5 -253.3,395.2 -253.9,395.2 -260,395.2 -260,396.5 -253.3,396.5 	" />
            <path d="M-260,392.5v1.3h4.6c-0.3-0.4-0.6-0.9-0.8-1.3H-260z" />
            <rect x="-262.7" y="397.8" width="1.3" height="1.3" />
            <rect x="-262.7" y="400.5" width="1.3" height="1.3" />
            <path d="M-252,395.9v7.3h-12v-12h7.5c0-0.2,0-0.4,0-0.7c0-0.7,0.1-1.4,0.4-2h-8.5c-0.4,0-0.7,0.3-0.7,0.7v14.7
                                        c0,0.4,0.3,0.7,0.7,0.7h13.3c0.4,0,0.7-0.3,0.7-0.7V396c-0.1,0-0.2,0-0.3,0C-251.3,396-251.7,396-252,395.9z"
            />
            <path d="M-251,386.5c-1.5,0-2.7,0.8-3.4,2c-0.3,0.6-0.6,1.3-0.6,2c0,0.2,0,0.4,0.1,0.7c0.1,0.5,0.3,0.9,0.5,1.3
                                        c0.3,0.5,0.7,0.9,1.1,1.2c0.4,0.3,0.8,0.5,1.3,0.6c0.3,0.1,0.7,0.1,1,0.1c0.1,0,0.2,0,0.3,0c2-0.2,3.7-1.9,3.7-4
                                        C-247,388.3-248.8,386.5-251,386.5z M-248.5,391.1c0,0.1-0.1,0.1-0.1,0.1h-1.9v1.9c0,0.1-0.1,0.1-0.1,0.1h0h-0.7
                                        c-0.1,0-0.1-0.1-0.1-0.1v-1.9h-0.5h-1.4c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1v-0.7c0-0.1,0.1-0.1,0.1-0.1h1.9v-1.8v-0.1
                                        c0-0.1,0.1-0.1,0.1-0.1h0.7c0.1,0,0.1,0.1,0.1,0.1v1.9h1.9c0.1,0,0.1,0.1,0.1,0.1V391.1z"
            />
        </g>
    </svg>`);
SvgIconList.set('html',
    `<svg class="SearchFileIcon" width="17" height="19" viewBox="0 0 17 29" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M0 27.5h21.42V0H0v27.5z"/><path id="c" d="M0 28.5h21.42V1H0z"/></defs><g fill="none" fill-rule="evenodd">
                                      <g transform="translate(0 .5)">
                                       <mask class="whiteColor" id="b" ><use xlink:href="#a"/></mask>
                                       <path d="M19.42 24.859a.641.641 0 0 1-.64.641H2.64a.641.641 0 0 1-.64-.641V2.64A.64.64 0 0 1 2.64 2h10.055l6.725 6.725v16.134zm1.487-17.475L14.036.512A1.74 1.74 0 0 0 12.798 0H2.64A2.643 2.643 0 0 0 0 2.64v22.219A2.643 2.643 0 0 0 2.64 27.5h16.14a2.644 2.644 0 0 0 2.64-2.641V8.621c0-.467-.182-.907-.513-1.237z" class="oceanBlue" mask=""/></g>
                                       <mask class="whiteColor" id="d" ><use xlink:href="#c"/></mask><path class="oceanBlue" mask="url(#d)" d="M4 14.063h11.75V12H4zM4 18.063h11.75V16H4zM4 22.063h11.75V20H4z"/></g>
                                    </svg>`);


SvgIconList.set("addSpacesIcon", `<svg class="svgIm greyColor9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              width="75%" height="1px" viewBox="0 0 148 1" xml:space="preserve">
              <g transform="scale(3.5)">
                <g transform="translate(-155,-2136.5)" xmlns="http://www.w3.org/2000/svg" id="plus_4_">
                  <path id="plus_5_" d="M178,2135.499v2.961c0,0.275-0.246,0.54-0.521,0.54H170v7.459c0,0.275-0.24,0.541-0.516,0.541h-2.961
                                                c-0.275,0-0.523-0.266-0.523-0.541V2139h-7.5c-0.275,0-0.5-0.265-0.5-0.54v-2.961c0-0.275,0.225-0.499,0.5-0.499h7.5v-7.5
                                                c0-0.275,0.248-0.5,0.523-0.5h2.961c0.275,0,0.516,0.225,0.516,0.5v7.5h7.479C177.754,2135,178,2135.224,178,2135.499z"></path>
                </g>
              </g>
            </svg>`)

SvgIconList.set("spaceElipses", `<svg class="svgIm OptionHorizontal  greyColor3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          width="18px" height="1px" viewBox="0 0 18 1" xml:space="preserve">
          <g transform="scale(1.5)">
            <g transform="translate(-343,-298)" xmlns="http://www.w3.org/2000/svg" id="option-horizontal">
              <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M344.5,298c0.828,0,1.5,0.672,1.5,1.5s-0.672,1.5-1.5,1.5
                                                  s-1.5-0.672-1.5-1.5S343.672,298,344.5,298z M347,299.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5
                                                  S347,298.672,347,299.5z M351,299.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5S351,298.672,351,299.5z"></path>
            </g>
          </g>
        </svg>`)


SvgIconList.set("spaceIcon", `<svg class="svgIm whiteColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      width="28px" height="1px" viewBox="0 0 28 1" xml:space="preserve">
      <g transform="scale(2.3)">
        <g transform="translate(-485,-371.9)" xmlns="http://www.w3.org/2000/svg" id="cube-black">
          <path d="M494,370.5v7c0,0.276-0.224,0.5-0.5,0.5h-7c-0.276,0-0.5-0.224-0.5-0.5v-7c0-0.276,0.224-0.5,0.5-0.5h7
                                            C493.776,370,494,370.224,494,370.5z M496.25,366h-6.344c-0.276,0-0.668,0.148-0.875,0.33l-2.656,2.339
                                            c-0.207,0.183-0.151,0.331,0.125,0.331h7.25c0.276,0,0.643-0.172,0.82-0.384l1.86-2.232C496.607,366.172,496.526,366,496.25,366z
                                            M497.675,366.88l-2.349,2.741c-0.18,0.21-0.325,0.604-0.325,0.88v7c0,0.276,0.146,0.33,0.325,0.12l0.837-0.977l1.512-1.764
                                            c0.18-0.21,0.325-0.604,0.325-0.88v-7C498,366.724,497.854,366.67,497.675,366.88z"
          />
        </g>
      </g>
    </svg>`)

SvgIconList.set("tileType2Ellipsis", `<svg class="svgIm " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      width="23px" height="1px" viewBox="0 0 30 1" xml:space="preserve">
      <g transform="scale(2.6)">
        <g transform="translate(-343,-296.5)" xmlns="http://www.w3.org/2000/svg" id="option-horizontal">
          <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M344.5,298c0.828,0,1.5,0.672,1.5,1.5s-0.672,1.5-1.5,1.5
                                                            s-1.5-0.672-1.5-1.5S343.672,298,344.5,298z M347,299.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5
                                                        S347,298.672,347,299.5z M351,299.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5S351,298.672,351,299.5z"
          />
        </g>
      </g>
    </svg>`)

SvgIconList.set("documentIcon", `<svg class="svgIm whiteColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        width="22px" height="1px" viewBox="0 0 28 1" xml:space="preserve">
        <g transform="scale(1.7)">
          <g transform="translate(-56,-61.5)" xmlns="http://www.w3.org/2000/svg" id="file">
            <path id="path_28_" d="M61,55v2.5c0,0.275,0.225,0.5,0.5,0.5H64L61,55z" />
            <path id="path_27_" d="M61,59c-0.55,0-1-0.45-1-1v-4h-4.5c-0.275,0-0.5,0.225-0.5,0.5v11c0,0.275,0.225,0.5,0.5,0.5h8
                          c0.275,0,0.5-0.225,0.5-0.5V59H61z" />
          </g>
        </g>
      </svg>`)


SvgIconList.set('EditSpaceTitle', `<svg class="svgIm  greyColor1 " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
              height="1px" viewBox="0 0 38 1"  xml:space="preserve">
          <g transform="scale(1.7)">
              <g transform="translate(-20,-107.9)" xmlns="http://www.w3.org/2000/svg" id="pencil">
                  <path d="M30.94,110.814l2.229,2.229l-3.327,1.114L30.94,110.814z M31.843,110l6.142-6.142L40.126,106l-6.142,6.142L31.843,110z
                      M38.844,103.001l0.941-0.966c0.087-0.087,0.203-0.135,0.327-0.135c0.123,0,0.239,0.048,0.326,0.134l1.53,1.53
                      c0.179,0.18,0.179,0.473,0,0.651l-0.986,0.923L38.844,103.001z"/>  
              </g>
          </g>	
      </svg> `)

SvgIconList.set("deleteSpace", `<svg class="svgIm  greyColor1 " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
                                            height="1px" viewBox="0 0 38 1"  xml:space="preserve">
                                            <g transform="scale(1.7)">
                                                <g transform="translate(-333,-35.9)" xmlns="http://www.w3.org/2000/svg" id="remove">
                                                    <path id="path_15_" d="M352.825,39.036l-1.787,1.788c-0.098,0.099-0.26,0.099-0.358,0L348,38.146l-2.679,2.679
                                                        c-0.098,0.098-0.26,0.098-0.358,0l-1.788-1.788c-0.098-0.099-0.098-0.26,0-0.358l2.679-2.679l-2.679-2.679
                                                        c-0.098-0.098-0.098-0.26,0-0.358l1.788-1.787c0.099-0.098,0.26-0.098,0.358,0L348,33.853l2.679-2.679
                                                        c0.098-0.098,0.26-0.098,0.358,0l1.788,1.788c0.098,0.098,0.098,0.26,0,0.358l-2.678,2.678l2.679,2.679
                                                        C352.923,38.776,352.923,38.937,352.825,39.036z"/> 
                                                </g>
                                            </g>	
                                        </svg> `)

SvgIconList.set("moveToFolder_space", `<svg class="svgIm " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
                                                                    height="1px" viewBox="0 0 38 1"  xml:space="preserve">
                                                                <g transform="scale(1.6)">
                                                                   <g transform="translate(-478,-371.9)" xmlns="http://www.w3.org/2000/svg" id="cube-black">
                                                                        <path d="M494,370.5v7c0,0.276-0.224,0.5-0.5,0.5h-7c-0.276,0-0.5-0.224-0.5-0.5v-7c0-0.276,0.224-0.5,0.5-0.5h7
                                                                            C493.776,370,494,370.224,494,370.5z M496.25,366h-6.344c-0.276,0-0.668,0.148-0.875,0.33l-2.656,2.339
                                                                            c-0.207,0.183-0.151,0.331,0.125,0.331h7.25c0.276,0,0.643-0.172,0.82-0.384l1.86-2.232C496.607,366.172,496.526,366,496.25,366z
                                                                            M497.675,366.88l-2.349,2.741c-0.18,0.21-0.325,0.604-0.325,0.88v7c0,0.276,0.146,0.33,0.325,0.12l0.837-0.977l1.512-1.764
                                                                            c0.18-0.21,0.325-0.604,0.325-0.88v-7C498,366.724,497.854,366.67,497.675,366.88z" />
                                                                    </g>
                                                                </g>	
                                                            </svg> `)

SvgIconList.set("shareDoc_spcae", `<svg class="svgIm  greyColor1 " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
                    height="1px" viewBox="0 0 38 1"  xml:space="preserve">
                <g transform="scale(1.1)">
                    <g transform="translate(-148,-1129.5)" xmlns="http://www.w3.org/2000/svg" id="share_2_">
                        <path d="M179.098,1128.293l-10.678,7.91c-0.222,0.164-0.402,0.072-0.402-0.203l-0.006-5.002 c-3.017-0.023-9.574-0.105-11.512,4.505c0.139-9.503,9.264-10.8,11.505-10.976l-0.005-4.514c0-0.275,0.182-0.367,0.402-0.203			l10.695,7.889C179.318,1127.862,179.319,1128.129,179.098,1128.293z"/>
                    </g>
                </g>	
            </svg> `);
SvgIconList.set("book",
    ` <svg class="svgIm bookIcon greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22px"
                            height="1px" viewBox="0 0 22 1"  xml:space="preserve">
                            <g transform="scale(0.72)">
                                <g transform="translate(-109,-410.6)" xmlns="http://www.w3.org/2000/svg" id="book_1_">
                                <path d="M127,396h-14.5l-3.5,3.75V420h15c1.65,0,3-1.35,3-3v-14c0-1.65-1.35-3-3-3h-12.266l2-2c0,0,11.266,0,12.266,0c2,0,3,1,3,3
                                    c0,1,0,16,0,16l2-2v-15C131,398,129,396,127,396z" />
                                </g>
                            </g>	
                </svg>`);
SvgIconList.set("flag",
    `<svg class="svgIm flagIcon greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px"
                        height="1px" viewBox="0 0 22 1"  xml:space="preserve">
                        <g transform="scale(0.73)">
                            <g transform="translate(-350,-1320.6)" xmlns="http://www.w3.org/2000/svg" id="flag_1_">
                            <path d="M353,1308.75c0,0.086,0,22.25,0,22.25h-0.03c0.02,0,0.03-0.086,0.03,0c0,0.69-0.672,1.125-1.5,1.125s-1.5-0.372-1.5-1.062
                                c0-0.086,0.011-0.062,0.03-0.062H350c0,0,0-22.164,0-22.25c0-0.69,0.672-1.25,1.5-1.25S353,1308.06,353,1308.75z
                                M354.871,1308.988c-0.501-0.233-0.871-0.04-0.871,0.512v11c0,0.552,0.37,1.255,0.871,1.488c0.943,0.438,2.653,1.012,5.129,1.012
                                c3.759,0,5.31-3.091,10.047-1.804c0.533,0.145,0.953-0.144,0.953-0.696c0-2.456,0-8.536,0-11c0-0.552-0.42-1.159-0.953-1.304
                                c-4.737-1.287-6.288,1.804-10.047,1.804C357.524,1310,355.815,1309.425,354.871,1308.988z" />
                            </g>
                        </g>	
            </svg>`);
SvgIconList.set("tag",
    `<svg class="svgIm tag greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22px"
                height="1px" viewBox="0 0 22 1"  xml:space="preserve">
                <g transform="scale(0.7)">
                    <g transform="translate(-349,-358.6)" xmlns="http://www.w3.org/2000/svg" id="tags_2_">
                    <path d="M374.289,362.803l-8.878,8.878c-0.193,0.193-0.518,0.111-0.713-0.082l-1.595-1.604l7.19-7.191
                        c0.528-0.527,0.487-0.928,0-1.414L356.894,348h3.005c0.275,0,0.905,0.08,1.065,0.177c0.158,0.097,0.448,0.336,0.643,0.53
                        l12.684,12.682C374.648,361.748,374.71,362.382,374.289,362.803z"/>
                    <path d="M355.252,348.353c-0.194-0.194-0.578-0.353-0.854-0.353H345.5c-0.275,0-0.5,0.225-0.5,0.5v8.899
                        c0,0.274,0.159,0.659,0.353,0.853l13.389,13.39c0.195,0.193,0.514,0.193,0.707,0l9.193-9.193c0.193-0.193,0.193-0.512,0-0.707
                        L355.252,348.353z M351.414,354.414c-0.781,0.781-2.047,0.781-2.828,0c-0.781-0.781-0.781-2.047,0-2.828
                        c0.781-0.781,2.047-0.781,2.828,0C352.195,352.367,352.195,353.633,351.414,354.414z"/>
                    </g>
                </g>	
            </svg>`);
SvgIconList.set("filterIcon_Big",
    `  <svg class="svgIm filterIcon ModalcloseIcon greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="38px"
                                 height="1px" viewBox="0 0 38 1"  xml:space="preserve">
                                <g transform="scale(2.3)">
                                    <g transform="translate(-433,-178.5)" xmlns="http://www.w3.org/2000/svg" id="filter">
                                        <path d="M443,181v5l2-2v-3l3.5-5h-9L443,181z" />
                                        <path d="M439.5,174c-0.275,0-0.5,0.225-0.5,0.5l0,0c0,0.275,0.225,0.5,0.5,0.5h9c0.275,0,0.5-0.225,0.5-0.5l0,0
                                                        c0-0.275-0.225-0.5-0.5-0.5H439.5z" />
                                    </g>
                                </g>	
                            </svg>`);

SvgIconList.set("moveFolderModal_space", `<svg class="svgIm ceruleanColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" height="1px" viewBox="0 0 28 1" xml:space="preserve">
      <g transform="scale(1.9)">
        <g transform="translate(-482,-372.3)" xmlns="http://www.w3.org/2000/svg" id="cube-black">
          <path d="M494,370.5v7c0,0.276-0.224,0.5-0.5,0.5h-7c-0.276,0-0.5-0.224-0.5-0.5v-7c0-0.276,0.224-0.5,0.5-0.5h7
                                            C493.776,370,494,370.224,494,370.5z M496.25,366h-6.344c-0.276,0-0.668,0.148-0.875,0.33l-2.656,2.339
                                            c-0.207,0.183-0.151,0.331,0.125,0.331h7.25c0.276,0,0.643-0.172,0.82-0.384l1.86-2.232C496.607,366.172,496.526,366,496.25,366z
                                            M497.675,366.88l-2.349,2.741c-0.18,0.21-0.325,0.604-0.325,0.88v7c0,0.276,0.146,0.33,0.325,0.12l0.837-0.977l1.512-1.764
                                            c0.18-0.21,0.325-0.604,0.325-0.88v-7C498,366.724,497.854,366.67,497.675,366.88z"></path>
        </g>
      </g>
    </svg>`

)
SvgIconList.set('date',
    `<svg class="svgIm steelgray" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49px"
                                                height="1px" viewBox="0 0 49 1" xml:space="preserve">
                                                <g transform="scale(0.75)">
                                                    <g transform="translate(-280,-264.5)" xmlns="http://www.w3.org/2000/svg" id="calendar_2_">
                                                    <path d="M300,259v16c0,0.55,0.45,1,1,1h21c0.55,0,1-0.45,1-1v-16H300z M305,274h-3v-3h3V274z M305,270h-3v-3h3V270z M305,266h-3
			                                            v-3h3V266z M309,274h-3v-3h3V274z M309,270h-3v-3h3V270z M309,266h-3v-3h3V266z M313,274h-3v-3h3V274z M313,270h-3v-3h3V270z
			                                             M313,266h-3v-3h3V266z M317,274h-3v-3h3V274z M317,270h-3v-3h3V270z M317,266h-3v-3h3V266z M321,274h-3v-3h3V274z M321,270h-3v-3
			                                            h3V270z M321,266h-3v-3h3V266z M323,255v3h-23v-3c0-0.55,0.45-1,1-1h2v-1c0-0.55,0.45-1,1-1h1c0.55,0,1,0.45,1,1v1h11v-1
			                                            c0-0.55,0.45-1,1-1h1c0.55,0,1,0.45,1,1v1h2C322.55,254,323,254.45,323,255z" />
                                                    </g>
                                                </g>	
                </svg>`)

SvgIconList.set('zip',
    `<svg class="svgIm oceanBlue" width="26px" height="28px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="12 10 29 29" xml:space="preserve">
                                                        <g id="zip_1_">
                                                        <path id="zip" d="M24,26h1v5h-1V26z M22,27v1h1v-1l-1-1h-2v1H22z M21,28v1h1v-1H21z M21,31h2v-1h-2v-1h-1v1L21,31z M27.5,18H33v7
                                                        H19c-0.55,0-1,0.45-1,1v5c0,0.55,0.45,1,1,1h14v3.5c0,0.275-0.225,0.5-0.5,0.5h-18c-0.275,0-0.5-0.225-0.5-0.5v-23
                                                        c0-0.275,0.225-0.5,0.5-0.5H27v5.5C27,17.775,27.225,18,27.5,18z M25,23h-2l0,0h-1v1h2v-1H25L25,23z M25,21h-2l0,0h-1v1h2v-1H25
                                                        L25,21z M25,19h-2l0,0h-1v1h2v-1H25L25,19z M25,17h-2l0,0h-1v1h2v-1H25L25,17z M25,15h-2l0,0h-1v1h2v-1H25L25,15z M25,13h-2l0,0h-1
                                                        v1h2v-1H25L25,13z M28.5,17H33l-5-5v4.5C28,16.775,28.225,17,28.5,17z M26,26v5h1v-1h1l1-1v-2l-1-1H26z M27,29v-2h1v2H27z"></path>
                                                        </g>
                                                    </svg>`)

SvgIconList.set('XML',
    `<svg class="SearchFileIcon" width="17" height="19" viewBox="0 0 17 29" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M0 27.5h21.42V0H0v27.5z"/><path id="c" d="M0 28.5h21.42V1H0z"/></defs><g fill="none" fill-rule="evenodd">
                                      <g transform="translate(0 .5)">
                                       <mask class="whiteColor" id="b" ><use xlink:href="#a"/></mask>
                                       <path d="M19.42 24.859a.641.641 0 0 1-.64.641H2.64a.641.641 0 0 1-.64-.641V2.64A.64.64 0 0 1 2.64 2h10.055l6.725 6.725v16.134zm1.487-17.475L14.036.512A1.74 1.74 0 0 0 12.798 0H2.64A2.643 2.643 0 0 0 0 2.64v22.219A2.643 2.643 0 0 0 2.64 27.5h16.14a2.644 2.644 0 0 0 2.64-2.641V8.621c0-.467-.182-.907-.513-1.237z" class="oceanBlue" mask=""/></g>
                                       <mask class="whiteColor" id="d" ><use xlink:href="#c"/></mask><path class="oceanBlue" mask="url(#d)" d="M4 14.063h11.75V12H4zM4 18.063h11.75V16H4zM4 22.063h11.75V20H4z"/></g>
                                    </svg>`)
SvgIconList.set('msg',
    `<svg class="svgIm oceanBlue" width="25px" height="28px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="5 4 15 16" xml:space="preserve">
                                                        <g id="envelope_2_">
                                                        <path id="path_3_" d="M12.176,12.854c-0.097,0.097-0.256,0.097-0.353,0L6.176,7.177C6.079,7.08,6.113,7,6.25,7h11.5
                                                        c0.138,0,0.171,0.08,0.074,0.177L12.176,12.854z M12.177,14.823c-0.097,0.097-0.256,0.097-0.354,0l-1.646-1.646
                                                        c-0.097-0.097-0.256-0.097-0.354,0l-3.646,3.646C6.08,16.92,6.113,17,6.25,17h11.5c0.138,0,0.17-0.08,0.073-0.177l-3.646-3.646
                                                        c-0.097-0.097-0.256-0.097-0.354,0L12.177,14.823z M15.177,11.823c-0.097,0.097-0.097,0.256,0,0.354l2.646,2.646
                                                        C17.92,14.92,18,14.887,18,14.75v-5.5c0-0.137-0.08-0.17-0.177-0.073L15.177,11.823z M6.177,9.177C6.08,9.08,6,9.113,6,9.25v5.5
                                                        c0,0.137,0.08,0.17,0.177,0.073l2.646-2.646c0.097-0.097,0.097-0.256,0-0.354L6.177,9.177z"></path>
                                                        </g>
                                                    </svg>`)


SvgIconList.set("ModalcloseIcon", `<svg class="svgIm ModalcloseIcon CloseIcon whiteColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23px"
                            height="1px" viewBox="0 0 35 1" xml:space="preserve">
                    <g transform="scale(1.4
                    )">
                    <g transform="translate(-156,-986.5)" xmlns="http://www.w3.org/2000/svg" id="remove-sign_2_">
                    <path d="M176.662,975.338c-4.784-4.784-12.54-4.784-17.324,0c-4.784,4.784-4.784,12.54,0,17.324c4.784,4.784,12.54,4.784,17.324,0
                                C181.445,987.878,181.446,980.122,176.662,975.338z M171.535,989.656c-0.194,0.195-0.513,0.195-0.707,0L168,986.828l-2.828,2.828
                                c-0.194,0.195-0.513,0.195-0.707,0l-2.121-2.121c-0.195-0.193-0.195-0.512,0-0.707l2.828-2.828l-2.828-2.828
                                c-0.195-0.195-0.195-0.514,0-0.707l2.121-2.121c0.194-0.195,0.512-0.195,0.707,0l2.828,2.828l2.828-2.828
                                c0.195-0.195,0.513-0.195,0.707,0l2.121,2.121c0.195,0.193,0.195,0.512,0,0.707L170.828,984l2.828,2.828
                                c0.195,0.195,0.195,0.514,0,0.707L171.535,989.656z" />
                                </g>
                            </g>	
                        </svg>`)

SvgIconList.set("topic", `<svg class="svgIm darkishPurple" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22px" height="1px" viewBox="0 0 22 1" xml:space="preserve">
                  <g transform="scale(0.9)">
                        <g transform="translate(-205,-1176.6)" xmlns="http://www.w3.org/2000/svg" id="direction_1_">
                        <path d="M226.922,1164.535c0.121-0.246,0.019-0.35-0.229-0.227l-24.521,12.044c-0.247,0.121-0.225,0.221,0.051,0.221h11.924
                              l0.025,11.975c0,0.275,0.1,0.298,0.221,0.051L226.922,1164.535z M210.68,1174.572c-0.275,0-0.299-0.35-0.052-0.471l12.597-6.295
                              c0.246-0.121,0.304-0.07,0.109,0.125l-6.843,6.068c-0.194,0.194-0.579,0.572-0.854,0.572H210.68z"></path>
                        
                        </g>
                  </g>	
            </svg>`)

SvgIconList.set("feature", `<svg *ngIf="type.toLowerCase()=='feature'" class="svgIm targetIcon yellowOrange" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22px" height="1px" viewBox="0 0 22 1" xml:space="preserve">
                  <g transform="scale(1.0)">
                        <g transform="translate(-494,-265.6)" xmlns="http://www.w3.org/2000/svg" id="star_2_">
                        <path d="M516.906,261h-9.438l-2.922-9l-2.971,9H492l7.757,5.455l-2.913,8.862l7.648-5.564l7.679,5.508l-2.909-8.85L516.906,261z"></path>
                        </g>
                  </g>	
            </svg>`)

SvgIconList.set("file", `<svg class="SearchFileIcon" width="17" height="19" viewBox="0 0 17 29" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M0 27.5h21.42V0H0v27.5z"/><path id="c" d="M0 28.5h21.42V1H0z"/></defs><g fill="none" fill-rule="evenodd">
                                      <g transform="translate(0 .5)">
                                       <mask class="whiteColor" id="b" ><use xlink:href="#a"/></mask>
                                       <path d="M19.42 24.859a.641.641 0 0 1-.64.641H2.64a.641.641 0 0 1-.64-.641V2.64A.64.64 0 0 1 2.64 2h10.055l6.725 6.725v16.134zm1.487-17.475L14.036.512A1.74 1.74 0 0 0 12.798 0H2.64A2.643 2.643 0 0 0 0 2.64v22.219A2.643 2.643 0 0 0 2.64 27.5h16.14a2.644 2.644 0 0 0 2.64-2.641V8.621c0-.467-.182-.907-.513-1.237z" class="oceanBlue" "/></g>
                                       <mask class="whiteColor" id="d" ><use xlink:href="#c"/></mask><path class="oceanBlue" mask="url(#d)" d="M4 14.063h11.75V12H4zM4 18.063h11.75V16H4zM4 22.063h11.75V20H4z"/></g>
                                    </svg>`)
SvgIconList.set("Content Page", `<svg class="svgIm oceanBlue listDocIcon homelist" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-263 386 17 22" width="26" height="30" xml:space="preserve">
        <g>
            <rect x="-262.7" y="392.5" width="1.3" height="1.3" />
            <rect x="-260" y="400.5" width="6.7" height="1.3" />
            <rect x="-260" y="397.8" width="6.7" height="1.3" />
            <rect x="-262.7" y="395.2" width="1.3" height="1.3" />
            <polygon points="-253.3,395.5 -253.3,395.2 -253.9,395.2 -260,395.2 -260,396.5 -253.3,396.5 	" />
            <path d="M-260,392.5v1.3h4.6c-0.3-0.4-0.6-0.9-0.8-1.3H-260z" />
            <rect x="-262.7" y="397.8" width="1.3" height="1.3" />
            <rect x="-262.7" y="400.5" width="1.3" height="1.3" />
            <path d="M-252,395.9v7.3h-12v-12h7.5c0-0.2,0-0.4,0-0.7c0-0.7,0.1-1.4,0.4-2h-8.5c-0.4,0-0.7,0.3-0.7,0.7v14.7
                                        c0,0.4,0.3,0.7,0.7,0.7h13.3c0.4,0,0.7-0.3,0.7-0.7V396c-0.1,0-0.2,0-0.3,0C-251.3,396-251.7,396-252,395.9z"
            />
            <path d="M-251,386.5c-1.5,0-2.7,0.8-3.4,2c-0.3,0.6-0.6,1.3-0.6,2c0,0.2,0,0.4,0.1,0.7c0.1,0.5,0.3,0.9,0.5,1.3
                                        c0.3,0.5,0.7,0.9,1.1,1.2c0.4,0.3,0.8,0.5,1.3,0.6c0.3,0.1,0.7,0.1,1,0.1c0.1,0,0.2,0,0.3,0c2-0.2,3.7-1.9,3.7-4
                                        C-247,388.3-248.8,386.5-251,386.5z M-248.5,391.1c0,0.1-0.1,0.1-0.1,0.1h-1.9v1.9c0,0.1-0.1,0.1-0.1,0.1h0h-0.7
                                        c-0.1,0-0.1-0.1-0.1-0.1v-1.9h-0.5h-1.4c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1v-0.7c0-0.1,0.1-0.1,0.1-0.1h1.9v-1.8v-0.1
                                        c0-0.1,0.1-0.1,0.1-0.1h0.7c0.1,0,0.1,0.1,0.1,0.1v1.9h1.9c0.1,0,0.1,0.1,0.1,0.1V391.1z"
            />
        </g>
    </svg>`)



SvgIconList.set("query", `<svg class="svgIm oceanBlue listDocIcon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-266 387 20 20" width="27" height="30" xml:space="preserve">
        <g>
            <rect x="-262.7" y="392.5" width="1.3" height="1.3" />
            <rect x="-260" y="400.5" width="6.7" height="1.3" />
            <rect x="-260" y="397.8" width="6.7" height="1.3" />
            <rect x="-262.7" y="395.2" width="1.3" height="1.3" />
            <polygon points="-253.3,395.5 -253.3,395.2 -253.9,395.2 -260,395.2 -260,396.5 -253.3,396.5 	" />
            <path d="M-260,392.5v1.3h4.6c-0.3-0.4-0.6-0.9-0.8-1.3H-260z" />
            <rect x="-262.7" y="397.8" width="1.3" height="1.3" />
            <rect x="-262.7" y="400.5" width="1.3" height="1.3" />
            <path d="M-252,395.9v7.3h-12v-12h7.5c0-0.2,0-0.4,0-0.7c0-0.7,0.1-1.4,0.4-2h-8.5c-0.4,0-0.7,0.3-0.7,0.7v14.7
                                        c0,0.4,0.3,0.7,0.7,0.7h13.3c0.4,0,0.7-0.3,0.7-0.7V396c-0.1,0-0.2,0-0.3,0C-251.3,396-251.7,396-252,395.9z"
            />
            <path d="M-251,386.5c-1.5,0-2.7,0.8-3.4,2c-0.3,0.6-0.6,1.3-0.6,2c0,0.2,0,0.4,0.1,0.7c0.1,0.5,0.3,0.9,0.5,1.3
                                        c0.3,0.5,0.7,0.9,1.1,1.2c0.4,0.3,0.8,0.5,1.3,0.6c0.3,0.1,0.7,0.1,1,0.1c0.1,0,0.2,0,0.3,0c2-0.2,3.7-1.9,3.7-4
                                        C-247,388.3-248.8,386.5-251,386.5z M-248.5,391.1c0,0.1-0.1,0.1-0.1,0.1h-1.9v1.9c0,0.1-0.1,0.1-0.1,0.1h0h-0.7
                                        c-0.1,0-0.1-0.1-0.1-0.1v-1.9h-0.5h-1.4c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1v-0.7c0-0.1,0.1-0.1,0.1-0.1h1.9v-1.8v-0.1
                                        c0-0.1,0.1-0.1,0.1-0.1h0.7c0.1,0,0.1,0.1,0.1,0.1v1.9h1.9c0.1,0,0.1,0.1,0.1,0.1V391.1z"
            />
        </g>
    </svg>`)

SvgIconList.set("burger", `<svg class="svgIm oceanBlue burgerMenuWidth" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
         height="1px" viewBox="0 0 28 1"  xml:space="preserve">
    <g transform="scale(.7)">
    <g transform="translate(-390,-791)" xmlns="http://www.w3.org/2000/svg" id="show-thumbnails-with-lines_2_">
        <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M420,799.5c0,0.275-0.225,0.5-0.5,0.5h-18c-0.275,0-0.5-0.225-0.5-0.5v-2
			c0-0.275,0.225-0.5,0.5-0.5h18c0.275,0,0.5,0.225,0.5,0.5V799.5z M399,799.5c0,0.275-0.225,0.5-0.5,0.5h-2
			c-0.275,0-0.5-0.225-0.5-0.5v-2c0-0.275,0.225-0.5,0.5-0.5h2c0.275,0,0.5,0.225,0.5,0.5V799.5z M420,793.5
			c0,0.275-0.225,0.5-0.5,0.5h-18c-0.275,0-0.5-0.225-0.5-0.5v-2c0-0.275,0.225-0.5,0.5-0.5h18c0.275,0,0.5,0.225,0.5,0.5V793.5z
			 M399,793.5c0,0.275-0.225,0.5-0.5,0.5h-2c-0.275,0-0.5-0.225-0.5-0.5v-2c0-0.275,0.225-0.5,0.5-0.5h2c0.275,0,0.5,0.225,0.5,0.5
			V793.5z M420,787.5c0,0.275-0.225,0.5-0.5,0.5h-18c-0.275,0-0.5-0.225-0.5-0.5v-2c0-0.275,0.225-0.5,0.5-0.5h18
			c0.275,0,0.5,0.225,0.5,0.5V787.5z M399,787.5c0,0.275-0.225,0.5-0.5,0.5h-2c-0.275,0-0.5-0.225-0.5-0.5v-2
			c0-0.275,0.225-0.5,0.5-0.5h2c0.275,0,0.5,0.225,0.5,0.5V787.5z"></path>
	                </g>
	            </g>	
     </svg>`)                                                                              