<div [@displaynotification]="notificationData?.displayNotification"  *ngIf="!notificationData?.showProgress"
        class="alert fade in"
        [ngClass]="{'alert-danger':notificationData?.status==Status.Failure,
                     'alert-success':notificationData?.status==Status.Success,
                     'alert-warning':notificationData?.status==Status.Warning
                }"
        role="alert">
            <a class="close" (click)="hideNotification()" aria-label="close">
                <span>
                <alex-svg-icon [icon]="'ModalcloseIcon'"></alex-svg-icon>
                </span>
            </a>
            <strong class="alertText">{{notificationData?.message}}</strong>
    </div>
    
<!-- progress bar -->
    <div *ngIf="notificationData?.showProgress" 
        class="alert-progress"
        role="alert">
        <!-- <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated" 
        role="progressbar"  
        data-progress="{{progressVal}}%">{{progressVal}}%</div>
</div> -->

<progressbar 
      class="progress-striped" 
      [value]="progressVal" 
      type="info" [striped]="true" [animate]="true"
    >
      {{progressVal}}%
    </progressbar>
</div> 