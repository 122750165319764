import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from "@angular/core";
import { HelpService } from "app/shared/components/help/help.service";
import { SafeHtml, SafeResourceUrl } from "@angular/platform-browser";
import { AlexUserService, AlexNavigationService } from "app/core/services";
import { User } from "app/feature/header";
import { AlexPages } from "app/shared/components/help/help.enum";
import { Subscription, Observable } from "rxjs";

@Component({
  selector: "alex-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpComponent {
  breadCrumb = "";
  page = "";
  helpData: SafeHtml;
  url: SafeResourceUrl;
  user: User;
  fileExtensionMatchPattern = "^.*.(htm|html)$";
  @Input() modalId: string;
  @Input() parentPage: AlexPages;
  @Input() pageTitle: string;
  @Output() OnCancelClicked: EventEmitter<any>;
  userServiceSubscription: Subscription;

  helpContent$: Observable<SafeHtml>;
  pageTitle$: Observable<string>;

  constructor(
    private helpService: HelpService,
    private userService: AlexUserService,
    private navigationService: AlexNavigationService
  ) {
    this.OnCancelClicked = new EventEmitter<any>();
    this.helpContent$ = this.helpService.getHelpContent();
    this.pageTitle$ = this.helpService.getCurrentPageTitle();
  }

  routeHandler(event: Event) {
    this.navigationService.ignoreRouteEvent = true;
    const href = (<HTMLAnchorElement>event.target).href;
    const regex = new RegExp(this.fileExtensionMatchPattern);
    if (regex.test(href)) {
      // load data if it's a html file
      this.navigationService.cancelCurrentRoute();
      this.helpService.getHelpDataFromUrl(href);
    } else if (!this.isExternalUrl(href)) {
      const hash = (<HTMLAnchorElement>event.target).hash;
      if (hash) {
        this.navigationService.cancelCurrentRoute();
        event.preventDefault();
        window.location.hash = hash;
        return false;
      } else if (href.indexOf("userGuide") !== -1) {
        this.userService.showUserGuide$.next(true);
        event.preventDefault();
        return false;
      }
    }
  }

  private isExternalUrl(href: string): boolean {
    let isExternalUrl = true;
    const newHashUrlSplit = href.split("/");
    const currentHashSplit = window.location.href.split("/");
    if (newHashUrlSplit[2] == currentHashSplit[2]) isExternalUrl = false;

    return isExternalUrl;
  }

  resetUrl() {
    this.helpData = "";
    this.OnCancelClicked.emit();
  }
}
