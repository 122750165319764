export const AlexMimeTypeMap = new Map();

AlexMimeTypeMap.set('application/vnd.ms-excel','excel');
AlexMimeTypeMap.set('application/msword','word');
AlexMimeTypeMap.set('text/plain','word');
AlexMimeTypeMap.set('text/rtf','word');
AlexMimeTypeMap.set('application/zip','zip');
AlexMimeTypeMap.set('application/vnd.ms-powerpoint','ppt');
AlexMimeTypeMap.set('application/pdf','pdf');
AlexMimeTypeMap.set('text/vnd.wap.ml','pdf');
AlexMimeTypeMap.set('application/video','video');
AlexMimeTypeMap.set('video/mpeg','video');
AlexMimeTypeMap.set('audio/mpeg','video');
AlexMimeTypeMap.set('audio/x-ms-wmv','video');
AlexMimeTypeMap.set('application/x-shockwave-flash','video');
AlexMimeTypeMap.set('video','video');
AlexMimeTypeMap.set('video/mp4','video');
AlexMimeTypeMap.set('APPLICATION/XML','XML');
AlexMimeTypeMap.set('application/xml','XML');
AlexMimeTypeMap.set('text/html','XML');
AlexMimeTypeMap.set('application/top','top');
AlexMimeTypeMap.set('application/zip','zip');
AlexMimeTypeMap.set('APPLICATION/OUTLOOK','msg');
AlexMimeTypeMap.set('application/outlook','msg');
AlexMimeTypeMap.set('text/vnd.wap.wml','msg');
AlexMimeTypeMap.set('TEXT/VND.WAP.WML','message');
AlexMimeTypeMap.set('application/web','web');
AlexMimeTypeMap.set('web','web');

