import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable ,  of } from "rxjs";
import { CkdSearchCountryWorkflowSelectorService } from "../../feature/ckd-search/services/ckd-search-country-workflow-selector.service";
import { Injectable } from "@angular/core";

@Injectable()
export class CkdCountryAndWorkflowTypesResolver implements Resolve<any> {

    constructor(private countryAndWorkflowService: CkdSearchCountryWorkflowSelectorService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        if (this.countryAndWorkflowService.fetched) {
            return of(null);
        } else {
            return this.countryAndWorkflowService.getckdSearchcountry();
        }
    }

}