
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CarouselItem } from "app/shared/components/carousel/model/carousel-item.model";
import { AlexUrlResolverService } from "app/core/services";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()

export class AddToAlexService{

    constructor(private http: HttpClient, private urlResolver: AlexUrlResolverService) { }

    addToSpace(searchReqModel: CarouselItem) {
        // let headers = new Headers({ 'Content-Type': 'application/json' });
        // let options = new RequestOptions({ headers: headers });

        const header = new HttpHeaders({ 
            "Content-Type": "application/json",
        });
        return this.http.post(this.urlResolver.resolveUrlApiV1(`search/searchdata`), searchReqModel, 
        {headers:header}).pipe(
            map(response =>console.log(response))); //TODO: remove console log
    }
}