import { throwError, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { tap, filter, catchError } from "rxjs/operators";
import { AlexNotificationService } from "app/shared/components/notification/notification.service";
import { Status } from "../services/app/app.enums";

@Injectable()
export class NotificationHttpConfigInterceptor implements HttpInterceptor {
  private readonly NOCONTENTFOUND = "No Data Found";
  constructor(private notificationService: AlexNotificationService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const header = request.headers;
    const noNotification = header && <any>header.get("noNotification");
    const updatedHeader = noNotification
      ? header.delete("noNotification")
      : header;
    const clonedReqest = request.clone({ headers: updatedHeader });
    return next.handle(clonedReqest).pipe(
      filter((event) => event instanceof HttpResponse),
      tap((event: HttpResponse<any>) => {
        if (event.status == 204) {
          if (!~noNotification.indexOf(event.status)) {
            this.notificationService.showApiError(
              Status.Failure,
              "No content found"
            );
          }
        }
        if (event.status == 302) {
          if (!~noNotification.indexOf(event.status)) {
            this.notificationService.showApiError(
              Status.Failure,
              "Session expired"
            );
          }
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        //added below condition as layer 7 is not able to handle request status 204
        try {
          if (
            event.body &&
            event.body.Message &&
            typeof event.body.Message == "string"
          ) {
            if (event.body.Message == this.NOCONTENTFOUND) {
              const clonedResponse = event.clone({ status: 204 });
              this.handleError(clonedResponse, noNotification);
            }
          }
        } catch (error) {
          console.log("JSON parse error occurred");
        }
      }),
      catchError((error) => {
        this.handleError(error, noNotification);
        return throwError(error);
      })
    );
  }

  handleError(error: HttpResponse<any>, noNotification?: Array<Number>) {
    console.log("From notification-http interceptor");
    noNotification = Array.isArray(noNotification) ? noNotification : [];
    if (error.status == 404) {
      if (!~noNotification.indexOf(error.status)) {
        this.notificationService.showApiError(
          Status.Failure,
          "No document found"
        );
      }
      return throwError(error.statusText);
    } else if (error.status == 500) {
      if (!~noNotification.indexOf(error.status)) {
        this.notificationService.showApiError(
          Status.Failure,
          "Oops! something went wrong"
        );
      }
      return throwError(error);
    } else return throwError(error);
  }
}
