<ng-template #subcriptionModal>
<alex-modal class="subscribeModal" (OnOkClicked)="subscriptionSubmit()" [modalId]="'subcriptionModal'"
 [headerText]="'Subscription Notice'" [dissmissOnSubmit]="true" [disableSubmit]="false"
 (OnCancelClicked)="showUserOnboarding()">
        <div modalBodyContent>
                <div class="spaceModalContent" modalBodyContent>
                        <div *ngIf="licenseInfo.popupType==SubscriptionConstant.popupType.PAM_USER_TRIAL_POPUP">
                                <p class="modalHdrTxt pamTrial" >
                                        {{warningMessages?.TRIAL_USER_MSG}}<br/> {{message}}
                                </p>
                                <p>
                                        <button class="btn btn-default" data-dismiss="modal" (click)="subscribeNow()">Subscribe Now</button>
                                        <button class="btn btn-default alignRight" data-dismiss="modal" (click)="showUserOnboarding()">Continue Trial</button>
                                </p>
                        </div>
                        <div *ngIf="licenseInfo.popupType==SubscriptionConstant.popupType.PAM_USER_RENEW_POPUP">
                            
                                <p class="modalHdrTxt pamSubscribed">
                                        {{message}}
                                </p>
                                <p [ngClass]="{'defaultHeight text-center': hideRenewButton()}">
                                        <button *ngIf="!hideRenewButton()" 
                                                 class="btn btn-default" data-dismiss="modal" (click)="renewLicense()">Renew Now</button>
                                        <button class="btn btn-default " [ngClass]="{ 'alignRight':!hideRenewButton() }" data-dismiss="modal" (click)="showUserOnboarding()">Continue</button>
                                </p>
                        </div>
                        <div *ngIf="licenseInfo.popupType==SubscriptionConstant.popupType.USER_TRIAL_POPUP">
                                <p class="modalHdrTxt userTrial ">
                                        {{warningMessages?.TRIAL_USER_MSG}}<br/> {{message}}
                                </p>
                                <p class="text-center">
                                        <button class="btn btn-default" data-dismiss="modal" (click)="showUserOnboarding()">Ok</button>
                                </p>
                        </div>
                        <div *ngIf="licenseInfo.popupType==SubscriptionConstant.popupType.USER_RENEW_POPUP">
                                <p class="modalHdrTxt userSubscribed">
                                        {{message}}<br/> {{warningMessages?.RENEWAL_MSG}}
                                </p>
                                <p class="text-center">
                                        <button class="btn btn-default" data-dismiss="modal" (click)="showUserOnboarding()">Ok</button>
                                </p>
                        </div>
                </div>
        </div>
</alex-modal>
</ng-template>
