<!--<li class="historyMenuActive dropdown-toggle"  role="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown" (click)="showRecentTab()">
    <svg class="svgIm greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        width="18px" height="1px" viewBox="0 0 18 1" xml:space="preserve">
        <g transform="scale(0.87)">
            <g transform="translate(-398,-314.5)" xmlns="http://www.w3.org/2000/svg" id="history_1_">
                <path d="M413.205,308.258l-3.268,3.27c0.037,0.153,0.062,0.314,0.062,0.479c0,1.105-0.895,2.006-2,2.006
			c-0.748,0-1.393-0.512-1.736-1.012H403.5c-0.275,0-0.5-0.249-0.5-0.523v-0.953c0-0.274,0.225-0.523,0.5-0.523h2.764
			c0.344-0.5,0.988-1.012,1.736-1.012c0.197,0,0.385,0.049,0.565,0.102l3.238-3.237c0.194-0.194,0.513-0.194,0.707,0l0.694,0.696
			C413.4,307.744,413.4,308.062,413.205,308.258z M419.791,314c0.115-1,0.197-1.334,0.197-2.018c0-6.618-5.383-12.01-12-12.01
			c-6.617,0-11.994,5.377-11.994,11.996c0,6.615,5.006,11.994,12.006,11.994v-0.002c2-0.006,4.509-0.643,6.527-1.961l0.057-0.039
			l1.476,2.046c0.162,0.224,0.361,0.189,0.442-0.073l2.205-6.439c0.082-0.263-0.077-0.48-0.353-0.486l-6.835,0.002
			c-0.276-0.006-0.37,0.174-0.21,0.396l1.515,2.12c-1.495,0.961-2.825,1.424-4.825,1.431v0.008c-5-0.001-9.008-4.03-9.006-9.001
			c0-4.971,4.027-9,8.998-9c4.97,0,8.998,4.048,8.998,9.02c0,0.688-0.092,1.018-0.24,2.018H419.791z" />
            </g>
        </g>
    </svg>
    <a href="#" title="My recent documents and searches">History <span class="caret"></span></a>-->
    <ul class="dropdown-menu headermenu historyMenuMob" role="menu" [ngClass]="{'displayRecentList':recentShow}">
        <li (click)="getRecentDocument()"><a>Recent Documents</a></li>
        <li (click)="getRecentSearch()"><a>Recent Searches</a></li>
    </ul>


    <div *ngIf="recentDocuments && recentDoc" class="historyTooltip recentDoc">
        <div class="historyTooltipText">
            <!-- <div class="scrollSection">-->
            <ul class="historyScroll">

                <li class="hisDocsEllipsis" *ngFor="let recent of recentDocuments" (click)="recentSearchClick(recent.reference)">
                    <span title="{{recent.documentName}}">{{recent.documentName}}</span>
                </li>
            </ul>

        </div>
    </div>

    <div *ngIf="recentSearches && recentSearch" class="historyTooltip recentSearch">
        <div class="historyTooltipText">
            <!--<div class="scrollSection">-->
            <ul class="historyScroll">
                <li class="hisDocsEllipsis" *ngFor="let recent of recentSearches" (click)="recentSearchClick(recent.reference)">
                    <span class="" title="{{recent.title}}">{{recent.title}} </span>

                </li>
            </ul>

        </div>
    </div>
<!--</li>-->