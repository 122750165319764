<div class=" row clearfix" [ngClass]="appService.frameworkType =='KCW' ? 'topLogoContainer2' : 'topLogoContainer'">
  <div class="col-md-3 col-sm-6 col-6">
    <div class="logo">
      <!--Placeholder for left logo-->
      <ng-content select="[logo-top-left]"></ng-content>
    </div>
  </div>
  <div class="col-md-6 d-none d-lg-block">
    <!--Placeholder for search box-->
    <ng-content *ngIf="isAuthorizedUser" select="[search-box]"></ng-content>
    <div *ngIf="isAuthorizedUser" class="col-sm-12 allSearchSection">
      <ng-content select="[combined-search-filters-desktop]"></ng-content>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <!--Placeholder for right logo-->
    <ng-content
      *ngIf="!user?.isExternalUser"
      select="[logo-top-right]"
    ></ng-content>
  </div>
</div>
<div  class="topLogoContainer clearfix" 
  style="margin:0;display: flex;" *ngIf="appService.frameworkType =='KCW'">
    <div class="col-md-2 col-sm-6 col-2">
      <div class="logo">
        <!--Placeholder for left logo-->
       </div>
    </div>
    <div  class="col-md-8 d-none d-lg-block">
      <!--Placeholder for search box-->
      <!-- change for sample wording -->
    <p class=" searchHeaderText greyColor2">Search From KPMG Clara</p>
    <!-- end -->
    </div>
    <div class="col-md-2 col-sm-6 col-10">
      <!--Placeholder for right logo-->
      <p class="greyColor2 logoStyle"></p>
     </div>
   </div>

<div class="menuContainer clearfix">
  <!--Placeholder for burger menu-->
  <ng-content
    *ngIf="isAuthorizedUser"
    select="[taxonomy-burger-menu]"
  ></ng-content>

  <!--Placeholder for user profile menu-->
  <ng-content *ngIf="isAuthorizedUser" select="[header-tabs]"></ng-content>

  <div class="btn-group float-right">
    <ng-content select="[user-profile-menu]"></ng-content>
  </div>
</div>

<div class="d-block d-lg-none">
  <!--Placeholder for search box-->
  <ng-content select="[search-bar-mobile]"></ng-content>
  <div class="col-sm-12 col-12 allSearchSection">
    <ng-content select="[combined-search-filters-mobile]"></ng-content>
  </div>
</div>
