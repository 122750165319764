import { Component, OnInit, Input, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';

import { AlexLocationQueryModel } from "app/shared/components/location/alex-location-query.model";
import { SpaceDocument } from "app/feature/spaces/model/space-document.model";
import { AlexLocationComponent } from "app/shared/components/location/position-mid/alex-location.component";
import { AlexPages } from "app/shared/components/help/help.enum";

@Component({
  selector: 'alex-tile-footer-location-date',
  templateUrl: './tile-footer-location-date.component.html',
  styleUrls: ['./tile-footer-location-date.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class TileFooterLocationDateComponent implements OnInit {
  @Input() data: any;
  @Input() type;
  @Input() showPopUp: boolean = false;
  @Input() showLocation: boolean = true;
  @ViewChild(AlexLocationComponent) location;
  @Output() locationClicked: EventEmitter<boolean>;
  constructor(private eref: ElementRef) {
    this.locationClicked = new EventEmitter<boolean>();
  }

  ngOnInit() {
  }

  clickLocation() {
    this.showPopUp = !this.showPopUp;
    this.location.GetDetails(this.getLocationDetails());
  }


  getLocationDetails(): AlexLocationQueryModel {

    let locationQueryDetails = new AlexLocationQueryModel();
    if (this.data.idolInfo) {
      locationQueryDetails.docId = (this.data.idolInfo ? this.data.idolInfo : "");
      locationQueryDetails.docName = this.data.title;

      if (this.data.documentType.toLowerCase() === "top" || this.data.documentType.toLowerCase() === "topic" || this.data.documentType.toLowerCase() === "topics") {
        locationQueryDetails.docType = "TOP";
        locationQueryDetails.isDocument = true;
        locationQueryDetails.elementIds = this.data.taxanomyIDList;
      }
      else if (this.data.documentType.toLowerCase() === "content page") {
        locationQueryDetails.docType = "";
        locationQueryDetails.isDocument = false;
        locationQueryDetails.elementIds = this.data.taxanomyIDList;
      }
      else {
        locationQueryDetails.docType = "LFC";
        locationQueryDetails.isDocument = true;
        // if (this.data.sourceTaxonomyIds && this.data.sourceTaxonomyIds.length > 0)
        //   locationQueryDetails.elementIds = this.data.sourceTaxonomyIds;
        // else
          locationQueryDetails.elementIds = this.data.taxanomyIDList;
      }
    }
    else if (this.data.docId) {
      locationQueryDetails.docId = (this.data.docId ? this.data.docId : "");
      locationQueryDetails.docName = this.data.docTitle;

      if (this.data.docType.toLowerCase() === "top" || this.data.docType.toLowerCase() === "topic" || this.data.docType.toLowerCase() === "topics") {
        locationQueryDetails.docType = "TOP";
        locationQueryDetails.isDocument = true;
        locationQueryDetails.elementIds = this.data.selectedTaxonomy;
      }
      else if (this.data.docType.toLowerCase() === "content page") {
        locationQueryDetails.docType = "";
        locationQueryDetails.isDocument = false;
        locationQueryDetails.elementIds = [this.data.taxonomyId];
      }
      else {
        locationQueryDetails.docType = "LFC";
        locationQueryDetails.isDocument = true;      
          locationQueryDetails.elementIds = this.data.selectedTaxonomy;
       
      }
    }
    return locationQueryDetails;
  }


  //called on click on outside element
  onClick(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.showPopUp = false;
    }
    if (this.showPopUp)
      this.locationClicked.emit(this.showPopUp);
  }



}
