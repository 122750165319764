import { AlexSearchResult } from "app/feature/search/model/content/search-result.model";
import { AlexAdvanceFilteViewModel } from "app/feature/search/model/filters/advance-filter.viewmodel";
import { AlexFilterTagModel } from "app/feature/search/search-result-tag/filter-tag.model";
import { AlexDropdownModel } from "app/shared/components/dropdown/dropdown.model";
import { AlexPaginationModel } from "app/shared/components/pagination/pagination.model";

export class AlexSearchViewModel {
    result: AlexSearchResult;
    filter: AlexAdvanceFilteViewModel;
    tags: Array<AlexFilterTagModel>;
    sortDropdownModel: AlexDropdownModel;
    pagination: AlexPaginationModel;
}