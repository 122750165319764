
<div class="spotlight clearfix" *ngIf="spotlight?.title || spotlight?.header ||
  spotlight?.description ||spotlight?.image">
  <h1 *ngIf="spotlight?.title" class="spotlightHding">{{spotlight?.title }}</h1>

  <div class="col-sm-12 imgArea clearfix" *ngIf="spotlight?.header ||
  spotlight?.description ||spotlight?.image">

    <div *ngIf="deviceType=='desktop'" class="imgLeft"><img *ngIf="spotlight?.image?.desktop" src={{resolveImagePath(spotlight?.image?.desktop)}}></div>
    <div *ngIf="deviceType=='tablet'" class="imgLeft"><img *ngIf="spotlight?.image?.tablet" src={{resolveImagePath(spotlight?.image?.tablet)}}></div>
    <div *ngIf="deviceType=='mobile'" class="imgLeft"><img *ngIf="spotlight?.image?.mobile" src={{resolveImagePath(spotlight?.image?.mobile)}}></div>
    
    <div class="spotlighteTextArea">
    <h2>{{spotlight?.header}}</h2>
    <p [innerHtml]="description"></p>

    </div>
  </div>
</div>