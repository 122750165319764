import { SessionState } from "app/core/services/session/session-state.model";
import { EventEmitter, Injectable } from "@angular/core";
import { SEND_KEEP_ALIVE_REQUEST } from "app/core/services/session/session.action";
import { AlexUrlResolverService } from "app/core/services/url-resolver/url-resolver.service";
import { Observable, Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AlexSessionManager {
    state: SessionState;
    timer: any;
    sessionCommands$ = new EventEmitter<string>();
    constructor(private urlResolver: AlexUrlResolverService, private http: HttpClient) {
        this.state = Object.assign(new SessionState(), this.urlResolver.getSessionConfig());
        this.state.keepAliveRequestInterval = this.state.layer7SessionExpirationTime - 120000;

        this.restartSessionTimer();
    }

    restartSessionTimer() {
        const maxLimitReached = !this.state.maxSessionKeepAliveTime && (this.state.maxSessionKeepAliveTime - this.state.userInActiveTime) > 0;
        this.clearSessionTimer();
        if (this.state.keepAlive && !maxLimitReached) {
            this.timer = setTimeout(() => {
                this.sendKeepAliveRequest()
            }, this.state.keepAliveRequestInterval);
        }
    }

    clearSessionTimer() {
        this.timer && clearTimeout(this.timer);
    }

    sendKeepAliveRequest() {
        this.sessionCommands$.next(SEND_KEEP_ALIVE_REQUEST);
    }

    increaseUserInActiveTime() {
        this.state.userInActiveTime += this.state.keepAliveRequestInterval;
    }

    resetUserInActiveTime() {
        this.state.userInActiveTime = 0;
    }

    callSessionAliveEndpoint(): Observable<any>{
        return this.http.get(this.urlResolver.resolveUrlApiV1('user/KeepAlive'));
    
    }
}