import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { SearchService } from "app/feature/search/services/search.service";
import { CarouselItem } from "app/shared/components/carousel/model/carousel-item.model";
import { Router } from "@angular/router";
// import { NgClass } from "@angular/common/src/directives";
import { DomainService } from "app/feature/home/domain.service";
import { AlexUserService, Status } from "app/core/services";
import { AlexDocumentType } from "app/core/services/app/app.constant";
import { AlexNotificationService } from "app/shared/components/notification/notification.service";
import { AlexDocumentService } from "app/feature/long-form-content/document/document.service";
// phase 2 tracking code
import { ExternalUrlClickTrackingData, ExternalUrlClickTrackingModel } from 'app/shared/models/tracking/external-url-click';
import { TrackingService } from 'app/shared/services/tracking';
import { PAGE_IDENTIFIERS } from 'app/shared/constants/tracking/external-url-click';
import { SearchParamService } from 'app/feature/search/services/search-param.service';

@Component({
  selector: 'alex-home-listview',
  templateUrl: './home-listview.component.html',
  styleUrls: ['./home-listview.component.scss'],
  providers: [SearchService],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class HomeListviewComponent implements OnInit {
  @Input() carouselItem: CarouselItem;
  @Input() columnCount: number;
  @Output() addDocumentToSpace: EventEmitter<any> = new EventEmitter();
  @Output() sendItem: EventEmitter<any> = new EventEmitter();
  toolTipElement: HTMLElement
  constructor(private eref: ElementRef, private userService: AlexUserService,
    private router: Router, private docService: AlexDocumentService, public domainService: DomainService, private notificationService: AlexNotificationService
    // phase 2 tracking code
    , private trackingService: TrackingService,
    private searchParamService: SearchParamService
    ) { }

  ngOnInit() { }

  addDocToSpace() {
     this.carouselItem.type = "addSpaceModalToolTip"
    this.addDocumentToSpace.emit(this.carouselItem);
  }

  share() {
    this.carouselItem.type ="shareModalToolTip"
    this.addDocumentToSpace.emit(this.carouselItem);
  }

  showTooltip($event) {
    this.hideTootTip();
    this.toolTipElement = $event.currentTarget.nextElementSibling;
    if (this.toolTipElement)
      this.toolTipElement.style.display = 'block';
  }

  hideTootTip() {
    if (this.toolTipElement)
      this.toolTipElement.style.display = 'none';
  }

  onClick(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.hideTootTip();
    }
  }

  locationClicked($event){
    this.hideTootTip();
  }

  openDocument(item: CarouselItem) {
    if (item.docType.toLowerCase() === "top" || item.docType.toLowerCase() === "topic" || item.docType.toLowerCase() === "topics") {
      this.router.navigate(['/document/top', item.docId]);
    } else if (item.docType.toLowerCase() === "content page") {
      // this.router.navigate(['/bridge', item.taxonomyId, item.taxonomyId]);
      this.router.navigate(["/bridge", item.taxonomyId, item.taxonomyId], { queryParams: { d: this.userService.getActiveDomain().join(',') } });
    }
    else if (item.mimeType == "video" || item.mimeType == "web" || (item.docType && item.docType.trim().toLowerCase() == AlexDocumentType.ExternalUrl.trim().toLowerCase())) {
      if (item.url) {

        // phase 2 tracking code
        // capture external url
        this.trackExternalURLClick(item.url, item.killDocId, item.docTitle, PAGE_IDENTIFIERS.homePage);
        
        window.open(item.url);
      }
      else {
        this.notificationService.show(Status.Failure, "No content found");
      }
    }
    else {
      if (this.domainService.getFileType(item.mimeType) == "file") {
        if (item.sectionId)//sectionId check
        {
          this.docService.hasKillDocID = false;
          this.docService.sectionId = item.sectionId;
          this.router.navigate(['document/lfc/find', item.sectionId]);
        }
        else {
          this.docService.hasKillDocID = true;
          if (item.parentId)
            this.router.navigate(['/document/lfc', item.parentId, 'toc', item.killDocId],{ queryParams: { tocref: item.tocRef} });//changed docId to killDocId
          else
            this.router.navigate(['/document/lfc', item.killDocId, 'toc', item.killDocId],{ queryParams: { tocref: item.tocRef} });//changed docId to killDocId
        }
      }
      else
        this.router.navigate(['/document/lfc', item.docId, 'toc', item.docId],{ queryParams: { tocref: item.tocRef} });
    }
  }



  leftSectionClass(colCount) {
    let colClass = "";
    switch (colCount) {
      case 1: colClass = "col-lg-1 col-md-2 col-sm-2 col-2";
        break;
      case 2: colClass = "col-lg-2 col-md-2 col-sm-2 col-2";
        break;
      case 3: colClass = "col-lg-3 col-md-3 col-sm-2 col-2"
        break;
    }
    return colClass;
  }

  middleSectionClass(colCount) {
    let colClass = "";
    switch (colCount) {
      case 1: colClass = "col-lg-10 col-md-9 col-sm-8 col-8";
        break;
      case 2: colClass = "col-lg-8 col-md-8 col-sm-8 col-8";
        break;
      case 3: colClass = "col-lg-7 col-md-7 col-sm-8 col-8"
        break;
    }
    return colClass;
  }

  rightSectionClass(colCount) {
    let colClass = "";
    switch (colCount) {
      case 1: colClass = "col-lg-1 col-md-1 col-sm-2 col-2";
        break;
      case 2: colClass = "col-lg-2 col-md-2 col-sm-2 col-2";
        break;
      case 3: colClass = "col-lg-2 col-md-2 col-sm-2 col-2"
        break;
    }
    return colClass;
  }

  // phase 2 tracking code
  /**
   * Track external url click
   * @param url url to capture
   * @param documentKillDocId KILLDOCID of the url
   * @param documentTitle title of the document of the url
   * @param pageInfo page identifier of the url
   * @param searchKey search term used, if any
   */
  private trackExternalURLClick(url: string, documentKillDocId: string, documentTitle: string, pageInfo: string) {
    const searchKey = this.searchParamService.filterParam.q;
    const externalURLTrackingData = new ExternalUrlClickTrackingData(url, documentKillDocId, documentTitle, pageInfo, searchKey, '', '');
    const externalURLClickTrackingModel = new ExternalUrlClickTrackingModel(externalURLTrackingData, searchKey);
    this.trackingService.track(externalURLClickTrackingModel);
  }
  
  clicktoView() {
    this.carouselItem.type="viewModalToolTip";  
    this.sendItem.emit(this.carouselItem);
  }
}
