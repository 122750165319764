
import {map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ISpecialCharacter } from "./Ispecial-character";
import { Observable ,  of } from "rxjs";
import { AlexUrlResolverService } from "app/core/services";
import { HttpClient } from "@angular/common/http";
import { environment } from 'environments/environment';

@Injectable()
export class SpecialCharacterResolver {
  specialCharacters: ISpecialCharacter;
  constructor(
    private http: HttpClient,
    private urlResolver: AlexUrlResolverService
  ) {}
  resolve(): Observable<any> | boolean {
    if (this.specialCharacters) {
      return of(this.specialCharacters);
    }
    const environmentInstance = environment;
    const url = environmentInstance["specialCharacterJsonUrl"];
    return this.http
      .get(url).pipe(
      // .map(response => response.json())
      // .tap(response => {
      //     this.specialCharacters = response as any;
      //   })
        map(response => {
          this.specialCharacters = response as any;
        }))
      ;
  }
}
