import { ElementRef, Injectable } from "@angular/core";
import { Bounds } from "app/shared/components/carousel/model/bounds.model";

@Injectable({
  providedIn: "root",
})
export class OnScreenCarouseService {
  
  constructor(private window: Window) {}

  isOnScreen(elref: ElementRef) {
    var win: any = this.window;
    var viewport: any = {
      top: win.scrollTop,
      left: win.scrollLeft,
    };
    viewport.right = viewport.left + win.innerWidth;
    viewport.bottom = viewport.top + win.innerHeight;

    var bounds = new Bounds();
    bounds.left = elref.nativeElement.offsetLeft;
    bounds.top = elref.nativeElement.offsetTop;
    bounds.right = bounds.left + elref.nativeElement.outerWidth;
    bounds.bottom = bounds.top + elref.nativeElement.outerHeight + 200;

    return !(
      viewport.right < bounds.left ||
      viewport.left > bounds.right ||
      viewport.bottom < bounds.top ||
      viewport.top > bounds.bottom
    );
  }
}
