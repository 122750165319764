<div class="accordion-heading" [ngClass]="{'root-node':isRootNode}">
    <!--<a class="accordion-toggle" data-toggle="collapse" 
    aria-expanded="true" (click)="navigetToBridgePage(locationDetail)">-->
    <a class="accordion-toggle" data-toggle="collapse" aria-expanded="true">
        <label class="locationEllipsis">
            <span [ngClass]="{'subChildSource': (locationDetail?.locationModel==null || locationDetail?.locationModel?.id ==null )}" *ngIf="locationDetail?.isRootOrLeafNode " class="rootLabel" >{{locationDetail?.name}}</span>
            <span [ngClass]="{'subChildSource': (locationDetail?.locationModel==null || locationDetail?.locationModel?.id ==null )}" *ngIf="!locationDetail?.isRootOrLeafNode "  (click)="navigetToBridgePage(locationDetail)" >{{locationDetail?.name}}</span>
        </label>
    </a>
</div>


<div class="accordion-body collapse in" aria-expanded="true">
    <div class="accordion-inner">
        <div class="accordion" id="childSource" *ngIf="locationDetail?.locationModel!=null && locationDetail?.locationModel?.id!=null">


            <alex-location-tree-item [locationDetail]="locationDetail?.locationModel"></alex-location-tree-item>





        </div>

    </div>
</div>