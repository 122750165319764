export function AutoUnsubscribeObservables(excludes?: Array<string>) {
    return (ctor: Function) => {
        const onDestroyOriginalMethod = ctor.prototype.ngOnDestroy;
        ctor.prototype.ngOnDestroy = function () {
            
            excludes = excludes || [];
            Object.keys(this).filter(propertyName => propertyName
                && !excludes.includes(propertyName)
                && this[propertyName]
                && (typeof this[propertyName].unsubscribe === "function"))
                .forEach(propertyName => {
                    this[propertyName].unsubscribe()
                });

            onDestroyOriginalMethod
                && typeof onDestroyOriginalMethod == "function"
                && onDestroyOriginalMethod.apply(this, arguments);
        }
    }
}