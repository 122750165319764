
import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';

import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AlexUrlResolverService } from "app/core/services";
import { CountrySelection } from "app/feature/country-selection/country-selection.model";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CountrySelectionService {

    countryList: Subject<Array<CountrySelection>>;
    constructor(private http: HttpClient, private urlResolver: AlexUrlResolverService) {
        this.countryList = new Subject<Array<CountrySelection>>();
     }

    getCountries() {

        return this.http.get(this.urlResolver.resolveUrlApiV1(`user/GetCountries`)).pipe(
            map(response => {
                return response as Array<CountrySelection>;
            })).subscribe(cList=>{
                this.countryList.next(cList);
            });

    }

    saveCountries(countryListReqModel: Array<CountrySelection>): Observable<Boolean> {
        // let header = new Headers({ 'Content-Type': 'application/json' });
        // let options = new RequestOptions({ headers: header });
        const header = new HttpHeaders({ 
            "Content-Type": "application/json",
        });
        // return this.http.post(this.urlResolver.resolveUrlApiV1('user/SaveCountries'), countryListReqModel, 
        // {headers:header}).pipe(
            // map(response => {
            //     //this.refreshPage();
            //      return response;
            // })).catch(this.handledError);
            return this.http.post<any>(this.urlResolver.resolveUrlApiV1('user/SaveCountries'), countryListReqModel, {headers:header}).pipe(
                catchError(this.handledError));
    }

    refreshPage() {
        window.location.reload();
    }

    handledError(error: Response): any {

        return observableThrowError(error || 'Server error');
    }

}

