import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

@Directive({ selector: '[anchorToRouterLink]' })
export class AnchorToRouterLinkDirective {
    constructor(el: ElementRef,private router: Router, private route: ActivatedRoute) {
       // el.nativeElement.style.backgroundColor = 'yellow';
    }

    @HostListener('click', ['$event'])
    onAnchorClick(event: Event) {
        
        const hash = (<any>event.srcElement).hash;
        
        
        if(hash){
            event.preventDefault();
            window.location.hash = hash;
            // this.router.navigate(['/document/lfc/US_DPP_PPL_13_021'],{fragment:'PPL_Attachment'});
        }
    }
}