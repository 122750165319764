import { Component, OnInit, Input, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy, Output,EventEmitter } from '@angular/core';
import { BurgerMenuContent } from "app/feature/header/services/burger-menu.model";
import { Router, ActivatedRoute } from "@angular/router";
import { HeaderTaxonomyTreeService } from "app/feature/header/services/header-taxonomy-tree.service";
import { AlexHeaderTabsComponent } from "app/feature/header";
import { AlexAppService } from "app/core/services";
import { AlexDocumentService } from "app/feature/long-form-content/document/document.service";

@Component({
  selector: 'alex-header-taxonomy-tree',
  templateUrl: './header-taxonomy-tree.component.html',
  styleUrls: ['./header-taxonomy-tree.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlexHeaderTaxonomyTreeComponent implements OnInit {
  @Input() bargarMenuData: { name: string; data: BurgerMenuContent[] }
  @Input() popupStatus:boolean;
  @Output() sendStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  scrollHeight
  headerHeightSubscription
  isVisible: boolean;
  constructor(private router: Router, private route: ActivatedRoute, private eref: ElementRef,
    private headerTaxonomyTreeService: HeaderTaxonomyTreeService,
    private cdref: ChangeDetectorRef, private appService:AlexAppService,
    private docService: AlexDocumentService) {

  }

  ngOnInit() {
    this.headerTaxonomyTreeService.burgerMenuStatus$.subscribe(isVisible => {
      this.isVisible = isVisible;
      if (this.isVisible)
        this.sendStatus.emit(this.isVisible);
      this.cdref.markForCheck();
    });


    this.headerTaxonomyTreeService.getBurgerMenu$.subscribe(content => {
      this.bargarMenuData = content;
      this.cdref.markForCheck();
    });
    this.setScrollHeight();
  }

  getAllChilds(e: Event, item: BurgerMenuContent): void {
    this.headerTaxonomyTreeService.getAppropiateContent(item);
    e.stopPropagation();
  }
  getallData(e: Event): void {
    this.headerTaxonomyTreeService.getBurgerMenu();
  }

  moveBack(e: Event): void {
    this.headerTaxonomyTreeService.moveBackBurgerMenu();
    e.stopPropagation();
  }
  //called on click on outside element
  onClick(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.headerTaxonomyTreeService.burgerMenuStatus$.next(false);
      this.headerTaxonomyTreeService.isMenuVisible = false;
    }

    if(this.popupStatus){
      this.headerTaxonomyTreeService.burgerMenuStatus$.next(false);
      this.headerTaxonomyTreeService.isMenuVisible = false;
    }
  }


  openBridgePage(e: Event, item: BurgerMenuContent, parentName: string) {
    //this.router.navigate(["/bridge",taxonomyid,{name:taxonomyName}]);
    this.headerTaxonomyTreeService.getNextPageDetails(item, parentName);
    e.stopPropagation();
  }

  setScrollHeight(){
    this.headerHeightSubscription = this.appService.appHeaderHeight$.subscribe(h=>{
      this.scrollHeight = window.innerHeight - h;
      this.cdref.markForCheck();
    })
  }
}
