<a target="_blank" href="{{url}}">
    <alex-svg-icon
    cssClass="colorNone"
    icon="feedbackForm"
    title="Feedback"
  ></alex-svg-icon>
</a>

<!-- 
<a (click)="showHelpModal(helpFromHome)">
  <alex-svg-icon
    cssClass="colorNone"
    icon="contextualBar_Help"
    title="Feedback"
  ></alex-svg-icon>
</a> -->

