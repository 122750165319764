import { ParentTaxonomyIds } from "app/feature/search/model/taxonomy-model";
import { AlexSourceFilterModel } from "app/feature/search/model/filters/source-filter.model";

export class QueryCriteria {
  domain: string;
  dateRange: string;
  fromDate: string;
  searchTerm: string;
  searchFor: string;
  typedTerm: string;
  suggestionLength: number;
  searchFilter: Array<string>;
  toDate: string;
  //for pagination
  startPage: number;
  endPage: number;
  languageNames: Array<string>;
  extensions: Array<string>;
  parentId: string;
  docId: string;
  allSourceChecked: boolean;
  parentTaxonomyIds: Array<AlexSourceFilterModel>;
  allFilter: boolean;
  topics: Array<string>;
  otherCategory: Array<string>;
  country: Array<string>;
  sourceFilters: Array<AlexSourceFilterModel>;
  killDocId: string;
  //for combined search
  applicableCountries: string[];
  auditingStandards: string[];
  versions: string[];
  tocRef: string;
  tocTitle: string;

  constructor(
    domain: string = "",
    dateRange: string = "",
    fromDate: string = "",
    searchTerm: string,
    searchFor: string,
    searchLimit: string = "",
    searchFilter: Array<string>,
    toDate: string,
    parentId: string = "",
    docId: string = "",
    parentTaxonomyIds = new Array<AlexSourceFilterModel>(),
    topics = new Array<string>(),
    typedTerm: string = "",
    suggestionLength: number = 0,
    tocRef: string = "",
    killDocId: string = "",
    //for combined search
    countries: string[] = [],
    workflowTypes: string[] = [],
    versions: string[] = []
  ) {
    this.domain = domain;
    this.dateRange = dateRange;
    this.fromDate = fromDate;
    this.searchTerm = searchTerm;
    this.searchFor = searchFor;
    this.searchFilter = searchFilter;
    this.toDate = toDate;
    this.languageNames = [];
    this.extensions = [];
    this.parentId = parentId;
    this.docId = docId;
    this.parentTaxonomyIds = parentTaxonomyIds;
    this.allFilter = true;
    this.typedTerm = typedTerm;
    this.suggestionLength = suggestionLength;
    this.tocRef = tocRef;
    this.killDocId = killDocId;
    //for combined search
    this.applicableCountries = countries;
    this.auditingStandards = workflowTypes;
    this.versions = versions;
  }
}
