import { TrackingMessageModel } from "../tracking-message.model";
import { CKD_SEARCH_RESULT_CLICK_TRACKING_IDENTIFIER } from "app/shared/constants/tracking/ckd-search";
import { CKDSearchResultClickTrackingData } from "./data";

/**
 * Model for tracking CKD Search Result click
 */
export class CkdSearchResultClickTrackingModel extends TrackingMessageModel {
    /**
     * constructor
     * @param data Payload data
     */
    constructor(public data: CKDSearchResultClickTrackingData, public searchKey: string) {
        super(CKD_SEARCH_RESULT_CLICK_TRACKING_IDENTIFIER, searchKey);
    }
}