export const AlexDocumentType = {
  Document : "Documents",
  Topic:"topic",
  Feature:'feature',
  ExternalUrl:'External URLs',
  BrowsePage: 'Content Page',
  BrowsePageQuery: 'contentPageQuery',
  Query: 'query'
}

export const LayOut = {
  list:"list",
  tile:"tile"
}

export const CurrentUsrStatus = {
    OK: "OK",
    CONCURRENTUSER: "ConcurrentUser",
    IDLETIMEOUT: "SessionExpired",
    DUPLICATEUSER: "Duplicate",
    USERLOGOUT: "LogOut",
    PREVIOUSSESSIONIDLE: "PreviousSessionIdle",
    NOTAUTHORISED: "NotAuthorised"
}

export const SELECTED_SOURCES = "Selected Sources";