import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AlexAppService,
  AlexUrlResolverService,
  AlexUserService,
  User,
} from "app/core/services";
import { AlexSearchViewModelService } from "app/feature/search/services/search-view-model.service";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";
import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { KcwSearchPageService } from "../../services/kcw-search-page.service";

@Component({
  selector: "alex-kcw-search-box",
  templateUrl: "./kcw-search-box.component.html",
  styleUrls: [
    "./kcw-search-box.component.scss",
    "./kcw-search-box.component.responsive.scss",
  ],
  host: {
    "(document:click)": "onClick($event)",
  },
})
export class KcwSearchBoxComponent implements OnInit, OnDestroy {
  @Input() enabled: boolean = false;
  searchConfig: any;
  searchClicked: boolean = false;
  showInputTextResult: boolean;
  showRemoveTextBtn: boolean = false;
  mainSearchClicked = false;
  user: User;
  isAuthorized: boolean;
  searchKcwText: string;

  @Output() kcwSearchClick = new EventEmitter<string>();

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    public userService: AlexUserService,
    public appService: AlexAppService,
    private urlResolver: AlexUrlResolverService,
    private spinnerService: SpinnerService,
    private eref: ElementRef,
    private searchViewModelService: AlexSearchViewModelService,
    private readonly kcwSearchPageService: KcwSearchPageService
  ) {
    this.searchConfig = this.urlResolver.getManualSearchConfig() || {};
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.userService
      .getUserData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((model) => {
        this.user = model;
      });

    this.userService.isAuthorizedUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((isAuth) => {
        this.isAuthorized = isAuth;
      });

    this.kcwSearchPageService
      .getSearchTerm()
      .pipe(
        tap((searchTerm) => {
          this.searchKcwText = searchTerm;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onSearchInputChange(): void {
    this.showRemoveTextBtn = this.searchKcwText !== "";
  }

  onClick(event) {
    if (!this.enabled) {
      return;
    }
    if (!this.eref.nativeElement.contains(event.target)) {
      this.showInputTextResult = false;
      this.showRemoveTextBtn = false;
    } else {
      if (this.searchKcwText) {
        this.showRemoveTextBtn = true;
      } else {
        this.showRemoveTextBtn = false;
      }
      if (this.mainSearchClicked) {
        this.showRemoveTextBtn = false;
      }
    }
  }
  mobileRightMenuClicked() {
    this.mainSearchClicked = true;
  }

  bigKcwSearch() {
    this.mainSearchClicked = true;
    this.showInputTextResult = false;
    this.searchClicked = true;
    this.showRemoveTextBtn = false;
    document.getElementById("txtSearch").blur();
    this.searchKcwDoc();
  }

  //fires on pressing the enter button in keyboard
  textKcwEnter() {
    this.searchKcwDoc();
    //this.searchKcwText = "";
  }

  searchKcwDoc() {
    this.spinnerService.noSpinner(false);
    this.searchViewModelService.resetPaginationModel();
    this.kcwSearchClick.emit(this.searchKcwText);
  }

  searchKcwClearPressed() {
    this.searchKcwText = "";
    this.showRemoveTextBtn = false;
    document.getElementById("txtSearch").focus();
  }
}
