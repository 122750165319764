import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'alex-tile-container',
  templateUrl: './tile-container.component.html',
  styleUrls: ['./tile-container.component.scss']
})
export class TileContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
