// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  dev: true,
  qaeu: false,
  qaacre:false,
  stagingacre: false,
  stagingeu: false,
  productionacre: false,
  productioneu: false,
    api: {
      v1: {
        base: "https://alexonline.dev.kpmg.com/ADS/api/v1",
        filePath: "https://alexonline.dev.kpmg.com/ADS/binaries/",
        archiveFilePath: "https://alexonline.dev.kpmg.com/ADS/Archive",
        binariesPath: "(AROWeb|ADS)/Binaries",
        archiveBinariesPath: "ADS/Archive",
        alexFriendlyUrl: "https://alexonline.dev.kpmg.com/AROWeb/",
        websiteRoot: "https://alexonline.dev.kpmg.com/ADS/binaries/",
        alexLogOutURL: "https://alexonline.dev.kpmg.com/AROWeb/sso/logout.html",
      }
  
    },
    session: {
      layer7SessionExpirationTime: "660000",
      maxSessionKeepAliveTime: 7200000
    },
    routeRedirect: {
      contentPage: [
        {
          "path": "US+KPMG+Sources_KPMG+Manuals_Internal+Control+Over+Financial+Reporting+(ICOFR)_ICOFR+Reference+Guide+for+KPMG+Auditors+(2016)%7Cqdom=US",
          "redirectTo": "bridge/17819/17819?d=US,INTL"
        },
        {
          "path": "US+KPMG+Sources_KPMG+Manuals_Reports+Manuals_US+Reports+Manual+-+PCAOB_US+Reports+Manual+-+PCAOB%7Cqdom=US",
          "redirectTo": "bridge/2342/23058?d=US,INTL"
        },
        {
          "path": "US+KPMG+US+GAAP+Work+Papers%2c+Templates%2c+and+Related+Guidance_Practice+Aids%7Cqdom=US",
          "redirectTo": "bridge/12305/12305?d=US,INTL"
        },
        {
          "path": "US+KPMG+US+GAAP+Work+Papers%2c+Templates%2c+and+Related+Guidance_Required+and+Other+Work+Papers%7Cqdom=US",
          "redirectTo": "bridge/12307/12307?d=US,INTL"
        },
        {
          "path": "US+KPMG+US+GAAP+Work+Papers,+Templates,+and+Related+Guidance_Forms+and+Letters_Correspondence+with+Clients%7Cqdom=US",
          "redirectTo": "bridge/12325/12325?d=US,INTL"
        },
        {
          "path": "US+KPMG+US+GAAP+Work+Papers,+Templates,+and+Related+Guidance_Forms+and+Letters_Representation+Letters%7Cqdom=US",
          "redirectTo": "bridge/12301/12331?d=US,INTL"
        },
        {
          "path": "US+KPMG+US+GAAP+Work+Papers,+Templates,+and+Related+Guidance_Required+and+Other+Work+Papers%7Cqdom=US",
          "redirectTo": "bridge/12307/12307?d=US,INTL"
        },
        {
          "path": "US+KPMG+US+GAAP+Work+Papers,+Templates,+and+Related+Guidance_Specialists%7Cqdom=US",
          "redirectTo": "bridge/12309/12309?d=US,INTL"
        }
      ]
    },
    helpPages: {
      home: {
        fileName: "Internal_Helpfile_Home.htm",
        title: "Alex Help"
      },
      bridge: {
        fileName: "Internal_Helpfile_Bridge.htm",
        title: "Alex Help"
      },
      spaces: {
        fileName: "Internal_Helpfile_Spaces.htm",
        title: "Alex Help – Spaces"
      },
      search: {
        fileName: "Internal_Helpfile_Search.htm",
        title: "Alex Help – Search"
      },
      manualsearch: {
        fileName: "KAEG_Helpfile_Search.htm",
        title: "Manuals Help – Search"
      },
      lfc: {
        fileName: "Internal_Helpfile_Content.htm",
        title: "Alex Help – Documents"
      },
      manualsLfc: {
        fileName: "Internal_Helpfile_KAEG_Content.htm",
        title: "Alex Help – KAEG Documents"
      },
      kcwsearch: {
        fileName: "KCWSearch_Helpfile_Search.htm",
        title: "Alex Help – KCw Search"
      },
      kcwsearchlfc: {
        fileNam: "KCWSearch_Helpfile_Content.htm",
        title: "Alex Help – KCw Search Documents"
      }
    },
    userGuideUrl: {
      base: "https://alexonline.dev.kpmg.com/ADS/binaries/userGuide"
    },
    styleSheets: {
      featured: "https://alexonline.dev.kpmg.com/ADS/binaries/featuredContent.css",
      aroGlobal: "https://alexonline.dev.kpmg.com/ADS/binaries/aro_global.css"
    },
  appInsight: {
      instrumentationKey: "cc1a3b71-1b2f-4d9e-a506-ab9073ade2b7",
      maxAjaxCallsPerView: "500",
      url: "",
      disableAjaxTracking: false,
    },
    resources: [
      {
        pageName: "unauthorized",
        resources: {
          "WARNING_HEADER_INTERNAL": "You are not authorized to access  Alex. Please contact your local Helpdesk.",
          "WARNING_HEADER_EXTERNAL": "You are not authorized to access KPMG's Accounting Research Online",
          "WARNING_PARAGRAPH_EXTERNAL": "Your subscription is not active or may have expired. If you believe that you have received this message in error, or would like to renew your subscription, please contact us at",
          "NOT_LOGGED_OUT": "Previous session was not properly logged out.",
          "SESSION_TIMEOUT": "Session Time out",
          "CONCURRENT_USER": "License is in use.",
          "DUPLICATE_USER": "User already logged in from another machine/different browser.",
          "USER_ALREADY_LOGGEDIN": "User with same license is already logged in to the system."
        }
      },
      {
        "pageName": "subscription_popup",
        "resources": {
          "SUBSCRIPTION_EXPIARY_MSG": "Your Subscription will expire in @noOfDays days",
          "TRIAL_USER_MSG": "You are Currently Logged in as a Trial User",
          "RENEWAL_MSG": "Please contact your Account Manager for renewal",
          "TRIAL_EXPIARY_MSG": "You have @noOfDays days left on your trial"
        }
      },
      {
        "pageName": "spaces",
        "resources": {
          "SPACE_LABEL": "Spaces",
          "SAVED_SEARCH_TITLE": "Saved Searches",
          "MASTER_DOC_HEADING": "Documents & Pages",
          "ADD_SPACE_MODAL_LABEL": {
            "header": "Add a Space",
            "footerOk": "Save",
            "footerCancel": "Cancel"
          },
          "EDIT_SPACE_MODAL_LABEL": {
            "header": "Edit Space Title",
            "footerOk": "Save",
            "footerCancel": "Cancel"
          },
          "DELETE_SPACE_MODAL_LABEL": {
            "header": "Delete Space",
            "footerOk": "Ok",
            "footerCancel": "Cancel"
          },
          "INVALID_TITLE": "Title is required",
          "DUPLICATE_TITLE": "Name already exists",
          "SPECIAL_CHARACTER": "Title should not contain special characters"
        }
      }
    ],
    CKDManual: {
      queryStringList: [
        "cntry",
        "chunkid",
        "tocref",
        "newactivityid",
        "qid",
        "newstandardid",
        "version",
        "uniqueid"
      ],
      endpoint: {
        fetchtocckdtoalex: "/CKDSearch/CKDTOCDocumentData",
        fetchtocalextoalex: "/CKDSearch/CKDTOCDocumentDataFromLFC",
        fetchchunk: "/CKDSearch/CKDGetLFCData",
        printpreview: "/ckdprint/GetPrintPreviewData",
        printpdf: "/CKDprint/GetPrintCKDPdfData",
      },
      endofdocmessage: "End of document",
      nocontentfoundmessage: "No content found",
      stopLoaderOnScroll: true,
      Document: "Document",
      Part: "Part",
      IEScrollHeightOffset: 1,
      currentDocumentBoundary: 200,
      documentDisplacementOffset: 100,
      ctypeSubQuestion: "Sub Question",
    },
    KCWManual: {
      queryStringList: [
        "cntry",
        "chunkid",
        "tocRef",
        "NewActivityId",
        "QId",
        "newstandardid",
        "version",
        "ruleSetId",
        "screenName",
        "uniqueid"
      ],
      endpoint: {
        fetchkcwtoc: "/KCwSearch/KCwTOCDocumentData",
        fetchkcwchunk: "/KCwSearch/KCwGetStandardLFCData"
      },
      endofdocmessage: "End of document",
      nocontentfoundmessage: "No content found",
      topLoaderOnScroll: true,
      Document: "Document",
      Part: "Part",
      IEScrollHeightOffset: 1,
      currentDocumentBoundary: 200,
      documentDisplacementOffset: 100,
      ctypeSubQuestion: "Sub Question"
    },
    KCWAchdManual: {
      queryStringList: [
        "cntry",
        "newactivityid",
        "qid",
        "wftype",
        "version",
        "ruleSetId",
        "searchType",
        "chunkid",
        "screenName",
        "newstandardid",
        "tocref",
        "uniqueid"
      ],
      endpoint: {
        KCwActivityLFCDataList: "KCwSearch/KCwActivityLFCDataList",
        KCwActivityLFCData: "KCwSearch/KCwActivityLFCData"
      },
      endofdocmessage: "End of document",
      nocontentfoundmessage: "No content found",
      stopLoaderOnScroll: true,
      Document: "Document",
      Part: "Part",
      IEScrollHeightOffset: 1,
      currentDocumentBoundary: 200,
      documentDisplacementOffset: 100,
      ctypeSubQuestion: "Sub Question"
    },
    CKDManualSearch: {
      noresultmsg: "No document found",
      invalidParameterMessage: "No document found",
      defaultCountry: "International",
      defaultWorkflow: "All",
      defaultCountryDisplayTxt: "International",
      defaultWorkflowDisplayTxt: "All KAEGs",
      invalidWorkflowDisplayTxt: "Select KAEG(s)",
      invalidCountryDisplayTxt: "Select Country",
      invalidVersionDisplayTxt: "Select Version(s)",
      selectedWorkflowDisplayTxt: "Selected KAEGs",
      selectedVersionDisplayTxt: "Selected Versions",
      workflowPrefix: "KAEG",
      searchPlaceHolder: "Search content, titles, topics or \"key phrases\"",
      sortDropdownPristineValue: [
        {
          "text": "Most relevant first",
          "value": "Relevance",
          "selected": true
        },
        {
          "text": "Most recent first",
          "value": "Date",
          "selected": false
        }
      ],
      logoText: "",
      searchLabel: "Search the KPMG Audit Execution Guide",
      welcomeMessage: {
        header: "Search the KPMG Audit Execution Guide",
        subHeader: "To search the KPMG Audit Execution Guide:",
        body: [
          "Type your search term in the ‘Search content, titles, topics or ‘key phrases’’ field above",
          "Select your specific country, if applicable, or select ‘International’",
          "Select the KPMG Audit Execution Guide you wish to search (e.g. KAEG-ISA, KAEG-PCAOB or KAEG-AICPA)",
          "Click the search icon"
        ],
        footer: "Your search results will appear on this page.",
      },
      tooltip: {
        help: "KAEG Search - Help",
        share: "Share link"
      },
      featureTxt: "Featured",
      seeMoreFrom: "See more from",
      seeMoreTooltip: "More results",
      itemPerPage: 25,
      showMoreButtonTxt: "Load More",
      browserTitle: "Alex – KAEG Search",
    },
  specialCharacterJsonUrl: "https://alexonline.dev.kpmg.com/ADS/binaries//SpecialCharacters.json",
    tracking: {
      endpoints: {
        CKDManualSearch: {
          loadMore: "/CKDSearch/LoadMoreContent"
        }
      }
    },
    combinedSearch: {
      categoryTypes: {
        alex: "ALEX",
        manuals: "KAEG",
        topics: "TOPIC",
        featured: {
          alex: "ALEXFEATURED",
          manuals: "KAEGFEATURED",
          both: "ALEXKAEGFEATURED",
        }
      },
      api: {
        endpoints: {
          get: {},
          post: {
            countryWorkflowVersions: "home/CombinedCountryVersionsWorkflow"
          }
        }
      }
    },
    lfcFocusTimeout: 3000,
    kcwSearch: {
      welcomeMessage: {
        header: "Search the KPMG Audit Execution Guide",
        subHeader: "To search the KPMG Audit Execution Guide:",
        body: [
          "Type your search term in the ‘Search content, titles, topics or ‘key phrases’’ field above",
          "Click the search icon"
        ],
        footer: "Your search results will appear on this page."
      },
      invalidRuleSetIdMessage: "Engagement parameters did not come through. Please close the window and re-initiate search from KPMG Clara.",
      breadCrumbText: {
        activityFlyout: "Search from \u201C${param.screenName}\u201D screen – Activity \u201C${param.activityTitle}\u201D",
        guidancePanel: "Search from \u201C${param.screenName}\u201D screen"
      },
      browserTitle: "Search From KPMG Clara",
      searchFields: [
        {
          "id": "activity",
          "field": "activityContent",
          "title": "Activity Content"
        },
        {
          "id": "relActivity",
          "field": "relActivityContent",
          "title": "Related Activity Content"
        },
        {
          "id": "standard",
          "field": "standardContent",
          "title": "Standard Content"
        },
        {
          "id": "relStandard",
          "field": "relStandardContent",
          "title": "Related Standard Content"
        }
      ]
    },
    alexBrowserTitle: "Alex",
    L72AAD: {
      clientId: "fc5a1356-8268-4ee4-a83d-012e9585f3d6",
      provider: "https://login.microsoftonline.com/",
      tenantId: "cf59843c-25a6-4e9e-a632-73d41e867a1f",
      redirectUri: "https://alexonline.dev.kpmg.com/AROWeb",
      postLogoutRedirectUri: "https://alexonline.dev.kpmg.com/AROWeb",
      cacheLocation: "sessionStorage",
      idleStartTime: 6900,
      idleTimeout: 300,
      pingInterval: 3300,
      apiScope: "api://f3bd8bb8-90c0-458f-a504-17ce5911cca2/Task.Validate",
    },
    contextualBarFeedback:"",
    totalSearchWordLimit: 11,
    staticArray:".,[,],?",
    previewAvailableForFileTypes: "doc,Doc,docm,docx,DOCX,dotm,dot,dotx,pdf,PDF,potm,potx,ppsm,ppsx,ppt,PPT,pptm,pptx,rtf,txt,xls,XLSB,XLS,xlsb,xlsm,XLSM,xlsx,XLSX,xlt,XLT,xltm,XLTM,xltx",
    allowedMimeTypes:"application/pdf",
    binaryDocViewHeader:"Alex Document Preview"
 
};
