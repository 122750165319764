
import {of as observableOf,  Observable } from 'rxjs';

import {map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { AlexUrlResolverService } from "app/core/services";
import { UserGuideItem } from "app/shared/components/userGuide/user-guide.model";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AlexUserGuideService {
    userGuideItems: Array<UserGuideItem>;
    constructor(
        private urlResolverService: AlexUrlResolverService,
        private http: HttpClient) {

    }

    // this.http.get('assets/userGuide/InternalUserGuide.json').map(data => {
    //   this.userGuideItems = data.json() as [UserGuideItem];
    //   this.headerImagePath = this.urlResolverService.getUserGuideUrl(this.userGuideItems[0].url);
    //   this.setDetails(this.currentIndex);
    // }).subscribe(
    //    userGuid => {
    //       this.updateOnboardingFlag();
    //     }
    // );

    fetchUserGuideContent(isExternal: boolean): Observable<Array<UserGuideItem>> {
        if (this.userGuideItems)
            return observableOf(this.userGuideItems);

        const url = this.urlResolverService.getUserGuideUrl(isExternal ? '/ExternalUserGuide.json' : '/InternalUserGuide.json');
        // return this.http.request(url)
        //     .map(response => {
            return this.http.get(url).pipe(
                map(response => {
                this.userGuideItems = response as Array<UserGuideItem>;
                return this.userGuideItems;
            }));
    }



    updateOnboardingFlag(): Observable<any>{
        return this.http.post(this.urlResolverService.resolveUrlApiV1("user/UpdateOnboarding"), null).pipe(
            map(response => {
                return response
            }));
    }

}