import { Component } from "@angular/core";
import { AlexHeaderSearchBoxComponent } from "app/feature/header/search-box/header-search-box.component";

@Component({
  selector: "alex-search-bar-mobile",
  templateUrl: "./search-bar-mobile.component.html",
  styleUrls: ["./search-bar-mobile.component.scss"],
  host: {
    "(document:click)": "onClick($event)",
  },
})
export class AlexHeaderSearchbarMobileComponent extends AlexHeaderSearchBoxComponent {
  //constructor(private allSource:AllSourcesService){}
  isVisible = false;
  clickHistory() {
    this.isVisible = true;
    event.stopPropagation();
  }
  hideDiv() {
    this.isVisible = false;
  }
}
