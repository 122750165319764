import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import {
  HIDDEN,
  SpinnerState,
  FETCHING_DATA,
  RENDERING_VIEW
} from "app/shared/components/spinner/spinner.state";

@Injectable()
export class SpinnerService {
  private _autoHideSpinnerAfter = 120000;
  private _autoHideSpinnerTracker = {};
  private _spinnerId = 0;
  private _enableWhiteBackground: boolean;
  private _isVisible: boolean;
  private _noSpinner: boolean;
  private _message: string;
  private _activatedSpinners = [];
  private _spinnerState: {
    visible: boolean;
    state: string;
    message: string;
    whiteBackground: boolean;
  };
  get isVisible() {
    return !this._noSpinner && this._isVisible;
  }
  onVisibilityChange$: Subject<boolean>;
  spinnerMessageChange$: Subject<string>;
  spinnerState$: Subject<{
    visible: boolean;
    state: string;
    message: string;
    whiteBackground: boolean;
  }>;
  constructor() {
    this.onVisibilityChange$ = new Subject<boolean>();
    this.spinnerMessageChange$ = new Subject<string>();
    this.spinnerState$ = new Subject<{
      visible: boolean;
      state: string;
      message: string;
      whiteBackground: boolean;
    }>();
    this._message = "";
    this._isVisible = false;
    this._noSpinner = false;
    this._spinnerState = {
      visible: false,
      state: HIDDEN,
      message: "",
      whiteBackground: false
    };
    this.notify();
  }
  showSpinner(enableWhiteBackground = false): number {
    const spinnerId = ++this._spinnerId;
    this._isVisible = true;
    this._spinnerState.whiteBackground = enableWhiteBackground;
    this.notify();
    this._activatedSpinners.push(spinnerId);
    this._autoHideSpinnerTracker[spinnerId] = setTimeout(() => {
      this.hideSpinner(spinnerId);
    }, this._autoHideSpinnerAfter);
    return spinnerId;
  }
  async showSpinnerAsync(enableWhiteBackground = false): Promise<number> {
    return new Promise<number>(resolve => {
      setTimeout(_ => resolve(this.showSpinner(enableWhiteBackground)), 0);
    });
  }
  hideSpinner(spinnerId = 0, hideAll = false) {
    clearTimeout(this._autoHideSpinnerTracker[spinnerId]);
    delete this._autoHideSpinnerTracker[spinnerId];
    this._activatedSpinners =
      this._activatedSpinners.filter(id => !hideAll && id != spinnerId) || [];
    if (!this._activatedSpinners.length) {
      this._isVisible = false;
      this._message = "";
      this._spinnerState.whiteBackground = false;
      this.notify();
    }
  }
  hideSpinnerAsync(spinnerId = 0, hideAll = false): Promise<void> {
    return new Promise<void>(resolve => {
      setTimeout(<any>resolve(this.hideSpinner(spinnerId, hideAll)), 0);
    });
  }
  setMessage(message: string) {
    this._message = message;
    this.notify();
  }
  noSpinner(status: boolean) {
    this._noSpinner = status;
    this.notify();
  }

  changeSpinnerState(spinnerState: SpinnerState, message = "") {
    switch (spinnerState) {
      case SpinnerState.Hidden:
        this._spinnerState.state = HIDDEN;
        this._spinnerState.visible = false;
        this._isVisible = false;
        break;
      case SpinnerState.RenderingView:
        this._spinnerState.state = RENDERING_VIEW;
        this._spinnerState.visible = true;
        this._isVisible = true;
        break;
      default:
        this._spinnerState.state = FETCHING_DATA;
        this._spinnerState.visible = true;
        this._isVisible = true;
    }
    this._message = message || this._message;
    this.spinnerState$.next(this._spinnerState);
  }
  private notify() {
    this.onVisibilityChange$.next(this.isVisible);
    this.spinnerMessageChange$.next(this._message);
    this.spinnerState$.next(this._spinnerState);
  }
}
