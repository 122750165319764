import { tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AllSourcesTaxonomy } from "./all-sources.model";
import { AlexUrlResolverService } from "app/core/services";
import { asyncScheduler, of, scheduled, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AllSourcesService {
  selectedNodes = [];
  allSourceData: AllSourcesTaxonomy[];
  allSourceStatus$: Subject<boolean>;
  getAllSourcesTaxonomy$: Subject<{
    allChecked: boolean;
    sources: AllSourcesTaxonomy[];
  }>;
  fetched = false;
  allSourceChecked = false;
  allSourcesTxt = "";
  private allSources: AllSourcesTaxonomy[] | null = null;

  constructor(
    private http: HttpClient,
    private urlResolver: AlexUrlResolverService
  ) {
    this.allSourceStatus$ = new Subject<boolean>();
    this.getAllSourcesTaxonomy$ = new Subject<{
      allChecked: boolean;
      sources: AllSourcesTaxonomy[];
    }>();
    this.allSourceChecked = true;
    this.allSourceStatus$.next(false);
    this.getAllSourcesTaxonomy$.next({ allChecked: false, sources: [] });
  }
  getTaxonomyName(id: string) {
    let name = null;
    try {
      if (this.allSourceData) {
        name = this.allSourceData.find((item) => item.id == id).name;
      }
    } catch (e) {}
    console.log("fetching source data from allsource model");
    return name;
  }
  getAllSourcesTaxonomy() {
    if (this.fetched) {
      return scheduled(of([...this.allSources]), asyncScheduler);
    }
    return this.http
      .get<AllSourcesTaxonomy[]>(
        this.urlResolver.resolveUrlApiV1(`/home/SourceFilter`)
      )
      .pipe(
        tap((allSources) => (this.allSources = allSources)),
        tap((data) => {
          this.allSourceData = data.map((item) =>
            Object.assign(item, {
              checked: this.selectedNodes.includes(item.id),
            })
          );
          this.getAllSourcesTaxonomy$.next({
            allChecked: this.allSourceData.every((item) => !item.checked),
            sources: this.allSourceData,
          });
          this.fetched = true;
          this.selectedNodes = [];
        })
      );
  }

  updateTaxonomySelection(taxonomyIds: Array<string>) {
    if (!this.fetched) {
      this.selectedNodes = [...taxonomyIds];
    } else {
      let allChecked = true;
      if (taxonomyIds && taxonomyIds.length) {
        allChecked = false;
        if (this.allSourceData != null) {
          this.allSourceData = this.allSourceData.map((item) => {
            return Object.assign(item, {
              checked: taxonomyIds.includes(item.id),
            });
          });
        }
      } else {
        if (this.allSourceData != null) {
          this.allSourceData = this.allSourceData.map((item) =>
            Object.assign(item, { checked: false })
          );
        }
      }
      this.getAllSourcesTaxonomy$.next({
        allChecked: allChecked,
        sources: this.allSourceData,
      });
    }
  }

  clearFilters(taxonomyIds: string[]) {
    this.allSourceData = this.allSourceData.map((item) => {
      if (taxonomyIds.find((taxonomyId) => taxonomyId == item.id)) {
        item.checked = false;
      }
      return item;
    });
    let allSourceChecked = !this.allSourceData.find((item) => item.checked);
    this.getAllSourcesTaxonomy$.next({
      allChecked: allSourceChecked,
      sources: this.allSourceData,
    });
  }

  clearAllFilters() {
    this.clearFilters(this.allSourceData.map((item) => item.id));
  }

  resetAllSourcesTxt() {
    this.allSourcesTxt = "Sources";
  }
}
