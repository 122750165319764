import { QueryCriteria } from "./carousel-query-criteria.model";

export class CarouselQuery {
  sortOrder: string;
  criteria: QueryCriteria;
  activeDomains: Array<string>;
  searchType: string = "";
  constructor(
    sortOrder: string = "",
    criteria: QueryCriteria | any = {},
    searchType: string = ""
  ) {
    this.sortOrder = sortOrder;
    this.criteria = criteria;
    this.searchType = searchType;
  }
}
