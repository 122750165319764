import { LicenseType } from "app/core/services/user/license-type.enum";
import { LicenseStatus } from "app/core/services/user/license-status.enum";

export class UserLicense {

    isExternalUser: boolean;
    sessionID: string;
    isPAM: boolean;
    subscriptionType: LicenseType;;
    licencseStatus: LicenseStatus;
    popupType: string;
    noOfDays: number;
    userStatus: string;
    isFirstLogin: boolean;
    isUserAgreed: boolean;
}