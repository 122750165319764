import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { SpotLight } from "app/shared/components/spotlight/spotlight.model";
import { AlexAppService, AlexUrlResolverService } from "app/core/services";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: 'alex-spotlight',
  templateUrl: './spotlight.component.html',
  styleUrls: ['./spotlight.component.scss']
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlexSpotlightComponent implements OnInit {

  @Input() spotlight: SpotLight;
  image: string;
  deviceType: string;
  imageBasePath: string;
    description: SafeHtml;
  constructor(private appService:AlexAppService,private urlResolver: AlexUrlResolverService, private sanitizer: DomSanitizer) { 
    
  }
  resolveImagePath(fileName:any):string{
    this.description = this.sanitizer.bypassSecurityTrustHtml(this.spotlight.description);
    return this.urlResolver.resolveFilePath(fileName);
  }
  ngOnInit() {
    this.description = this.sanitizer.bypassSecurityTrustHtml(this.spotlight.description);
    this.deviceType = this.appService.getDeviceType();  
    this.spotlight.title=""
  }
  

}
