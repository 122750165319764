<alex-header-container>
  <!--Items for first row start-->
  <svg
    class="logoWidth"
    xmlns="http://www.w3.org/2000/svg"
    width="77"
    height="35"
    viewBox="0 0 65 27"
    logo-top-left
  >
    <path
      fill="#00338D"
      fill-rule="evenodd"
      d="M50.47 0v12.956c-.455.357-.84.74-1.2 1.152V0H34.867v11.797h-1.193V0H19.27v11.816h-1.192V0H3.675v13.467L0 25.677h3.232l1.624-5.407h.466l2.681 5.408h3.906L9.308 20.27h5.901l-1.637 5.408h3.532l1.616-5.393h.775v-.015h7.864l-1.56 5.392h3.561l1.512-5.392h1.602l.043 5.392h2.988l3.428-5.392h2.245l-1.157 5.392h3.506l1.137-5.392h2.028c-.08 1.671.348 3.19 1.46 4.212 1.36 1.244 3.445 1.482 4.99 1.482 2.112 0 4.305-.302 6.512-.798l1.215-4.896h4.002V0H50.47zM17.56 12.503l-.21.692-1.914 6.347-.075.223h-6.3l-.49-1.034 6.79-6.915h-4.363l-5.31 5.7 1.72-5.7H4.19V.512H17.56v11.991zm3.719 5.367h-.005c-.129.007-.26.018-.409.018-.195 0-.348.007-.492.007l-.856-.007.396-1.459.187-.724.45-1.697c.199 0 .394-.007.58-.007h.663c1.131 0 1.847.064 2.108.427.2.272.174.742-.062 1.458-.404 1.233-.915 1.859-2.56 1.984zm9.737 1.895l1.409-4.984.05 4.984h-1.46zm2.14-7.968h-3.348l-2.296 7.968H23.96c1.81-.667 2.91-1.94 3.25-3.83.281-1.469.148-2.435-.453-3.159-.9-1.076-2.708-.984-4.314-.984l-2.656.005V.512h13.369v11.285zm8.124 7.968h-2.013l3.047-4.777-1.034 4.777zm7.471-7.744l-.006 2.76c-.933 1.284-1.513 2.687-1.783 3.816a7.68 7.68 0 0 0-.214 1.168h-1.966l1.681-7.95-5.658-.004-5.064 7.954h-.369V.512h13.38v11.51zm7.713 10.998c-.764.138-1.525.23-2.25.23-1.93 0-3.272-.894-3.3-2.98h6.235l-.685 2.75zm7.899-3.254h-3.37l.556-2.221h-6.753l-.555 2.22h-3.264v-.455c.05-.25.094-.513.157-.794.594-2.395 2.172-4.761 4.901-4.761 1.08 0 2.154.41 2.007 1.899h4.017c.159-.695.425-1.878-.333-2.966-.857-1.182-2.582-1.656-4.82-1.656-1.59 0-3.918.253-5.929 1.561V.512h13.386v19.253z"
    />
  </svg>
  <alex-header-search-box
    *ngIf="appService.frameworkType != 'KCW'"
    (allSourceClick)="allSourceDClick($event)"
    (searchClick)="onSearchClick()"
    [status]="status"
    [filterLabel]="isAllSourcesSelected ? 'All Sources' : 'Selected Sources'"
    search-box
  >
  </alex-header-search-box>

  <alex-kcw-search-box
    *ngIf="appService.frameworkType == 'KCW'"
    [enabled]="enableKcwSearch$ | async"
    (kcwSearchClick)="onKcwSearchClick($event)"
    search-box
  >
  </alex-kcw-search-box>

  <div hidden-xs hidden-md combined-search-filters-desktop>
    <alex-combined-search-filters
      [sources]="sources"
      [countries]="countries"
      [workflowTypes]="workflowTypes"
      [versions]="versions"
      [isManualsSelected]="isManualsSelected$ | async"
      (sourcesChange)="onSourcesChange($event)"
      (countriesChange)="onCountryChange($event)"
      (workflowTypesChange)="onWorkflowTypesChange($event)"
      (versionsChange)="onVersionsChange($event)"
      (searchClick)="onSearchClick()"
      (resetClick)="onResetClick()"
      (click)="trackEvent($event)"
      *ngIf="showDropdown"
    ></alex-combined-search-filters>
  </div>
  <div d-lg-none combined-search-filters-mobile>
    <alex-combined-search-filters
      [sources]="sources"
      [countries]="countries"
      [workflowTypes]="workflowTypes"
      [versions]="versions"
      [isManualsSelected]="isManualsSelected$ | async"
      (sourcesChange)="onSourcesChange($event)"
      (countriesChange)="onCountryChange($event)"
      (workflowTypesChange)="onWorkflowTypesChange($event)"
      (versionsChange)="onVersionsChange($event)"
      (searchClick)="onSearchClick()"
      (resetClick)="onResetClick()"
      (click)="trackEvent($event)"
      *ngIf="showDropdown"
    ></alex-combined-search-filters>
  </div>

  <div class="float-right" logo-top-right>
    <svg
      version="1.1"
      id="Layer_1"
      class="alexLogo logoWidth greyColor2"
      width="80px"
      height="35px"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="-8 1 76 36"
      style=""
      xml:space="preserve"
    >
      <g>
        <path
          class=""
          d="M17.2,8.3l0-0.2h-2.1l-4.7,23.5h1.7l1.2-6.1h5.6l1.2,6.1h1.8L17.2,8.3z M18.6,24.1h-5l2.5-12.6L18.6,24.1z"
        />
        <rect x="29.8" y="8.2" class="" width="1.8" height="23.5" />
        <path
          class=""
          d="M49.7,21.8v-5c0-1.6-0.4-2.9-1.2-3.8c-0.8-0.9-2-1.3-3.5-1.3c-1.5,0-2.7,0.5-3.5,1.4
                    c-0.8,0.9-1.2,2.2-1.2,3.8v9.7c0,1.6,0.4,2.9,1.3,3.8c0.8,0.9,2.1,1.4,3.6,1.4c1.5,0,2.7-0.4,3.5-1.3c0.8-0.8,1.2-2.1,1.2-3.7
                    v-1.7H48V27c0,2.2-1,3.3-3,3.3c-1,0-1.8-0.3-2.3-0.9c-0.5-0.6-0.8-1.5-0.8-2.6v-4.9H49.7z M44.9,13.3c1,0,1.8,0.3,2.3,0.9
                    c0.5,0.6,0.8,1.5,0.8,2.6v3.6h-6.1v-3.6c0-1.1,0.3-2,0.8-2.6C43.2,13.6,43.9,13.3,44.9,13.3z"
        />
        <polygon
          class=""
          points="63.8,21.3 67.5,12 65.8,12 62.6,19.8 59.5,12 57.6,12 61.4,21.3 57.3,31.6 59.1,31.6 62.6,23 
                    65.8,31.6 67.6,31.6 			"
        />
      </g>
    </svg>
  </div>
  <alex-burger-menu
    taxonomy-burger-menu
    *ngIf="appService.frameworkType != 'KCW'"
  >
    <alex-header-taxonomy-tree burger-menu-icon [popupStatus]="popupStatus">
    </alex-header-taxonomy-tree>
  </alex-burger-menu>
  <alex-header-tabs header-tabs> </alex-header-tabs>
  <alex-header-user-profile-menu user-profile-menu>
  </alex-header-user-profile-menu>

  <alex-search-bar-mobile
    *ngIf="appService.frameworkType != 'KCW'"
    search-bar-mobile
    (allSourceClick)="allSourceDClick($event)"
    (searchClick)="onSearchClick()"
    [status]="status"
    [filterLabel]="isAllSourcesSelected ? 'All Sources' : 'Selected Sources'"
  >
  </alex-search-bar-mobile>
  <alex-kcw-search-box-mobile
    *ngIf="appService.frameworkType == 'KCW'"
    [enabled]="enableKcwSearch$ | async"
    (kcwSearchClick)="onKcwSearchClick($event)"
    search-bar-mobile
  >
  </alex-kcw-search-box-mobile>

  <!--Items for third row end-->
</alex-header-container>
