<!--for one column start-->
<li class="borderGrey clearfix"
 [ngClass]="{'forTwoColumn':columnCount==2,'forThreeColumn':columnCount==3}">
    <div class="hoverState col-md-12 col-sm-12 col-12">
        <div class="documentLeftSection"
          [ngClass]="leftSectionClass(columnCount)">
            <alex-document-space-header [type]="carouselItem?.docType"
             [mimeType]="carouselItem?.mimeType">
                <span class="spaceDocTooltip" leftIcon title="More actions">
                      <span (click)="showTooltip($event)" class="svgpadding">  
                        <svg class="svgIm OptionHorizontal greyColor3 " version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="38px" height="1px" viewBox="0 0 38 1" xml:space="preserve">
                            <g transform="scale(1.5)">
                                <g transform="translate(-336,-298)" xmlns="http://www.w3.org/2000/svg" id="option-horizontal">
                                    <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M344.5,298c0.828,0,1.5,0.672,1.5,1.5s-0.672,1.5-1.5,1.5
                                            s-1.5-0.672-1.5-1.5S343.672,298,344.5,298z M347,299.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5
                                            S347,298.672,347,299.5z M351,299.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5S351,298.672,351,299.5z"></path>
                                </g>
                            </g>	
                        </svg>
                      </span>
                        <div class="docTooltipTxt spaceTxt tooltipList" (click)="hideTootTip()" >
                            <ul>
                                <li data-toggle="modal" [attr.data-target]="'#addSpaceModalToolTip'" (click)="addDocToSpace()"  title="Add to Spaces">
                                    <span>
                                     <alex-svg-icon [icon]="'moveToFolder_space'" cssClass="spaceGrey"></alex-svg-icon>
                                    </span>
                                <div class="toolTiopListText">Add to Spaces</div>
                                </li>
                                <li data-toggle="modal" [attr.data-target]="'#shareModalToolTip'" (click)="share()"  title="Share">
                                    <alex-svg-icon [icon]="'shareDoc_spcae'"></alex-svg-icon>
                                    <div class="toolTiopListText">Share</div>
                                </li>
                                <li title="Preview" class="mobile-preview item-group-b" *ngIf="domainService.checkIsPreviewAllowed(carouselItem?.mimeType)!='other'" (click)="clicktoView()"> 
                                    <span>
                                                                <svg class="svgIm greyColor1" xmlns="http://www.w3.org/2000/svg" width="21" height="16"  viewBox="0 0 16 16" xml:space="preserve" style="
                                                                padding-left: 4px;
                                                                padding-right: 1px;
                                                                margin-right: 0px;
                                                                margin-left: 5px;
                                                            ">
                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" ></path></svg>
                                                                </span>
                                    <div class="toolTiopListText" style="padding-left: 11px;">Preview</div> 
                                </li>
                            </ul>
                        </div>
                    </span>
            </alex-document-space-header>
        </div>
        <div (click)="openDocument(carouselItem)" class=" documentMidSection" title="{{carouselItem?.docTitle}}"
        [ngClass]="middleSectionClass(columnCount)">
            <h3><p>{{carouselItem?.docTitle}}</p></h3>
        </div>
        <div class="documentRightSection"
        [ngClass]="rightSectionClass(columnCount)">
            <alex-tile-footer-location-date [data]="carouselItem" (locationClicked)="locationClicked($event)"></alex-tile-footer-location-date>
        </div>
    </div>
</li>



