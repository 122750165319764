import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";




if (environment.productionacre) {
  enableProdMode();
}
if (environment.productioneu) {
  enableProdMode();
}

// AppConfig.load().then(()=>{
//   platformBrowserDynamic().bootstrapModule(AppModule);
// });
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


/**
 * Import custom pollyfill
 */
if (!Array.prototype.includes) {
  Array.prototype.includes = function(value) {
    const returnValue = Array.prototype.indexOf.call(this, value) > -1;
    return returnValue;
  };
}

// $.fn.isOnScreen = function() {
//   var win: any = $(window);

//   var viewport: any = {
//     top: win.scrollTop(),
//     left: win.scrollLeft()
//   };
//   viewport.right = viewport.left + win.width();
//   viewport.bottom = viewport.top + win.height();

//   var bounds = this.offset();
//   bounds.right = bounds.left + this.outerWidth();
//   bounds.bottom = bounds.top + this.outerHeight() + 200;

//   return !(
//     viewport.right < bounds.left ||
//     viewport.left > bounds.right ||
//     viewport.bottom < bounds.top ||
//     viewport.top > bounds.bottom
//   );
// };
