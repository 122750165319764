<div class="menubar d-none d-lg-block">
    <ul>
        <li [routerLink]="['/home']" routerLinkActive="active menuActive">
            <svg class="svgIm greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22px"
                height="1px" viewBox="0 0 24 20" xml:space="preserve">
                <g transform="scale(1.8)">
                    <g transform="translate(-30,-56.6)" xmlns="http://www.w3.org/2000/svg" id="home">
                        <path id="path_26_" d="M41,60h1.75c0.138,0,0.17-0.08,0.073-0.177l-6.084-6.084c-0.097-0.097-0.257-0.098-0.355-0.002
			            l-6.206,6.087C30.08,59.921,30.112,60,30.25,60H32v5.75c0,0.138,0.112,0.25,0.25,0.25h2.5c0.138,0,0.25-0.112,0.25-0.25V62h3v3.75
			            c0,0.138,0.112,0.25,0.25,0.25h2.5c0.138,0,0.25-0.112,0.25-0.25V60z" />
                    </g>
                </g>
            </svg>
            <a class="" title="Home">{{appSearvice.frameworkType != 'KCW' ? 'Home' : 'Alex'}}</a>
        </li>
            
        <li [routerLink]="['/spaces']" routerLinkActive="active spaceMenuActive" *ngIf="appSearvice.frameworkType!='KCW'">
            <a class="" title="Spaces">
                <svg class="svgIm greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="20px" height="1px" viewBox="0 0 20 1" xml:space="preserve">
                    <g transform="scale(1.8)">
                        <g transform="translate(-486,-372.6)" xmlns="http://www.w3.org/2000/svg" id="cube-black">
                            <path d="M494,370.5v7c0,0.276-0.224,0.5-0.5,0.5h-7c-0.276,0-0.5-0.224-0.5-0.5v-7c0-0.276,0.224-0.5,0.5-0.5h7
			                C493.776,370,494,370.224,494,370.5z M496.25,366h-6.344c-0.276,0-0.668,0.148-0.875,0.33l-2.656,2.339
			                c-0.207,0.183-0.151,0.331,0.125,0.331h7.25c0.276,0,0.643-0.172,0.82-0.384l1.86-2.232C496.607,366.172,496.526,366,496.25,366z
			                 M497.675,366.88l-2.349,2.741c-0.18,0.21-0.325,0.604-0.325,0.88v7c0,0.276,0.146,0.33,0.325,0.12l0.837-0.977l1.512-1.764
			                c0.18-0.21,0.325-0.604,0.325-0.88v-7C498,366.724,497.854,366.67,497.675,366.88z" />
                        </g>
                    </g>
                </svg>
                Spaces</a>
        </li>
        <li (click)="clickHistory()" *ngIf="appSearvice.frameworkType!='KCW'">
            <svg class="svgIm greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                width="23px" height="1px" viewBox="0 0 25 1" xml:space="preserve">
                <g transform="scale(0.98)">
                    <g transform="translate(-396,-314.5)" xmlns="http://www.w3.org/2000/svg" id="history_1_">
                        <path d="M413.205,308.258l-3.268,3.27c0.037,0.153,0.062,0.314,0.062,0.479c0,1.105-0.895,2.006-2,2.006
			c-0.748,0-1.393-0.512-1.736-1.012H403.5c-0.275,0-0.5-0.249-0.5-0.523v-0.953c0-0.274,0.225-0.523,0.5-0.523h2.764
			c0.344-0.5,0.988-1.012,1.736-1.012c0.197,0,0.385,0.049,0.565,0.102l3.238-3.237c0.194-0.194,0.513-0.194,0.707,0l0.694,0.696
			C413.4,307.744,413.4,308.062,413.205,308.258z M419.791,314c0.115-1,0.197-1.334,0.197-2.018c0-6.618-5.383-12.01-12-12.01
			c-6.617,0-11.994,5.377-11.994,11.996c0,6.615,5.006,11.994,12.006,11.994v-0.002c2-0.006,4.509-0.643,6.527-1.961l0.057-0.039
			l1.476,2.046c0.162,0.224,0.361,0.189,0.442-0.073l2.205-6.439c0.082-0.263-0.077-0.48-0.353-0.486l-6.835,0.002
			c-0.276-0.006-0.37,0.174-0.21,0.396l1.515,2.12c-1.495,0.961-2.825,1.424-4.825,1.431v0.008c-5-0.001-9.008-4.03-9.006-9.001
			c0-4.971,4.027-9,8.998-9c4.97,0,8.998,4.048,8.998,9.02c0,0.688-0.092,1.018-0.24,2.018H419.791z" />
                    </g>
                </g>
            </svg>
            <a class="">History</a>
            <alex-recent-search *ngIf=(isVisible) [recentShow]="isVisible"></alex-recent-search>
            </li>
        
        <li [routerLink]="['/search']" [queryParams]="searchQueryParams" *ngIf="showSearch"
         routerLinkActive="active menuActive"
         [routerLinkActiveOptions]="{exact:false}">
            <svg class="svgIm greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                width="20px" height="1px" viewBox="0 0 28 1" xml:space="preserve">
                <g transform="scale(1.31)">
                    <g transform="translate(-399,-168.5)" xmlns="http://www.w3.org/2000/svg" id="search_1_">
                        <path d="M419.602,176.755l-5.878-5.879c0.956-1.482,1.52-3.24,1.52-5.132c0-5.235-4.262-9.494-9.496-9.494
			                        c-5.236,0-9.494,4.259-9.494,9.494c0,5.236,4.258,9.495,9.494,9.495c1.891,0,3.65-0.562,5.132-1.519l5.878,5.878
			                        c0.195,0.196,0.518,0.196,0.712,0l2.132-2.132C419.799,177.271,419.799,176.951,419.602,176.755z M399.27,165.744
			                        c0-3.571,2.906-6.479,6.478-6.479c3.573,0,6.479,2.907,6.479,6.479c0,3.573-2.905,6.48-6.479,6.48
			                        C402.176,172.224,399.27,169.317,399.27,165.744z" />
                    </g>
                </g>
            </svg> <a class="">Search</a></li>
    </ul>

</div>