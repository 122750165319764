import { AlexTaxonomyNodeModel } from "app/feature/search/model/filters/taxonomy-node.model";
import { AlexSearchFilterModel } from "app/feature/search/model/filters/search-filter.model";
import { AlexFilterTagModel } from "app/feature/search/search-result-tag/filter-tag.model";
import { Injectable } from "@angular/core";


@Injectable()
export class AlexSearchCacheService {
    private initialized: boolean;

    private topics: Array<AlexTaxonomyNodeModel>;
    private filter: AlexSearchFilterModel;
    private tags: Array<AlexFilterTagModel>;
    private sources: Array<AlexTaxonomyNodeModel>;

    constructor() {
        this.topics = new Array<AlexTaxonomyNodeModel>();
        this.filter = new AlexSearchFilterModel();
        this.tags = new Array<AlexFilterTagModel>();
        this.sources = new Array<AlexTaxonomyNodeModel>();
    }

    addTopics(items: Array<AlexTaxonomyNodeModel>) {
        if (this.topics) {
            items.forEach(item => {
                if (!this.topics.find(topic => topic.id == item.id)) {
                    this.topics.push(item);
                }
            });
        }
    }
    getTopic(id:string):AlexTaxonomyNodeModel {
        console.log('fetching topic from cache');
        return this.topics.find(topic=>topic.id == id);
    }
    clearTopics() {
        this.topics = new Array<AlexTaxonomyNodeModel>();
    }

    addFilter(searchFilter: AlexSearchFilterModel) {
        if (searchFilter) {
            this.filter = searchFilter;
        }
    }
    getFilter(): AlexSearchFilterModel {
        return this.filter;
    }
    addTags(searchTags: Array<AlexFilterTagModel>) {
        if (searchTags) {
           this.tags = searchTags;
        }
    }
    getTags(): Array<AlexFilterTagModel> {
        return this.tags;
    }
    addSource(sourceFilter: Array<AlexTaxonomyNodeModel>) {
        if (sourceFilter) {
           this.sources = sourceFilter;
        }
    }
    getSource(): Array<AlexTaxonomyNodeModel>{
        return this.sources;
    }

}