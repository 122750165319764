import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { CountrySelection } from "app/feature/country-selection/country-selection.model";
import { CountrySelectionService } from "app/feature/country-selection/country-selection.service";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AlexNavigationService, AlexUserService } from "app/core/services";
import { AlexRouteStatus } from "app/core/services/navigation/route-status.model";
import { Router, ActivatedRoute } from "@angular/router";
import { ModalContentService } from "app/shared/modal-content.service";

@Component({
  selector: "alex-country-selection",
  templateUrl: "./country-selection.component.html",
  styleUrls: ["./country-selection.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CountrySelectionComponent implements OnInit {
  country: CountrySelection;
  @Input() countryList: Array<CountrySelection> = [];
  index: number;
  disableSub: boolean;
  alexRouterStatus: AlexRouteStatus;
  @ViewChild("addCountryModal") addCountryModal: TemplateRef<any>;

  constructor(
    private countrySelectionService: CountrySelectionService,
    private alexNavigationService: AlexNavigationService,
    private router: Router,
    private userService: AlexUserService,
    private modalContentService: ModalContentService
  ) {
    this.countrySelectionService.countryList.subscribe((result) => {
      this.countryList = result;
      this.disableSub = result.filter((item) => item.isSelected).length == 0;
    });
  }

  ngOnInit() {
    this.getCountryList();
  }
  ngAfterViewInit() {
    this.userService.isUserCountryLoaded.subscribe((countrySelected) => {
      if (countrySelected == true) {
        this.modalContentService.openModal(this.addCountryModal);
      }
    });
  }
  getCountryList() {
    this.countryList = new Array<CountrySelection>();
    this.countrySelectionService.getCountries();
  }

  updateCountrySelection($event) {
    this.countryList.forEach((item) => {
      item.order = this.countryList.indexOf(item);
    });
    this.countrySelectionService
      .saveCountries(this.countryList)
      .subscribe((result) => {
        this.alexRouterStatus = this.alexNavigationService.getActiveRoute();
        var activeUrl = this.alexRouterStatus.routeUrl;
        const regexReplacedomainPath = /(&d=(.*)[&]?)/g;
        var matchData = regexReplacedomainPath.exec(activeUrl);
        if (matchData && matchData.length > 0) {
          let domainPath = activeUrl ? matchData[0] : "";
          if (domainPath && domainPath.length > 0) {
            let selectedDomain = [];
            var selectedCountry = this.countryList
              .filter((item) => item.isSelected == true)
              .forEach((item) => {
                selectedDomain.push(item.id);
              });
            domainPath = "&d=" + selectedDomain.join(",");
            activeUrl = activeUrl.replace(
              regexReplacedomainPath,
              `${domainPath}`
            );
            this.userService.setUserDomain(selectedDomain);
            this.router.navigateByUrl(activeUrl);
            this.countrySelectionService.countryList.subscribe((result) => {
              this.countryList = result;
              this.disableSub =
                result.filter((item) => item.isSelected).length == 0;
            });
          }
          this.reset();
        } else {
          window.location.reload();
        }
      });
  }

  onCheckboxChanged($event) {
    if ($event.target.checked && this.disableSub) {
      this.disableSub = false;
    } else if (!$event.target.checked) {
      if (this.countryList.filter((item) => item.isSelected).length == 0) {
        this.disableSub = true;
      }
    }
  }
  reset() {
    this.modalContentService.resetModal();
  }
}
