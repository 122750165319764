import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let accessTokenKey = null;
    let idTokenKey = null;
    let itemArr =[];
    let sessionStorageItems = {...sessionStorage};
    Object.keys(sessionStorageItems).forEach(item => {
       itemArr = item.split('-');
      if(itemArr.includes('accesstoken')){
        accessTokenKey = item
      }
      if(itemArr.includes('idtoken')){
        idTokenKey = item
      }
    })

    let accessToken = JSON.parse(sessionStorage.getItem(accessTokenKey));
    let idToken = JSON.parse(sessionStorage.getItem(idTokenKey));
   
    let tokenizedReq = request.clone({
      setHeaders: {
        Authorization: 'Bearer ' +  accessToken?.secret,
        IdToken: 'Bearer ' + idToken?.secret
      }
    })
    return next.handle(tokenizedReq);
  }
}
