import { AlexSourceFilterModel } from "app/feature/search/model/filters/source-filter.model";
import { AlexCheckBoxModel } from "app/feature/search/model/filters/checkbox.model";
import { AlexDateFilterModel } from "app/feature/search/model/filters/date-filter.model";
import { AlexTaxonomyNodeModel } from "app/feature/search/model/filters/taxonomy-node.model";
import { AlexSeeAllResultFilterModel } from "app/feature/search/model/filters/see-all-result-fiter.model";
import { AlexFilterBaseModel } from "app/feature/search/model/filters/filter-base.model";
import { SearchResultViewMode } from "app/feature/search/enums/alex-search-enums";
import { CombinedSearchQueryType } from "../../types";

export class AlexSearchFilterModel {
  q: string;
  sources: Array<AlexSourceFilterModel>;
  topics: Array<AlexFilterBaseModel>;
  //Add for Other Category
  otherCategories: Array<AlexFilterBaseModel>;
  //Add for Country
  countries: Array<AlexFilterBaseModel>;
  fileTypes: Array<AlexFilterBaseModel>;
  languages: Array<AlexFilterBaseModel>;
  date: AlexDateFilterModel;
  sort: string;
  allSource = true;
  seeAllResult: AlexSeeAllResultFilterModel;
  mxo: number;
  viewMode: SearchResultViewMode;
  //Added for combined search
  applicableCountries: Array<AlexFilterBaseModel>;
  workflowType: Array<AlexFilterBaseModel>;
  version: Array<AlexFilterBaseModel>;
  combinedSearchType: CombinedSearchQueryType;
  isManuals: boolean = false;
  tocCountry: string;
  tocWorkflow: string;
  tocVersion: string;
}
