import { AlexFeaturedContent } from "app/feature/search/model/content/featured-content.model";
import { AlexSearchContent } from "app/feature/search/model/content/search-content.model";
import { AlexTopicOverviewContent } from "app/feature/search/model/content/topic-overview-content.model";

export class AlexSearchResult {
  name: string;
  count: number;
  topicOverviewContent: AlexTopicOverviewContent;
  featuredContent: AlexFeaturedContent;
  searchContent: AlexSearchContent;
  manualsSearchContent: AlexSearchContent;
  errorMessage: string;
  errorCode: string;
  combinedPerPageCount: number;
  combinedTotalCount: number;
}
