<alex-notification *ngIf="isNotificationVisible"></alex-notification>
<div
  *ngIf="(pageLayout$.pageLayoutChange$ | async)?.isHeaderVisible"
  [@slideTopContainer]="_slideTopContainer"
  #topContainer
  class="col-sm-12 topContainer affix headerFixed clearfix"
>
  <ng-content select="alex-header"> </ng-content>
</div>
<div
  *ngIf="(pageLayout$.pageLayoutChange$ | async)?.isBodyVisible"
  class="row contArea"
>
  <ng-content select="[content-section]"> </ng-content>
</div>
<footer *ngIf="(pageLayout$.pageLayoutChange$ | async)?.isFooterVisible">
  <ng-content select="alex-footer"> </ng-content>
</footer>
