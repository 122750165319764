<div class="btn-group" dropdown>
 <!-- <button type="button" class="userDropdown" data-toggle="dropdown" title="User Settings"> -->
<button type="button" class="userDropdown" dropdownToggle title="User Settings">
           <!--<span class="badge">5</span>-->
            
                <svg class="svgIm greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                   height="1px" viewBox="0 0 22 1"  xml:space="preserve">
              <g transform="scale(0.9)">
              <g transform="translate(-205,-73.5)" xmlns="http://www.w3.org/2000/svg" id="user_1_">
                  <path id="user_2_" d="M227,78.208c-0.644-0.366-4.843-2.851-7-3.724v-3.777c0.624-0.456,1-1.158,1-2.223V65.5
			c0-2.475-1.5-4.5-4.5-4.5s-4.5,2.025-4.5,4.5c0,0,0,0.484,0,2.984c0,1.075,0.376,1.779,1,2.233v3.767
			c-2.032,0.873-6.356,3.357-7,3.724c-0.801,0.455-1,0.888-1,1.94c0,0.43,0,2.352,0,2.352c0,0.275,0.225,0.5,0.5,0.5h22
			c0.275,0,0.5-0.225,0.5-0.5c0,0,0-1.922,0-2.352C228,79.096,227.801,78.663,227,78.208z" />
	                </g>
	            </g>	
            </svg>

            {{user?.firstName +" "+user?.lastName}} 
            <label class="caretSection">
              <span class="caret"></span>
            </label>
          </button>
          <ul class="dropdown-menu userMenu dropPosition" *dropdownMenu data-display="static" role="menu">
            <li *ngIf="IsAuthorizedUser && appService.frameworkType!='KCW'" (click)="onCountrySelection()">
              <a role="button">
              Country/Region/Jurisdiction Selection
              </a>
            </li>
            <li *ngIf="user?.isExternalUser && IsAuthorizedUser" 
              (click)="openExtUserProfile()"><a>User Profile</a></li>
            <li *ngIf="user?.isExternalUser" (click)="openManageAcc()"><a>Manage account</a></li>
            <li (click)="onLogout()" ><a>Log out</a></li>
          </ul>         

        </div>

