export const SubscriptionConstant = {
    // SUBSCRIPTION_EXPIARY_MSG: "Your Subscription will expire in @noOfDays days.",
    // TRIAL_USER_MSG: "You are Currently Logged in as Trial User.",
    // TRIAL_EXPIARY_MSG: "You have @noOfDays left on your trial.",
    // RENEWAL_MSG: "Please contact your Account Manager for renewal.",
    // NOT_LOGGED_OUT: "Previous session was not properly logged out.",
    // SESSION_TIMEOUT: "Session Time out.", 
    // CONCURRENT_USER: "License is in use.",
    // DUPLICATE_USER: "User already logged in from another machine/different browser.",

    popupType:{
        NO_POPUP : "No Popup",
        USER_TRIAL_POPUP : "ShowUserTrialPopup",
        USER_RENEW_POPUP : "ShowUserRenewPopup",
        PAM_USER_TRIAL_POPUP : "ShowPamTrialPopup",
        PAM_USER_RENEW_POPUP : "ShowPamRenewPopup"
    }

}