import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";
import { Download } from 'app/shared/download';
import { DownloadService } from 'app/shared/download.service';
import { AlexNotificationService } from "app/shared/components/notification/notification.service";
import { Status } from "app/core/services";

@Injectable()
export class AlexFileService {
    spinnerId: any;
    constructor(private http: HttpClient, private spinnerService: SpinnerService, private downloads: DownloadService, private notificationService: AlexNotificationService) {
    }
    
    downloadOrOpenFile(fileName: string, binaryContent: any, mimeType: string, isContentArrayBuffer: boolean = false): void {
        this.spinnerId = this.spinnerService.showSpinner();
        if (this.spinnerId != undefined && this.spinnerId != '' && this.spinnerId != null) {
            this.spinnerService.hideSpinner(this.spinnerId);
        }
        else {
            this.spinnerService.hideSpinner(0, true);
        }
        var blob = null;
        if (!isContentArrayBuffer) {
            blob = new Blob([this.base64ToArrayBuffer(binaryContent)], { type: mimeType }); // pass a useful mime type here        
        } else {
            blob = new Blob([binaryContent], { type: mimeType }); // pass a useful mime type here        
        }
        let obj = { name: fileName, url: URL.createObjectURL(blob), showBar: false };
        let fileDownload = this.notificationService.DownloadStatus.subscribe(result => {
            if (!result) {
                obj.showBar = true;
                this.download(obj);
            }
            else {
                let anchor = document.createElement('a');
                anchor.href = URL.createObjectURL(blob);
                anchor.download = fileName;
                anchor.dispatchEvent(new MouseEvent('click'));
                anchor.remove();
                anchor = null;
                setTimeout(() => {
                    this.notificationService.show(
                        Status.Success,
                        "Document downloaded successfully",
                        true
                    );
                }, 1000);
            }
        });
        fileDownload.unsubscribe();
    }
    downloadOrOpenFileUrl(fileUrl: string): void {
        this.spinnerId = this.spinnerService.showSpinner();
        if (this.spinnerId != undefined && this.spinnerId != '' && this.spinnerId != null) {
            this.spinnerService.hideSpinner(this.spinnerId);
        }
        else {
            this.spinnerService.hideSpinner(0, true);
        }

        let obj = { name: /(?:.*)\/(.*)\./.exec(fileUrl)[1], url: fileUrl, showBar: false };

        let fileDownload = this.notificationService.DownloadStatus.subscribe(result => {
            // console.log("download in progress", result);
            if (!result) {
                obj.showBar = true;
                this.download(obj);
            }
            else {

                try {
                    this.http.get(fileUrl, { responseType: 'blob' }).subscribe(data => {
                        const blob = new Blob([data], { type: data.type });
                        const url = window.URL.createObjectURL(blob);
                        let anchor = document.createElement('a');
                        anchor.href = url;
                        anchor.download = /(?:.*)\/(.*)\./.exec(fileUrl)[1];
                        document.body.appendChild(anchor);
                        anchor.click();
                        anchor.remove();
                        anchor = null;
                        setTimeout(() => {
                            this.notificationService.show(
                                Status.Success,
                                "Document downloaded successfully",
                                true
                            );
                        }, 1000);
                    });
                } catch (error) {
                    console.error(error);
                }
            }
        });
        fileDownload.unsubscribe();
    }

    private base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    download({ name, url, showBar }: { name: string, url: string, showBar: boolean }) {
        // this.download$ = this.downloads.download(url, name);
        // console.log("state of download",this.download$);   
        this.downloads.download(url, name).subscribe(result => {
            if (result.state == "DONE") {
                if (showBar) {
                    this.notificationService.show(
                        Status.Success,
                        "",
                        false,
                        true
                    );
                }
                this.notificationService.SetDownloadProgressVal(result, true);
                setTimeout(() => {
                    this.notificationService.show(
                        Status.Success,
                        // "Document "+name+" downloaded successfully",
                        "Document downloaded successfully",
                        true
                    );
                }, 3000);
            }
            else if (result.state == "IN_PROGRESS" || result.state == "PENDING") {
                if (result.progress > 0 && showBar) {
                    this.notificationService.show(
                        Status.Success,
                        "",
                        false,
                        true
                    );
                    this.notificationService.SetDownloadProgressVal(result, false);
                }
            }
            else {
                this.notificationService.show(
                    Status.Failure,
                    "No Document Found",
                    true
                );
            }
        })
    }
}
