<alex-tile-container (click)="onTileClick($event,item)" title="{{item?.title}}">

  <div class="headerLeft" headerLeft>
    <alex-svg-icon icon="spaceIcon" cssClass="leftSpaceIcon"></alex-svg-icon>
    <!--<span class="badge spaceBadge ">5</span>-->
  </div>
  <div class="spaceTooltip" headerRight *ngIf="showMoreAction" (click)="setshowTooltip($event)">
      
      <!--<alex-fly-out [flyOutOptions]="flyOutOptions" [itemId]="item?.id" (editItemTitle)="editItemTitle($event)"
                    (deleteItem)="deleteItem($event)"></alex-fly-out>-->

    <alex-svg-icon icon="tileType2Ellipsis" cssClass="rightSpaceIcon" title="More actions" ></alex-svg-icon>
    <div class="spaceTooltipTxt spaceTxt tooltipList" *ngIf="showTooltip">
      <ul >
        <li (click)="editItemTitle(item?.id)" title="Edit Title">
          <alex-svg-icon [icon]="'EditSpaceTitle'" ></alex-svg-icon>
          <div class="toolTiopListText" >Edit Title</div>
        </li>
        <li (click)="deleteItem(item?.id)" title="Delete Space">
          <alex-svg-icon [icon]="'deleteSpace'"></alex-svg-icon>
          <div class="toolTiopListText" >Delete Space</div>
        </li>
      </ul>
    </div>



  </div>
  <div title="{{item?.title}}" class="spaceHdingPadding spaceHdingTxt" title>
    <h3>{{item?.title}}</h3>
  </div>
  <div class="spaceBottomSection" footerLeft>
    <div class="spaceBottomLeftIcon">
      <alex-svg-icon icon="documentIcon"></alex-svg-icon>
      <span>{{item?.documentCount}}</span>
    </div>
  </div>

</alex-tile-container>