import { Component, OnInit } from '@angular/core';
import { AppConfig } from "app/app.config";

@Component({
  selector: 'alex-feedback-link',
  templateUrl: './feedback-link.component.html',
  styleUrls: ['./feedback-link.component.scss']
})
export class FeedbackLinkComponent implements OnInit {
  url:string;

  constructor(
    private appConfig: AppConfig

  ) { }

  ngOnInit(): void {
    const appConfigInstance = AppConfig.getInstance();;
    this.url = appConfigInstance["contextualBarFeedback"];
  }

}
