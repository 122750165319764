import { SafeHtml } from "@angular/platform-browser";

export class AlexDocumentModel {
  contentRef: string;
  referenceId: string;
  documentName: string;
  parentTitle:string;
  docPath: string;
  createdDate: string;
  stylesheet: string;
  binaryPath: string;
  binaryContent: string;
  fileName: string;
  secordPos: string;
  previousDocId: string;
  previousSecordDocId: string;
  nextDocId: string;
  nextSecordDocId: string;
  htmlContentRef: SafeHtml;
  topicName: string;
  //htmlContent:string;
  //docPath:string;
  mimeType: string;
  taxonomyIds: [string];
  topicTaxonomyIDs:[string];
  sourceTaxonomyIds:[string];
  parentId: string;
  maxSecordPos:string;
  minSecordPos:string;
  tocRef:string;
  killDocId:string;
  showSpinner: boolean;
  message:string;
}