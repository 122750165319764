import { SearchResultViewMode } from "app/feature/search/enums/alex-search-enums";
import { CKDSeeAllResultFilterModel } from "./ckd-see-all-result-fiter.model";

export class CKDSearchFilterModel {
  q: string;
  country: string;
  workflow: Array<string>;
  validWorkFlowTypes: Array<string>;
  sort: string;
  mxo: number;
  viewMode: SearchResultViewMode;
  seeAllResult: CKDSeeAllResultFilterModel;
  versions: Array<string>;
  validVersions: Array<string>;
}
