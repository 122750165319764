import { Component, OnInit, Input } from '@angular/core';
import { HeaderTabsService } from "app/feature/header/tabs/header-tabs.service";
import { AlexAppService } from 'app/core/services';


@Component({
  selector: 'alex-header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss']
})
export class AlexHeaderTabsComponent implements OnInit {
  @Input() showSearch: boolean;
  searchQueryParams: any;
  isVisible = false;
  constructor(private headerTabs: HeaderTabsService, public appSearvice:AlexAppService) { }

  ngOnInit() {
    this.headerTabs.tabSource.subscribe(tab => {
      this.showSearch = tab.showSearch;
      this.searchQueryParams = tab.searchQueryParams || {};
    })
  }

  clickHistory() {
    this.isVisible = true;

  }

}
