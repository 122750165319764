import { AlexPaginationEventModel } from "app/shared/components/pagination/pagination.event.model";
import { AlexPaginationActions } from "app/shared/components/pagination/pagination.actions";
import { AlexPaginationModel } from "app/shared/components/pagination/pagination.model";
import { Injectable } from "@angular/core";

@Injectable()
export class AlexPaginationService {
  updatePaginationModel(
    paginationModel: AlexPaginationModel,
    itemsPerPage: number,
    resultCount: number
  ): AlexPaginationModel {
    paginationModel.endPage = Math.ceil(resultCount / itemsPerPage);
    if (paginationModel.endPage > 1) {
      paginationModel.isRequired = true;
      paginationModel.itemsPerPage = itemsPerPage;
      paginationModel.recordCount = resultCount;
      paginationModel.pageNumberDisplayList = new Array<number>();

      const { currentPage, endPage } = paginationModel;
      let startPage = 1;

      if (endPage > 9) {
        if (endPage - currentPage < 9) {
          startPage = endPage - 8;
        } else if (currentPage > 3) {
          startPage = currentPage - 3;
        }
      } else {
        startPage = 1;
      }

      paginationModel.startPage = startPage;

      const counter = paginationModel.endPage < 9 ? paginationModel.endPage : 9;

      for (var index = 0; index < counter; index++) {
        paginationModel.pageNumberDisplayList.push(
          paginationModel.startPage + index
        );
      }
    } else {
      paginationModel.isRequired = false;
    }
    paginationModel.lastVisitedPage = paginationModel.currentPage;
    return paginationModel;
  }
  handlePaginationEvent(
    paginationEventModel: AlexPaginationEventModel
  ): AlexPaginationModel {
    let paginationModel = paginationEventModel.data;

    if (paginationEventModel.action == AlexPaginationActions.PAGE_CHANGE) {
      paginationModel = this.onPageNumberChange(paginationEventModel);
    } else if (
      paginationEventModel.action == AlexPaginationActions.NEXT_PAGE_CLICK
    ) {
      paginationModel = this.onNextPageClick(paginationEventModel);
    } else if (
      paginationEventModel.action == AlexPaginationActions.PREV_PAGE_CLICK
    ) {
      paginationModel = this.onPrevPageClick(paginationEventModel);
    }
    return paginationModel;
  }

  private onPageNumberChange(
    paginationEventModel: AlexPaginationEventModel
  ): AlexPaginationModel {
    let paginationModel = paginationEventModel.data;
    paginationModel.currentPage = paginationEventModel.additionalData;
    return paginationModel;
  }

  private onNextPageClick(
    paginationEventModel: AlexPaginationEventModel
  ): AlexPaginationModel {
    let paginationModel = paginationEventModel.data;
    if (paginationModel.currentPage + 1 <= paginationModel.endPage) {
      paginationModel.currentPage += 1;
    }
    return paginationModel;
  }

  private onPrevPageClick(
    paginationEventModel: AlexPaginationEventModel
  ): AlexPaginationModel {
    let paginationModel = paginationEventModel.data;
    if (paginationModel.currentPage - 1 >= 1) {
      paginationModel.currentPage -= 1;
    }
    return paginationModel;
  }
}
