import { TocSectionSearchState } from "app/feature/long-form-content/toc-section-search/toc-section-search-state.model";
import { Injectable } from "@angular/core";

@Injectable()
export class TocSectionSearchStateManagerService {
    stateId = -1;
    states = new Array<TocSectionSearchState>();

    addNewState(state: TocSectionSearchState): TocSectionSearchState {
        state.id = ++this.stateId;
        this.states.splice(0, 0, state);
        return state;
    }
    clearState() {
        this.states = new Array<TocSectionSearchState>();
    }
    hasAnyState() : boolean {
        return this.states.length > 0;
    }
    hasAnyActiveState() : boolean{
      return  !!this.getActiveState();
    }
    getLastResolvedState() : TocSectionSearchState{
      return this.states.find(state => state.isActive && state.isResolved) ;
    }
    getActiveState() : TocSectionSearchState {
       return this.states.find(state => state.isActive && !state.isResolved);
    }
    updateState(state: TocSectionSearchState) {
        if (state.id < this.states.length) {
            this.states[state.id] = state;
        }
    }
}