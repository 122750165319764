import {
  throwError as observableThrowError,
  Observable,
  throwError,
  asyncScheduler,
  scheduled,
  of,
} from "rxjs";

import { tap, map, switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { QueryCriteria } from "app/shared/components/carousel/model/carousel-query-criteria.model";
import { AlexUrlResolverService, AlexUserService } from "app/core/services";

import { CarouselQuery } from "app/shared/components/carousel/model/carousel-query.model";
import { SearchModel } from "app/feature/search/model/search-service-model";
import { Search } from "app/feature/search/model/search.model";
import { SearchParamService } from "app/feature/search/services/search-param.service";
import { AlexSearchFilterModel } from "app/feature/search/model/filters/search-filter.model";
import { AlexDocumentEventModel } from "app/feature/search/model/document-event.model";
import { Router } from "@angular/router";
import { AlexTaxonomyNodeModel } from "app/feature/search/model/filters/taxonomy-node.model";
import { AlexSearchCacheService } from "app/feature/search/services/search-cache.service";
import { RecentDocument } from "app/feature/header/recent-search/recent-document.model";
import { RecentSearch } from "app/feature/header/recent-search/recent-search.model";
import { AlexSearchViewModelService } from "app/feature/search/services/search-view-model.service";
import { AlexSearchResult } from "app/feature/search/model/content/search-result.model";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";
import { replaceQuestionMark } from "app/shared/utils";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CombinedSearchFiltersService } from "app/feature/header/services";
import { ICombinedSearchConfig } from "../interfaces";
import {
  CombinedSearchCategoryType,
  combinedSearchQueryCategories,
  CombinedSearchQueryType,
} from "../types";
import { CKDDocumentEventModel } from "app/feature/ckd-search/models/ckd-document-event.model";
import { CKDSearchItemModel } from "app/feature/ckd-search/models/ckd-search-item.model";
import {
  REMOVE_TOC_REF_FILTER,
  REMOVE_VERSION_FILTER,
  SEE_ALL_RESULT,
  VERSION_FILTER,
} from "../ACTIONS/search-action-types";
import { AlexPaginationActions } from "app/shared/components/pagination/pagination.actions";
import { AlexPaginationEventModel } from "app/shared/components/pagination/pagination.event.model";
import { AlexPaginationService } from "app/shared/components/pagination/pagination.service";
import {
  CKDSearchResultClickTrackingData,
  CkdSearchResultClickTrackingModel,
} from "app/shared/models/tracking/ckd-search";
import { TrackingService } from "app/shared/services/tracking";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  recentDoc: RecentDocument;
  private _search: Search;
  get search() {
    return this._search || new Search();
  }
  set search(value: Search) {
    this._search = value;
  }

  manualConfig: any;
  combinedSearchConfig: ICombinedSearchConfig;
  combinedSearchCategoryByQueryTypeMap: Map<
    CombinedSearchQueryType,
    CombinedSearchCategoryType[]
  > = new Map<CombinedSearchQueryType, CombinedSearchCategoryType[]>();
  showSeeMore: boolean = true;

  private allResultsCache: Search = null;
  private specificResultsCache: Search = null;

  constructor(
    private http: HttpClient,
    private urlResolver: AlexUrlResolverService,
    private searchParamService: SearchParamService,
    private router: Router,
    private searchViewModelService: AlexSearchViewModelService,
    private searchCacheService: AlexSearchCacheService,
    private userService: AlexUserService,
    private spinnerService: SpinnerService,
    private combinedSearchFiltersService: CombinedSearchFiltersService,
    private paginationService: AlexPaginationService,
    private trackingService: TrackingService
  ) {
    this.combinedSearchConfig = this.urlResolver.getCombinedSearchConfig();
    this.manualConfig = this.urlResolver.getCKDManualsConfig() || {};
  }

  getSearchResult(): Observable<Search> {
    return this.http
      .post<any>(
        this.urlResolver.resolveUrlApiV1(`search/SearchData`),
        this.getSearchRequestModel()
      )
      .pipe(
        map((response) => {
          return response() as Search;
        }),
        tap((searchresult) => {
          this.search = searchresult;
          try {
            this.updateTopicCache(
              (<any>searchresult.searchFilterModel).taxonomyTopicFilterSearch
            );
          } catch (error) {
            console.log("unable to update topics cache");
          }
        })
      );
  }

  private updateTopicCache(topicFilter: any) {
    if (topicFilter && topicFilter.nodes && topicFilter.nodes.length) {
      const topics = topicFilter.nodes as Array<AlexTaxonomyNodeModel>;
      if (topics) {
        this.searchCacheService.addTopics(
          this.searchViewModelService.taxonomyTreeToFlatArray(topics)
        );
      }
    }
  }

  private getSearchRequestModel(fromBridge:boolean=false): SearchModel {
    const searchReqModel = new SearchModel();
    const filter = this.searchParamService.getFilterData();
    const pagination = this.searchViewModelService.getPaginationData();
    // this.searchParam.parentDocName = "";
    let carouselQuery = new CarouselQuery();
    carouselQuery.activeDomains = this.userService.getActiveDomain();
    carouselQuery.criteria = new QueryCriteria(
      "",
      "",
      "",
      filter.q,
      this.searchParamService.searchFor,
      "",
      [],
      "",
      "",
      "",
      filter.sources,
      [],
      this.searchParamService.typedTerm,
      this.searchParamService.suggestionLength
    );
    carouselQuery.criteria.startPage = pagination.currentPage;
    carouselQuery.criteria.endPage = pagination.currentPage;
    if (filter.date) {
      carouselQuery.criteria.toDate = filter.date.to;
      carouselQuery.criteria.fromDate = filter.date.from;
    }

    carouselQuery.criteria.languageNames =
      filter.languages && filter.languages.map((lang) => lang.k);
    carouselQuery.criteria.extensions =
      filter.fileTypes && filter.fileTypes.map((fileType) => fileType.k);
    carouselQuery.sortOrder = filter.sort;
    carouselQuery.criteria.allFilter = this.searchParamService.shouldLoadAllFilter;
    carouselQuery.criteria.sourceFilters = filter.sources;
    carouselQuery.criteria.allSourceChecked = filter.allSource;
    carouselQuery.criteria.topics =
      filter.topics && filter.topics.map((topic) => topic.k);
    carouselQuery.criteria.otherCategory =
      filter.otherCategories && filter.otherCategories.map((other) => other.k);
    //added for country
    carouselQuery.criteria.country =
      filter.countries && filter.countries.map((country) => country.k);
    // this.search.typeAheadModel = this.searchRequest;
    //added for combined search
    carouselQuery.criteria.applicableCountries =
      filter.applicableCountries &&
      filter.applicableCountries.map((appCountry) => appCountry.k);
    carouselQuery.criteria.auditingStandards =
      filter.workflowType &&
      filter.workflowType.map((audStndrd) => audStndrd.k);
    carouselQuery.criteria.versions =
      filter.version && filter.version.map((version) => version.k);

    const newSearchQueryTerm = replaceQuestionMark(filter.q);
    searchReqModel.queryItemModel = carouselQuery;
    searchReqModel.queryItemModel.criteria.searchTerm = encodeURIComponent(
      newSearchQueryTerm
    );
    const tags = this.searchViewModelService.getTags();
    if (tags && tags.length) {
      searchReqModel.tags = tags.map((item) => item.key);
    }

    const {
      alex,
      topics,
      featured,
      manuals,
    } = this.combinedSearchConfig.categoryTypes;

    const {
      topic,
      featured: queryFeatured,
      alex: queryAlex,
      kaeg,
    } = combinedSearchQueryCategories;

    const {
      allSource,
      combinedSearchType,
      sources,
      applicableCountries,
      workflowType,
      version,
      isManuals,
    } = filter;

    const considerKaeg = isManuals && this.isKaegFiltersAvailable();

    const {
      countries,
      workflowTypes,
      versions,
    } = this.combinedSearchFiltersService.getAvailableFilters();

    const isFirstPage = !combinedSearchType;

    let applicableSearchTypesList: CombinedSearchCategoryType[] = [
      alex,
      topics,
      considerKaeg
        ? !allSource && (sources || []).length === 1
          ? featured.manuals
          : featured.both
        : featured.alex,
    ];

    if (considerKaeg) {
      applicableSearchTypesList = [...applicableSearchTypesList, manuals];

      if (!allSource && sources.length === 1) {
        applicableSearchTypesList = [featured.manuals, manuals];
      }

      if (
        !Array.isArray(applicableCountries) ||
        applicableCountries.length === 0
      ) {
        searchReqModel.queryItemModel.criteria = {
          ...searchReqModel.queryItemModel.criteria,
          applicableCountries: countries,
        };
      }

      if (!Array.isArray(workflowType) || workflowType.length === 0) {
        searchReqModel.queryItemModel.criteria = {
          ...searchReqModel.queryItemModel.criteria,
          auditingStandards: workflowTypes,
        };
      }

      if (!Array.isArray(version) || version.length === 0) {
        searchReqModel.queryItemModel.criteria = {
          ...searchReqModel.queryItemModel.criteria,
          versions,
        };
      }
    }

    if (!isFirstPage) {
      // search type will be one of alex , topics, all featured, kaeg
      this.combinedSearchCategoryByQueryTypeMap.clear();

      this.combinedSearchCategoryByQueryTypeMap.set(topic, [topics]);
      this.combinedSearchCategoryByQueryTypeMap.set(queryAlex, [alex]);
      this.combinedSearchCategoryByQueryTypeMap.set(kaeg, [manuals]);

      if (considerKaeg) {
        this.combinedSearchCategoryByQueryTypeMap.set(queryFeatured, [
          !allSource && sources.length === 1 ? featured.manuals : featured.both,
        ]);
      } else {
        this.combinedSearchCategoryByQueryTypeMap.set(queryFeatured, [
          featured.alex,
        ]);
      }

      applicableSearchTypesList = this.combinedSearchCategoryByQueryTypeMap.get(
        combinedSearchType
      );
    }

    if (!allSource && isManuals) {
      const sourcesExcludingKaeg = (sources || []).filter((s) => s.k !== "0");
      const parentTaxonomyIdsExcludingKaeg = searchReqModel.queryItemModel.criteria.parentTaxonomyIds.filter(
        (pti) => pti.k !== "0"
      );
      searchReqModel.queryItemModel.criteria = {
        ...searchReqModel.queryItemModel.criteria,
        sourceFilters: sourcesExcludingKaeg,
        parentTaxonomyIds: parentTaxonomyIdsExcludingKaeg,
      };
      searchReqModel.tags = (searchReqModel.tags || []).filter(
        (t) => t !== "0"
      );
    }

    if(fromBridge){
      const index = applicableSearchTypesList.indexOf("KAEG");
      if (index > -1) { 
        applicableSearchTypesList.splice(index, 1); 
      }
    }
    
    searchReqModel.queryItemModel.searchType = applicableSearchTypesList.join(
      "&"
    );

    return searchReqModel;
  }

  isKaegFiltersAvailable(): boolean {
    const {
      countries,
      workflowTypes,
      versions,
    } = this.combinedSearchFiltersService.getAvailableFilters();

    const kaegFiltersPresent =
      Array.isArray(countries) &&
      countries.length > 0 &&
      Array.isArray(workflowTypes) &&
      workflowTypes.length > 0 &&
      Array.isArray(versions) &&
      versions.length > 0;

    return kaegFiltersPresent;
  }

  getSeeAllResultRequestModel() {
    const payload = this.getSearchRequestModel();

    const {
      seeAllResult,
      tocCountry,
      tocWorkflow,
      tocVersion,
      combinedSearchType,
    } = this.searchParamService.getFilterData();

    if (combinedSearchType === "kaeg") {
      payload.queryItemModel.criteria = {
        ...payload.queryItemModel.criteria,
        applicableCountries: [tocCountry],
        auditingStandards: [tocWorkflow],
        versions: [tocVersion],
      };
    }

    payload.queryItemModel.criteria.allFilter = true;
    payload.queryItemModel.criteria.docId = seeAllResult.docId;
    payload.queryItemModel.criteria.parentId = seeAllResult.parentDocId;
    payload.queryItemModel.criteria.tocRef = seeAllResult.tocRef;
    payload.queryItemModel.criteria.tocTitle = seeAllResult.tocTitle;

    return payload;
  }

  fetchSearchDataWithAllFilters(fromBridge:boolean=false) {
    let obs$;
    if (!this.searchParamService.needToFetchData && this.allResultsCache) {
      obs$ = scheduled(of(this.allResultsCache), asyncScheduler);
    } else {
      this.allResultsCache = null;
      const payload = this.getSearchRequestModel(fromBridge);
      obs$ = this.http
        .post<Search>(
          this.urlResolver.resolveUrlApiV1(`search/DataWithAllFilters`),
          payload
        )
        .pipe(
          map((response) => {
            if ((<any>response)?.Message) {
              const emptyResponse = new Search();
              emptyResponse.searchFilterModel = new AlexSearchFilterModel();
              emptyResponse.searchResultModel = new AlexSearchResult();
              emptyResponse.searchResultModel.errorMessage =
                "No document found";
              return emptyResponse;
            }
            return response;
          }),
          tap((results) => {
            this.allResultsCache = results;
          })
        );
    }

    const subscription = obs$.subscribe(
      (searchData) => {
        this.spinnerService.showSpinnerAsync().then((spinnerId) => {
          //TODO:
          this.searchViewModelService.updateSearchResultViewModel(
            searchData.searchResultModel
          );

          if (searchData?.searchFilterModel?.allSource) {
            let filterModel = new AlexSearchFilterModel();
            filterModel = this.searchCacheService.getFilter();
            this.searchViewModelService.updateAllFilterViewModel(filterModel);
            // add for other category,country
            this.searchViewModelService.updateTagDisplay(
              this.searchCacheService.getTags(),
              (<any>filterModel).taxonomyTopicFilterSearch,
              (<any>filterModel).taxonomyOtherFilterSearch,
              (<any>filterModel).taxonomCountryFilterModel,
              this.searchCacheService.getSource()
            );
          } else {
            this.searchViewModelService.updateAllFilterViewModel(
              searchData?.searchFilterModel
            );
            this.searchViewModelService.updateTagDisplayValue(
              (<any>searchData)?.filterTags
            );

            this.searchCacheService.addFilter(searchData?.searchFilterModel);
            this.searchCacheService.addTags((<any>searchData)?.filterTags);
            this.searchCacheService.addSource(
              (<any>searchData.searchFilterModel).taxonomySourceFilterSearch
            );
          }

          const { version } = this.searchParamService.getFilterData();
          if (Array.isArray(version) && version.length > 0) {
            version.forEach((version) => {
              this.searchViewModelService.searchViewModel.tags.push({
                id: `tag${version.k}`,
                key: version.k,
                filterType: VERSION_FILTER,
                action: REMOVE_VERSION_FILTER,
                displayText: version.k,
                data: version.k,
                order: version.o,
              });
            });
          }

          this.searchViewModelService.updateView();
          subscription.unsubscribe();
        });
      },
      (error) => {
        const emptyResponse = new Search();
        // emptyResponse.searchResultModel.errorMessage = "Oops! something went wrong";
        emptyResponse.searchFilterModel = new AlexSearchFilterModel();
        emptyResponse.searchResultModel = new AlexSearchResult();
        emptyResponse.searchResultModel.errorMessage =
          "Oops! something went wrong";
        emptyResponse.searchResultModel.errorCode =
          error.statusText + " " + error.status;
        this.searchViewModelService.updateSearchResultViewModel(
          emptyResponse.searchResultModel
        );
        this.searchViewModelService.updateSpecificFilterViewModel(
          emptyResponse.searchFilterModel
        );
        this.searchViewModelService.updateTagDisplayValue(
          (<any>emptyResponse).filterTags
        );
        this.searchViewModelService.updateView();
        subscription.unsubscribe();
      }
    );
  }

  fetchSearchDataWithSpecificFilters(fromBridge:boolean=false) {
    let obs$;
    if (!this.searchParamService.needToFetchData && this.specificResultsCache) {
      obs$ = scheduled(of(this.specificResultsCache), asyncScheduler);
    } else {
      this.specificResultsCache = null;
      const payload = this.getSearchRequestModel(fromBridge);
      obs$ = this.http
        .post<Search>(
          this.urlResolver.resolveUrlApiV1(`search/DataWithSpecificFilters`),
          payload
        )
        .pipe(
          map((response) => {
            if ((<any>response)?.Message) {
              const emptyResponse = new Search();
              emptyResponse.searchFilterModel = new AlexSearchFilterModel();
              emptyResponse.searchResultModel = new AlexSearchResult();
              emptyResponse.searchResultModel.errorMessage =
                "No document found";
              return emptyResponse;
            }
            return response;
          }),
          tap((results) => {
            this.specificResultsCache = results;
          })
        );
    }

    const subscription = obs$.subscribe(
      (searchData) => {
        this.searchViewModelService.updateSearchResultViewModel(
          searchData.searchResultModel
        );

        if (searchData.searchFilterModel.allSource) {
          let filterModel = new AlexSearchFilterModel();
          filterModel = this.searchCacheService.getFilter();
          this.searchViewModelService.updateSpecificFilterViewModel(
            filterModel
          );
          // add for other category,country
          this.searchViewModelService.updateTagDisplay(
            this.searchCacheService.getTags(),
            (<any>filterModel).taxonomyTopicFilterSearch,
            (<any>filterModel).taxonomyOtherFilterSearch,
            (<any>filterModel).taxonomCountryFilterModel,
            this.searchCacheService.getSource()
          );
        } else {
          this.searchViewModelService.updateSpecificFilterViewModel(
            searchData.searchFilterModel
          );
          this.searchViewModelService.updateTagDisplayValue(
            (<any>searchData).filterTags
          );

          this.searchCacheService.addFilter(searchData.searchFilterModel);
          this.searchCacheService.addTags((<any>searchData).filterTags);
        }

        const { version } = this.searchParamService.getFilterData();
        if (Array.isArray(version) && version.length > 0) {
          version.forEach((version) => {
            this.searchViewModelService.searchViewModel.tags.push({
              id: `tag${version.k}`,
              key: version.k,
              filterType: VERSION_FILTER,
              action: REMOVE_VERSION_FILTER,
              displayText: version.k,
              data: version.k,
              order: version.o,
            });
          });
        }

        this.searchViewModelService.updateView();
        subscription.unsubscribe();
      },
      (error) => {
        const emptyResponse = new Search();

        emptyResponse.searchFilterModel = new AlexSearchFilterModel();
        emptyResponse.searchResultModel = new AlexSearchResult();
        emptyResponse.searchResultModel.errorMessage =
          "Oops! something went wrong";
        emptyResponse.searchResultModel.errorCode =
          error.statusText + " " + error.status;
        this.searchViewModelService.updateSearchResultViewModel(
          emptyResponse.searchResultModel
        );
        this.searchViewModelService.updateSpecificFilterViewModel(
          emptyResponse.searchFilterModel
        );
        this.searchViewModelService.updateTagDisplayValue(
          (<any>emptyResponse).filterTags
        );
        this.searchViewModelService.updateView();
        subscription.unsubscribe();
      }
    );
  }

  fetchRollupDataWithFilters() {
    const payload = this.getSeeAllResultRequestModel();
    const subscription = this.http
      .post<any>(
        this.urlResolver.resolveUrlApiV1(`search/ShowMoreData`),
        payload
      )
      .pipe(
        switchMap((response) => {
          if ((<any>response)?.Message) {
            const emptyResponse = new Search();
            emptyResponse.searchFilterModel = new AlexSearchFilterModel();
            emptyResponse.searchResultModel = new AlexSearchResult();
            emptyResponse.searchResultModel.errorMessage = "No document found";
            return throwError(emptyResponse);
          }
          return scheduled(of(response as Search), asyncScheduler);
        })
      )
      .subscribe(
        (searchResponse) => {         
          if(searchResponse.searchResultModel.manualsSearchContent.tocTitle!='' && searchResponse.searchResultModel.manualsSearchContent.tocTitle!=null && searchResponse.searchResultModel.manualsSearchContent.tocTitle!=undefined)
          {
            this.searchParamService.tocTitleFromShowmore = searchResponse.searchResultModel.manualsSearchContent.tocTitle;
          }
          if (searchResponse.searchResultModel.searchContent.items.length) {
            (<any>searchResponse).filterTags =
              (<any>searchResponse).filterTags || {};
            (<any>searchResponse).filterTags[
              searchResponse.searchResultModel.searchContent.items[0].parentId
            ] =
              searchResponse.searchResultModel.searchContent.items[0].parentTitle;
          }

          this.searchViewModelService.updateSearchResultViewModel(
            searchResponse.searchResultModel
          );
          this.searchViewModelService.updateAllFilterViewModel(
            searchResponse.searchFilterModel
          );
          this.searchViewModelService.updateTagDisplayValue(
            (<any>searchResponse).filterTags
          );
          const {
            combinedSearchType,
            seeAllResult: { tocTitle },
          } = this.searchParamService.getFilterData();
          if (combinedSearchType === "kaeg") {
            this.searchViewModelService.searchViewModel.tags.push({
              id: `tag${this.searchParamService.tocTitleFromShowmore}`,
              key: this.searchParamService.tocTitleFromShowmore,
              filterType: SEE_ALL_RESULT,
              action: REMOVE_TOC_REF_FILTER,
              displayText: this.searchParamService.tocTitleFromShowmore,
              data: this.searchParamService.tocTitleFromShowmore,
              order: this.searchViewModelService.searchViewModel.tags?.length,
            });
          }

          const { version } = this.searchParamService.getFilterData();
          if (Array.isArray(version) && version.length > 0) {
            version.forEach((version) => {
              this.searchViewModelService.searchViewModel.tags.push({
                id: `tag${version.k}`,
                key: version.k,
                filterType: VERSION_FILTER,
                action: REMOVE_VERSION_FILTER,
                displayText: version.k,
                data: version.k,
                order: version.o,
              });
            });
          }

          this.searchViewModelService.updateView();
          subscription.unsubscribe();
        },
        (error) => {
          this.spinnerService.hideSpinner(0, true);
        }
      );
  }

  updatePaginationModel(currentPage: number) {
    const model = new AlexPaginationEventModel();
    model.action = AlexPaginationActions.PAGE_CHANGE;
    model.data = this.searchViewModelService.searchViewModel.pagination;
    model.additionalData = currentPage;

    const updatedPaginationStartEnd = this.paginationService.handlePaginationEvent(
      model
    );
    const updatedPaginationModel = this.paginationService.updatePaginationModel(
      updatedPaginationStartEnd,
      updatedPaginationStartEnd.itemsPerPage,
      updatedPaginationStartEnd.recordCount
    );
    this.searchViewModelService.searchViewModel.pagination = updatedPaginationModel;
  }

  handledError(error: Response): any {
    return observableThrowError(error || "Server error");
  }

  mapToChecked(arr) {
    if (arr)
      return arr.map((e) => {
        return {
          name: e,
          checked: false,
        };
      });
    else return [];
  }

  getFileType(mimeType: string): string {
    let iconType = "file";
    mimeType = mimeType ? mimeType.toLowerCase() : mimeType;
    switch (mimeType) {
      case "application/vnd.ms-excel":
        iconType = "excel";
        break;
      case "application/msword":
        iconType = "word";
        break;
      case "text/plain":
        iconType = "word";
        break;
      case "text/rtf":
        iconType = "word";
        break;
      case "application/zip":
        iconType = "zip";
        break;
      case "application/xml":
        iconType = "file";
        break;
      case "application/vnd.ms-powerpoint":
        iconType = "ppt";
        break;
      case "application/pdf":
        iconType = "pdf";
        break;
      case "text/vnd.wap.wml":
        iconType = "msg";
        break;
      case "application/outlook":
        iconType = "msg";
        break;
      case "video/mpeg":
        iconType = "video";
        break;
      case "audio/mpeg":
        iconType = "video";
        break;
      case "audio/x-ms-wmv":
        iconType = "video";
        break;
      case "application/x-shockwave-flash":
        iconType = "video";
        break;
      case "video":
        iconType = "video";
        break;
      case "video/mp4":
        iconType = "video";
        break;
      case "web":
        iconType = "web";
        break;
      case "text/html" || "html/xml" || "application/xml" || "xml" || "html":
        iconType = "file";
        break;
      default:
        iconType = "file";
    }
    return iconType;
  }

  getSeeAllResult(): Observable<Search> {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    const header = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http
      .post<any>(
        this.urlResolver.resolveUrlApiV1(`search/ShowMoreData`),
        this.getSeeAllResultRequestModel(),
        { headers: header }
      )
      .pipe(
        map((response) => {
          let search: any;
          try {
            let result: any = response;
            search = new Search();
            search.searchResultModel = result.searchResultModel;
          } catch (e) {}

          return search;
        })
      );
  }

  handleDocumentClickEvent(
    eventModel: AlexDocumentEventModel | CKDDocumentEventModel
  ) {
    if (eventModel.action == "DOC_TITLE_CLICK") {
      if (eventModel.additionalData == "topic") {
        this.router.navigate(["/document/top", eventModel.data.docId]);
      } else if (eventModel.additionalData == "feature") {
        //Chenge docID to killDocId
        this.router.navigate(
          [
            "/document/lfc",
            eventModel.data.killDocId,
            "toc",
            eventModel.data.killDocId,
          ],
          { queryParams: { tocref: eventModel.data.tocRef } }
        );
      } else if (
        eventModel.additionalData === "manualsearch" ||
        eventModel.additionalData === "manualsfeature"
      ) {
        const { data: eventData } = eventModel as CKDDocumentEventModel;
        // track manuals doc click
        const payload = new CKDSearchResultClickTrackingData(
          eventData.docId,
          eventData.killDocId,
          eventData.title,
          eventData.url,
          eventData.tocRef,
          eventData.displayText,
          eventData.tocTitle,
          eventData.countries.join(","),
          eventData.workFlowTypes.join(","),
          eventData.weightage,
          eventData.type
        );
        this.trackCkdSearchResultClick(payload);
        // handle manuals doc click
        const editionId = sessionStorage.getItem("alex_edition");

        const { tocCountry, tocRef, killDocId } = eventData;

        let queryParams: { [key: string]: string } = {
          tocref: tocRef,
          cntry: tocCountry,
          chunkid: killDocId,
        };

        if (editionId) {
          queryParams = { ...queryParams, editionId };
        }

        this.router.navigate(["document", "manuals"], { queryParams });
      } else {
        //Chenge docID to killDocId

        if (this.getFileType(eventModel.data.mimeType) == "file") {
          if (eventModel.data.parentId) {
            this.router.navigate(
              [
                "/document/lfc",
                eventModel.data.parentId,
                "toc",
                eventModel.data.killDocId,
              ],
              { queryParams: { tocref: eventModel.data.tocRef } }
            );
          } else {
            this.router.navigate(
              [
                "/document/lfc",
                eventModel.data.killDocId,
                "toc",
                eventModel.data.killDocId,
              ],
              { queryParams: { tocref: eventModel.data.tocRef } }
            );
          }
        } else {
          this.router.navigate(
            [
              "/document/lfc",
              eventModel.data.docId,
              "toc",
              eventModel.data.docId,
            ],
            { queryParams: { tocref: eventModel.data.tocRef } }
          );
        }
      }
    } else if (eventModel.action == "SEE_ALL_RESULT") {
      this.searchParamService.loadAllFilter(true);
      const { type } = eventModel.data;
      let combinedSearchType: CombinedSearchQueryType = null;
      if (type === "search") {
        combinedSearchType = "alex";
      } else if (type === "manualsearch") {
        combinedSearchType = "kaeg";
      } else if (type === "topic") {
        combinedSearchType = "topic";
      } else if (type === "featured" || type === "manualsfeature") {
        combinedSearchType = "featured";
      }
      if (type === "manualsearch" || type === "manualsfeature") {
        this.router.navigate(
          [
            "/search/more",
            eventModel.data.tocRef,
            this.searchParamService.getRouteParam(),
          ],
          {
            queryParams: {
              type: combinedSearchType,
              from: this.searchParamService.filterParam.combinedSearchType,
              tocCountry: (eventModel.data as CKDSearchItemModel).tocCountry,
              tocWorkflow: (eventModel.data as CKDSearchItemModel).tocWorkflow,
              tocVersion: (eventModel.data as CKDSearchItemModel).tocVersion,
              page: null,
            },
            queryParamsHandling: "merge",
          }
        );
      } else {
        this.router.navigate(
          [
            "/search/more",
            eventModel.data.parentId,
            eventModel.data.docId,
            this.searchParamService.getRouteParam(),
          ],
          {
            queryParams: {
              type: combinedSearchType,
              from: this.searchParamService.filterParam.combinedSearchType,
              page: null,
            },
            queryParamsHandling: "merge",
          }
        );
      }
    }
  }

  saveSearchCritera(recentSearch: RecentSearch) {
    return this.http.post(
      this.urlResolver.resolveUrlApiV1(`user/AddRecentSearch`),
      recentSearch
    );
  }

  /**
   * Track search result click
   * @param searchResultData data to capture
   */
  private trackCkdSearchResultClick(
    searchResultData: CKDSearchResultClickTrackingData
  ) {
    const searchKey = this.searchParamService.filterParam.q;
    const ckdSearchResultClickTrackingModel = new CkdSearchResultClickTrackingModel(
      searchResultData,
      searchKey
    );
    this.trackingService.track(ckdSearchResultClickTrackingModel);
  }
}
