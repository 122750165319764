<div *ngIf="docType=='Documents'" class="carouselSvgLeft">
    <svg *ngIf="alexDocumentType == 'excel'" class="SearchExcelIcon carouselSvgTopLeft" width="20" height="20" viewBox="-3 0 15 20"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- <title>51D78A30-761F-408A-8B3B-69FFCD93E0B1</title> -->
        <!--<defs>
            <path id="a" d="M0 18.965h14.604V0H0v18.965z"></path>
        </defs>-->
        <g fill="none" fill-rule="evenodd">
            <g transform="translate(0 .345)">
                <mask id="b" class="whiteColor">
                    <use xlink:href="#a"></use>
                </mask>
                <path d="M13.24 17.144a.44.44 0 0 1-.435.442H1.8a.44.44 0 0 1-.436-.442V1.821a.44.44 0 0 1 .436-.442h6.856l4.585 4.638v11.127zm1.015-12.052L9.57.353A1.18 1.18 0 0 0 8.726 0H1.8C.807 0 0 .817 0 1.82v15.324c0 1.004.807 1.822 1.8 1.822h11.005c.992 0 1.8-.818 1.8-1.822V5.946c0-.323-.125-.626-.35-.854z"
                    class="deepgreen" mask="url(#b)"></path>
            </g>
            <path class="deepgreen" d="M5.197 15.032H3.409l2.367-3.793-2.252-3.653h1.838l1.312 2.425 1.35-2.425H9.8L7.55 11.178l2.392 3.854H8.073l-1.399-2.539z">
            </path>
        </g>
    </svg>

    <svg *ngIf="alexDocumentType =='word'" class="SearchWordIcon carouselSvgTopLeft" width="18" height="20" viewBox="0 0 15 20"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- <title>5066062F-B74C-4E2F-8E67-C9BDEDEA8186</title> -->
        <!--<defs>
            <path id="a" d="M0 18.965h14.604V0H0v18.965z"></path>
        </defs>-->
        <g fill="none" fill-rule="evenodd">
            <g transform="translate(0 .345)">
                <mask id="b" class="whiteColor">
                    <use xlink:href="#a"></use>
                </mask>
                <path d="M13.24 17.144a.44.44 0 0 1-.435.442H1.8a.44.44 0 0 1-.436-.442V1.821a.44.44 
      0 0 1 .436-.442h6.856l4.585 4.638v11.127zm1.015-12.052L9.57.353A1.18 1.18 0 0 0 8.726 0H1.8C.807 0 0 .817 0 1.82v15.324c0 1.004.807 1.822 1.8 1.822h11.005c.992 0 1.8-.818 1.8-1.822V5.946c0-.323-.125-.626-.35-.854z"
                    class="lightblue" mask="url(#b)"></path>
            </g>
            <path class="lightblue" d="M3.678 7.586l.972 4.264.212 1.187.212-1.163.832-4.288h1.623l.874 4.264.224 1.187.228-1.142.98-4.309h1.567l-2.071
        7.446H7.858l-.888-4.355-.26-1.44-.26 1.44-.89 4.355H4.134L2.045 7.586z">
            </path>
        </g>

    </svg>

    <svg *ngIf="alexDocumentType=='ppt'" class="SearchPowerPointIcon carouselSvgTopLeft" width="18" height="20" viewBox="0 0 22 28"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- <title>ABAB5F02-6D30-4EE7-BF39-D3069E91C549</title> -->
        <defs>
            <path id="a" d="M0 27.5h21.42V0H0v27.5z"></path>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g transform="translate(0 .5)">
                <mask id="b" class="whiteColor">
                    <use xlink:href="#a"></use>
                </mask>
                <path d="M19.42 24.859a.641.641 0 0 1-.64.641H2.64a.641.641 0 0
            1-.64-.641V2.64A.64.64 0 0 1 2.64 2h10.055l6.725 6.725v16.134zm1.487-17.475L14.036.512A1.74 1.74 0 0 0 12.798 0H2.64A2.643 2.643 0 0 0 0 2.64v22.219A2.643 2.643 0 0 0 2.64 27.5h16.14a2.644 2.644 0 0 0 2.64-2.641V8.621c0-.467-.182-.907-.513-1.237z"
                    class="lightorange" mask=""></path>
            </g>
            <path d="M11.475 13.234c-.286-.24-.686-.359-1.2-.359H8.241v3.179h2.034c.514 0 .914-.13 1.2-.388.286-.259.428-.669.428-1.231s-.142-.961-.428-1.201m1.722
            3.889c-.632.528-1.535.791-2.708.791H8.241v3.882H6V11h4.636c1.068 0 1.92.278 2.556.835.635.557.953
            1.419.953 2.586 0 1.274-.317 2.175-.948 2.702" class="lightorange">
            </path>
        </g>
    </svg>
    <svg *ngIf="alexDocumentType=='pdf'" class="SearchPdfIcon carouselSvgTopLeft" width="20" height="22" viewBox="-2 -2 15 21"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- <title>BEF072D1-B64E-4B54-84AA-A2678000145E</title> -->
        <defs>
            <path id="a" d="M0 18.965h14.604V0H0v18.965z"></path>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g transform="translate(0 .345)">
                <mask id="b" class="whiteColor">
                    <use xlink:href="#a"></use>
                </mask>
                <path d="M13.24 17.144a.44.44 0 0 1-.435.442H1.8a.44.44 0 0 1-.436-.442V1.821a.44.44 0 0 1 .436-.442h6.856l4.585 4.638v11.127zm1.015-12.052L9.57.353A1.18 1.18 0 0 0 8.726 0H1.8C.807 0 0 .817 0 1.82v15.324c0 1.004.807 1.822 1.8 1.822h11.005c.992 0 1.8-.818 1.8-1.822V5.946c0-.323-.125-.626-.35-.854z"
                    class="deepRed" mask="url(#b)"></path>
            </g>
            <path d="M6.623 6.551c-.282.306-.325 1.308-.077 1.792.092-.617.278-1.179.077-1.792m3.229 6.013c.564.342 1.29.499 1.765.392-.54-.477-1.16-.409-1.765-.392M3.978 14.17c-.593.327-1.012.82-1.312 1.425.704-.218 1.014-.816 1.312-1.425m2.62-3.754l-1.259 2.752 2.632-.753c-.234-.303-.499-.614-.729-.95-.236-.343-.438-.71-.644-1.049m.618-1.294c-.029.168-.034.37.03.522.337.818.765 1.575 1.492 2.125.307.233.618.28.981.23.625-.086 1.25-.076 1.852.14.62.222.768.758.352
          1.264-.226.274-.562.274-.865.267a5.72 5.72 0 0 1-2.203-.488.69.69 0 0 0-.354-.06c-1.012.135-2.033.236-2.993.617a.78.78 0 0 0-.31.256c-.455.61-.883 1.241-1.356 1.835-.159.2-.408.343-.642.458-.356.175-.821.071-1.032-.235-.197-.285-.135-.595.036-.89.352-.606.857-1.05 1.464-1.363.476-.245.79-.594 1.044-1.069a16.43 16.43 0 0 0 1.185-2.787c.08-.25.098-.47.008-.718a3.6 3.6 0 0 1-.04-2.391c.188-.585.65-.785 1.182-.5.301.162.373.473.412.776.083.643-.153 1.486-.243 2.01"
                class="deepRed"></path>
        </g>
    </svg>
    <svg *ngIf="alexDocumentType=='video'" class="SearchVideoIcon carouselSvgTopLeft oceanBlue" width="23" height="23" version="1.1"
        id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-271 384 28 28"
        xml:space="preserve">
        <g id="facetime-video_2_">
            <path d="M-245,391v12c0,0.3-0.2,0.4-0.4,0.2l-6.6-5.3v5.6c0,0.8-0.7,1.5-1.5,1.5h-14c-0.8,0-1.5-0.7-1.5-1.5v-13
                                                    c0-0.8,0.7-1.5,1.5-1.5h14c0.8,0,1.5,0.7,1.5,1.5v5.6l6.6-5.3C-245.2,390.6-245,390.7-245,391z"
            />
        </g>
    </svg>
    <svg *ngIf="alexDocumentType=='web'" class="svgIm carouselSvgTopLeft oceanBlue" x="0px" y="5px" width="28" height="25" version="1.1"
        id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-271 380 29 29"
        xml:space="preserve">
        <g id="global_2_">
            <path d="M-257,385c-6.6,0-12,5.4-12,12s5.4,12,12,12c6.6,0,12-5.4,12-12S-250.4,385-257,385z M-260.9,388.1
                                                    c-0.7,0.7-1.4,1.7-1.9,2.9h-1.8C-263.6,389.8-262.4,388.8-260.9,388.1z M-265.8,393h2.3c-0.3,0.9-0.4,2-0.5,3.1h-2.7
                                                    C-266.5,395-266.2,393.9-265.8,393z M-265.8,401.1c-0.4-1-0.7-2-0.9-3.1h2.7c0.1,1.1,0.2,2.2,0.5,3.1H-265.8z M-264.6,403h1.8
                                                    c0.5,1.2,1.2,2.1,1.9,2.9C-262.4,405.2-263.6,404.2-264.6,403z M-258,406.1c-0.5-0.3-1.8-1.3-2.8-3.1h2.8V406.1z M-258,401.1h-3.6
                                                    c-0.3-0.9-0.5-1.9-0.6-3.1h4.2V401.1z M-258,396.1h-4.2c0.1-1.2,0.3-2.2,0.6-3.1h3.6V396.1z M-258,391.1h-2.8
                                                    c1-1.8,2.3-2.8,2.8-3.1V391.1z M-248.2,393c0.4,1,0.7,2,0.8,3.1h-2.7c-0.1-1.1-0.2-2.2-0.5-3.1H-248.2z M-249.3,391.1h-1.9
                                                    c-0.6-1.3-1.3-2.2-2-3C-251.6,388.7-250.3,389.8-249.3,391.1z M-256,388c0.5,0.3,1.8,1.3,2.8,3.1h-2.8V388z M-256,393h3.6
                                                    c0.3,0.9,0.5,1.9,0.6,3.1h-4.2V393z M-256,398h4.2c-0.1,1.2-0.3,2.2-0.6,3.1h-3.6V398z M-256,406.1V403h2.8
                                                    C-254.2,404.8-255.5,405.7-256,406.1z M-253.1,405.9c0.7-0.7,1.3-1.7,1.9-2.9h1.8C-250.3,404.2-251.6,405.2-253.1,405.9z
                                                    M-248.2,401.1h-2.3c0.3-0.9,0.4-2,0.5-3.1h2.7C-247.4,399.1-247.7,400.1-248.2,401.1z"
            />
        </g>
    </svg>

     <svg *ngIf="alexDocumentType=='file'" class="SearchFileIcon" width="17" height="19" viewBox="0 0 17 29" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- <title>B51C8791-A637-4827-8D91-67308FBD6B69</title> -->
        <defs><path id="a" d="M0 27.5h21.42V0H0v27.5z"/><path id="c" d="M0 28.5h21.42V1H0z"/></defs><g fill="none" fill-rule="evenodd">
                                      <g transform="translate(0 .5)">
                                       <mask class="whiteColor" id="b" ><use xlink:href="#a"/></mask>
                                       <path d="M19.42 24.859a.641.641 0 0 1-.64.641H2.64a.641.641 0 0 1-.64-.641V2.64A.64.64 0 0 1 2.64 2h10.055l6.725 6.725v16.134zm1.487-17.475L14.036.512A1.74 1.74 0 0 0 12.798 0H2.64A2.643 2.643 0 0 0 0 2.64v22.219A2.643 2.643 0 0 0 2.64 27.5h16.14a2.644 2.644 0 0 0 2.64-2.641V8.621c0-.467-.182-.907-.513-1.237z" class="oceanBlue" mask=""/></g>
                                       <mask class="whiteColor" id="d" ><use xlink:href="#c"/></mask><path class="oceanBlue" mask="url(#d)" d="M4 14.063h11.75V12H4zM4 18.063h11.75V16H4zM4 22.063h11.75V20H4z"/></g>
                                    </svg>
                                    
    <svg *ngIf="alexDocumentType=='zip'" class="svgIm oceanBlue" width="26px" height="28px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="12 10 29 29" xml:space="preserve">
                                                        <g id="zip_1_">
                                                        <path id="zip" d="M24,26h1v5h-1V26z M22,27v1h1v-1l-1-1h-2v1H22z M21,28v1h1v-1H21z M21,31h2v-1h-2v-1h-1v1L21,31z M27.5,18H33v7
                                                        H19c-0.55,0-1,0.45-1,1v5c0,0.55,0.45,1,1,1h14v3.5c0,0.275-0.225,0.5-0.5,0.5h-18c-0.275,0-0.5-0.225-0.5-0.5v-23
                                                        c0-0.275,0.225-0.5,0.5-0.5H27v5.5C27,17.775,27.225,18,27.5,18z M25,23h-2l0,0h-1v1h2v-1H25L25,23z M25,21h-2l0,0h-1v1h2v-1H25
                                                        L25,21z M25,19h-2l0,0h-1v1h2v-1H25L25,19z M25,17h-2l0,0h-1v1h2v-1H25L25,17z M25,15h-2l0,0h-1v1h2v-1H25L25,15z M25,13h-2l0,0h-1
                                                        v1h2v-1H25L25,13z M28.5,17H33l-5-5v4.5C28,16.775,28.225,17,28.5,17z M26,26v5h1v-1h1l1-1v-2l-1-1H26z M27,29v-2h1v2H27z"></path>
                                                        </g>
                                                    </svg>
    <svg *ngIf="alexDocumentType=='msg'" class="svgIm oceanBlue" width="25px" height="28px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="4 4 16 16" xml:space="preserve">
                                                        <g id="envelope_2_">
                                                        <path id="path_3_" d="M12.176,12.854c-0.097,0.097-0.256,0.097-0.353,0L6.176,7.177C6.079,7.08,6.113,7,6.25,7h11.5
                                                        c0.138,0,0.171,0.08,0.074,0.177L12.176,12.854z M12.177,14.823c-0.097,0.097-0.256,0.097-0.354,0l-1.646-1.646
                                                        c-0.097-0.097-0.256-0.097-0.354,0l-3.646,3.646C6.08,16.92,6.113,17,6.25,17h11.5c0.138,0,0.17-0.08,0.073-0.177l-3.646-3.646
                                                        c-0.097-0.097-0.256-0.097-0.354,0L12.177,14.823z M15.177,11.823c-0.097,0.097-0.097,0.256,0,0.354l2.646,2.646
                                                        C17.92,14.92,18,14.887,18,14.75v-5.5c0-0.137-0.08-0.17-0.177-0.073L15.177,11.823z M6.177,9.177C6.08,9.08,6,9.113,6,9.25v5.5
                                                        c0,0.137,0.08,0.17,0.177,0.073l2.646-2.646c0.097-0.097,0.097-0.256,0-0.354L6.177,9.177z"></path>
                                                        </g>
                                                    </svg>

</div>
<div *ngIf="docType!='Documents'" class="carouselSvgLeft">
    <svg *ngIf="docType=='TOP'" class="svgIm darkishPurple topDocIcon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        width="22px" height="1px" viewBox="0 0 22 1" xml:space="preserve">
        <g transform="scale(0.9)">
            <g transform="translate(-205,-1177)" xmlns="http://www.w3.org/2000/svg" id="direction_1_">
                <path d="M226.922,1164.535c0.121-0.246,0.019-0.35-0.229-0.227l-24.521,12.044c-0.247,0.121-0.225,0.221,0.051,0.221h11.924
                              l0.025,11.975c0,0.275,0.1,0.298,0.221,0.051L226.922,1164.535z M210.68,1174.572c-0.275,0-0.299-0.35-0.052-0.471l12.597-6.295
                              c0.246-0.121,0.304-0.07,0.109,0.125l-6.843,6.068c-0.194,0.194-0.579,0.572-0.854,0.572H210.68z"></path>

            </g>
        </g>
    </svg>
    <svg *ngIf="docType=='Content Page'" class="svgIm oceanBlue listDocIcon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-265 386 18 21" width="25" height="30" xml:space="preserve">
        <g>
            <rect x="-262.7" y="392.5" width="1.3" height="1.3" />
            <rect x="-260" y="400.5" width="6.7" height="1.3" />
            <rect x="-260" y="397.8" width="6.7" height="1.3" />
            <rect x="-262.7" y="395.2" width="1.3" height="1.3" />
            <polygon points="-253.3,395.5 -253.3,395.2 -253.9,395.2 -260,395.2 -260,396.5 -253.3,396.5 	" />
            <path d="M-260,392.5v1.3h4.6c-0.3-0.4-0.6-0.9-0.8-1.3H-260z" />
            <rect x="-262.7" y="397.8" width="1.3" height="1.3" />
            <rect x="-262.7" y="400.5" width="1.3" height="1.3" />
            <path d="M-252,395.9v7.3h-12v-12h7.5c0-0.2,0-0.4,0-0.7c0-0.7,0.1-1.4,0.4-2h-8.5c-0.4,0-0.7,0.3-0.7,0.7v14.7
                                        c0,0.4,0.3,0.7,0.7,0.7h13.3c0.4,0,0.7-0.3,0.7-0.7V396c-0.1,0-0.2,0-0.3,0C-251.3,396-251.7,396-252,395.9z"
            />
            <path d="M-251,386.5c-1.5,0-2.7,0.8-3.4,2c-0.3,0.6-0.6,1.3-0.6,2c0,0.2,0,0.4,0.1,0.7c0.1,0.5,0.3,0.9,0.5,1.3
                                        c0.3,0.5,0.7,0.9,1.1,1.2c0.4,0.3,0.8,0.5,1.3,0.6c0.3,0.1,0.7,0.1,1,0.1c0.1,0,0.2,0,0.3,0c2-0.2,3.7-1.9,3.7-4
                                        C-247,388.3-248.8,386.5-251,386.5z M-248.5,391.1c0,0.1-0.1,0.1-0.1,0.1h-1.9v1.9c0,0.1-0.1,0.1-0.1,0.1h0h-0.7
                                        c-0.1,0-0.1-0.1-0.1-0.1v-1.9h-0.5h-1.4c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1v-0.7c0-0.1,0.1-0.1,0.1-0.1h1.9v-1.8v-0.1
                                        c0-0.1,0.1-0.1,0.1-0.1h0.7c0.1,0,0.1,0.1,0.1,0.1v1.9h1.9c0.1,0,0.1,0.1,0.1,0.1V391.1z"
            />
        </g>
    </svg>
</div>
<div *ngIf="docType!='Documents'" class="carouselSvgLeft">
    <svg *ngIf="docType=='External URLs'" class="svgIm carouselSvgTopLeft oceanBlue" x="0px" y="5px" width="28" height="25" version="1.1"
        id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-271 380 29 29"
        xml:space="preserve">
        <g id="global_2_">
            <path d="M-257,385c-6.6,0-12,5.4-12,12s5.4,12,12,12c6.6,0,12-5.4,12-12S-250.4,385-257,385z M-260.9,388.1
                                                    c-0.7,0.7-1.4,1.7-1.9,2.9h-1.8C-263.6,389.8-262.4,388.8-260.9,388.1z M-265.8,393h2.3c-0.3,0.9-0.4,2-0.5,3.1h-2.7
                                                    C-266.5,395-266.2,393.9-265.8,393z M-265.8,401.1c-0.4-1-0.7-2-0.9-3.1h2.7c0.1,1.1,0.2,2.2,0.5,3.1H-265.8z M-264.6,403h1.8
                                                    c0.5,1.2,1.2,2.1,1.9,2.9C-262.4,405.2-263.6,404.2-264.6,403z M-258,406.1c-0.5-0.3-1.8-1.3-2.8-3.1h2.8V406.1z M-258,401.1h-3.6
                                                    c-0.3-0.9-0.5-1.9-0.6-3.1h4.2V401.1z M-258,396.1h-4.2c0.1-1.2,0.3-2.2,0.6-3.1h3.6V396.1z M-258,391.1h-2.8
                                                    c1-1.8,2.3-2.8,2.8-3.1V391.1z M-248.2,393c0.4,1,0.7,2,0.8,3.1h-2.7c-0.1-1.1-0.2-2.2-0.5-3.1H-248.2z M-249.3,391.1h-1.9
                                                    c-0.6-1.3-1.3-2.2-2-3C-251.6,388.7-250.3,389.8-249.3,391.1z M-256,388c0.5,0.3,1.8,1.3,2.8,3.1h-2.8V388z M-256,393h3.6
                                                    c0.3,0.9,0.5,1.9,0.6,3.1h-4.2V393z M-256,398h4.2c-0.1,1.2-0.3,2.2-0.6,3.1h-3.6V398z M-256,406.1V403h2.8
                                                    C-254.2,404.8-255.5,405.7-256,406.1z M-253.1,405.9c0.7-0.7,1.3-1.7,1.9-2.9h1.8C-250.3,404.2-251.6,405.2-253.1,405.9z
                                                    M-248.2,401.1h-2.3c0.3-0.9,0.4-2,0.5-3.1h2.7C-247.4,399.1-247.7,400.1-248.2,401.1z"
            />
        </g>
    </svg>
</div>
