import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityCloseService {

  constructor() { }

  isClosest(source, selector, context?) {
    if (!Array.isArray(source)) {
      source = [source];
    }
    if (!context) {
      context = document.body;
    }
    const matches = [];
    for (let index = 0; index < source.length; index++) {
      let current = source[index];
      for (
        current = current.parentNode;
        current && current !== context;
        current = current.parentNode
      ) {
        if (current.matches(selector)) {
          matches[index] = current;
          break;
        }
      }
    }
    return matches;
  }
}
