
import { finalize } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpClient } from "@angular/common/http";
import { Observable ,  Subscription } from "rxjs";
import { AlexSessionManager } from "../services/session/session-manager.service";
import { AlexUrlResolverService } from "../services";
import { AutoUnsubscribeObservables } from "../decorators/auto-unsubscribe-observables";

@AutoUnsubscribeObservables()
@Injectable({providedIn:'root'}) 
export class SessionConfigInterceptor implements HttpInterceptor {
    private isKeepAliveRequest = true;
    private sessionCommandsSubscription:Subscription ;
    constructor(private sessionManager: AlexSessionManager,
        private urlResolver: AlexUrlResolverService) {
            this.sessionCommandsSubscription = this.sessionManager.sessionCommands$.subscribe(command => {
            const userkeepAliveSubscription = this.sessionManager.callSessionAliveEndpoint().subscribe(() => {
                console.log('KeepAlive Request Successful');
                userkeepAliveSubscription && userkeepAliveSubscription.unsubscribe();
            },
                (error) => {
                    console.log('KeepAlive Request Failed');
                    userkeepAliveSubscription && userkeepAliveSubscription.unsubscribe();
                }
            );
            this.isKeepAliveRequest = true;
        })
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isKeepAliveRequest) {
            this.sessionManager.increaseUserInActiveTime();
          } else {
            this.sessionManager.resetUserInActiveTime();
          }
          this.isKeepAliveRequest = false;
      
        return next.handle(request).pipe(
            finalize(()=>{
                this.sessionManager.restartSessionTimer();
            }))
        ;
    }
}
