import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { AlexContextualBarService } from "app/shared/components/contextual-bar/contextul-bar.service";
import { AlexAppService, DeviceType } from "app/core/services";
import { AlexPageLayoutService } from "app/shared/components/page-layout/page-layout.service";
import { AlexNotificationService } from "app/shared/components/notification/notification.service";
import { Subject } from "rxjs";

import { AlexSkinnyHeaderService } from "app/core/services/skinny-header/skinny-header.service";
import { HeaderState } from "app/core/services/skinny-header/header-state.enum";
@Component({
  selector: "alex-page-layout",
  templateUrl: "./page-layout.component.html",
  styleUrls: [
    "./page-layout.component.scss",
    "./page-layout.component.responsive.scss",
  ],
  animations: [
    trigger("slideTopContainer", [
      state(
        "down",
        style({
          transform: "translateY(0%)",
        })
      ),
      state(
        "up",
        style({
          transform: "translateY(-100%)",
        })
      ),
      state(
        "init",
        style({
          transform: "translateY(0%)",
        })
      ),
      transition("down => up", animate("250ms ease-in")),
      transition("up => down", animate("250ms ease-out")),
    ]),
  ],
})
export class AlexPageLayoutComponent implements OnInit {
  @ViewChild("topContainer") topContainer: ElementRef;
  deviceType: DeviceType;
  _slideTopContainer: string = "";
  lastScrollTop = 0;
  pageLayout$: any;
  isNotificationVisible: boolean = true;
  skinnyHeaderOnClick: boolean;
  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event) {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    this.lastScrollTop = st;
    this.slideTopContainer(event);
  }
  constructor(
    private contextualBarService: AlexContextualBarService,
    private appService: AlexAppService,
    private pageLayoutService: AlexPageLayoutService,
    private alexNotificationService: AlexNotificationService,
    private skinnyHeaderService: AlexSkinnyHeaderService
  ) {
    this.deviceType = this.appService.detectDeviceType();
    this.pageLayout$ = pageLayoutService;
    this.alexNotificationService.onVisibilityChange$.subscribe((isVisible) => {
      this.isNotificationVisible = isVisible;
    });
  }

  ngOnInit() {
    this.skinnyHeaderService.headerState.subscribe((headerState) => {
      if (headerState.state == HeaderState.up) {
        this._slideTopContainer = "up";
        this.appService.setHeaderHeight(0);
      } else {
        headerState.state == HeaderState.init
          ? (this._slideTopContainer = "init")
          : (this._slideTopContainer = "down");
        this.appService.setHeaderHeight(
          this.topContainer.nativeElement.offsetHeight
        );
      }
    });
  }

  ngAfterViewInit() {
    this.appService.setHeaderHeight(
      this.topContainer.nativeElement.offsetHeight
    );
  }

  slideTopContainer(event: Event) {
    if (this.lastScrollTop > this.topContainer.nativeElement.offsetHeight) {
      this.skinnyHeaderService.slideHeader(HeaderState.up, event);
    } else {
      this.skinnyHeaderService.slideHeader(HeaderState.down, event);
    }
  }
}
