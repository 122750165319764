<div class="row searchBarMob d-block d-lg-none">
  <div class="col-11 c-11">
    <!---search field mobile start-->
    <div *ngIf="isAuthorized" class="input-group mobSearch" id="advSearch">
      <input
        id="txt1"
        type="text"
        autocomplete="off"
        class="form-control searchTop"
        placeholder='Search content, titles, topics or "key phrases"...'
        [disabled]="!enabled"
        [(ngModel)]="searchKcwText"
        (keyUp.enter)="textKcwEnter()"
        (input)="onSearchInputChange()"
      />
      <span
        id="searchclear"
        (click)="searchKcwClearPressed()"
        *ngIf="showRemoveTextBtn"
      >
        <svg
          class="svgIm"
          version="1.1"
          style="fill: #acacac"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="30px"
          height="1px"
          viewBox="0 0 28 1"
          xml:space="preserve"
        >
          <g transform="scale(0.75)">
            <g
              transform="translate(-146,-985.5)"
              xmlns="http://www.w3.org/2000/svg"
              id="remove-sign_2_"
            >
              <path
                d="M176.662,975.338c-4.784-4.784-12.54-4.784-17.324,0c-4.784,4.784-4.784,12.54,0,17.324c4.784,4.784,12.54,4.784,17.324,0
                                              C181.445,987.878,181.446,980.122,176.662,975.338z M171.535,989.656c-0.194,0.195-0.513,0.195-0.707,0L168,986.828l-2.828,2.828
                                              c-0.194,0.195-0.513,0.195-0.707,0l-2.121-2.121c-0.195-0.193-0.195-0.512,0-0.707l2.828-2.828l-2.828-2.828
                                              c-0.195-0.195-0.195-0.514,0-0.707l2.121-2.121c0.194-0.195,0.512-0.195,0.707,0l2.828,2.828l2.828-2.828
                                              c0.195-0.195,0.513-0.195,0.707,0l2.121,2.121c0.195,0.193,0.195,0.512,0,0.707L170.828,984l2.828,2.828
                                              c0.195,0.195,0.195,0.514,0,0.707L171.535,989.656z"
              ></path>
            </g>
          </g>
        </svg>
      </span>

      <div class="input-group-btn">
        <div class="btn-group" role="group">
          <button
            type="button"
            class="btn btn-primary searchBg radiusnone"
            title="Search"
            [disabled]="!enabled"
            (click)="bigKcwSearch()"
          >
            <svg
              class="svgIm"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="25px"
              height="25px"
              viewBox="0 0 35 35"
              style="fill: #fff"
              xml:space="preserve"
            >
              <g transform="scale(1.4)">
                <g
                  transform="translate(-396,-156.5)"
                  xmlns="http://www.w3.org/2000/svg"
                  id="search_1_"
                >
                  <path
                    d="M419.602,176.755l-5.878-5.879c0.956-1.482,1.52-3.24,1.52-5.132c0-5.235-4.262-9.494-9.496-9.494
                                      c-5.236,0-9.494,4.259-9.494,9.494c0,5.236,4.258,9.495,9.494,9.495c1.891,0,3.65-0.562,5.132-1.519l5.878,5.878
                                      c0.195,0.196,0.518,0.196,0.712,0l2.132-2.132C419.799,177.271,419.799,176.951,419.602,176.755z M399.27,165.744
                                      c0-3.571,2.906-6.479,6.478-6.479c3.573,0,6.479,2.907,6.479,6.479c0,3.573-2.905,6.48-6.479,6.48
                                      C402.176,172.224,399.27,169.317,399.27,165.744z"
                  />
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="searchListContent" *ngIf="showInputTextResult && isAuthorized">
        <ul class="SearchListresults">
          <li
            *ngFor="let suggestion of input.suggestions"
            (click)="typeAheadRowClicked(suggestion)"
          >
            {{ suggestion }}
          </li>
        </ul>
      </div> -->
  </div>
  <div class="col-1 c-1" *ngIf="isAuthorized">
    <div class="mobileRightMenu" (click)="mobileRightMenuClicked()">
      <div dropdown>
        <svg
          class="svgIm greyColor1"
          dropdownToggle
          data-toggle="dropdown"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="22px"
          height="1px"
          viewBox="0 0 22 1"
          xml:space="preserve"
        >
          <g transform="scale(1.9)">
            <g
              transform="translate(-437,-372)"
              xmlns="http://www.w3.org/2000/svg"
              id="list-plus"
            >
              <path
                d="M440,373.5v1c0,0.275-0.225,0.5-0.5,0.5h-1c-0.275,0-0.5-0.225-0.5-0.5v-1c0-0.275,0.225-0.5,0.5-0.5h1
                  C439.775,373,440,373.225,440,373.5z M449.5,376h-8c-0.275,0-0.5,0.225-0.5,0.5v1c0,0.275,0.225,0.5,0.5,0.5h8
                  c0.275,0,0.5-0.225,0.5-0.5v-1C450,376.225,449.775,376,449.5,376z M439.5,376h-1c-0.275,0-0.5,0.225-0.5,0.5v1
                  c0,0.275,0.225,0.5,0.5,0.5h1c0.275,0,0.5-0.225,0.5-0.5v-1C440,376.225,439.775,376,439.5,376z M449.5,367h-6.592
                  c0.056,0.157,0.092,0.324,0.092,0.5v1c0,0.176-0.036,0.343-0.092,0.5h6.592c0.275,0,0.5-0.225,0.5-0.5v-1
                  C450,367.225,449.775,367,449.5,367z M449.5,370h-8c-0.275,0-0.5,0.225-0.5,0.5v1c0,0.275,0.225,0.5,0.5,0.5h8
                  c0.275,0,0.5-0.225,0.5-0.5v-1C450,370.225,449.775,370,449.5,370z M449.5,373h-8c-0.275,0-0.5,0.225-0.5,0.5v1
                  c0,0.275,0.225,0.5,0.5,0.5h8c0.275,0,0.5-0.225,0.5-0.5v-1C450,373.225,449.775,373,449.5,373z M441.5,367H440v-1.5
                  c0-0.275-0.225-0.5-0.5-0.5h-1c-0.275,0-0.5,0.225-0.5,0.5v1.5h-1.5c-0.275,0-0.5,0.225-0.5,0.5v1c0,0.275,0.225,0.5,0.5,0.5h1.5
                  v1.5c0,0.275,0.225,0.5,0.5,0.5h1c0.275,0,0.5-0.225,0.5-0.5V369h1.5c0.275,0,0.5-0.225,0.5-0.5v-1
                  C442,367.225,441.775,367,441.5,367z"
              />
            </g>
          </g>
        </svg>
        <ul
          class="dropdown-menu headerMenu"
          *dropdownMenu
          role="menu"
          aria-labelledby="menu1"
        >
          <li [routerLink]="['/home']" routerLinkActive="active menuActive">
            <svg
              class="svgIm greyColor1"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="25px"
              height="1px"
              viewBox="0 0 25 1"
              xml:space="preserve"
            >
              <g transform="scale(1.7)">
                <g
                  transform="translate(-29,-61.6)"
                  xmlns="http://www.w3.org/2000/svg"
                  id="home"
                >
                  <path
                    id="path_26_"
                    d="M41,60h1.75c0.138,0,0.17-0.08,0.073-0.177l-6.084-6.084c-0.097-0.097-0.257-0.098-0.355-0.002
                          l-6.206,6.087C30.08,59.921,30.112,60,30.25,60H32v5.75c0,0.138,0.112,0.25,0.25,0.25h2.5c0.138,0,0.25-0.112,0.25-0.25V62h3v3.75
                          c0,0.138,0.112,0.25,0.25,0.25h2.5c0.138,0,0.25-0.112,0.25-0.25V60z"
                  />
                </g>
              </g>
            </svg>
            <a>Alex</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
