import { Injectable } from "@angular/core";
import { AlexCacheModel } from "app/core/services/cache/cache.model";

@Injectable()
export class AlexAppCacheService {
    private cacheModel: Map<string, AlexCacheModel>
    constructor() {
        this.cacheModel = new Map<string, AlexCacheModel>();
    }
    add(model: AlexCacheModel) {
        if (!this.cacheModel.has(model.key)) {
            this.cacheModel.set(model.key, model);
        } else {
            console.log("same key already exists in cache. can't add");
        }
    }
    update(model: AlexCacheModel) {
        if (this.cacheModel.has(model.key)) {
            this.cacheModel[model.key] = model;
        } else {
            console.log("couldn't find the key");
        }
    }
    addOrUpdate(model: AlexCacheModel) {
        if (!this.cacheModel.has(model.key)) {
            this.cacheModel.set(model.key, model);
        } else {
            this.cacheModel[model.key] = model;
        }
    }
    remove(model: AlexCacheModel) {
        if (this.cacheModel.has(model.key)) {
            this.cacheModel.delete(model.key);
        } else {
            console.log("couldn't find the key");
        }
    }
    lookup(key: string) {
        if (this.cacheModel.has(key)) {
            return this.cacheModel.get(key);
        } else {
            console.log("couldn't find the key you are looking for");
            return null;
        }
    }
    clear(model: AlexCacheModel) {
        this.cacheModel = new Map<string, AlexCacheModel>();
    }
}