import { Injectable } from "@angular/core";
@Injectable()
export class SearchItem {
  docId: string;
  killDocId: string;
  title: string;
  synopsis: string;
  mimeType: string;
  domains: [string];
  createdDate: string;
  // taxonomyNodes: [string];
  sources: [string];
  taxonomyIds: [string];
  sourceTaxonomyIds: [string];
  topicTaxonomyIds: [string];
  hasChild: boolean;
  parentId: string;
  parentTitle: string;
  docPath: string;
  docType: string;
  url: string;
  weightage: string;
  tocCountry:string;
  type: string;
  tocRef: string;
  countries: [string];
  displayText:string;
}
