import { Injectable } from '@angular/core';
import { environment } from "environments/environment";
// import { AppInsights } from "applicationinsights-js";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';


import { AlexUrlResolverService, AlexUserService } from "app/core/services";
// import { AppInsights } from 'JavaScriptSDK.Module/AppInsightsModule';

@Injectable()
export class AppInsightService {
    private inProgressTracking = [];
    private config: any = {
        instrumentationKey: this.urlResolverService.getAppInsightConfig().instrumentationKey,
        maxAjaxCallsPerView: this.urlResolverService.getAppInsightConfig().maxAjaxCallsPerView,
        url: this.urlResolverService.getAppInsightConfig().url,
        disableAjaxTracking: this.urlResolverService.getAppInsightConfig().disableAjaxTracking
    };
    //  private config: Microsoft.ApplicationInsights.IConfig = {
    //   instrumentationKey:this.urlResolverService.getAppInsightConfig().instrumentationKey
    // };
    
    appInsights: ApplicationInsights;
    constructor(private urlResolverService: AlexUrlResolverService, private user: AlexUserService) {
        // if (!AppInsights.config && this.config.instrumentationKey) {
        //     AppInsights.downloadAndSetup(this.config);
        // }
        this.appInsights = new ApplicationInsights({
            config: {
              instrumentationKey: this.urlResolverService.getAppInsightConfig().instrumentationKey,
              enableAutoRouteTracking: true // option to log all route changes
            }
        });
        this.appInsights.loadAppInsights();
    }

    // logPageView(name?: string, url?: string, properties?: any,
    //     measurements?: any, duration?: number) {
    //     if (this.config.instrumentationKey)
    //         AppInsights.trackPageView(name, url, properties, measurements, duration);
    // }

    logPageView(name?: string, url?: string) {
        if (this.config.instrumentationKey)
            this.appInsights.trackPageView({name: name,uri:url});
    }

    // logEvent(name: string, properties?: any, measurements?: any) {
    //     if (this.config.instrumentationKey)
    //         AppInsights.trackEvent(name, properties, measurements);
    // }

    logEvent(name: string, properties?: any) {
        if (this.config.instrumentationKey)
            this.appInsights.trackEvent({
                name: name,
                properties: properties
              });
    }

    startTrackPage(name?: string) {
        if (this.config.instrumentationKey) {
            if (!this.inProgressTracking.includes(name)) {
                this.inProgressTracking.push(name);
                // AppInsights.startTrackPage(name);
                this.appInsights.startTrackPage(name);
                console.log(name);
                console.time();
            }
        }
    }

    stopTrackPage(name?: string, url?: string, properties?: { [name: string]: string; }, measurements?: { [name: string]: number; }) {
        if (this.config.instrumentationKey) {
            if (this.inProgressTracking.includes(name)) {
                this.inProgressTracking.splice(this.inProgressTracking.indexOf(name), 1);
                // AppInsights.stopTrackPage(name, url, properties, measurements);
                this.appInsights.stopTrackPage(name, url, properties, measurements);
                console.timeEnd();
            }
        }
    }
}
