
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Domain } from "./domain.model";
import { SpotLight, Carousel } from "app/shared/components";
import { ImageResponsive } from "app/shared/models";
import { CarouselItem } from "app/shared/components/carousel/model/carousel-item.model";
import { CarouselQuery } from "app/shared/components/carousel/model/carousel-query.model";
import { QueryCriteria } from "app/shared/components/carousel/model/carousel-query-criteria.model";
import { AlexUrlResolverService } from "app/core/services";
import { AppConfig } from "app/app.config";


import { Space } from "app/feature/spaces/model/space.model";
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class DomainService {
    environmentInstances:any;
    allMimeTypes:any;

    constructor(private http: HttpClient, private urlResolver: AlexUrlResolverService, private appConfig: AppConfig) {
        this.environmentInstances = environment;
        this.allMimeTypes = this.environmentInstances["allowedMimeTypes"];
    }

    getDomainData(domainName: string, carouselCount: Number): Observable<Domain> {
        return this.http.get<any>(this.urlResolver.resolveUrlApiV1(`home/homepage/${domainName}/${carouselCount}`)).pipe(
            map(response => {
                var domain = response.homepage as Domain;
                domain.name = domainName;
                domain.carousels.forEach(item => item.domain = domainName);
                return domain;
            }))
    }

    getDomainDataFromIdol(carousels: Array<Carousel>): Observable<Domain> {
        return this.http.post<any>(this.urlResolver.resolveUrlApiV1(`home/carousel`), carousels).pipe(
            //return this.http.post("http://localhost:59388/api/v1/home/carousel",carousels)
            map(response => {
                let obj = response;
                obj.Homepage = {
                    Spotlight: obj.Spotlight,
                    Carousels: obj.Carousels
                }
                var domain = obj as Domain;
                domain.carousels?.forEach(carousel => carousel.isLoaded = true);
                return domain;
            }))
    }

    getFileType(mimeType: string): string {
        let iconType = "file";
        mimeType = mimeType ? mimeType.toLowerCase() : mimeType;
        switch (mimeType) {
            case ("application/vnd.ms-excel" || "application/vnd.ms-excel.sheet.binary.macroenabled.12" || "application/vnd.ms-excel.sheet.macroenabled.12" || "application/vnd.openxmlformats-officedocument.spreadsheetml.template" || "application/vnd.ms-excel.template.macroenabled.12"):
                iconType = "excel";
                break;
            case ("application/msword" || "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || "application/vnd.ms-word.document.macroenabled.12" || "application/vnd.openxmlformats-officedocument.wordprocessingml.template" || "application/vnd.ms-word.template.macroenabled.12"):
                iconType = "word";
                break;
            case "text/plain":
                iconType = "word";
                break;
            case ("text/rtf" || "application/rtf"):
                iconType = "word";
                break;
            case "application/zip":
                iconType = "zip";
                break;
            case "application/xml":
                iconType = "file";
                break;
            case ("application/vnd.ms-powerpoint" || "application/vnd.ms-powerpoint.template.macroenabled.12" || "application/vnd.openxmlformats-officedocument.presentationml.template" || "application/vnd.ms-powerpoint.slideshow.macroenabled.12" || "application/vnd.ms-powerpoint.presentation.macroenabled.12" || "application/vnd.openxmlformats-officedocument.presentationml.presentation"):
                iconType = "ppt";
                break;
            case "application/pdf":
                iconType = "pdf";
                break;
            case "text/vnd.wap.wml":
                iconType = "msg";
                break;
            case "application/outlook":
                iconType = "msg";
                break;
            case "video/mpeg":
                iconType = "video";
                break;
            case "audio/mpeg":
                iconType = "video";
                break;
            case "audio/x-ms-wmv":
                iconType = "video";
                break;
            case "application/x-shockwave-flash":
                iconType = "video";
                break;
            case "video":
                iconType = "video";
                break;
            case "video/mp4":
                iconType = "video";
                break;
            case "web":
                iconType = "web";
                break;
            case ("text/html" || "html/xml" || "application/xml" || "xml" || "html"):
                iconType = "file";
                break;
            default: iconType = "other";

        }
        return iconType;
    }

    checkIsPreviewAllowed(mimeType: any) {
        let mimeTypeArr = this.allMimeTypes.split(",");
        const mimeType_trimArray = mimeTypeArr.map(element => {
            return element.toLowerCase().trim();
        });

        let iconType = "";
        mimeType = mimeType ? mimeType.toLowerCase() : mimeType;

        if (mimeType_trimArray.includes(mimeType)) {
            switch (mimeType) {
                case ("application/vnd.ms-excel" || "application/vnd.ms-excel.sheet.binary.macroenabled.12" || "application/vnd.ms-excel.sheet.macroenabled.12" || "application/vnd.openxmlformats-officedocument.spreadsheetml.template" || "application/vnd.ms-excel.template.macroenabled.12"):
                    iconType = "excel";
                    break;
                case ("application/msword" || "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || "application/vnd.ms-word.document.macroenabled.12" || "application/vnd.openxmlformats-officedocument.wordprocessingml.template" || "application/vnd.ms-word.template.macroenabled.12"):
                    iconType = "word";
                    break;
                case "text/plain":
                    iconType = "word";
                    break;
                case ("text/rtf" || "application/rtf"):
                    iconType = "word";
                    break;
                case ("application/vnd.ms-powerpoint" || "application/vnd.ms-powerpoint.template.macroenabled.12" || "application/vnd.openxmlformats-officedocument.presentationml.template" || "application/vnd.ms-powerpoint.slideshow.macroenabled.12" || "application/vnd.ms-powerpoint.presentation.macroenabled.12" || "application/vnd.openxmlformats-officedocument.presentationml.presentation"):
                    iconType = "ppt";
                    break;
                case "application/pdf":
                    iconType = "pdf";
                    break;
                default: iconType = "other";

            }
        }
        else {
            iconType = "other";
        }
        return iconType;
    }

}
