import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable ,  of } from "rxjs";
// import "rxjs/add/operator/filter";
// import "rxjs/add/operator/switchMap";

import { AlexUserService } from "app/core/services";
import { AllSourcesService } from 'app/feature/header/services/all-sources.service';

@Injectable()
export class AlexAllSourcesTaxonomyResolver implements Resolve<any> {

  constructor(
    private userService: AlexUserService,
    private allSourcesService: AllSourcesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (this.allSourcesService.fetched) {
      return of(null);
    } else {
      return this.allSourcesService.getAllSourcesTaxonomy();
    }
  }

}
