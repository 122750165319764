import { Component, OnInit, Input } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { AlexUserService, AlexUrlResolverService } from "app/core/services";
import { UserGuideItem } from "app/shared/components/userGuide/user-guide.model";
import { AlexUserGuideService } from "app/shared/components/userGuide/user-guide.service";

@Component({
  selector: "alex-user-guide",
  templateUrl: "./user-guide.component.html",
  styleUrls: ["./user-guide.component.scss"],
  animations: [
    trigger("visibilityChanged", [
      state("true", style({ opacity: 1 })),
      state("false", style({ opacity: 0 })),
      transition("1 => 0", animate("0ms")),
      transition("0 => 1", animate("250ms")),
    ]),
  ],
})
export class AlexUserGuideComponent implements OnInit {
  @Input() external: boolean;
  imagePathList: Array<string>;
  userGuideItem = new UserGuideItem();
  userGuideItems: Array<UserGuideItem> = [];
  currentImagePath: string;
  currentTitle: string;
  currentDesc: string;
  currentIndex: number = 1;
  clicked = true;
  parentPage = "";
  isBackValid = false;
  changeEvent = false;

  //animation
  transitionState = false;
  timer: any;

  constructor(
    private urlResolverService: AlexUrlResolverService,
    private userGuideService: AlexUserGuideService,
    private userService: AlexUserService
  ) {}

  ngOnInit() {
    this.userGuideService
      .fetchUserGuideContent(this.external)
      .subscribe((userGuideItem) => {
        this.userGuideItems = userGuideItem;
        this.setDetails(this.currentIndex);

        //Update the onboarding flag
        this.userGuideService.updateOnboardingFlag().subscribe(() => {
          this.userService.user.isNewUser = false;
        });
      });
  }

  close() {
    this.userService.showUserGuide$.next(false);
  }

  back() {
    if (this.currentIndex >= 2) {
      --this.currentIndex;
      this.setDetails(this.currentIndex);
      this.changeEvent = false;
    }
    if (this.currentIndex == 1) {
      this.isBackValid = false;
    }
  }

  next() {
    if (!this.changeEvent) {
      if (this.currentIndex + 1 < this.userGuideItems.length) {
        ++this.currentIndex;
        this.setDetails(this.currentIndex);
        this.isBackValid = true;
      }
      if (this.currentIndex + 1 == this.userGuideItems.length) {
        this.changeEvent = true;
      }
    } else if (this.changeEvent) {
      this.close();
    }
  }

  setDetails(index) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.transitionState = false;

    this.userGuideItem = this.userGuideItems[index];

    this.currentImagePath = this.urlResolverService.getUserGuideUrl(
      this.userGuideItem.url
    );

    this.currentTitle = this.userGuideItem.title;
    this.currentDesc = this.userGuideItem.desc;
    this.timer = setTimeout(() => {
      this.transitionState = true;
    }, 250);
  }
}
