export enum ExcerptNavigationType{
    Click,
    Next,    
    Previous
}

export enum Direction{
    Back,
    Forward
}

export enum AlexContext{
    TOC,
    TOCSearch
}