
import { DefaultUrlSerializer, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class AlexLowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        if (url && url.toLowerCase().indexOf('documentwindow.aspx') >= 0) {
            url = url.toLowerCase();
        }
        if (url && url.toLowerCase().indexOf('home.aspx') >= 0) {
            url = url.toLowerCase();
        }
        //Added for manuals
        if (url && url.toLowerCase().indexOf('document/manuals') >= 0) {
            url = url.toLowerCase();
        }
        try {
            if (url) {
                const urlPart = url.split('/');
                if (urlPart && urlPart.length) {
                    let lastUrlPart = urlPart.pop();
                    if (!lastUrlPart)
                        lastUrlPart = urlPart.pop();
                    if (lastUrlPart.toLowerCase() == 'aroweb')
                        url = url.toLowerCase();
                }
            }
        } catch (error) {}

        return super.parse(url);
    }
}