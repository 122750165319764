import { AlexFilterTagModel } from "app/feature/search/search-result-tag/filter-tag.model";
import {
  REMOVE_SOURCE_FILTER,
  REMOVE_TOPIC_FILTER,
  REMOVE_DATE_FILTER,
  REMOVE_FILE_FILTER,
  REMOVE_LANGUAGE_FILTER,
  REMOVE_ALL_TAGS,
  SOURCE_FILTER,
  TOPIC_FILTER,
  DATE_FILTER,
  FILE_FILTER,
  LANGUAGE_FILTER,
  REMOVE_PARENT_ID_FILTER,
  SEE_ALL_RESULT,
  OTHER_CATEGORY_FILTER,
  REMOVE_OTHER_CATEGORY_FILTER,
  REMOVE_COUNTRY_FILTER,
  COUNTRY_FILTER,
  APPLICABLE_COUNTRY_FILTER,
  REMOVE_APPLICABLE_COUNTRY_FILTER,
  WORKFLOW_FILTER,
  REMOVE_WORKFLOW_FILTER,
  VERSION_FILTER,
  REMOVE_VERSION_FILTER,
  REMOVE_TOC_REF_FILTER,
} from "app/feature/search/ACTIONS/search-action-types";
import { AlexSearchViewModel } from "app/feature/search/model/search.viewmodel";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { AlexTaxonomyNodeModel } from "app/feature/search/model/filters/taxonomy-node.model";
import { SearchParamService } from "app/feature/search/services/search-param.service";
import { AlexSourceFilterModel } from "app/feature/search/model/filters/source-filter.model";
import { DateSelectionType } from "app/feature/search/model/filters/date-selction-type.enums";
import { AlexSearchResult } from "app/feature/search/model/content/search-result.model";
import { AlexDateFilterModel } from "app/feature/search/model/filters/date-filter.model";
import { AlexCheckBoxModel } from "app/feature/search/model/filters/checkbox.model";
import { AlexAdvanceFilteViewModel } from "app/feature/search/model/filters/advance-filter.viewmodel";
import * as moment from "moment";
import { AlexPaginationModel } from "app/shared/components/pagination/pagination.model";
import { AlexDropdownModel } from "app/shared/components/dropdown/dropdown.model";
import { AlexDropdownItemModel } from "app/shared/components/dropdown/dropdown-item.model";
import { AlexPaginationEventModel } from "app/shared/components/pagination/pagination.event.model";
import { AlexPaginationService } from "app/shared/components/pagination/pagination.service";
import { TaxonomyFilter } from "app/feature/search/model/taxonomy-filter.model";
import { SpinnerState } from "app/shared/components/spinner/spinner.state";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";

@Injectable()
export class AlexSearchViewModelService {
  public searchViewModel: AlexSearchViewModel;
  public readonly searchViewModel$: Subject<AlexSearchViewModel> = new Subject<AlexSearchViewModel>();

  constructor(
    private searchParamService: SearchParamService,
    private spinnerService: SpinnerService,
    private paginationService: AlexPaginationService
  ) {
    this.searchViewModel = new AlexSearchViewModel();
    this.searchViewModel.pagination = new AlexPaginationModel();
    this.initializeSortDropdown();
  }

  /* new change start */
  private initializeSortDropdown() {
    this.searchViewModel.sortDropdownModel = new AlexDropdownModel();
    this.searchViewModel.sortDropdownModel.id = "searchPageSortDropdown";
    this.searchViewModel.sortDropdownModel.name = "sortDropdown";
    this.searchViewModel.sortDropdownModel.items = this.searchParamService.getSortDropdownPristineValue();
  }
  getsortDropdownData(): AlexDropdownModel {
    if (!this.searchViewModel.sortDropdownModel) {
      this.initializeSortDropdown();
    }
    return this.searchViewModel.sortDropdownModel;
  }
  updateSortDropDownData(dropdownModel: AlexDropdownModel) {
    this.searchViewModel.sortDropdownModel = dropdownModel;
  }
  resetSortDropDownData(dropdownModel: AlexDropdownModel) {
    this.searchViewModel.sortDropdownModel.items = this.searchParamService.getSortDropdownPristineValue();
  }
  changeSortSelection(key: string) {
    this.searchViewModel.sortDropdownModel.items =
      this.searchViewModel.sortDropdownModel.items ||
      this.searchParamService.getSortDropdownPristineValue();
    this.searchViewModel.sortDropdownModel.items = this.searchViewModel.sortDropdownModel.items.map(
      (item) => {
        item.selected = item.value == key;
        return item;
      }
    );
  }

  getPaginationData(): AlexPaginationModel {
    return this.searchViewModel.pagination;
  }

  public getTags(): Array<AlexFilterTagModel> {
    return this.searchViewModel.tags;
  }
  public updateFilterTagList() {
    this.searchViewModel.tags = new Array<AlexFilterTagModel>();
    this.addSourceFilterTagKeys();
    this.addTopicFilterTagKeys();
    this.addOtherCategoryFilterTagKeys();
    //Added for country
    this.addCountryFilterTagKeys();
    this.addDateFilterTags();
    this.addFileTypeFilterTags();
    this.addLanguageFilterTags();
    this.addParentDocIdTagKey();
    this.sortFilterTags();

    //Added for combined search
    this.addAppCountryFilterTags();
    this.addAuditStandardFilterTags();
  }

  updateTagDisplayValue(tags = {}) {
    this.searchViewModel.tags.forEach((item) => {
      if (tags[item.key]) {
        item.displayText = tags[item.key];
      }
    });
  }
  updateTagDisplay(
    tags = {},
    topics: TaxonomyFilter,
    otherCategories: TaxonomyFilter,
    countries: TaxonomyFilter,
    sources: Array<AlexTaxonomyNodeModel>
  ) {
    this.searchViewModel.tags.forEach((item) => {
      if (tags[item.key]) {
        item.displayText = tags[item.key];
      } else {
        if (topics != null && topics.nodes != null) {
          topics.nodes.forEach((topic) => {
            if (item.key == topic.id) {
              item.displayText = topic.name;
            }
          });
        }
        if (otherCategories != null && otherCategories.nodes != null) {
          otherCategories.nodes.forEach((otherCategory) => {
            if (item.key == otherCategory.id) {
              item.displayText = otherCategory.name;
            }
          });
        }
        if (countries != null && countries.nodes != null) {
          countries.nodes.forEach((country) => {
            if (item.key == country.id) {
              item.displayText = country.name;
            }
          });
        }
        if (sources) {
          sources.forEach((source) => {
            if (item.key == source.id) {
              item.displayText = source.name;
            } else {
              source.nodes.forEach((node) => {
                if (item.key == node.id) {
                  item.displayText = node.name;
                }
              });
            }
          });
        }
      }
    });
  }

  private addTocRefTagKey() {
    if (
      this.searchParamService.filterParam &&
      this.searchParamService.filterParam.seeAllResult &&
      this.searchParamService.filterParam.seeAllResult.tocRef
    ) {
      this.searchViewModel.tags.unshift({
        id: `tag${1}`,
        key: this.searchParamService.filterParam.seeAllResult.tocRef,
        filterType: SEE_ALL_RESULT,
        action: REMOVE_TOC_REF_FILTER,
        displayText: "",
        data: this.searchParamService.filterParam.seeAllResult.tocRef,
        order: 0,
      });
    }
  }

  private sortFilterTags() {
    this.searchViewModel.tags = this.searchViewModel.tags.sort(
      (item1, item2) => {
        if (item1.order < item2.order) return -1;
        if (item1.order > item2.order) return 1;
        return 0;
      }
    );
  }

  taxonomyTreeToFlatArray(
    tree: Array<AlexTaxonomyNodeModel>
  ): Array<AlexTaxonomyNodeModel> {
    const flatArray = new Array<AlexTaxonomyNodeModel>();
    tree.forEach((item) => {
      flatArray.push(item);
      if (item.nodes && item.nodes.length) {
        flatArray.push(...this.taxonomyTreeToFlatArray(item.nodes));
      }
    });
    return flatArray;
  }
  private removeFilterTags(filterTag: AlexFilterTagModel) {}
  private addSourceFilterTagKeys() {
    if (
      this.searchParamService.filterParam.sources &&
      this.searchParamService.filterParam.sources.length
    ) {
      this.searchParamService.filterParam.sources.forEach((selectedSource) =>
        this.addTagFromSourceTree(selectedSource)
      );
    }
  }

  private addTagFromSourceTree(source: AlexSourceFilterModel, path = []) {
    path.push(source.k);
    if (source.o) {
      this.searchViewModel.tags.push({
        id: `tag${source.k}`,
        key: source.k,
        filterType: SOURCE_FILTER,
        action: REMOVE_SOURCE_FILTER,
        displayText: source.k === "0" ? "KAEG" : source.k,
        data: path,
        order: source.o,
      });
    }

    if (source.c && source.c.length) {
      source.c.forEach((taxonomyNode) => {
        this.addTagFromSourceTree(taxonomyNode, path.slice());
      });
    }
  }

  private addTopicFilterTagKeys() {
    if (
      this.searchParamService.filterParam.topics &&
      this.searchParamService.filterParam.topics.length
    ) {
      this.searchParamService.filterParam.topics.forEach((topic) => {
        this.searchViewModel.tags.push({
          id: `tag${topic.k}`,
          key: topic.k,
          filterType: TOPIC_FILTER,
          action: REMOVE_TOPIC_FILTER,
          displayText: topic.k,
          data: topic,
          order: topic.o,
        });
      });
    }
  }
  private addOtherCategoryFilterTagKeys() {
    if (
      this.searchParamService.filterParam.otherCategories &&
      this.searchParamService.filterParam.otherCategories.length
    ) {
      this.searchParamService.filterParam.otherCategories.forEach(
        (othercategory) => {
          this.searchViewModel.tags.push({
            id: `tag${othercategory.k}`,
            key: othercategory.k,
            filterType: OTHER_CATEGORY_FILTER,
            action: REMOVE_OTHER_CATEGORY_FILTER,
            displayText: othercategory.k,
            data: othercategory,
            order: othercategory.o,
          });
        }
      );
    }
  }
  private addCountryFilterTagKeys() {
    if (
      this.searchParamService.filterParam.countries &&
      this.searchParamService.filterParam.countries.length
    ) {
      this.searchParamService.filterParam.countries.forEach((country) => {
        this.searchViewModel.tags.push({
          id: `tag${country.k}`,
          key: country.k,
          filterType: COUNTRY_FILTER,
          action: REMOVE_COUNTRY_FILTER,
          displayText: country.k,
          data: country,
          order: country.o,
        });
      });
    }
  }
  private addDateFilterTags() {
    if (this.searchParamService.filterParam.date) {
      this.searchViewModel.tags.push({
        id: `tag${
          DateSelectionType[this.searchParamService.filterParam.date.selection]
        }`,
        key: "",
        filterType: DATE_FILTER,
        action: REMOVE_DATE_FILTER,
        displayText: this.getDateFilterDisplayText(
          this.searchParamService.filterParam.date.selection
        ),
        data: this.searchParamService.filterParam.date,
        order: this.searchParamService.filterParam.date.o,
      });
    }
  }
  private getDateFilterDisplayText(dateSelection: DateSelectionType): string {
    let displayText = "";
    switch (dateSelection) {
      case DateSelectionType.Custom:
        displayText = "Custom date";
        break;
      case DateSelectionType.Last30Days:
        displayText = "Last 30 Days";
        break;
      case DateSelectionType.Last60Days:
        displayText = "Last 60 Days";
        break;
      case DateSelectionType.Last90Days:
        displayText = "Last 90 Days";
        break;
      default:
        displayText = "";
    }

    return displayText;
  }
  private addFileTypeFilterTags() {
    if (
      this.searchParamService.filterParam.fileTypes &&
      this.searchParamService.filterParam.fileTypes.length
    ) {
      this.searchParamService.filterParam.fileTypes.forEach((fileType) => {
        this.searchViewModel.tags.push({
          id: `tag${fileType.k}`,
          key: fileType.k,
          filterType: FILE_FILTER,
          action: REMOVE_FILE_FILTER,
          displayText: fileType.k,
          data: fileType.k,
          order: fileType.o,
        });
      });
    }
  }
  private addLanguageFilterTags() {
    if (
      this.searchParamService.filterParam.languages &&
      this.searchParamService.filterParam.languages.length
    ) {
      this.searchParamService.filterParam.languages.forEach((language) => {
        this.searchViewModel.tags.push({
          id: `tag${language.k}`,
          key: language.k,
          filterType: LANGUAGE_FILTER,
          action: REMOVE_LANGUAGE_FILTER,
          displayText: language.k,
          data: language.k,
          order: language.o,
        });
      });
    }
  }

  //Added for combined search starts
  private addAppCountryFilterTags() {
    if (
      this.searchParamService.filterParam.applicableCountries &&
      this.searchParamService.filterParam.applicableCountries.length
    ) {
      this.searchParamService.filterParam.applicableCountries.forEach(
        (applicableCountries) => {
          this.searchViewModel.tags.push({
            id: `tag${applicableCountries.k}`,
            key: applicableCountries.k,
            filterType: APPLICABLE_COUNTRY_FILTER,
            action: REMOVE_APPLICABLE_COUNTRY_FILTER,
            displayText: applicableCountries.k,
            data: applicableCountries.k,
            order: applicableCountries.o,
          });
        }
      );
    }
  }

  private addAuditStandardFilterTags() {
    if (
      this.searchParamService.filterParam.workflowType &&
      this.searchParamService.filterParam.workflowType.length
    ) {
      this.searchParamService.filterParam.workflowType.forEach(
        (workflowType) => {
          this.searchViewModel.tags.push({
            id: `tag${workflowType.k}`,
            key: workflowType.k,
            filterType: WORKFLOW_FILTER,
            action: REMOVE_WORKFLOW_FILTER,
            displayText: workflowType.k,
            data: workflowType.k,
            order: workflowType.o,
          });
        }
      );
    }
  }

  private addVersionFilterTags() {
    if (
      this.searchParamService.filterParam.version &&
      this.searchParamService.filterParam.version.length
    ) {
      this.searchParamService.filterParam.version.forEach((version) => {
        this.searchViewModel.tags.push({
          id: `tag${version.k}`,
          key: version.k,
          filterType: VERSION_FILTER,
          action: REMOVE_VERSION_FILTER,
          displayText: version.k,
          data: version.k,
          order: version.o,
        });
      });
    }
  }
  //Added for combined search ends
  private addParentDocIdTagKey() {
    if (
      this.searchParamService.filterParam &&
      this.searchParamService.filterParam.seeAllResult &&
      this.searchParamService.filterParam.seeAllResult.parentDocId
    ) {
      this.searchViewModel.tags.unshift({
        id: `tag${1}`,
        key: this.searchParamService.filterParam.seeAllResult.parentDocId,
        filterType: SEE_ALL_RESULT,
        action: REMOVE_PARENT_ID_FILTER,
        displayText: "",
        data: this.searchParamService.filterParam.seeAllResult.parentDocId,
        order: 0,
      });
    }
  }

  removeFilterTag(tag: AlexFilterTagModel) {
    if (tag.action == REMOVE_SOURCE_FILTER) {
      if (Array.isArray(tag.data)) {
        const nodesToKeep = this.findNodesToKeep(tag);
        this.searchParamService.filterParam.sources = this.removeNodeFromSourceTree(
          tag.data,
          nodesToKeep,
          this.searchParamService.filterParam.sources
        );
        if (
          Array.isArray(this.searchParamService.filterParam.sources) &&
          this.searchParamService.filterParam.sources.length === 0
        ) {
          this.searchParamService.filterParam.allSource = true;
        }
      }
    } else if (tag.action == REMOVE_TOPIC_FILTER) {
      this.searchParamService.filterParam.topics = this.searchParamService.filterParam.topics.filter(
        (topic) => topic.k != tag.key
      );
    } else if (tag.action == REMOVE_OTHER_CATEGORY_FILTER) {
      this.searchParamService.filterParam.otherCategories = this.searchParamService.filterParam.otherCategories.filter(
        (other) => other.k != tag.key
      );
    } else if (tag.action == REMOVE_COUNTRY_FILTER) {
      this.searchParamService.filterParam.countries = this.searchParamService.filterParam.countries.filter(
        (country) => country.k != tag.key
      );
    } else if (tag.action == REMOVE_DATE_FILTER) {
      this.searchParamService.filterParam.date = null;
    } else if (tag.action == REMOVE_FILE_FILTER) {
      this.searchParamService.filterParam.fileTypes = this.searchParamService.filterParam.fileTypes.filter(
        (file) => file.k != tag.key
      );
    } else if (tag.action == REMOVE_LANGUAGE_FILTER) {
      this.searchParamService.filterParam.languages = this.searchParamService.filterParam.languages.filter(
        (language) => language.k != tag.key
      );
    }
    //Added for combined search
    else if (tag.action == REMOVE_APPLICABLE_COUNTRY_FILTER) {
      this.searchParamService.filterParam.applicableCountries = this.searchParamService.filterParam.applicableCountries.filter(
        (applicableCountry) => applicableCountry.k != tag.key
      );
    } else if (tag.action == REMOVE_WORKFLOW_FILTER) {
      this.searchParamService.filterParam.workflowType = this.searchParamService.filterParam.workflowType.filter(
        (workflow) => workflow.k != tag.key
      );
    } else if (tag.action == REMOVE_VERSION_FILTER) {
      this.searchParamService.filterParam.version = this.searchParamService.filterParam.version.filter(
        (version) => version.k != tag.key
      );
    } else if (tag.action == REMOVE_PARENT_ID_FILTER) {
      this.searchParamService.filterParam.seeAllResult = null;
      this.searchParamService.loadAllFilter(true);
    } else if (tag.action == REMOVE_ALL_TAGS) {
      this.searchParamService.clearAllFilter();
    }
  }

  private findNodesToKeep(currentTag: AlexFilterTagModel): Array<string> {
    const sourceTags = this.searchViewModel.tags.filter(
      (tag) => tag.action == REMOVE_SOURCE_FILTER && tag.key != currentTag.key
    );
    const otherFilterPath = new Array<string>();

    if (sourceTags && sourceTags.length)
      sourceTags.forEach((sourceTag) => {
        otherFilterPath.unshift(...sourceTag.data);
      });

    return otherFilterPath;
  }

  private removeNodeFromSourceTree(
    nodePath: Array<string>,
    nodesToKeep: Array<string>,
    sourceFilters: Array<AlexSourceFilterModel>
  ): Array<AlexSourceFilterModel> {
    sourceFilters = sourceFilters || new Array<AlexSourceFilterModel>();

    const node = nodePath[0];
    const nodeIndex = sourceFilters.findIndex((source) => source.k == node);

    const remainingPath = nodePath.slice(1);
    if (nodeIndex > -1 && remainingPath.length && nodesToKeep.includes(node)) {
      sourceFilters[nodeIndex].c = this.removeNodeFromSourceTree(
        remainingPath,
        nodesToKeep,
        sourceFilters[nodeIndex].c
      );
    } else {
      sourceFilters.splice(nodeIndex, 1);
    }

    return sourceFilters;
  }

  updateSearchResultViewModel(searchResult: AlexSearchResult) {
    this.searchViewModel.result = searchResult;
    const { combinedSearchType } = this.searchParamService.getFilterData();
    if (combinedSearchType) {
      this.updatePaginationModel(
        Number(searchResult.combinedPerPageCount) || 25,
        Number(searchResult.combinedTotalCount)
      );
    } else {
      this.updatePaginationModel(0, 0);
    }
  }
  setFilterViewModel(filterViewModel: any) {
    this.searchViewModel.filter = filterViewModel;
  }
  setFilterTagViewModel(filterTagsViewModel: any) {
    this.searchViewModel.tags = filterTagsViewModel;
  }

  updateAllFilterViewModel(searchFilterResponse: any) {
    this.searchViewModel.filter = new AlexAdvanceFilteViewModel();
    this.updateSpecificFilterViewModel(searchFilterResponse);
    this.updateSourceFilterViewModel(
      searchFilterResponse.taxonomySourceFilterSearch
    );
  }
  updateSpecificFilterViewModel(searchFilterResponse: any) {
    this.searchViewModel.filter = Object.assign(
      {},
      this.searchViewModel.filter
    );
    this.updateDateFilterViewModel();
    this.updateFileTypeViewModel(searchFilterResponse.fileTypeFilterModel);
    this.updateLanguageFilterViewModel(
      searchFilterResponse.languageFilterModel
    );
    this.updateTopicFilterViewModel(
      searchFilterResponse.taxonomyTopicFilterSearch
    );
    //Add for other categories
    this.updateOtherCategoriesFilterViewModel(
      searchFilterResponse.taxonomyOtherFilterSearch
    );
    //Add for Country
    this.updateCountryFilterViewModel(
      searchFilterResponse.taxonomCountryFilterModel
    );
    //Added for combined search
    this.updateApplicableCountryViewModel(searchFilterResponse.kaegFilterModel);
    this.updateWorkflowTypeViewModel(searchFilterResponse.kaegFilterModel);
    this.updateVersionViewModel(searchFilterResponse.kaegFilterModel);
  }

  updateSourceFilterViewModel(sourceFilter: any) {
    // logic to sync source filter with allsource dropdown
    if (Array.isArray(sourceFilter)) {
      if (
        Array.isArray(this.searchParamService.filterParam.sources) &&
        this.searchParamService.filterParam.sources.length
      ) {
        const selectedSourcesSet = new Set<string>(
          this.searchParamService.filterParam.sources.map((source) =>
            source.k?.toString()
          )
        );
        this.searchViewModel.filter.sources = sourceFilter.filter((item) =>
          selectedSourcesSet.has(item.id?.toString())
        ) as Array<AlexTaxonomyNodeModel>;
      } else {
        this.searchViewModel.filter.sources = sourceFilter as Array<AlexTaxonomyNodeModel>;
      }
    }
  }
  private updateTopicFilterViewModel(topicFilter: any) {
    if (topicFilter && topicFilter.nodes) {
      this.searchViewModel.filter.topics = topicFilter.nodes as Array<AlexTaxonomyNodeModel>;
    } else this.searchViewModel.filter.topics = null;
  }

  //Add for other categories
  private updateOtherCategoriesFilterViewModel(otherFilter: any) {
    if (otherFilter && otherFilter.nodes) {
      this.searchViewModel.filter.otherCategories = otherFilter.nodes as Array<AlexTaxonomyNodeModel>;
    } else this.searchViewModel.filter.otherCategories = null;

    // const othercategoryNone = new AlexTaxonomyNodeModel();
    // othercategoryNone.name = othercategoryNone.id = "All";
    // this.searchViewModel.filter.otherCategories.unshift(othercategoryNone);
  }

  private updateCountryFilterViewModel(country: any) {
    if (country && country.nodes) {
      this.searchViewModel.filter.countries = country.nodes as Array<AlexTaxonomyNodeModel>;
    } else this.searchViewModel.filter.countries = null;

    // const othercategoryNone = new AlexTaxonomyNodeModel();
    // othercategoryNone.name = othercategoryNone.id = "All";
    // this.searchViewModel.filter.otherCategories.unshift(othercategoryNone);
  }

  private updateDateFilterViewModel() {
    if (this.searchParamService.filterParam.date) {
      this.searchViewModel.filter.date = this.searchParamService.filterParam.date;
    } else {
      this.searchViewModel.filter.date = new AlexDateFilterModel();
      this.searchViewModel.filter.date.to = this.searchViewModel.filter.date.from = moment(
        new Date()
      ).format("DD MMM YYYY");
      this.searchViewModel.filter.date.selection = DateSelectionType.None;
    }

    this.searchViewModel.filter.date = Object.assign(
      {},
      this.searchViewModel.filter.date
    );
  }
  private updateFileTypeViewMode1(fileFilter: any) {
    if (fileFilter && fileFilter.extensions) {
      this.searchViewModel.filter.fileTypes = fileFilter.extensions.map(
        (extension) => {
          const fileType = new AlexCheckBoxModel();
          fileType.name = fileType.value = extension;
          fileType.checked = !!(
            this.searchParamService.filterParam.fileTypes &&
            this.searchParamService.filterParam.fileTypes.includes(extension)
          );
          return fileType;
        }
      );
    } else {
      //when we are not getting any filter from search result
      if (
        this.searchViewModel &&
        this.searchViewModel.filter &&
        this.searchViewModel.filter.fileTypes
      ) {
        this.searchViewModel.filter.fileTypes = this.searchViewModel.filter.fileTypes.map(
          (fileType) => {
            fileType.checked = !!(
              this.searchParamService.filterParam.fileTypes &&
              this.searchParamService.filterParam.fileTypes
                .map((fileType) => fileType.k)
                .includes(fileType.value)
            );
            return fileType;
          }
        );
      }
    }
  }
  private updateFileTypeViewModel(fileFilter: any) {
    if (fileFilter && fileFilter.extensions) {
      this.searchViewModel.filter.fileTypes = fileFilter.extensions.map(
        (extension) => {
          const fileType = new AlexCheckBoxModel();
          fileType.name = fileType.value = extension;
          return fileType;
        }
      );
      const fileTypeNone = new AlexCheckBoxModel();
      fileTypeNone.name = fileTypeNone.value = "All";
      this.searchViewModel.filter.fileTypes.unshift(fileTypeNone);
    }
    if (
      this.searchViewModel &&
      this.searchViewModel.filter &&
      this.searchViewModel.filter.fileTypes &&
      this.searchViewModel.filter.fileTypes.length
    ) {
      if (
        this.searchParamService.filterParam.fileTypes &&
        this.searchParamService.filterParam.fileTypes.length
      ) {
        this.searchViewModel.filter.fileTypes = this.searchViewModel.filter.fileTypes.map(
          (fileType) => {
            fileType.checked = !!(
              this.searchParamService.filterParam.fileTypes &&
              this.searchParamService.filterParam.fileTypes
                .map((fileType) => fileType.k)
                .includes(fileType.value)
            );
            return fileType;
          }
        );
      } else {
        this.searchViewModel.filter.fileTypes = this.searchViewModel.filter.fileTypes.map(
          (fileType) => {
            fileType.checked = false;
            return fileType;
          }
        );
        this.searchViewModel.filter.fileTypes[0].checked = true;
      }
    }
  }

  private updateLanguageFilterViewModel(languageFilter: any) {
    if (languageFilter && languageFilter.languageNames) {
      this.searchViewModel.filter.languages = languageFilter.languageNames.map(
        (lang) => {
          const language = new AlexCheckBoxModel();
          language.name = language.value = lang;
          language.checked = !!(
            this.searchParamService.filterParam.languages &&
            this.searchParamService.filterParam.languages.includes(lang)
          );
          return language;
        }
      );
    } else {
      if (
        this.searchViewModel &&
        this.searchViewModel.filter &&
        this.searchViewModel.filter.languages
      ) {
        this.searchViewModel.filter.languages = this.searchViewModel.filter.languages.map(
          (language) => {
            language.checked = !!(
              this.searchParamService.filterParam.languages &&
              this.searchParamService.filterParam.languages
                .map((lang) => lang.k)
                .includes(language.value)
            );
            return language;
          }
        );
      }
    }
  }

  //Added for Combined Search start
  private updateApplicableCountryViewModel(kaegFilters: any) {
    const selectedCountriesSet = new Set<string>(
      (this.searchParamService.filterParam.applicableCountries || []).map((c) =>
        c.k.toLowerCase()
      )
    );
    if (
      Array.isArray(kaegFilters?.applicableCountries) &&
      kaegFilters?.applicableCountries.length > 0
    ) {
      this.searchViewModel.filter.applicableCountries = kaegFilters.applicableCountries.map(
        (country) => {
          const countryCheckbox = new AlexCheckBoxModel();
          countryCheckbox.name = countryCheckbox.value = country;
          countryCheckbox.checked = selectedCountriesSet.has(
            country.toLowerCase()
          );
          return countryCheckbox;
        }
      );
    } else {
      if (
        this.searchViewModel &&
        this.searchViewModel.filter &&
        this.searchViewModel.filter.applicableCountries
      ) {
        this.searchViewModel.filter.applicableCountries = this.searchViewModel.filter.applicableCountries.map(
          (country) => {
            country.checked = selectedCountriesSet.has(
              country.value.toLowerCase()
            );
            return country;
          }
        );
      }
    }
  }

  private updateWorkflowTypeViewModel(kaegFilters: any) {
    const selectedWorkflowTypesSet = new Set<string>(
      (this.searchParamService.filterParam.workflowType || []).map((wft) =>
        wft.k.toLowerCase()
      )
    );
    if (
      Array.isArray(kaegFilters?.auditingStandards) &&
      kaegFilters?.auditingStandards.length > 0
    ) {
      this.searchViewModel.filter.workflowType = kaegFilters.auditingStandards.map(
        (audit) => {
          const auditStandard = new AlexCheckBoxModel();
          auditStandard.name = auditStandard.value = audit;
          auditStandard.checked = selectedWorkflowTypesSet.has(
            audit.toLowerCase()
          );
          return auditStandard;
        }
      );
    } else {
      if (
        this.searchViewModel &&
        this.searchViewModel.filter &&
        this.searchViewModel.filter.workflowType
      ) {
        this.searchViewModel.filter.workflowType = this.searchViewModel.filter.workflowType.map(
          (audit) => {
            audit.checked = selectedWorkflowTypesSet.has(
              audit.value.toLowerCase()
            );
            return audit;
          }
        );
      }
    }
  }

  private updateVersionViewModel(kaegFilters: any) {
    const selectedVersionsSet = new Set<string>(
      (this.searchParamService.filterParam.version || []).map((v) =>
        v.k.toLowerCase()
      )
    );
    if (
      Array.isArray(kaegFilters?.versions) &&
      kaegFilters?.versions.length > 0
    ) {
      this.searchViewModel.filter.version = kaegFilters.versions.map(
        (version) => {
          const versionCheckbox = new AlexCheckBoxModel();
          versionCheckbox.name = versionCheckbox.value = version;
          versionCheckbox.checked = selectedVersionsSet.has(
            version.toLowerCase()
          );

          return versionCheckbox;
        }
      );
    } else {
      if (
        this.searchViewModel &&
        this.searchViewModel.filter &&
        this.searchViewModel.filter.version
      ) {
        this.searchViewModel.filter.version = this.searchViewModel.filter.version.map(
          (ver) => {
            ver.checked = selectedVersionsSet.has(ver.value.toLowerCase());
            return ver;
          }
        );
      }
    }

    if (
      Array.isArray(this.searchViewModel.filter.version) &&
      this.searchViewModel.filter.version.length > 0
    ) {
      this.searchViewModel.filter.version = this.searchViewModel.filter.version.sort(
        (v1, v2) => {
          const v1Num = Number(v1?.value);
          const v2Num = Number(v2?.value);
          return v1Num - v2Num;
        }
      );
    }
  }

  //Added for Combined Search end

  private updateLanguageFilterExistingViewModel() {
    if (
      this.searchViewModel.filter.languages &&
      this.searchViewModel.filter.languages.length
    ) {
      this.searchViewModel.filter.languages.map((lang) => {
        lang.checked = !!(
          this.searchParamService.filterParam.languages &&
          this.searchParamService.filterParam.languages
            .map((lang) => lang.k)
            .includes(lang.value)
        );
        return lang;
      });
    }
  }
  private updateFileTypeFilterExistingViewModel() {
    if (
      this.searchViewModel.filter.fileTypes &&
      this.searchViewModel.filter.fileTypes.length
    ) {
      this.searchViewModel.filter.fileTypes.map((file) => {
        file.checked = !!(
          this.searchParamService.filterParam.fileTypes &&
          this.searchParamService.filterParam.fileTypes
            .map((fileType) => fileType.k)
            .includes(file.value)
        );
        return file;
      });
    }
  }

  public enableFilter(status: boolean) {
    if (this.searchViewModel.filter) {
      this.searchViewModel.filter.isRequired = status;
    }
  }

  public updateView() {
    this.spinnerService.changeSpinnerState(
      SpinnerState.RenderingView,
      "Rendering View"
    );
    this.searchViewModel$.next(this.searchViewModel);
  }

  public resetPaginationModel() {
    this.searchViewModel.pagination = new AlexPaginationModel();
  }
  public updatePaginationModel(itemsPerPage: number, resultCount: number) {
    this.searchViewModel.pagination = this.paginationService.updatePaginationModel(
      this.searchViewModel.pagination,
      itemsPerPage,
      resultCount
    );
  }
  public handlePaginationEvent(model: AlexPaginationEventModel) {
    const updatedPaginationStartEnd = this.paginationService.handlePaginationEvent(
      model
    );
    const updatedPaginationModel = this.paginationService.updatePaginationModel(
      updatedPaginationStartEnd,
      updatedPaginationStartEnd.itemsPerPage,
      updatedPaginationStartEnd.recordCount
    );
    this.searchViewModel.pagination = updatedPaginationModel;
    this.searchViewModel$.next({ ...this.searchViewModel });
    return updatedPaginationModel;
  }
}
