import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { SkinnyHeaderModel } from "app/core/services/skinny-header/skinny-header.model";
import { HeaderState } from "app/core/services/skinny-header/header-state.enum";
import { DeviceType, AlexAppService } from "app/core/services";

@Injectable()
export class AlexSkinnyHeaderService {
  headerState: Subject<SkinnyHeaderModel>;
  currentHeaderState:HeaderState;
  private deviceType: DeviceType;

  constructor(private appService: AlexAppService) {
    this.deviceType = this.appService.detectDeviceType();
    this.headerState = new Subject<SkinnyHeaderModel>();
  }
  
  slideHeader(headerState: HeaderState, _event:Event=null) {
    this.currentHeaderState=headerState;
    if (this.deviceType == DeviceType.Desktop) {
      let skinnyHeaderModel = new SkinnyHeaderModel();   
      skinnyHeaderModel.state = headerState;
      skinnyHeaderModel._event=_event;
      this.headerState.next(skinnyHeaderModel);
    }
  }
}
