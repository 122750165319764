import { Component, OnInit, ElementRef, AfterViewChecked, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core'; import { RecentSearch } from "app/feature/header/recent-search/recent-search.model";
import { RecentSearchService } from "app/feature/header/recent-search/recent-search.service";
import { Router, ActivatedRoute } from '@angular/router';
import { RecentDocument } from "app/feature/header/recent-search/recent-document.model";
import { SearchParamService } from "app/feature/search/services/search-param.service";
import { HeaderTaxonomyTreeService } from "app/feature/header/services/header-taxonomy-tree.service";
import { AlexNotificationService } from "app/shared/components/notification/notification.service";
import { Status } from "app/core/services";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";

@Component({
  selector: 'alex-recent-search',
  templateUrl: './recent-search.component.html',
  styleUrls: ['./recent-search.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})

export class RecentSearchComponent implements OnInit, OnChanges {

  hasChange = false;
  recentSearches: Array<RecentSearch>;
  recentDocuments: Array<RecentDocument>;
  constructor(private recentSearchService: RecentSearchService,
    private router: Router,
    private eref: ElementRef,
    private searchParamService: SearchParamService,
    private headerService: HeaderTaxonomyTreeService,
    private notificationService: AlexNotificationService,
    private spinnerService: SpinnerService,
  ) {
  }
  recentSearch: boolean = false;
  recentDoc: boolean = false;
  @Input() recentShow: boolean;
  @Output() hideDiv: EventEmitter<any> = new EventEmitter();


  ngOnChanges(changes: SimpleChanges): void {
    this.hasChange = true;
  }
  ngOnInit() {
  }

  getRecentSearch() {
    this.spinnerService.noSpinner(true);
    this.recentSearchService.fetchRecentSearch();
    this.recentSearchService.recenSearches$.subscribe(content => {
      this.hasChange = true;
      this.recentSearches = content;
      if (this.recentSearches.length > 0) {
        this.recentSearch = true;
        this.recentDoc = false;
        this.recentShow = true;
      }
      else {
        this.notificationService.show(Status.Failure, 'No Content Found');
      }
    });
  }
  getRecentDocument() {
    this.spinnerService.noSpinner(true);
    this.recentSearchService.fetchRecentDocument();
    this.recentSearchService.recentDocuments$.subscribe(content => {
      this.hasChange = true;
      this.recentDocuments = content;
      if (this.recentDocuments.length > 0) {
        this.recentDoc = true;
        this.recentSearch = false;
        this.recentShow = true;
      }
      else {
        this.notificationService.show(Status.Failure, 'No Content Found');
      }
    });
  }
  recentSearchClick(reference) {
    //enable spinner when moving out of recent search component
    this.spinnerService.noSpinner(false);
    this.searchParamService.IsRecentSearch(true);
    this.router.navigateByUrl(reference);
    this.recentSearch = false;
    this.recentDoc = false;
    this.recentShow = false;
  }
  //called on click on outside element
  onClick(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      if (event.target.innerHTML != "History") {
        this.recentSearch = false;
        this.recentDoc = false;
        this.recentShow = false;

        this.hideDiv.emit();
      }
      else {
        this.recentShow = true;
      }
    }
  }
  showRecentTab() {
    this.recentShow = !this.recentShow;
    this.recentSearch = false;
    this.recentDoc = false;
    // if (this.recentShow == true) {
    //   this.recentShow = false;
    // }
    // else {
    //   this.recentShow = true;
    // }

  }
  ngAfterViewChecked(): void {
    if (this.hasChange) {
      //$('.scrollbar-light').scrollbar();
      this.hasChange = false;
    }
  }
}
