export enum DeviceType{
    Mobile,
    Tablet,
    Desktop
}

export enum Status{
    Success,
    Failure,
    Warning
}