import { Component, OnInit } from '@angular/core';

import { FooterService } from "app/feature/footer/footer.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { AlexUserService } from "app/core/services";

@Component({
  selector: 'alex-footer',
  template: ` 
    <footer>
        <div *ngIf="footerText" class="footerText" [innerHtml]="footerText"></div>
    </footer>
`,
  providers: [FooterService]
})
export class AlexFooterComponent implements OnInit {

  footerText: SafeHtml;

  constructor(private footerService: FooterService,
    private sanitizer: DomSanitizer,
    private userService: AlexUserService) { }

  ngOnInit(): void {
    this.userService.isAuthorizedUser.subscribe(isAuth => {
      if (isAuth) {
        this.footerService.getFooterText()
          .subscribe(text => {
            this.footerText = this.sanitizer.bypassSecurityTrustHtml(text);
          })
      }
    })
  }

}