
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable ,  Subject } from "rxjs";
import { BurgerMenuContent } from "app/feature/header/services/burger-menu.model";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";
import { AlexUrlResolverService, AlexUserService } from "app/core/services";
import { CarouselQuery } from "app/shared/components/carousel/model/carousel-query.model";
import { QueryCriteria } from "app/shared/components/carousel/model";
import { Router } from "@angular/router";
import { BridgePageContentModel } from "app/shared/models/bridge-page-content.model";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HeaderTaxonomyTreeService {
    burgarMenuData: BurgerMenuContent[];
    isMenuVisible: boolean = false;
    fetched = false;
    parentPath = new Array<string>();
    burgerMenuStatus$: Subject<boolean>;
    getBurgerMenu$: Subject<{ name: string; data: BurgerMenuContent[] }>;
    documentFilter: Array<string>;
    bridgePageDocDetails: BridgePageContentModel;
    //parentBurgerMenuNode$: Subject<string>;


    constructor(private http: HttpClient,
        private urlResolver: AlexUrlResolverService,
        private spinner: SpinnerService,
        private router: Router,
        private userService: AlexUserService) {
        // this.spinner.noSpinner(true);
        this.burgerMenuStatus$ = new Subject<boolean>();
        this.getBurgerMenu$ = new Subject<{ name: string; data: BurgerMenuContent[] }>();
        // this.parentBurgerMenuNode$=new Subject<BurgerMenuContent>();
        this.burgerMenuStatus$.next(false);
        this.getBurgerMenu$.next(null);

    }
    getBurgerMenu() {
        if (!this.fetched) {
            this.http.get(this.urlResolver.resolveUrlApiV1(`/Search/BargerMenu`)).pipe(
                map(response => {
                    return response as BurgerMenuContent[];
                }))
                .subscribe(burgerMenuData => {
                    this.burgarMenuData = burgerMenuData;
                    this.getBurgerMenu$.next({
                        name: "",
                        data: burgerMenuData
                    });
                    this.fetched = true;
                    //burgerMenu set to false
                    //  this.isMenuVisible=false;
                    this.toggleBurgerMenu();
                    this.spinner.noSpinner(false);
                });
        }
        else {
            this.getBurgerMenu$.next({
                name: "",
                data: this.burgarMenuData
            });
            //burgerMenu set to false
            //  this.isMenuVisible=false;
            this.toggleBurgerMenu();
        }
        this.parentPath = ["0"];
        // this.parentBurgerMenuNode$.next(null);
    }


    getAppropiateContent(item: BurgerMenuContent): void {
        this.parentPath.push(item.id);
        this.getChildContentDetails();
    }

    getChildContentDetails(): void {
        let newData = this.burgarMenuData;
        let parentData = null;

        for (let i = 1; i < this.parentPath.length; i++) {
            parentData = newData.find(data => data.id == this.parentPath[i]);
            newData = parentData.nodes;
        }
        this.getBurgerMenu$.next({
            name: parentData ? parentData.name : "",
            data: newData
        });
    }

    toggleBurgerMenu() {
        if (this.burgarMenuData) {
            this.isMenuVisible = !this.isMenuVisible;
            this.burgerMenuStatus$.next(this.isMenuVisible);
        }
    }


    moveBackBurgerMenu(): void {
        this.parentPath = this.parentPath.slice(0, this.parentPath.length - 1);
        this.getChildContentDetails();

    }

    getNextPageDetails(item: BurgerMenuContent, parentName: string) {
        if (item.isBottomLevel) {

            if ((item.sourceTaxIdHitCount + item.tlpHitCount) > 1 || item.hasChild) {
                this.router.navigate(["/bridge", item.id, item.id], { queryParams: { d: this.userService.getActiveDomain().join(',') } });
            }
            else if ((item.sourceTaxIdHitCount + item.tlpHitCount) == 1) {
                this.documentFilter = [item.id];
                let documentQuery = new CarouselQuery();
                documentQuery.criteria = new QueryCriteria("", "", "", "", "", "", this.documentFilter, "", "", "", []);
                this.http.post(this.urlResolver.resolveUrlApiV1(`Bridge/BurgerMenuSingleDocument`), documentQuery).pipe(
                    map(response => {
                        return response as BridgePageContentModel;
                    })).subscribe(bridgePageDocDetails => {
                        this.bridgePageDocDetails = bridgePageDocDetails;
                   

                if (this.bridgePageDocDetails.searchContent.items.length >= 1) {
                    // this.router.navigate(["/document/lfc", this.bridgePageDocDetails.searchContent.items[0].docId]);
                    // this.router.navigate(["/document/lfc", this.bridgePageDocDetails.searchContent.items[0].parentId, "toc", this.bridgePageDocDetails.searchContent.items[0].docId]);

                    if (this.getFileType(this.bridgePageDocDetails.searchContent.items[0].mimeType) == "file") {
                        if (this.bridgePageDocDetails.searchContent.items[0].parentId != null && this.bridgePageDocDetails.searchContent.items[0].parentId != '') {
                            this.router.navigate(["/document/lfc", this.bridgePageDocDetails.searchContent.items[0].parentId, "toc", this.bridgePageDocDetails.searchContent.items[0].killDocId]
                            ,{ queryParams: { tocref: this.bridgePageDocDetails.searchContent.items[0].tocRef} });
                        }
                        else
                            this.router.navigate(["/document/lfc", this.bridgePageDocDetails.searchContent.items[0].killDocId, "toc", this.bridgePageDocDetails.searchContent.items[0].killDocId],
                            { queryParams: { tocref: this.bridgePageDocDetails.searchContent.items[0].tocRef} });
                    }
                    else
                        this.router.navigate(["/document/lfc", this.bridgePageDocDetails.searchContent.items[0].docId, "toc", this.bridgePageDocDetails.searchContent.items[0].docId]
                        ,{ queryParams: { tocref: this.bridgePageDocDetails.searchContent.items[0].tocRef} });

                }
                else if (this.bridgePageDocDetails.topicOverviewContent.items.length >= 1) {
                    this.router.navigate(["/document/top", this.bridgePageDocDetails.topicOverviewContent.items[0].docId]);
                }
                 });
            }
            
            else {
                console.log("No Source or TLP document");
            }
            //this.burgerMenuStatus$.next(false);
            this.toggleBurgerMenu();
        }
        else {
            if (item.hasChild && (parentName == "" || parentName == null)) {
                this.getAppropiateContent(item);
            }
            else if ((item.sourceTaxIdHitCount + item.tlpHitCount) > 1 || item.hasChild) {
                 this.router.navigate(["/bridge", item.id, item.id], { queryParams: { d: this.userService.getActiveDomain().join(',') } });
                this.toggleBurgerMenu();
            }
            else if ((item.sourceTaxIdHitCount + item.tlpHitCount) == 1) {
                this.documentFilter = [item.id];
                let documentQuery = new CarouselQuery();
                documentQuery.criteria = new QueryCriteria("", "", "", "", "", "", this.documentFilter, "", "", "", []);
                this.http.post(this.urlResolver.resolveUrlApiV1(`Bridge/BurgerMenuSingleDocument`), documentQuery).pipe(
                    map(response => {
                        return response as BridgePageContentModel;
                    })).subscribe(bridgePageDocDetails => {
                        this.bridgePageDocDetails = bridgePageDocDetails;
                   

                if (this.bridgePageDocDetails.searchContent.items.length >= 1) {
                    // this.router.navigate(["/document/lfc", this.bridgePageDocDetails.searchContent.items[0].docId]);
                    //this.router.navigate(["/document/lfc", this.bridgePageDocDetails.searchContent.items[0].parentId, "toc", this.bridgePageDocDetails.searchContent.items[0].docId]);
                    if (this.getFileType(this.bridgePageDocDetails.searchContent.items[0].mimeType) == "file") {
                        if (this.bridgePageDocDetails.searchContent.items[0].parentId != null && this.bridgePageDocDetails.searchContent.items[0].parentId != '') {
                            this.router.navigate(["/document/lfc", this.bridgePageDocDetails.searchContent.items[0].parentId, "toc", this.bridgePageDocDetails.searchContent.items[0].killDocId]
                            ,{ queryParams: { tocref: this.bridgePageDocDetails.searchContent.items[0].tocRef} });
                        }
                        else
                            this.router.navigate(["/document/lfc", this.bridgePageDocDetails.searchContent.items[0].killDocId, "toc", this.bridgePageDocDetails.searchContent.items[0].killDocId]
                            ,{ queryParams: { tocref: this.bridgePageDocDetails.searchContent.items[0].tocRef} });
                    }
                    else
                        this.router.navigate(["/document/lfc", this.bridgePageDocDetails.searchContent.items[0].docId, "toc", this.bridgePageDocDetails.searchContent.items[0].docId]
                        ,{ queryParams: { tocref: this.bridgePageDocDetails.searchContent.items[0].tocRef} });

                }
                else if (this.bridgePageDocDetails.topicOverviewContent.items.length >= 1) {
                    this.router.navigate(["/document/top", this.bridgePageDocDetails.topicOverviewContent.items[0].docId]);
                }
                 });
                this.toggleBurgerMenu();
            }
            else {
                console.log("No Source or TLP document");
                // this.toggleBurgerMenu();
            }
        }
    }


    getFileType(mimeType: string): string {
        let iconType = "file";
        mimeType = mimeType ? mimeType.toLowerCase() : mimeType;
        switch (mimeType) {
            case "application/vnd.ms-excel":
                iconType = "excel";
                break;
            case "application/msword":
                iconType = "word";
                break;
            case "text/plain":
                iconType = "word";
                break;
            case "text/rtf":
                iconType = "word";
                break;
            case "application/zip":
                iconType = "zip";
                break;
            case "application/xml":
                iconType = "file";
                break;
            case "application/vnd.ms-powerpoint":
                iconType = "ppt";
                break;
            case "application/pdf":
                iconType = "pdf";
                break;
            case "text/vnd.wap.wml":
                iconType = "msg";
                break;
            case "application/outlook":
                iconType = "msg";
                break;
            case "video/mpeg":
                iconType = "video";
                break;
            case "audio/mpeg":
                iconType = "video";
                break;
            case "audio/x-ms-wmv":
                iconType = "video";
                break;
            case "application/x-shockwave-flash":
                iconType = "video";
                break;
            case "video":
                iconType = "video";
                break;
            case "video/mp4":
                iconType = "video";
                break;
            case "web":
                iconType = "web";
                break;
            case ("text/html" || "html/xml" || "application/xml" || "xml" || "html"):
                iconType = "file";
                break;
            default: iconType = "other";

        }
        return iconType;
    }

}