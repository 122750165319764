import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'alex-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  @Input() whiteBackground = false;
  @Input() isVisible = false;
  @Input() message: string;
  @Input() state : string;
  constructor() {
  }

}
