<alex-page-layout [ngClass]="{ pop: this.mode == 'popOut' }" *ngIf="isLoggedIn">
    <!-- Header section -->
    <alex-header *ngIf="this.mode != 'popOut'"></alex-header>
    <!-- Content Section -->
    <div class="col-sm-12" content-section>
      <alex-subscription></alex-subscription>
      <alex-country-selection></alex-country-selection>
      <router-outlet *ngIf="!isIframe"></router-outlet> 
    </div>
    <!-- Footer section -->
    <alex-footer *ngIf="mode != 'popOut'"></alex-footer>
  </alex-page-layout>
  <alex-spinner
    *ngIf="isSpinnerVisible"
    [whiteBackground]="spinnerState?.whiteBackground"
    [message]="spinnerMessage"
  ></alex-spinner>

<div class="modal fade keepalive-modal" bsModal #keepAliveModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h6 id="dialog-child-name" class="modal-title" pull-left>Your Session is going to expire. Click on “Continue” to extend</h6>
                <button class="close pull-right" type="button" aria-level="Close" (click)="hidekeepAliveModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mrgbtm">
                    <div class="col-md-6">
                        <button class="btn btn-danger" type="button" (click)="logout()">Logout</button>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-success" type="button" (click)="stay()">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>