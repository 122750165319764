import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Status } from "app/core/services";
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { AlexNotificationService } from "app/shared/components/notification/notification.service";
import { AlexNotificationModel } from "app/shared/components/notification/notification.model";

@Component({
  selector: 'alex-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
   host: {
    '(document:click)': 'onClick($event)'
  },
  animations: [
    trigger('displaynotification', [
      state('show', style({
        //height: '38px',
        //padding:'10px 10px 5px 10px'
        transform: 'translateY(0%)'

      })),
      state('hide', style({
        //height: '0px',
        //padding:'0'
        transform: 'translateY(-100%)'
      })),
      transition('show => hide', animate('500ms ease-in')),
      transition('hide => show', animate('500ms ease-out'))
    ])
  ]
})
export class AlexNotificationComponent implements OnInit {
  Status = Status;
  notificationData: AlexNotificationModel;
   @Input() isVisible = true;
   progressVal:number=0;
  constructor(public notificationService: AlexNotificationService,private eref: ElementRef) {
    this.notificationData=new AlexNotificationModel();
    this.notificationData.displayNotification="hide";
    this.notificationData.showProgress = false;
  }

  ngOnInit() {  
    this.downloadState();    
    this.getNotificationDetails();
  }

  hideNotification() {
    this.notificationService.hide();
  }
//called on click on outside element
  onClick(event) {
    //tEMPORARY CODE
    if (!this.eref.nativeElement.contains(event.target)) {
       this.notificationService.hide();
    }
  }
  getNotificationDetails() {
    this.notificationService.notification$.subscribe(notification => {
      this.notificationData = notification;
    })
  }
  
  downloadState(){      
    this.notificationService.Data.subscribe(result => {
      this.progressVal = result;
    });
  }
}
