export enum AlexPages {
    home,
    bridge,
    search,
    lfc,
    manualsLfc,
    spaces,
    manualsearch,
    kcwSearch,
    kcwLfc
}