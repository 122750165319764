import { MailDetails } from "./mail.model";
import { Injectable } from "@angular/core";

@Injectable()
export class AlexMailService {
  constructor() { }

  openMailClient(mailDetails: MailDetails): void {
    window.open(`mailto:?Subject=${mailDetails.subject}&body=${mailDetails.body}`);
  }
}