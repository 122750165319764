import { Injectable } from "@angular/core";
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { IManualsFiltersResponse } from "app/feature/header/interfaces";
import { CombinedSearchFiltersService } from "app/feature/header/services";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class KaegFiltersResolver implements Resolve<IManualsFiltersResponse> {
  constructor(private combinedSearchFilters: CombinedSearchFiltersService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IManualsFiltersResponse> {
    return this.combinedSearchFilters.fetchKaegFilters().pipe(take(1));
  }
}
