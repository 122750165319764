import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "alex-combined-search-filters",
  templateUrl: "./combined-search-filters.component.html",
  styleUrls: ["./combined-search-filters.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CombinedSearchFiltersComponent {
  @Input() countries: {
    label: string;
    value: string;
    isChecked: boolean;
  }[] = [];
  @Input() workflowTypes: {
    label: string;
    value: string;
    isChecked: boolean;
  }[] = [];
  @Input() versions: {
    label: string;
    value: string;
    isChecked: boolean;
  }[] = [];
  @Input() sources: {
    label: string;
    value: string;
    isChecked: boolean;
  }[] = [];
  @Input() isManualsSelected: boolean = true;

  @Output() sourcesChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output() countriesChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output() workflowTypesChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output() versionsChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output() searchClick: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() resetClick: EventEmitter<string[]> = new EventEmitter<string[]>();

  onSourcesChange(sources: string[]): void {
    this.sourcesChange.emit(sources);
  }

  onCountryChange(countries: string[]): void {
    this.countriesChange.emit(countries);
  }

  onWorkflowTypesChange(workflowTypes: string[]): void {
    this.workflowTypesChange.emit(workflowTypes);
  }

  onVersionsChange(versions: string[]): void {
    this.versionsChange.emit(versions);
  }

  onSearchClick(): void {
    this.searchClick.emit();
  }

  onResetClick(): void {
    this.resetClick.emit();
  }
}
