import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatTabsModule } from "@angular/material/tabs";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import {MatProgressBarModule} from '@angular/material/progress-bar'; 

import {
  AlexBurgerMenuComponent,
  AlexContextualBarComponent,
  AlexSpotlightComponent,
  AlexPageLayoutComponent,
  AlexCarouselComponent,
  AlexCarouselItemType1Component,
  AlexCarouselItemType2Component,
  CarouselItemHeaderComponent,
  CarouselItemHeaderLeftComponent,
  AlexSvgIconComponent,
  AlexTreeMenuComponent,
  AlexTreeMenuItemComponent,
  AlexDocumentViewerComponent,
  AlexContentAreaContainerComponent,
} from "./components";
import { DomainService } from "app/feature/home/domain.service";
import { SortByParamPipe } from "./pipes/sort-by-param.pipe";

// import { MaterialModule, MdDatepickerModule, MdNativeDateModule, DateAdapter, MD_DATE_FORMATS } from '@angular/material';
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MatNativeDateModule,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material/core";
import { MatRippleModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

//
import { DatePickerComponent } from "app/shared/components/datePicker/datePicker.component";
import { HelpComponent } from "./components/help/help.component";
import { RouterModule } from "@angular/router";
import { AlexDateAdapter } from "app/shared/components/datePicker/date.adapter";
import { ALEX_DATE_FORMATS } from "app/shared/components/datePicker/date.formats";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { PaginationComponent } from "app/shared/components/pagination/pagination.component";
import { AlexDropdownComponent } from "app/shared/components/dropdown/dropdown.component";
import { AlexPaginationService } from "app/shared/components/pagination/pagination.service";
import { TileContainerComponent } from "app/shared/components/carousel/tile-container/tile-container.component";
import { TileFooterLocationDateComponent } from "app/shared/components/tile-footer-location-date/tile-footer-location-date.component";
import { DraggableDirective } from "app/shared/directives/draggable.directive";
import { DropTargetDirective } from "app/shared/directives/drop-target.directive";
import { DocumentSpacesComponent } from "app/shared/components/document-spaces/document-spaces.component";
import { AlexModalComponent } from "app/shared/components/alex-modal/alex-modal.component";
import { AddToSpacesComponent } from "app/shared/components/add-to-spaces/add-to-spaces.component";
import { DragService } from "app/shared/directives/drag.service";
import { DocumentSpaceHeaderComponent } from "app/shared/components/document-space-header/document-space-header.component";
import { TagsComponent } from "./components/tags/tags.component";
import { AnchorToRouterLinkDirective } from "app/feature/long-form-content/directive/anchor-to-routerlink.directive";
import { AlexLocationService } from "app/shared/components/location/alex-location.service";
import { AlexLocationTeeItemComponent } from "app/shared/components/location-tree-item/alex-location-tree-item.component";
import { AlexLocationComponent } from "app/shared/components/location/position-mid/alex-location.component";
import { AlexLocationTopComponent } from "app/shared/components/location/position-top/alex-location-top.component";
import { AlexNotificationComponent } from "./components/notification/notification.component";
import { AlexTopicTagComponent } from "app/shared/components/topic-tag/topic-tag.component";
import { AlexTopicTagService } from "app/shared/components/topic-tag/topic-tag.service";
import { SubscriptionComponent } from "./components/subscription/subscription.component";
import { AlexUserGuideComponent } from "app/shared/components/userGuide/user-guide.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { DocumentViewerSpinnerComponent } from "app/shared/components/document-viewer/document-viewer-spinner.component";
import { CKDSearchContextualBarComponent } from "app/feature/ckd-search/ckd-contextual-bar/ckd-search-contextual-bar.component";
import { CKDSearchContextualBarService } from "app/feature/ckd-search/ckd-contextual-bar/ckd-search-contextul-bar.service";
// phase 2 tracking code
import { TrackingService } from "./services/tracking";
import { CarouselModule } from "ngx-owl-carousel-o";
import { HomeListviewComponent } from "app/shared/components/carousel/home-listview/home-listview.component";
import { OnScreenCarouseService } from "./services/tracking/window/on-screen-carouse.service";
import { CheckBoxGroupComponent } from "./components/check-box-group/check-box-group.component";
import { MultiSelectComponent } from "./components/multi-select/multi-select.component";
import { MultiSelectSmallComponent } from "./components/multi-select-small/multi-select-small.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { DropDownDirective } from "./directives/drop-down.directive";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { CKDSearchResultComponent } from "app/feature/ckd-search/ckd-search-result/ckd-search-result.component";
import { CKDSearchContainerComponent } from "app/feature/ckd-search/container/ckd-search-container.component";
import { FeedbackLinkComponent } from './components/feedback-link/feedback-link.component';
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { PreviewBinaryComponent } from './components/preview-binary/preview-binary.component';
import { SAVER, getSaver } from './saver.provider';
import { ProgressbarModule } from "ngx-bootstrap/progressbar";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    // MaterialModule,
    MatRippleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    CarouselModule,
    ModalModule,
    ModalModule.forRoot(),
    CollapseModule,
    CollapseModule.forRoot(),
    BsDropdownModule,
    BsDropdownModule.forRoot(),
    NgxDocViewerModule,
    MatProgressBarModule,
    ProgressbarModule
  ],
  declarations: [
    AlexContextualBarComponent,
    AlexBurgerMenuComponent,
    AlexSpotlightComponent,
    CKDSearchResultComponent,
    CKDSearchContainerComponent,
    AlexContentAreaContainerComponent,
    AlexPageLayoutComponent,
    AlexCarouselComponent,
    AlexCarouselItemType1Component,
    AlexCarouselItemType2Component,
    CarouselItemHeaderComponent,
    CarouselItemHeaderLeftComponent,
    SortByParamPipe,
    AlexDropdownComponent,
    DatePickerComponent,
    HelpComponent,
    SpinnerComponent,
    DocumentViewerSpinnerComponent,
    PaginationComponent,
    AlexSvgIconComponent,
    AlexTreeMenuComponent,
    AlexTreeMenuItemComponent,
    TileContainerComponent,
    TileFooterLocationDateComponent,
    DraggableDirective,
    DropTargetDirective,
    DocumentSpacesComponent,
    AlexModalComponent,
    AddToSpacesComponent,
    DocumentSpaceHeaderComponent,
    AddToSpacesComponent,
    TagsComponent,
    AlexLocationComponent,
    AlexLocationTopComponent,
    AlexDocumentViewerComponent,
    AlexLocationTeeItemComponent,
    AlexNotificationComponent,
    AlexTopicTagComponent,
    SubscriptionComponent,
    AlexUserGuideComponent,
    UnauthorizedComponent,
    CKDSearchContextualBarComponent,
    HomeListviewComponent,
    CheckBoxGroupComponent,
    MultiSelectComponent,
    MultiSelectSmallComponent,
    DropDownDirective,
    SafeHtmlPipe,
    FeedbackLinkComponent,
    PreviewBinaryComponent,
  ],
  exports: [
    AlexBurgerMenuComponent,
    AlexContextualBarComponent,
    CKDSearchResultComponent,
    CKDSearchContainerComponent,
    AlexSpotlightComponent,
    AlexContentAreaContainerComponent,
    AlexLocationTopComponent,
    AlexPageLayoutComponent,
    AlexCarouselComponent,
    AlexCarouselItemType1Component,
    AlexCarouselItemType2Component,
    CarouselItemHeaderComponent,
    CarouselItemHeaderLeftComponent,
    SortByParamPipe,
    AlexDropdownComponent,
    DatePickerComponent,
    HelpComponent,
    CommonModule,
    FormsModule,
    MatTabsModule,
    SpinnerComponent,
    DocumentViewerSpinnerComponent,
    PaginationComponent,
    AlexSvgIconComponent,
    AlexTreeMenuComponent,
    AlexTreeMenuItemComponent,
    DocumentSpacesComponent,
    AlexModalComponent,
    DraggableDirective,
    DropTargetDirective,
    // SearchFilterModalComponent, //TODO: duplicate component
    DocumentSpaceHeaderComponent,
    TileFooterLocationDateComponent,
    AlexDocumentViewerComponent,
    AlexLocationComponent,
    AlexLocationTeeItemComponent,

    AlexTopicTagComponent,
    AlexNotificationComponent,
    AlexTopicTagComponent,
    SubscriptionComponent,
    AlexUserGuideComponent,
    CKDSearchContextualBarComponent,
    CheckBoxGroupComponent,
    MultiSelectComponent,
    MultiSelectSmallComponent,
    HomeListviewComponent,
    ModalModule,
    CollapseModule,
    BsDropdownModule,
    DropDownDirective,
    SafeHtmlPipe,
    FeedbackLinkComponent,
    NgxDocViewerModule,
    PreviewBinaryComponent,
    MatProgressBarModule,
    ProgressbarModule
  ],
  providers: [
    DomainService,
    AlexPaginationService,
    { provide: DateAdapter, useClass: AlexDateAdapter },
    DragService,
    AnchorToRouterLinkDirective,
    AlexLocationService,
    AlexTopicTagService,
    CKDSearchContextualBarService,
    // phase 2 tracking code
    TrackingService,
    OnScreenCarouseService,
    { provide: Window, useValue: window },
    {provide: SAVER, useFactory: getSaver}
  ],
})
export class AlexSharedModule {}
