<div class="input-group searchBarDesktop" id="adv-search">
  <input
    id="txtSearch"
    type="text"
    autocomplete="off"
    #abcd
    class="form-control hasclear radiusnone searchTop"
    placeholder='Search content, titles, topics or "key phrases"'
    [(ngModel)]="input.searchText"
    (input)="searchText()"
    (keyup.ArrowDown)="onDownArrowPressed()"
    (keyup.ArrowUp)="onUpArrowPressed()"
    (keyup.escape)="onEscapeKeyPressed()"
    (keyUp.enter)="textEnter()"
  />
  <span
    id="searchclear"
    (click)="searchClearPressed()"
    *ngIf="this.showRemoveTextBtn"
  >
    <svg
      class="svgIm"
      version="1.1"
      style="fill: #acacac"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="30px"
      height="1px"
      viewBox="0 0 28 1"
      xml:space="preserve"
    >
      <g transform="scale(0.9)">
        <g
          transform="translate(-155,-985.5)"
          xmlns="http://www.w3.org/2000/svg"
          id="remove-sign_2_"
        >
          <path
            d="M176.662,975.338c-4.784-4.784-12.54-4.784-17.324,0c-4.784,4.784-4.784,12.54,0,17.324c4.784,4.784,12.54,4.784,17.324,0
                                            C181.445,987.878,181.446,980.122,176.662,975.338z M171.535,989.656c-0.194,0.195-0.513,0.195-0.707,0L168,986.828l-2.828,2.828
                                            c-0.194,0.195-0.513,0.195-0.707,0l-2.121-2.121c-0.195-0.193-0.195-0.512,0-0.707l2.828-2.828l-2.828-2.828
                                            c-0.195-0.195-0.195-0.514,0-0.707l2.121-2.121c0.194-0.195,0.512-0.195,0.707,0l2.828,2.828l2.828-2.828
                                            c0.195-0.195,0.513-0.195,0.707,0l2.121,2.121c0.195,0.193,0.195,0.512,0,0.707L170.828,984l2.828,2.828
                                            c0.195,0.195,0.195,0.514,0,0.707L171.535,989.656z"
          ></path>
        </g>
      </g>
    </svg>
  </span>
  <div class="dropdown searchDropDwn allSourceTrigger">
    <button
      class="btn btn-default dropdown-toggle searchDropDwnBtn"
      type="button"
      (click)="allSourceDropDownClick($event)"
    >
      {{ filterLabel }}
      <span class="caret"></span>
    </button>
  </div>

  <div class="input-group-btn">
    <div class="btn-group" role="group">
      <button
        type="button"
        class="btn btn-primary searchBg radiusnone"
        title="Search"
        (click)="bigSearch()"
      >
        <svg
          class="svgIm"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="25px"
          width="28px"
          viewBox="0 0 38 32"
          style="fill: #fff"
          xml:space="preserve"
        >
          <g transform="scale(1.5)">
            <g
              transform="translate(-395,-157.5)"
              xmlns="http://www.w3.org/2000/svg"
              id="search_1_"
            >
              <path
                d="M419.602,176.755l-5.878-5.879c0.956-1.482,1.52-3.24,1.52-5.132c0-5.235-4.262-9.494-9.496-9.494
			                        c-5.236,0-9.494,4.259-9.494,9.494c0,5.236,4.258,9.495,9.494,9.495c1.891,0,3.65-0.562,5.132-1.519l5.878,5.878
			                        c0.195,0.196,0.518,0.196,0.712,0l2.132-2.132C419.799,177.271,419.799,176.951,419.602,176.755z M399.27,165.744
			                        c0-3.571,2.906-6.479,6.478-6.479c3.573,0,6.479,2.907,6.479,6.479c0,3.573-2.905,6.48-6.479,6.48
			                        C402.176,172.224,399.27,169.317,399.27,165.744z"
              />
            </g>
          </g>
        </svg>
      </button>
    </div>
  </div>
</div>

<div class="searchListContent" *ngIf="showInputTextResult">
  <ul class="SearchListresults">
    <li
      [ngClass]="{ active: activeSuggestionIndex == i }"
      *ngFor="let suggestion of input.suggestions; let i = index"
      (click)="typeAheadRowClicked(suggestion)"
    >
      {{ suggestion }}
    </li>
  </ul>
</div>
