import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import {
  AlexMailService,
  AlexUrlResolverService,
  MailDetails,
  AlexNavigationService,
  AlexAppService,
} from "app/core/services";
import { AlexContextualBarService } from "./contextul-bar.service";
import { SafeHtml } from "@angular/platform-browser";
import { AlexRouteStatus } from "app/core/services/navigation/route-status.model";
import { Subscription } from "rxjs";
import { AlexLocationQueryModel } from "app/shared/components/location/alex-location-query.model";
import { AlexLocationTopComponent } from "app/shared/components/location/position-top/alex-location-top.component";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { AlexSkinnyHeaderService } from "app/core/services/skinny-header/skinny-header.service";
import { HeaderState } from "app/core/services/skinny-header/header-state.enum";
@Component({
  selector: "alex-contextual-bar",
  templateUrl: "./contextual-bar.component.html",
  styleUrls: ["./contextual-bar.component.scss"],
  animations: [
    trigger("slideContextualBar", [
      state(
        "down",
        style({
          // transform: 'translateY(0%)'
          // position: "relative",
        })
      ),
      state(
        "up",
        style({
          //transform: 'translateY(-165%)'
          // transform: 'translateY(-268%)'
          position: "fixed",
          top: 0,
          right: 0,
          width: "100%",
        })
      ),
      transition("down => up", animate("250ms ease-in")),
      transition("up => down", animate("250ms ease-out")),
    ]),
  ],
  host: {
    "(document:click)": "onClick($event)",
    "(mouseenter)": "cnbMouseHover($event)",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlexContextualBarComponent implements OnInit {
  @Input() breadCrumb: string;
  @Input() screenName: string;
  breadCrumbHtml: SafeHtml;
  isSafeHtml = false;
  tooltip: string;
  alexRouteStatus: AlexRouteStatus;
  alexNavigationSubscription: any;
  @Input() locationQueryModel: AlexLocationQueryModel;
  @ViewChild(AlexLocationTopComponent, { static: true }) location;
  @ViewChild("cnb", { static: true }) cnbElement: ElementRef;
  showPopOver = false;
  showPointer = false;
  slideContextualBar: string = "";
  constructor(
    private contextualBarService: AlexContextualBarService,
    private alexNavigationService: AlexNavigationService,
    private cdref: ChangeDetectorRef,
    private eref: ElementRef,
    private appService: AlexAppService,
    private skinnyHeaderService: AlexSkinnyHeaderService
  ) {
    //   this.contextualBarService.slideContexualBar.subscribe(val=>{
    //   this.slideContextualBar=val;
    //
    // })
    this.skinnyHeaderService.headerState.subscribe((headerSate) => {
      this.slideContextualBar =
        headerSate.state == HeaderState.up ? "up" : "down";
      this.cdref.markForCheck();
    });
  }
  private subscription: Subscription;
  ngOnInit() {
    if (this.screenName == "bridge" || this.screenName == "lfc") {
      this.showPointer = true;
    } else {
      this.showPointer = false;
    }

    this.alexRouteStatus = new AlexRouteStatus();

    this.alexNavigationSubscription = this.alexNavigationService.alexRouteStatus$.subscribe(
      (routeStatus) => {
        this.alexRouteStatus = routeStatus;
      }
    );

    this.alexNavigationService.getActiveRoute();

    this.contextualBarService.breadCrumbText$.subscribe((text) => {
      this.isSafeHtml = false;
      this.breadCrumb = text || this.breadCrumb;
      this.cdref.markForCheck();
    });
    this.contextualBarService.breadCrumbHtml$.subscribe((html) => {
      this.breadCrumbHtml = html;
      this.isSafeHtml = true;
      this.cdref.markForCheck();
    });
    this.contextualBarService.breadCrumbTooltip$.subscribe((tooltip) => {
      this.tooltip = tooltip;
      this.cdref.markForCheck();
    });

    this.contextualBarService.locationQueryDetail$.subscribe(
      (locationQueryModel) => {
        this.locationQueryModel = locationQueryModel;
        this.cdref.markForCheck();
        //this.location.GetDetails(this.locationQueryModel);
      }
    );

    this.appService.setCNBHeight(this.cnbElement.nativeElement.offsetHeight);
  }

  navigateBack() {
    this.alexNavigationService.navigateBack();
  }

  //called on click on outside element
  onClick(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.showPopOver = false;
    }
  }

  navigateFwd() {
    this.alexNavigationService.navigateForward();
  }

  clickLocation() {
    if (this.screenName == "bridge" || this.screenName == "lfc") {
      this.showPopOver = !this.showPopOver;
      this.location.GetDetails(this.locationQueryModel);
    } else this.showPopOver = false;
  }

  cnbMouseHover(event) {
    if (this.skinnyHeaderService.currentHeaderState == HeaderState.up)
      this.skinnyHeaderService.slideHeader(HeaderState.down, event);
  }

  ngOnDestroy() {
    if (this.alexNavigationSubscription) {
      this.alexNavigationSubscription.unsubscribe();
    }
  }
}
