import { AlexFilterTagModel } from "app/feature/search/search-result-tag/filter-tag.model";
import { REMOVE_SOURCE_FILTER, REMOVE_TOPIC_FILTER, REMOVE_DATE_FILTER, REMOVE_FILE_FILTER, REMOVE_LANGUAGE_FILTER, REMOVE_ALL_TAGS, SOURCE_FILTER, TOPIC_FILTER, DATE_FILTER, FILE_FILTER, LANGUAGE_FILTER, REMOVE_PARENT_ID_FILTER, SEE_ALL_RESULT, OTHER_CATEGORY_FILTER, REMOVE_OTHER_CATEGORY_FILTER, REMOVE_COUNTRY_FILTER, COUNTRY_FILTER, REMOVE_TOC_REF_FILTER } from "app/feature/search/ACTIONS/search-action-types";
import { AlexSearchViewModel } from "app/feature/search/model/search.viewmodel";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { AlexTaxonomyNodeModel } from "app/feature/search/model/filters/taxonomy-node.model";
import { SearchParamService } from "app/feature/search/services/search-param.service";
import { AlexSourceFilterModel } from "app/feature/search/model/filters/source-filter.model";
import { DateSelectionType } from "app/feature/search/model/filters/date-selction-type.enums";
import { AlexSearchResult } from "app/feature/search/model/content/search-result.model";
import { AlexDateFilterModel } from "app/feature/search/model/filters/date-filter.model";
import { AlexCheckBoxModel } from "app/feature/search/model/filters/checkbox.model";
import { AlexAdvanceFilteViewModel } from "app/feature/search/model/filters/advance-filter.viewmodel";
import * as moment from 'moment';
import { AlexPaginationModel } from "app/shared/components/pagination/pagination.model";
import { AlexDropdownModel } from "app/shared/components/dropdown/dropdown.model";
import { AlexDropdownItemModel } from "app/shared/components/dropdown/dropdown-item.model";
import { AlexPaginationEventModel } from "app/shared/components/pagination/pagination.event.model";
import { AlexPaginationService } from "app/shared/components/pagination/pagination.service";
import { TaxonomyFilter } from "app/feature/search/model/taxonomy-filter.model";
import { SpinnerState } from "app/shared/components/spinner/spinner.state";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";
import { CKDSearchResultModel } from "../models/ckd-search-result.model";
import { CKDSearchViewModel } from "../models/ckd-search.viewmodel";
import { CKDSearchParamService } from "./ckd-search-param.service";

@Injectable()
export class CKDSearchViewModelService {

    public searchViewModel: CKDSearchViewModel;
    public searchViewModel$ = new Subject<CKDSearchViewModel>();

    constructor(private searchParamService: CKDSearchParamService,
        private spinnerService: SpinnerService,
        private paginationService: AlexPaginationService) {
        this.searchViewModel = new CKDSearchViewModel();
        this.searchViewModel.pagination = new AlexPaginationModel();
        this.initializeSortDropdown();
    }

    /* new change start */
    private initializeSortDropdown() {
        this.searchViewModel.sortDropdownModel = new AlexDropdownModel();
        this.searchViewModel.sortDropdownModel.id = "searchPageSortDropdown";
        this.searchViewModel.sortDropdownModel.name = "sortDropdown";
        this.searchViewModel.sortDropdownModel.items = this.searchParamService.getSortDropdownPristineValue()
    }
    getsortDropdownData(): AlexDropdownModel {
        if (!this.searchViewModel.sortDropdownModel) {
            this.initializeSortDropdown();
        }
        return this.searchViewModel.sortDropdownModel;
    }
    updateSortDropDownData(dropdownModel: AlexDropdownModel) {
        this.searchViewModel.sortDropdownModel = dropdownModel;
    }
    resetSortDropDownData(dropdownModel: AlexDropdownModel) {
        this.searchViewModel.sortDropdownModel.items = this.searchParamService.getSortDropdownPristineValue();
    }
    changeSortSelection(key: string) {
        this.searchViewModel.sortDropdownModel.items = this.searchViewModel.sortDropdownModel.items || this.searchParamService.getSortDropdownPristineValue();
        this.searchViewModel.sortDropdownModel.items = this.searchViewModel.sortDropdownModel.items.map(item => {
            item.selected = item.value == key;
            return item;
        });
    }

    getPaginationData(): AlexPaginationModel {
        return this.searchViewModel.pagination;
    }
    public getTags(): Array<AlexFilterTagModel> {
        return this.searchViewModel.tags;
    }
    public updateFilterTagList() {
        this.searchViewModel.tags = new Array<AlexFilterTagModel>();
        this.addTocRefTagKey();
    }

    updateTagDisplayValue(tags = {}) {
        this.searchViewModel.tags.forEach(item => {
            if (tags[item.key]) {
                item.displayText = tags[item.key];
            }else{
                item.displayText=this.searchParamService.tocTitleFromShowmore;
            }
        })
    }

    updateSearchResultViewModel(searchResult: CKDSearchResultModel) {
        this.searchViewModel.result = searchResult;
        if (searchResult && searchResult.manualContent) {
            this.updatePaginationModel(searchResult.manualContent.perPageCount, searchResult.manualContent.totalCount);
        } else {
            this.updatePaginationModel(0, 0);
        }
    }
    public updateView() {
        this.spinnerService.changeSpinnerState(SpinnerState.RenderingView, "Rendering View");
        this.searchViewModel$.next(this.searchViewModel);
    }

    public resetPaginationModel() {
        this.searchViewModel.pagination = new AlexPaginationModel();
    }
    public updatePaginationModel(itemsPerPage: number, resultCount: number) {
        this.searchViewModel.pagination = this.paginationService.updatePaginationModel(this.searchViewModel.pagination, itemsPerPage, resultCount);
    }
    public handlePaginationEvent(model: AlexPaginationEventModel) {
        this.searchViewModel.pagination = this.paginationService.handlePaginationEvent(model);
    }
    private addTocRefTagKey() {
        if (this.searchParamService.filterParam && this.searchParamService.filterParam.seeAllResult && this.searchParamService.filterParam.seeAllResult.tocRef) {
            this.searchViewModel.tags.unshift({
                id: `tag${1}`,
                key: this.searchParamService.filterParam.seeAllResult.tocRef,
                filterType: SEE_ALL_RESULT,
                action: REMOVE_TOC_REF_FILTER,
                displayText: '',
                data: this.searchParamService.filterParam.seeAllResult.tocRef,
                order: 0
            });
        }
    }
    removeFilterTag(tag: AlexFilterTagModel) {
        // if (tag.action == REMOVE_SOURCE_FILTER) {
        //     if (Array.isArray(tag.data)) {
        //         const nodesToKeep = this.findNodesToKeep(tag);
        //         this.searchParamService.filterParam.sources = this.removeNodeFromSourceTree(tag.data, nodesToKeep, this.searchParamService.filterParam.sources);
        //     }
        // }
        // else if (tag.action == REMOVE_TOPIC_FILTER) {
        //     this.searchParamService.filterParam.topics = this.searchParamService.filterParam.topics.filter(topic => topic.k != tag.key);
        // }
        // else if (tag.action == REMOVE_OTHER_CATEGORY_FILTER) {
        //     this.searchParamService.filterParam.otherCategories = this.searchParamService.filterParam.otherCategories.filter(other => other.k != tag.key);
        // }
        //  else if (tag.action == REMOVE_COUNTRY_FILTER) {
        //     this.searchParamService.filterParam.countries = this.searchParamService.filterParam.countries.filter(country => country.k != tag.key);
        // }
        // else if (tag.action == REMOVE_DATE_FILTER) {
        //     this.searchParamService.filterParam.date = null;
        // }
        // else if (tag.action == REMOVE_FILE_FILTER) {
        //     this.searchParamService.filterParam.fileTypes = this.searchParamService.filterParam.fileTypes.filter(file => file.k != tag.key);
        // }
        // else if (tag.action == REMOVE_LANGUAGE_FILTER) {
        //     this.searchParamService.filterParam.languages = this.searchParamService.filterParam.languages.filter(language => language.k != tag.key);
        // }
        //else 
        if (tag.action == REMOVE_TOC_REF_FILTER) {
            this.searchParamService.filterParam.seeAllResult = null;
        }
        else if (tag.action == REMOVE_ALL_TAGS) {
            this.searchParamService.clearAllFilter();
        }
    }
    private findNodesToKeep(currentTag: AlexFilterTagModel): Array<string> {
        const sourceTags = this.searchViewModel.tags.filter(tag => tag.action == REMOVE_SOURCE_FILTER
            && tag.key != currentTag.key);
        const otherFilterPath = new Array<string>();

        if (sourceTags && sourceTags.length)
            sourceTags.forEach(sourceTag => {
                otherFilterPath.unshift(...sourceTag.data);
            })

        return otherFilterPath;

    }

    private removeNodeFromSourceTree(nodePath: Array<string>, nodesToKeep: Array<string>, sourceFilters: Array<AlexSourceFilterModel>): Array<AlexSourceFilterModel> {

        sourceFilters = sourceFilters || new Array<AlexSourceFilterModel>();

        const node = nodePath[0];
        const nodeIndex = sourceFilters.findIndex(source => source.k == node);

        const remainingPath = nodePath.slice(1);
        if (nodeIndex > -1 && remainingPath.length && nodesToKeep.includes(node)) {
            sourceFilters[nodeIndex].c = this.removeNodeFromSourceTree(remainingPath, nodesToKeep, sourceFilters[nodeIndex].c);
        } else {
            sourceFilters.splice(nodeIndex, 1);
        }

        return sourceFilters;
    }

}