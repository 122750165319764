import { Component, Inject, OnInit } from '@angular/core';
import { User } from './header-user-profile-menu.model';
import { AlexUserService, AlexAppService } from "app/core/services";
import { CountrySelectionService } from "app/feature/country-selection/country-selection.service"
import { CountrySelection } from "app/feature/country-selection/country-selection.model";
import { AlexUrlResolverService } from "app/core/services";
import { MsalService } from '@azure/msal-angular';
import { AppConfig } from 'app/app.config';
import { BROADCAST_SERVICE } from 'app/broadcast.token';
import { BroadcastService } from 'app/broadcast.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'alex-header-user-profile-menu',
  templateUrl: './header-user-profile-menu.component.html',
  styleUrls: ['./header-user-profile-menu.component.scss']
})
export class AlexHeaderUserProfileMenuComponent implements OnInit {
  user: User;
  countryClicked: boolean = false;
  countryList: Array<CountrySelection>;
  IsAuthorizedUser: boolean;
  constructor(private userService: AlexUserService,
    private countryService: CountrySelectionService,
    private urlResolver: AlexUrlResolverService,
    public appService:AlexAppService,
    private msalService:MsalService,
    private appConfig:AppConfig,
    @Inject(BROADCAST_SERVICE) private broadCastService: BroadcastService
    ) { }
  ngOnInit() {
    this.userService.getUserData().subscribe(
      user => {
        this.user = user;
      }
    );

    this.userService.isAuthorizedUser.subscribe(uAuth => {
      this.IsAuthorizedUser = uAuth;
    })


  }
  onCountrySelection() {
    this.userService.IsCountryLoaded();
    this.countryService.getCountries()
  }

  onLogout() {
    navigator.serviceWorker.getRegistration('/sw.js').then(registration => {
      console.log('service worker unregistered');
      registration.unregister();
      
    });
    if (this.user.isExternalUser) {
      this.userService.extUserLogOut().subscribe(s => {
        this.userService.isAuthorizedUser.next(false);
      });
    }
    let account = this.msalService.instance.getAllAccounts()[0];
    this.msalService.instance.setActiveAccount(null);
    this.msalService.logoutRedirect({account:account, postLogoutRedirectUri:environment.L72AAD.postLogoutRedirectUri});
    sessionStorage.clear();
    this.broadCastService.publishDataToOtherTab();
    return false;
  }
  openExtUserProfile() {
    window.location.href = this.urlResolver.getExtUserProfileUrl() + "?UserSession=" + this.userService.user.userLicenseModel.sessionID;
    return false;
  }

  openManageAcc() {
    window.location.href = this.urlResolver.getExtManagAccUrl() + "?UserSession=" + this.userService.user.userLicenseModel.sessionID;
  }
}
