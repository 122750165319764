import { CarouselItem } from './carousel-item.model';
import { CarouselQuery } from './carousel-query.model';

export class Carousel {
    curatedItems: Array<CarouselItem>;
    dataFetched: boolean;
    description: string;
    id: string;    
    isLoading = false;
    isCurated: boolean;
    imageRadio: string;
    query: CarouselQuery;
    title: string;
    domain: string;
    columnCount:number;
    displayOption:string
    private _isLoaded= false;
    get isLoaded():boolean{
            return this._isLoaded||this.isCurated || this.curatedItems.length>0;
    }
    set isLoaded(value: boolean){
            this._isLoaded = value;
    }
    constructor(id: string="",domain: string="", title: string="", isCurated: boolean=true, description: string="",
        curatedItems: Array<CarouselItem> = new Array<CarouselItem>(),
        query: CarouselQuery | any = {}) {

        this.id = id;
        this.title = title;
        this.isCurated = isCurated;
        this.description = description;
        this.curatedItems = curatedItems;
        this.query = query;
        this.domain = domain;
    }
}

export enum CarouselAction{
    EDITCAROUSEL,
    DELETECAROUSEL,
    MOREACTION
}