import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AlexAppService,
  // AlexHttpService,
  AlexIconFinder,
  AlexMailService,
  AlexNavigationService,
  AlexUrlResolverService,
  AlexUserService  
} from "./services";

import { AlexLowerCaseUrlSerializer } from "app/core/url-serializers/lower-case-url-serializer.provider";
import { AppInsightsLoggerComponent } from "app/core/logging/app-insight-Logger.component";
import { AppInsightService } from "app/core/logging/app-insight-service.service";
import { AlexTaskTrackerService } from "app/core/services/task-tracker/task-tracker.service";
import { CkdCountryAndWorkflowTypesResolver, CkdVersionsResolver, AlexAllSourcesTaxonomyResolver } from './resolver';
import { UtilityCloseService } from './services/utility-close/utility-close.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AppInsightsLoggerComponent
  ],
  providers: [
    AlexAppService,
    // AlexHttpService,
    AlexIconFinder,
    AlexMailService,
    AppInsightService,
    AlexUrlResolverService,
    AlexLowerCaseUrlSerializer,
    AlexTaskTrackerService,
    AlexUserService,
    
    CkdCountryAndWorkflowTypesResolver,
    CkdVersionsResolver,
    AlexAllSourcesTaxonomyResolver,
    UtilityCloseService
  ]
})
export class AlexCoreModule {
  static forRoot(): ModuleWithProviders<AlexCoreModule> {
    return {
      ngModule: AlexCoreModule,
      providers: [
        AlexNavigationService
      ]
    }
  }
}
