import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { User } from "app/feature/header";
import { filter } from 'rxjs/operators';
import {
  AlexUserService,
  AlexAppService,
  AlexUrlResolverService,
} from "app/core/services";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";
import { AutoUnsubscribeObservables } from "app/core/decorators/auto-unsubscribe-observables";
import { Observable, Subject, Subscription } from "rxjs";
import { AlexUserGuideService } from "app/shared/components/userGuide/user-guide.service";
import { ALEX_EDITION_IDENTIFIER } from "./shared/constants";
import { distinctUntilChanged, takeUntil, tap } from "rxjs/operators";
import { HelpService } from "./shared/components/help/help.service";
import { BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { AlexUserGuideComponent } from "./shared/components/userGuide/user-guide.component";
import { ModalContentService } from "./shared/modal-content.service";
import { Router } from "@angular/router";
import { CurrentPageService } from "../app/shared/services/current-page.service";
import { AlexPages } from "./shared/components/help/help.enum";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { EventMessage, EventType, InteractionRequiredAuthError, InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { AppConfig } from "./app.config";
import { BroadcastService } from "./broadcast.service";
import {BROADCAST_SERVICE} from "./broadcast.token";
import { TokenService } from "./token.service";
import { environment } from "environments/environment";

/**
 * Model for checking edition id in URL
 */
export interface AlexEditionCheckModel {
  /**
   * Is edition id present in URL
   */
  archive: boolean;
  /**
   * Value of edition id if present in URL
   */
  editionId: string | null;
}

@Component({
  selector: "alex-app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
@AutoUnsubscribeObservables()
export class AppComponent implements OnInit, OnDestroy {
  user: User;
  errorMessage: string;
  isSpinnerVisible = false;
  spinnerMessage: string;
  spinnerState: any;
  mode: string;
  isFooterVisible = true;
  isUserCountryLoaded: boolean;
  showUserGuide$: Observable<boolean>;
  userServiceSubscription: Subscription;
  isIframe = false;
  isLoggedIn = false;

  timedOut = false;
  logoutTime = null;
  lastPing:Date = null;
  pingInterval:any;
  broadcastSubs:Subscription;
  sessionStorageData:any;

  private destroy$: Subject<void> = new Subject<void>();
  private modalRef: BsModalRef;
  private keepAliveModalRef: BsModalRef;
  @ViewChild('keepAliveModal',{static: false}) keepAliveModal:ModalDirective;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private userService: AlexUserService,
    private alexAppService: AlexAppService,
    private spinnerService: SpinnerService,
    private userGuideService: AlexUserGuideService,
    private urlResolverService: AlexUrlResolverService,
    private helpService: HelpService,
    private modalService: ModalContentService,
    private router: Router,
    private CurrentPageService: CurrentPageService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private idle:Idle,
    private keepAlive:Keepalive,
    private tokenService : TokenService,
    @Inject(BROADCAST_SERVICE) private broadCastService: BroadcastService
  ) {
      // this.hideAlexHeaderFooter();
      this.spinnerService.onVisibilityChange$.subscribe((isVisible) => {
        this.isSpinnerVisible = isVisible;
      });
      this.spinnerService.spinnerMessageChange$.subscribe((message) => {
        this.spinnerMessage = message;
      });
  
      this.spinnerService.spinnerState$.subscribe((state) => {
        this.spinnerState = state;
      });
      this.alexAppService.mode$.subscribe((mode) => {
        this.mode = mode;
      });
  
      const testr = this.CurrentPageService.getCurrentPage();
          testr.subscribe((data) => {
            //console.log(data)
            if(data === AlexPages.kcwLfc || data === AlexPages.kcwSearch){
              this.alexAppService.frameworkType = "KCW";
              //console.log("KCW");
            }else{
              this.alexAppService.frameworkType = "ALEX";
              //console.log("ALEX");
            }
          })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this._destroying$.next(undefined);
    this._destroying$.complete();

    if(this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    this.broadcastSubs.unsubscribe();

    navigator.serviceWorker.getRegistration('/sw.js').then(registration => {
      console.log('service worker unregistered');
      registration.unregister();
      
    });
  }
  


  ngOnInit() {
    
    this.isIframe = window !== window.parent && !window.opener;
    this.setIsLoggedin();
    this.msalService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$.pipe(
      filter((msg:EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    ).subscribe((result: EventMessage) => {
      if(this.msalService.instance.getAllAccounts().length === 0) {
       // window.location.pathname = '/'
       this.loginRedirect();

      }else{
        this.setIsLoggedin();
       // this.msalService.instance.setActiveAccount(result);
      }
    });

    
    


    this.msalBroadcastService.inProgress$.pipe(
      filter((status:InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setIsLoggedin();
     this.checkAndSetActiveAccount();
     
    console.log('entered inprogress');
    // navigator.serviceWorker.getRegistration('/sw.js').then(registration => {
    //   this.urlResolverService.setIsToken(false);
    //   let accessToken = this.getTokenValue('accesstoken').secret;
    //       let idToken = this.getTokenValue('idtoken').secret;
    //      let postData = {
    //       access_token : accessToken,
    //       id_token : idToken
    //      }
    //     registration.active.postMessage(postData);
    //     console.log('token set', postData);
        
    //     if(accessToken) {
    //       this.urlResolverService.setIsToken(true);
    //     }
    // });
     
     
     
     this.broadCastService.publishDataToOtherTab();

        //User Details calls
        if(this.isLoggedIn) {
          this.userService.showUserGuide$
      .asObservable()
      .pipe(
        distinctUntilChanged(),
        tap((show) => {
          if (show) {
            // handle show modal case.
            this.modalRef = this.modalService.openModalFromComponent(
              AlexUserGuideComponent,
              "helpModal"
            );
            return;
          }
          if (this.modalRef) {
            // handle hide modal case.
            this.modalRef.hide();
            this.modalRef = null;
            return;
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.updateHelpContentOnPageChange();
    this.setEditionId(this.readEditionIdFromUrl(document.location.href));
    this.userServiceSubscription = this.userService
      .getUserData()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result: any) => {
          this.user = <User>result;
          if (this.user.isNewUser && !this.user.isExternalUser) {
            const userGuideServieSubscription = this.userGuideService
              .fetchUserGuideContent(false)
              .subscribe(() => {
                this.userService.showUserGuide$.next(true);
                userGuideServieSubscription &&
                  userGuideServieSubscription.unsubscribe();
              });
          }
          this.userServiceSubscription &&
            this.userServiceSubscription.unsubscribe();
        },
        (error) => {
          this.errorMessage = <any>error;
          this.userServiceSubscription &&
            this.userServiceSubscription.unsubscribe();
        },
        () => {
          this.userServiceSubscription &&
            this.userServiceSubscription.unsubscribe();
        }
      );
        }
        
    })

  //broadcast-channel implementation
  

  this.broadcastSubs = this.broadCastService.messagesOfType(environment.L72AAD.redirectUri).subscribe(data => {
    this.sessionStorageData = data.payload;
    let sessionItems = Object.keys(this.sessionStorageData);
    if(sessionItems.length > 0){
        sessionItems.forEach(item => {
        sessionStorage.setItem(item,this.sessionStorageData[item]);
      });
    }else{
      sessionStorage.clear();
    }
    
  });

  //keep alive implementation
  //set ping interval
   this.pingInterval = setInterval (() => {
    this.getSilentToken();
  },environment.L72AAD.pingInterval * 1000);

  //set idle timeout
    this.idle.setIdle(environment.L72AAD.idleStartTime);
  
  //After this time of inactivity, the user will be considered timed out
  this.idle.setTimeout(environment.L72AAD.idleTimeout);

  //sets default interrupts like clicks,scrolls
  this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

  

  

  this.idle.onIdleEnd.subscribe(() => {
    this.idleReset();
    //this.getSilentToken();
  });

  this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.logout();
  });

  this.idle.onIdleStart.subscribe(() => {
    this.keepAliveModal.show();
  });

  this.idle.onTimeoutWarning.subscribe((timeLeft) =>{
    this.logoutTime = timeLeft;
  })

  
    
  this.idleReset();
    
  }

  


  getSilentToken(){
    //session management
    let sessionItems = {...sessionStorage};

    if(Object.keys(sessionItems).length > 0){
      let accessTokenKey = this.tokenService.getTokenKey('accesstoken');
     let accessToken = this.tokenService.getTokenValue('accesstoken');
     let idToken = this.tokenService.getTokenValue('idtoken');
  let currentAccount = this.msalService.instance.getAccountByHomeId(accessToken.homeAccountId);
  let silentRequest = {
    scopes: [environment.L72AAD.apiScope],
    account:currentAccount,
    forceRefresh: true
  };
  let redirectRequest = {
    scopes: [environment.L72AAD.apiScope],
    loginHint: currentAccount.username
  };

   this.msalService.instance.acquireTokenSilent(silentRequest)
   .then(resp =>{
      accessToken.secret = resp.accessToken;
      idToken.secret = resp.idToken;
      accessToken.expiresOn = resp.expiresOn.getTime() / 1000;
      accessToken.extendedExpiresOn = resp.extExpiresOn.getTime() / 1000;

      sessionStorage.setItem(accessTokenKey,JSON.stringify(accessToken));
      this.broadCastService.publishDataToOtherTab();
      this.tokenService.onTokenUpdate$.next();
    }).catch(error =>{
      if( error instanceof InteractionRequiredAuthError) {
        return this.msalService.instance.acquireTokenRedirect(redirectRequest);
      }
    })
    }else {
      this.broadCastService.publishDataToOtherTab();
      this.loginRedirect();
    }

    
  }

  logout(){
    this.hidekeepAliveModal();
    let sessionItems = {...sessionStorage};
    if(Object.keys(sessionItems).length > 0) {
      let accessToken = this.tokenService.getTokenValue('accesstoken');
      let currentAccount = this.msalService.instance.getAccountByHomeId(accessToken.homeAccountId);
      this.msalService.logoutRedirect({account:currentAccount, postLogoutRedirectUri:environment.L72AAD.postLogoutRedirectUri});
      
    }else{
      this.msalService.logoutRedirect();

    }
    navigator.serviceWorker.getRegistration('/sw.js').then(registration => {
      console.log('service worker unregistered');
      registration.unregister();
      
    });
    sessionStorage.clear();
      this.broadCastService.publishDataToOtherTab();
    
  }

  stay() {
    this.hidekeepAliveModal();
    this.idleReset();
    this.getSilentToken();
  }

  hidekeepAliveModal(){
    this.keepAliveModal.hide();
  }

  updateHelpContentOnPageChange(): void {
    this.helpService
      .updateHelpContentOnPageChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  readEditionIdFromUrl(url): AlexEditionCheckModel {
    // remove the hash part before operating on the url
    const hashIndx = url.indexOf("#");
    url = hashIndx === -1 ? url : url.substr(0, hashIndx);
    let match = /editionid=(.*?)(?:&|$)/i.exec(url);
    return {
      archive: !!match,
      editionId: match && match[1],
    } as AlexEditionCheckModel;
  }

  /**
   * Set Edition id if present
   * @param result object specifying whether edition is present
   */
  setEditionId(result: AlexEditionCheckModel) {
    this.setEditionInSessionStorage(result);
    this.updateEditionFilePath(result);
  }

  /**
   * Store edition id in session storage if present else remove edition
   * from session storage
   * @param result object specifying whether edition is present
   */
  setEditionInSessionStorage(result: AlexEditionCheckModel) {
    if (result.archive) {
      sessionStorage.setItem(ALEX_EDITION_IDENTIFIER, result.editionId);
    } else if (sessionStorage.getItem(ALEX_EDITION_IDENTIFIER)) {
      sessionStorage.removeItem(ALEX_EDITION_IDENTIFIER);
    }
  }

  updateEditionFilePath(result: AlexEditionCheckModel) {
    if (result.archive) {
      this.urlResolverService.setFilePathForArchive();
    }
  }

  setIsLoggedin() {
    this.isLoggedIn = this.msalService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if(!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      
      let accounts = this.msalService.instance.getAllAccounts();
      let account = accounts[0];
      this.msalService.instance.setActiveAccount(account);
    }
  }

  loginRedirect() {
    if(this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    }else {
      this.msalService.loginRedirect();
    }
  }

  idleReset() {
    this.idle.watch();
    this.timedOut = false;
  }

}
