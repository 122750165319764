
import { finalize } from 'rxjs/operators';
          
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';
import { SpinnerService } from 'app/shared/components/spinner/spinner.service';
import { Observable } from 'rxjs';

@Injectable()
export class SpinnerHttpConfigInterceptor implements HttpInterceptor {
    constructor(private spinnerService: SpinnerService) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const header = request.headers;
        const noSpinner = header && (<any>header.get("noSpinner"));
        const updatedHeader = noSpinner ? (header.delete("noSpinner")) : header;
        const clonedReqest = request.clone({ headers: updatedHeader });
        const spinnerId = !noSpinner && this.spinnerService.showSpinner();
        return next.handle(clonedReqest).pipe(
            finalize(() => {
            // finally(() => {
                !noSpinner && this.spinnerService.hideSpinner(spinnerId);
            }))
        

    }
}

