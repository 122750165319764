export const CONTEXT_LFC = 'lfc';
export const CONTEXT_TOC_SEARCH = 'tocsearch';
export const CONTEXT_TOP = 'top';

export const TASK_FETCH_TOC_CONTENT = 'FETCH_TOC_CONTENT';
export const TASK_FETCH_LFC_DOCUMENT = 'FETCH_LFC_DOCUMENT';
export const TASK_FETCH_TOC_SEARCH_RESULT = 'FETCH_TOC_SEARCH_RESULT';
export const TASK_RENDER_VIEW = 'RENDER_VIEW';



