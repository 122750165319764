import { Component, OnInit, Input } from '@angular/core';
import { DocumentViewerService } from "app/shared/components/document-viewer/document-viewer.service";

@Component({
  selector: 'alex-document-viewer-spinner',
  template: `
 <div *ngIf="(spinner$ | async).isVisible" class="loaderBody">
</div> 
<div *ngIf="(spinner$ | async).isVisible" class="documentloading style-2" >
  <div class="docspinner" >
    <div class="double-bounce1" ></div>
    <div class="double-bounce2" ></div>
  </div>
</div>


    



  `,
  styles: [`
  /* Absolute Center Spinner */
  .loaderBody{
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 1;
   background-color:white;
   z-index:5;
   
}

.documentloading {
  position: relative;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.documentloading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(138, 156, 202, 0.18);
}


.docspinner {
  width: 40px;
  height: 40px;
  position: fixed;
  margin:0px auto;
  top:60%;
  left:60%;
  z-index:999;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #005EB8;
  opacity: 0.4;
  position:absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 1.5  s infinite ease-in-out;
  animation: sk-bounce 1.5s infinite ease-in-out;
}

.double-bounce1[state='RENDERING_VIEW'], .double-bounce2[state='RENDERING_VIEW'] {  
  background-color: rgba(17, 193, 6, 0.5);  
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

@media screen and (max-width:544px) {
.docspinner {
    top:55%;
    left:45%;   
    }
}
 @media (min-width:545px) and (max-width:767px) {
    .docspinner {
    top:62%;
    left:45%;   
    }      
}
@media (min-width:768px) and (max-width:991px) {
      .docspinner {
        top:50%;
        left:50%;   
    }
}
     
  /*end spinner*/
   
    `]
})
export class DocumentViewerSpinnerComponent {
  spinner$ = this.documentViewerService.spinner$;
  // @Input() isVisible = false;
  // @Input() message: string;
  constructor(private documentViewerService: DocumentViewerService) {
  }

}
