<div class="combined-search-filters__wrapper">
  <div class="row">
    <div class="col-sm-12">
      <div class="filter-section__wrapper">
        <div class="filter-section__header">Sources</div>
        <div class="filter-section__content">
          <div class="row">
            <div class="col-md-4">
              <alex-multi-select
                [items]="sources"
                [allLabel]="'Sources'"
                [partialLabel]="'Sources'"
                (selectionChange)="onSourcesChange($event)"
              ></alex-multi-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr style="border-top-color:#8c8b8b;"/>
  <div class="row">
    <div class="col-sm-12">
      <div class="filter-section__wrapper">
        <div class="filter-section__header">KAEG</div>

        <div class="filter-section__content">
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <alex-multi-select-small
                [items]="countries"
                [allLabel]="'Country/Region/Jurisdiction'"
                [partialLabel]="'Country/Region/Jurisdiction'"
                [disabled]="!isManualsSelected"
                (selectionChange)="onCountryChange($event)"
              ></alex-multi-select-small>
            </div>
            <div class="col-md-4 col-sm-12">
              <alex-multi-select-small
                [items]="workflowTypes"
                [allLabel]="'Auditing Standard'"
                [partialLabel]="'Auditing Standard'"
                [disabled]="!isManualsSelected"
                (selectionChange)="onWorkflowTypesChange($event)"
              ></alex-multi-select-small>
            </div>
            <div class="col-md-4 col-sm-12">
              <alex-multi-select-small
                [items]="versions"
                [allLabel]="'Version'"
                [partialLabel]="'Version'"
                [disabled]="!isManualsSelected"
                (selectionChange)="onVersionsChange($event)"
              ></alex-multi-select-small>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row filter-actions__wrapper">
    <div class="col-sm-12">
      <div class="float-right">
        <button class="btn btn-primary btn-sm" (click)="onSearchClick()">
          Search
        </button>
        <button
          style="color: #d7d7d7"
          class="btn btn-default btn-sm"
          (click)="onResetClick()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</div>
