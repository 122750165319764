
<li class="inactiveTile box-shadow" 
    [ngClass]="{'saveTile':item.isSpaceAssigned}" > 
  <alex-carousel-item-header [docType]="item.docType" [docId]="item.docId" [docTitle]="item.docTitle" [mimeType]="item.mimeType" [documentObject]="item" (sendItem)="sendItem($event)" (sendFlyOutOpenStatus)="sendFlyOutOpenStatus($event)"></alex-carousel-item-header>

  <div *ngIf="thubmnailImage" title="{{item?.docTitle}}" (click)="openDocument(item)" class="carouselImg clickableTitle"><img [src]="thubmnailImage" class="img-fluid"/></div>

  <div (click)="openDocument(item)" title="{{item?.docTitle}}" class=" carouselHdingPadding clickableTitle"
    [ngClass]="{'headingSectionNoImg': !thubmnailImage,'headingSectionWithImg': thubmnailImage}"><h3  [ngClass]="{'h3Padding': !thubmnailImage }">{{item?.docTitle}}</h3>
  </div>

  <div class="carouselSvgBottom">
     <a class="float-left Alextooltip"  title="Path">
                                <svg (click)="clickLocation()" class="svgIm greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
                                    height="1px" viewBox="0 0 22 1"  xml:space="preserve">
                                <g transform="scale(1.2)">
                                <g transform="translate(-54 -110)" xmlns="http://www.w3.org/2000/svg" id="map-marker_2_">
                                    <path d="M63.972,106.585c0,0.881-0.183,1.838-0.727,2.701c-1.638,2.544-3.711,4.878-3.711,4.878s-2.383-2.253-3.906-4.924
                                c-0.382-0.834-0.631-1.716-0.631-2.6c0-2.483,2.008-4.64,4.488-4.64C61.963,102,63.972,104.102,63.972,106.585z M62.208,106.404
                                c0-1.484-1.202-2.683-2.683-2.683c-1.481,0-2.683,1.2-2.683,2.683c0,1.483,1.202,2.682,2.683,2.682
                                C61.006,109.086,62.208,107.886,62.208,106.404z" />
                                        </g>
                                    </g>	
                                </svg>

  <alex-location [showPopUp]="showPopUp" ></alex-location>


     </a>

    <span *ngIf="item?.createdDate" title="{{item?.createdDate}}">
                                     <svg *ngIf="item?.createdDate" class="svgIm greyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22px"
                                                height="1px" viewBox="0 0 22 1" xml:space="preserve">
                                                <g transform="scale(0.7)">
                                                    <g transform="translate(-298,-269)" xmlns="http://www.w3.org/2000/svg" id="calendar_2_">
                                                    <path d="M300,259v16c0,0.55,0.45,1,1,1h21c0.55,0,1-0.45,1-1v-16H300z M305,274h-3v-3h3V274z M305,270h-3v-3h3V270z M305,266h-3
			                                            v-3h3V266z M309,274h-3v-3h3V274z M309,270h-3v-3h3V270z M309,266h-3v-3h3V266z M313,274h-3v-3h3V274z M313,270h-3v-3h3V270z
			                                             M313,266h-3v-3h3V266z M317,274h-3v-3h3V274z M317,270h-3v-3h3V270z M317,266h-3v-3h3V266z M321,274h-3v-3h3V274z M321,270h-3v-3
			                                            h3V270z M321,266h-3v-3h3V266z M323,255v3h-23v-3c0-0.55,0.45-1,1-1h2v-1c0-0.55,0.45-1,1-1h1c0.55,0,1,0.45,1,1v1h11v-1
			                                            c0-0.55,0.45-1,1-1h1c0.55,0,1,0.45,1,1v1h2C322.55,254,323,254.45,323,255z" />
                                                    </g>
                                                </g>	
                                 </svg>

                                     {{item?.createdDate}}</span>
  </div>
</li>


