
import {of as observableOf, throwError as observableThrowError,  Observable ,  Subject ,  Subscription ,  BehaviorSubject } from 'rxjs';

import {share, map, catchError, finalize} from 'rxjs/operators';
import { Injectable } from '@angular/core';
// import { RequestOptions } from '@angular/http';

import { User } from './user.model';


// import 'rxjs/add/Observable/of';
import { AlexUrlResolverService } from "app/core/services/url-resolver/url-resolver.service";
// import { AlexHttpService } from "app/core/services";
import { Router, ActivatedRoute } from "@angular/router";
import { AlexSessionManager } from "app/core/services/session/session-manager.service";
import { LicenseStatus } from "app/core/services/user/license-status.enum";
import { SubscriptionService } from "app/shared/components/subscription/subscription.service";
import { HttpClient } from '@angular/common/http';


@Injectable()
export class AlexUserService {
  isNewUser = new Subject<boolean>();
  user: User;
  user$ = new Subject<User>();
  userObservable: Observable<User>;
  userSubscription: Subscription;
  isRequestInProgress: boolean;
  isUserCountryLoaded: Subject<boolean>;
  isAuthorizedUser: BehaviorSubject<boolean>;
  showUserGuide$: BehaviorSubject<boolean>;
  
  constructor(private http: HttpClient,
    private urlResolver: AlexUrlResolverService,
    private router: Router,
    private route: ActivatedRoute,
    private sessionManager: AlexSessionManager,
    private subscriptionService: SubscriptionService) {
    this.isUserCountryLoaded = new Subject<boolean>();
    this.isUserCountryLoaded.next(false);
    this.isAuthorizedUser = new BehaviorSubject<boolean>(false);
    this.showUserGuide$ = new BehaviorSubject<boolean>(false);
  }

  IsCountryLoaded() {
    this.isUserCountryLoaded.next(true);
  }
  getUserData(fromServer = false): Subject<User> {
    if (this.user && !fromServer) {
      setTimeout(() => {
        this.user$.next(this.user);
      }, 0);
    } else {
      this.userSubscription = this.getUserDataAsObservable(fromServer).subscribe(() => {
        this.userSubscription && this.userSubscription.unsubscribe();
      })
    }
    return this.user$;
  }

  getUserDataAsObservable(fromServer = false): Observable<User> {
    if (!this.isRequestInProgress || fromServer) {
      this.userObservable = this.http.get(this.urlResolver.resolveUrlApiV1('user/userdetails')).pipe(
        share(),
        map(response => {
          this.user = response as User;
          if (this.user.userLicenseModel)
            this.subscriptionService.setSessionID(this.user.userLicenseModel.sessionID);
          this.user.activeDomains = this.user.domains;
          this.user$.next(this.user);
          return this.user;
        }),
        catchError(e => {
          return observableThrowError(e);
        }),
        finalize(() => this.isRequestInProgress = false),);

      this.isRequestInProgress = true;
    }
    return this.userObservable;
  }

  getSubscribedDomainList(checkServer = false): Observable<Array<string>> {
    if (!checkServer && this.user) {
      return observableOf(this.user.domains);
    }
    return this.getUserData().pipe(
      map(user => user.domains));
  }

  setActiveDomain(domains: Array<string>) {
    this.user.activeDomains = domains;
    this.user$.next(this.user);
  }

  getActiveDomain() {
    return this.user.activeDomains || [];
  }

  setUserDomain(domains: Array<string>) {
    this.user.domains = domains;
    this.user$.next(this.user);
  }

  extUserLogOut() {
    return this.http.get(this.urlResolver.resolveUrlApiV1('user/LogOut')).pipe(
      map(response => {
        return response;
      }));
  }

}
