import { mapTo, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AlexNotificationService } from "app/shared/components/notification/notification.service";
import { Status, AlexUrlResolverService } from "app/core/services";
import { AlexPages } from "app/shared/components/help/help.enum";
import { HttpClient } from "@angular/common/http";
import { CurrentPageService } from "app/shared/services/current-page.service";

@Injectable({
  providedIn: "root",
})
export class HelpService {
  lastRouteUrl = "";
  pageLocationUrl: string;
  helpPages: any;
  parentPage: AlexPages;

  helpContent$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private currentPageTitle$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );

  constructor(
    private http: HttpClient,
    private notificationService: AlexNotificationService,
    private urlResolverService: AlexUrlResolverService,
    private currentPageService: CurrentPageService
  ) {
    //
    this.helpPages = this.urlResolverService.getHelpPageList();
  }

  getHelpContent(): Observable<string> {
    return this.helpContent$.asObservable();
  }

  getCurrentPageTitle(): Observable<string> {
    return this.currentPageTitle$.asObservable();
  }

  updateHelpContentOnPageChange(): Observable<boolean> {
    return this.currentPageService.getCurrentPage().pipe(
      tap((currentPage) => {
        this.parentPage = currentPage;
      }),
      tap(() => this.formUrl()),
      tap(() => this.getHelpData()),
      mapTo(true)
    );
  }

  getHelpData(): void {
    this.getHelpDataFromUrl(this.pageLocationUrl);
  }

  formUrl() {
    switch (this.parentPage) {
      case AlexPages.search:
        this.getSearchPageUrl();
        break;
      case AlexPages.home:
        this.getHomePageUrl();
        break;
      case AlexPages.bridge:
        this.getBridgePageUrl();
        break;
      case AlexPages.lfc:
        this.getLfcPageUrl();
        break;
      case AlexPages.manualsLfc:
        this.getManualsLfcPageUrl();
        break;
      case AlexPages.spaces:
        this.getSpacePageUrl();
        break;
      case AlexPages.manualsearch:
        this.getManualSearchPageUrl();
        break;
      case AlexPages.kcwSearch:
        this.getKCwSearchPageUrl();
        break;
      case AlexPages.kcwLfc:
        this.getKCwSearchPagelfcUrl();
        break;
        
      default:
        this.getDefaultPageUrl();
    }
  }

  getHomePageUrl() {
    const { title, fileName } = this.helpPages.home;
    this.setCurrentPageTitle(title);
    this.pageLocationUrl = this.urlResolverService.resolveFilePath(
      fileName,
      true
    );
  }

  getSearchPageUrl() {
    const { title, fileName } = this.helpPages.search;
    this.setCurrentPageTitle(title);
    this.pageLocationUrl = this.urlResolverService.resolveFilePath(
      fileName,
      true
    );
  }
  getManualSearchPageUrl() {
    const { title, fileName } = this.helpPages.manualsearch;
    this.setCurrentPageTitle(title);
    this.pageLocationUrl = this.urlResolverService.resolveFilePath(
      fileName,
      true
    );
  }

  getBridgePageUrl() {
    const { title, fileName } = this.helpPages.bridge;
    this.setCurrentPageTitle(title);
    this.pageLocationUrl = this.urlResolverService.resolveFilePath(
      fileName,
      true
    );
  }

  getLfcPageUrl() {
    const { title, fileName } = this.helpPages.lfc;
    this.setCurrentPageTitle(title);
    this.pageLocationUrl = this.urlResolverService.resolveFilePath(
      fileName,
      true
    );
  }

  getManualsLfcPageUrl() {
    const { title, fileName } = this.helpPages.manualsLfc;
    this.setCurrentPageTitle(title);
    this.pageLocationUrl = this.urlResolverService.resolveFilePath(
      fileName,
      true
    );
  }

  getSpacePageUrl() {
    const { title, fileName } = this.helpPages.spaces;
    this.setCurrentPageTitle(title);
    this.pageLocationUrl = this.urlResolverService.resolveFilePath(
      fileName,
      true
    );
  }
  getKCwSearchPageUrl() {
    const { title, fileName } = this.helpPages.kcwsearch;
    this.setCurrentPageTitle(title);
    this.pageLocationUrl = this.urlResolverService.resolveFilePath(
      fileName,
      true
    );
  }
  getKCwSearchPagelfcUrl() {
    const { title, fileName } = this.helpPages.kcwsearchlfc;
    this.setCurrentPageTitle(title);
    this.pageLocationUrl = this.urlResolverService.resolveFilePath(
      fileName,
      true
    );
  }
  getDefaultPageUrl() {
    const { title, fileName } = this.helpPages.default;
    this.setCurrentPageTitle(title);
    this.pageLocationUrl = this.urlResolverService.resolveFilePath(
      fileName,
      true
    );
  }

  getHelpDataFromUrl(url): void {
    const sub = this.http.get(url, { responseType: "text" }).subscribe(
      (result) => {
        this.helpContent$.next(result);
        sub.unsubscribe();
      },
      (error) => {
        this.notificationService.show(Status.Failure, "File not found", false);
        this.helpContent$.next("");
      }
    );
  }

  private setCurrentPageTitle(pageTitle: string): void {
    this.currentPageTitle$.next(pageTitle);
  }
}
