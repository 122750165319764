import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AddToAlexService } from "app/shared/add-to-alex.service";
import { CarouselItem } from "app/shared/components/carousel/model/carousel-item.model";
import { Location } from "@angular/common";
import { AlexMailService } from "app/core/services";
import { AlexModalComponent } from "app/shared/components/alex-modal/alex-modal.component";
import { Space } from "app/feature/spaces/model/space.model";
import { SearchItem } from "app/feature/search/model/search-item.model";
import { SpaceDocument } from "app/feature/spaces/model/space-document.model";
import { DomainService } from "app/feature/home/domain.service";

@Component({
  selector: 'alex-carousel-item-header',
  templateUrl: './carousel-item-header.component.html',
  styleUrls: ['./carousel-item-header.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class CarouselItemHeaderComponent implements OnInit {
  @Input() docType: string;
  @Input() mimeType: string;
  @Input() docId: string;
  @Input() docTitle: string;
  @Input() documentObject: CarouselItem;
  @Output() whenSavedToAlex: EventEmitter<any> = new EventEmitter();
  @Output() sendItem: EventEmitter<any> = new EventEmitter();
  @Output() sendFlyOutOpenStatus: EventEmitter<any> = new EventEmitter();

  ifAddToAlex = false;
  showShareDiv = false;
  path: string;
  spaces: Array<Space>;
  clicked = false;
  @ViewChild(AlexModalComponent) modal;
  constructor(private addInSpace: AddToAlexService,
    private location: Location,
    private mailService: AlexMailService,
    private eref: ElementRef,
    private searchItem: SearchItem,
    private spaceService: SpaceDocument,
    public domainService: DomainService) { }

  ngOnInit() { }



  clickAddToSpace(event: Event) {
    this.documentObject.type="addSpaceModalToolTip"
    // this.documentObject.docType="addSpaceModalToolTip"
    this.sendItem.emit(this.documentObject);
    this.clicked = false;
  }

  flyoutClicked() {
    this.clicked = !this.clicked;
    this.sendFlyOutOpenStatus.emit(this.clicked);
  }

  //called on click on outside element
  onClick(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.clicked = false;
    }
  }

  copyContent(element: any) {
    element.select();
    document.execCommand('copy', false, null);
  }

  clickShare() {
    this.documentObject.type="shareModalToolTip"
    // this.documentObject.docType="shareModalToolTip"    
    this.sendItem.emit(this.documentObject);
  }
  
  clicktoView() {
    this.documentObject.type="viewModalToolTip";  
    this.sendItem.emit(this.documentObject);
  }
}
