


import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'alex-modal',
  templateUrl: './alex-modal.component.html',
  styleUrls: ['./alex-modal.component.scss']
})
export class AlexModalComponent implements OnInit {
  @Input() headerText:string
  @Input() modalId:string
  @Input() footerCancelText:string;
  @Input() footerOkText:string;
  @Input() disableSubmit:boolean=true;
  @Input() width:string
  @Input() dissmissOnSubmit:boolean=true;
  @Input() applyDisabledCSS:boolean=false;
  @Output() OnOkClicked: EventEmitter<any>;
  @Output() OnCancelClicked: EventEmitter<any>;
  @Output() removeText: EventEmitter<any>;
  @ViewChild('cancel') cancel:ElementRef;
  constructor() {    
    this.OnOkClicked= new EventEmitter<any>();
    this.removeText = new EventEmitter<any>();
    this.OnCancelClicked = new EventEmitter<any>();
   }

  ngOnInit() {
  }

  onOkClick($event){
    this.OnOkClicked.emit($event);
  }

  hideModal(){
    this.cancel.nativeElement.click();
  }
  onCancelClick($event)
  {
     this.OnCancelClicked.emit($event);
  }
}
