import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Resolve } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { SubscriptionService } from "app/shared/components/subscription/subscription.service";
import { AlexUserService, AlexUrlResolverService, User } from "app/core/services";
import { LicenseStatus } from "app/core/services/user/license-status.enum";
import { AutoUnsubscribeObservables } from "app/core/decorators/auto-unsubscribe-observables";
import { MsalService } from '@azure/msal-angular';

@Injectable()
@AutoUnsubscribeObservables()
export class UserAuthorizationGuard implements CanActivate {
  userServiceSubscription: Subscription;
  constructor(private userService: AlexUserService,
    private router: Router,

    private subscriptionService: SubscriptionService,
    private urlResolver: AlexUrlResolverService,
    private msalService:MsalService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let isAuthorizedUser = true;
    let isLoggedIn = this.msalService.instance.getAllAccounts().length > 0;

      this.userServiceSubscription = this.userService.getUserDataAsObservable().subscribe(user => {
        if (user.isAudienceGroupAvailable) {
          if (user.isExternalUser && user.userLicenseModel) {
            if (user.userLicenseModel.userStatus != "OK" ||
              user.userLicenseModel.licencseStatus == LicenseStatus.Expired) {
              isAuthorizedUser = false;
              this.router.navigate(["/unauthorized"]);
            } else if (user.userLicenseModel.isFirstLogin || !user.userLicenseModel.isUserAgreed) {
              let manageAccountUrl = this.urlResolver.getExtManagAccUrl();
              window.location.href = manageAccountUrl + "?UserSession="
                + user.userLicenseModel.sessionID;
            } else {
              this.subscriptionService.subscriptionPopupVisible.next(user.userLicenseModel.noOfDays >= 0);
            }
          }
          else {
            isAuthorizedUser = true;
          }
        } else {
          isAuthorizedUser=false;
          this.router.navigate(["/unauthorized"]);
        }
        this.userService.isAuthorizedUser.next(isAuthorizedUser);
        this.userServiceSubscription && this.userServiceSubscription.unsubscribe();
      },
        error => {
          if (error.status == 401 || error.status == 403) {
            isAuthorizedUser = false;
            this.userService.isAuthorizedUser.next(isAuthorizedUser);
            this.router.navigate(["/unauthorized"]);
          } else {
            isAuthorizedUser = true; 
          }
          this.userServiceSubscription && this.userServiceSubscription.unsubscribe();
        },
        () => {
          this.userServiceSubscription && this.userServiceSubscription.unsubscribe();
        })

    return isAuthorizedUser;
  }
}
