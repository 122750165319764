import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  SimpleChanges,
  ViewEncapsulation,
  Renderer2,
  ElementRef,
  AfterViewChecked,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import {
  SafeResourceUrl,
  DomSanitizer,
  SafeStyle,
} from "@angular/platform-browser";
import {
  AlexUrlResolverService,
  AlexNavigationService
} from "app/core/services";
import { AlexDocumentService } from "app/feature/long-form-content/document/document.service";
import { AutoUnsubscribeObservables } from "app/core/decorators/auto-unsubscribe-observables";
import { Subscription} from "rxjs";
import {
  Router,
  ActivatedRouteSnapshot,
  ActivatedRoute,
} from "@angular/router";
import { DocumentProcessorService } from "app/shared/components/document-viewer/document-processor.service";
import { DocumentViewerService } from "app/shared/components/document-viewer/document-viewer.service";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";
import { AlexFileService } from "../../../core/services/file/alex-file.service";
import { ModalContentService } from "app/shared/modal-content.service";
import { BsLegacyModalService } from "app/core/services/modal/bootstrap-legacy-modal.service";

@Component({
  selector: "alex-document-viewer",
  templateUrl: "./document-viewer.component.html",
  styleUrls: ["./document-viewer.component.scss"],
})
@AutoUnsubscribeObservables()
export class AlexDocumentViewerComponent
  implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  @Input() styleSheet: string;
  @Input() htmlContent: any;
  @Input() section: string;
  modifiedHtmlContent: any;
  @Input() document: string;
  styleSheetUrl: SafeResourceUrl;
  style: SafeStyle;
  private nativeElement: Node;
  private fragment = null;
  @Output() ContentLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() LoadGlossary: EventEmitter<string> = new EventEmitter<string>();

  private styleSheetSubscription: Subscription;
  private routerSubscription: Subscription;

  @Input() showLoader: boolean;
  @Input() message: string;

  constructor(
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private urlResolver: AlexUrlResolverService,
    private documentService: AlexDocumentService,
    private navigationService: AlexNavigationService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private fileService: AlexFileService,
    private router: Router,
    private modalService: ModalContentService,
    private bsLegacyModalService: BsLegacyModalService
  ) {
    this.nativeElement = elementRef.nativeElement;
    
  }

  ngOnInit() {
    this.styleSheetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlResolver.resolveStyleSheetUrl(this.styleSheet));
    
    //	If not able to fetch style sheet then add it as link tag
    // this.spinnerService.noSpinner(true);
    // this.styleSheetSubscription = this.documentService.fetchStyleSheet(this.styleSheet).subscribe(styleSheet => {
    //     this.creteStyleSheet(styleSheet);
    //     this.spinnerService.noSpinner(false);
    // }, (error) => {
    //     this.styleSheetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlResolver.resolveStyleSheetUrl(this.styleSheet));
    //     this.spinnerService.noSpinner(false);
    // });
  }

  ngOnDestroy(): void {
      
  }

  ngOnChanges(changes: SimpleChanges): void {
        let doc = "";
    if (
      (this.htmlContent &&
        (<any>this.htmlContent).changingThisBreaksApplicationSecurity) ||
      this.document
    ) {
      doc =
        (<any>this.htmlContent).changingThisBreaksApplicationSecurity ||
        this.document;
      const html = new DOMParser().parseFromString(doc, "text/html");
      this.modifiedHtmlContent = this.sanitizer.bypassSecurityTrustHtml(
        html.head.innerHTML + html.body.innerHTML
      );

      const scripts = html.getElementsByTagName("script");
      if (scripts && scripts.length) {
        this.createScriptElements(<any>scripts);
      }
    }
  }

  private creteStyleSheet(text: string) {
    const styleElement = this.renderer.createElement("style");
    styleElement.type = "text/css";
    styleElement.appendChild(this.renderer.createText(text));
    this.renderer.appendChild(this.nativeElement, styleElement);
  }

  private createScriptElements(scripts: NodeListOf<HTMLScriptElement>) {
    for (let i = 0; i < scripts.length; i++) {
      const scriptElement = this.renderer.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.text = scripts[i].text;
      scriptElement.async = "";
      this.renderer.appendChild(this.nativeElement, scriptElement);
    }
  }

  clear() {
    //this.renderer.removeChild(this.nativeElement,this.renderer)
  }

  scrollToId(id: string) { }
  routeHandler(event: Event) {
    const matchingElement = this.findLinkNode(event.srcElement as any)as any;
    if (matchingElement) {
      event.preventDefault();
      this.LoadGlossary.emit(matchingElement.id);
    } else {
      let returnValue;
      if ((returnValue = this.isHashRoute(event.srcElement))) {
        event.preventDefault();
        window.location.hash = returnValue;
        return false;
      } else if (
        (returnValue = this.isOldDownloadLinkFormat((<any>event).srcElement))
      ) {
        if (
          this.route.snapshot.routeConfig.path.toLowerCase() == "top/:docid" ||
          this.route.snapshot.routeConfig.path.toLowerCase() == "lfc/find/:id"
        ) {
          this.router.navigate(["lfc", returnValue, "toc", returnValue], {
            relativeTo: this.route.parent,
          });
        } else {
          this.router.navigate(["toc", returnValue], {
            relativeTo: this.route,
          });
        }
        event.preventDefault();
        return false;
      } else if ((returnValue = this.isFileurl((<any>event).srcElement))) {
        this.spinnerService.showSpinnerAsync().then((spinnerId) => {
          this.fileService.downloadOrOpenFileUrl(returnValue);
          this.spinnerService.hideSpinner(spinnerId);
        });
        event.preventDefault();
        return false;
      } else if (!this.isNewWindow((<any>event).srcElement)) {
        if ((<any>event).srcElement.attributes["href"]) {
          const href = (<any>event).srcElement.attributes["href"].value;
          if (!this.isInlineScriptTargeted(href)) {
            this.navigationService.ignoreRouteEvent = true;
            this.router.navigateByUrl(href);
            return false;
          }
        }
      }
      // append alex editionId, to document links
      else if (
        sessionStorage.getItem("alex_edition") &&
        this.isInternalUrl((<any>event).srcElement.attributes["href"].value)
      ) {
        const editionId = sessionStorage.getItem("alex_edition");
        event.preventDefault();
        let href = (<any>event).srcElement.attributes["href"].value as string;
        if (href.includes("?")) {
          href += `&editionId=${editionId}`;
        } else {
          href += `?editionId=${editionId}`;
        }
        window.open(href, "_blank");
        // this.InternalLinkClick.emit(href);
      }
    }
  }

  findLinkNode(a: HTMLElement) {
    if (a.className.includes('aLinkGlossary')) {
      return a;
    } else {
      if (a.tagName == 'BODY') {
        return null;
      } else {
        return this.findLinkNode(a.parentElement);
      }
    }
  }

  isHashRoute(element: any) {
    let hash = "";
    try {
      hash = element.hash;
      if (!hash) hash = this.closestByClass(element, "AlexLink").hash; //catch block added because sometime closest throws error
    } catch (error) { }
    return hash;
  }

  closestByClass = function (el, clazz) {
    while (el.className != clazz) {
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  };
  isNewWindow(element: Element): boolean {
    let isNewWindow = false;
    try {
      const href = element.attributes["href"].value;
      const target = element.attributes["target"].value;
      isNewWindow = href && target === "_blank";
    } catch (error) { }
    return isNewWindow;
  }

  isOldDownloadLinkFormat(element: Element): any {
    let documentId = "";
    const queryParams = new Map<string, string>();
    try {
      const href = element.attributes["href"].value;
      href
        .split("?")[1]
        .split("&")
        .forEach((item) => {
          queryParams.set(item.split("=")[0], item.split("=")[1]);
        });
    } catch (error) { }

    if (
      queryParams.get("from") == "attach" ||
      queryParams.get("isBinaryLinks") == "true"
    ) {
      documentId = queryParams.get("ref");
    }

    return documentId;
  }

  isFileurl(element: Element) {
    let isFileurl = false;
    try {
      const href = element.attributes["href"].value;
      isFileurl = /\/binaries\/(.*)/i.test(href);
      return isFileurl && href;
    } catch (error) { }
    return isFileurl;
  }
  // isDownloadableContent(element: Element): boolean {
  //     let documentId = '';

  //     const queryParams = new Map<string, string>();
  //     try {
  //         const href = element.attributes['href'].value;
  //         href.split('?')[1]
  //             .split('&').forEach(item => {
  //                 queryParams.set(item.split('=')[0], item.split('=')[1]);
  //             });
  //     } catch (error) {

  //     }

  //     if (queryParams.get('from') == 'attach' && queryParams.get('ref')) {
  //         // console.log(queryParams.get('ref'));
  //         this.documentService.getBinaryPath(queryParams.get('ref'));
  //         return true;
  //     }
  //     else if (queryParams.get('isBinaryLinks') == 'true' && queryParams.get('ref')) {
  //         //console.log(queryParams.get('ref'));
  //         this.documentService.getBinaryPath(queryParams.get('ref'));
  //         return true;
  //     }

  //     return false;
  // }

  isInlineScriptTargeted(href: string) {
    return href.toLowerCase().replace(/\s/g, "").includes("javascript:");
  }

  ngAfterViewChecked() {
    this.ContentLoaded.emit(true);
  }

  ngAfterViewInit() {
    //bridge between the legacy modal and new modal
    this.bsLegacyModalService
      .unregisterAllModals()
      .registerModals(document.querySelectorAll("[data-toggle='modal']"));
  }

  /**
   * Check if test url href is internal or external url
   * @param href url href
   */
  private isInternalUrl(href: string): boolean {
    const externalURLRegEx = new RegExp("^(?:[a-z]+:)?//", "i");
    return (
      href.toLowerCase().includes("aroweb") || !externalURLRegEx.test(href)
    );
  }

}
