/*Combined search workflow filter */
export const ADD_WORKFLOW_FILTER = "ADD_WORKFLOW_FILTER";
export const REMOVE_WORKFLOW_FILTER = "REMOVE_WORKFLOW_FILTER";
export const WORKFLOW_FILTER = "WORKFLOW_FILTER";

/* Combined search version filter */
export const ADD_VERSION_FILTER = "ADD_VERSION_FILTER";
export const REMOVE_VERSION_FILTER = "REMOVE_VERSION_FILTER";
export const VERSION_FILTER = "VERSION_FILTER";

/* Combined search applicable country filter */
export const ADD_APPLICABLE_COUNTRY_FILTER = "ADD_APPLICABLE_COUNTRY_FILTER";
export const REMOVE_APPLICABLE_COUNTRY_FILTER = "REMOVE_APPLICABLE_COUNTRY_FILTER";
export const APPLICABLE_COUNTRY_FILTER = "APPLICABLE_COUNTRY_FILTER";

/* source filter */
export const ADD_SOURCE_FILTER = "ADD_SOURCE_FILTER";
export const REMOVE_SOURCE_FILTER = "REMOVE_SOURCE_FILTER";
export const SOURCE_FILTER = "SOURCE_FILTER";

/* topic filter */
export const ADD_TOPIC_FILTER = "ADD_TOPIC_FILTER";
export const REMOVE_TOPIC_FILTER = "REMOVE_TOPIC_FILTER";
export const TOPIC_FILTER = "TOPIC_FILTER";

/* other category filter */
export const ADD_OTHER_CATEGORY_FILTER = "ADD_OTHER_CATEGORY_FILTER";
export const REMOVE_OTHER_CATEGORY_FILTER = "REMOVE_OTHER_CATEGORY_FILTER";
export const OTHER_CATEGORY_FILTER = "OTHER_CATEGORY_FILTER";

/* date filter */
export const ADD_DATE_FILTER = "ADD_DATE_FILTER";
export const REMOVE_DATE_FILTER = "REMOVE_DATE_FILTER";
export const DATE_FILTER = "REMOVE_DATE_FILTER";

/* file filter */
export const ADD_FILE_FILTER = "ADD_FILE_FILTER";
export const REMOVE_FILE_FILTER = "REMOVE_FILE_FILTER";
export const FILE_FILTER = "FILE_FILTER";

/* language filter */
export const ADD_LANGUAGE_FILTER = "ADD_LANGUAGE_FILTER";
export const REMOVE_LANGUAGE_FILTER = "REMOVE_LANGUAGE_FILTER";
export const LANGUAGE_FILTER = "LANGUAGE_FILTER";

/* Country filter */
export const ADD_COUNTRY_FILTER = "ADD_COUNTRY_FILTER";
export const REMOVE_COUNTRY_FILTER = "REMOVE_COUNTRY_FILTER";
export const COUNTRY_FILTER = "COUNTRY_FILTER";

/* tags */
export const REMOVE_ALL_TAGS = "REMOVE_ALL_TAGS";

/* see all result */
export const SEE_ALL_RESULT = "SEE_ALL_RESULT";
export const REMOVE_PARENT_ID_FILTER = "REMOVE_PARENT_ID_FILTER";
export const REMOVE_TOC_REF_FILTER = "REMOVE_TOC_REF_FILTER";