import { AlexDropdownModel } from "app/shared/components/dropdown/dropdown.model";
import { AlexPaginationModel } from "app/shared/components/pagination/pagination.model";
import { CKDSearchResultModel } from "./ckd-search-result.model";
import { CKDFilterTagModel } from "../ckd-search-result-tag/ckd-filter-tag.model";

export class CKDSearchViewModel {
    result: CKDSearchResultModel;
    sortDropdownModel: AlexDropdownModel;
    pagination: AlexPaginationModel;
    tags: Array<CKDFilterTagModel>;
}