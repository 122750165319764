
import {map} from 'rxjs/operators';
import { Injectable, ViewChild } from "@angular/core";
import { AlexUrlResolverService } from "app/core/services";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";


@Injectable()
export class AlexTopicTagService {
    TopicDetail: string;
    TopicDetail$: Subject<string>;
    
    constructor(private http: HttpClient, private urlResolver: AlexUrlResolverService) {

        this.TopicDetail$ = new Subject<string>();
        
    }

    fetchTopicTagDetails(taxonomyIds: Array<string>) {

        return this.http.post(this.urlResolver.resolveUrlApiV1('/common/TopicTagDetails'), taxonomyIds).pipe(
            map(response => {
                return response as Array<string>;
            })).subscribe(topicData => {
                this.TopicDetail = topicData.join(' , ');
                
                    this.TopicDetail$.next(this.TopicDetail);
               
            });
    }

   
}