export class UrlConfig {
    baseUrlApiV1: string;
    baseFilePath: string;
    baseArchiveFilePath: string;
    binariesPath: string;
    archiveBinariesPath: string;
    baseAlexFriendlyUrl: string;
    userGuideUrl : string;
    websiteRoot:string;
    baseAlexLogoutUrl;
    extUserLoginUrl:string;
    renewLicenseUrl:string;
    extUserProfileUrl:string;
    extManageAccUrl:string;
    siteAdminEmail:string;
}