import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { CarouselItem } from "app/shared/components/carousel/model/carousel-item.model";
import { AlexAppService, AlexUrlResolverService } from "app/core/services";
import { Router } from "@angular/router";
import { SearchItem } from "app/feature/search/model/search-item.model";
import { SpacesService } from "app/feature/spaces/spaces.service";
import { AlexLocationQueryModel } from "app/shared/components/location/alex-location-query.model";
import { AlexLocationComponent } from "app/shared/components/location/position-mid/alex-location.component";
import { AlexDocumentService } from "app/feature/long-form-content/document/document.service";
import { AlexUserService, Status } from "app/core/services";
import { AlexDocumentType } from "app/core/services/app/app.constant";
import { AlexNotificationService } from "app/shared/components/notification/notification.service";
import { DomainService } from "app/feature/home/domain.service";
// phase 2 tracking code
import { ExternalUrlClickTrackingData, ExternalUrlClickTrackingModel } from 'app/shared/models/tracking/external-url-click';
import { TrackingService } from 'app/shared/services/tracking';
import { PAGE_IDENTIFIERS } from 'app/shared/constants/tracking/external-url-click';
import { SearchParamService } from 'app/feature/search/services/search-param.service';


@Component({
  selector: 'alex-carousel-item-type1',
  templateUrl: './carousel-item-type1.component.html',
  styleUrls: [
    './carousel-item-type1.component.scss',
    '../carousel-item-header/carousel-item-header.component.scss'
  ],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class AlexCarouselItemType1Component implements OnInit {
  thubmnailImage: string;
  @Input() item: CarouselItem;
  deviceType: string;
  imageBasePath: string;
  @Input() document: CarouselItem;
  newSpaceName = "";
  disableAddSpace = false;
  showPopUp = false;
  locationQueryDetails: AlexLocationQueryModel;
  @ViewChild(AlexLocationComponent, { static: true }) location;
  @Output() sendItemtoParent: EventEmitter<any> = new EventEmitter();
  constructor(private appService: AlexAppService,
    private urlResolver: AlexUrlResolverService,
    private router: Router,
    private searchItem: SearchItem,
    private spaceService: SpacesService,
    private eref: ElementRef,
    private userService: AlexUserService,
    private docService: AlexDocumentService,
    private notificationService: AlexNotificationService,
    private domainService: DomainService
    // phase 2 tracking code
    , private trackingService: TrackingService,
    private searchParamService: SearchParamService
  ) { }

  ngOnInit() {
    this.deviceType = this.appService.getDeviceType();
    this.getImageBydeviceType();
    this.resolveImagePath();
    // this.getLocationDetails();
  }

  clickLocation() {
    this.showPopUp = !this.showPopUp;
    if (!this.locationQueryDetails) {
      this.locationQueryDetails = this.getLocationDetails();
    }
    this.location.GetDetails(this.locationQueryDetails);

  }


  getLocationDetails(): AlexLocationQueryModel {

    let locationQueryDetails = new AlexLocationQueryModel();
    locationQueryDetails.docId = this.item.docId;
    locationQueryDetails.docName = this.item.docTitle;

    if (this.item.docType.toLowerCase() === "top" || this.item.docType.toLowerCase() === "topic" || this.item.docType.toLowerCase() === "topics") {
      locationQueryDetails.docType = "TOP";
      locationQueryDetails.isDocument = true;
      locationQueryDetails.elementIds = this.item.selectedTaxonomy;
    }
    else if (this.item.docType.toLowerCase() === "content page") {
      locationQueryDetails.docType = "";
      locationQueryDetails.isDocument = false;
      locationQueryDetails.elementIds = [this.item.taxonomyId];
    }
    else {
      locationQueryDetails.docType = "LFC";
      locationQueryDetails.isDocument = true;
      locationQueryDetails.elementIds = this.item.selectedTaxonomy;
    }
    return locationQueryDetails;
  }

  //called on click on outside element
  onClick(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.showPopUp = false;
    }
  }

  sendFlyOutOpenStatus($event) {
    if ($event)
      this.showPopUp = false;
    //this.showPopUp = !$event;
  }

  sendItem($event) {
    // console.log("SENDITEMTOPARENT",$event)
    this.sendItemtoParent.emit($event);
  }

  resolveImagePath(): void {
    this.thubmnailImage = this.urlResolver.resolveFilePath(this.thubmnailImage);
  }

  openDocument(item: CarouselItem) {

    if (item.docType.toLowerCase() === "top" || item.docType.toLowerCase() === "topic" || item.docType.toLowerCase() === "topics") {
      this.router.navigate(['/document/top', item.docId]);
    }

    else if (item.docType.toLowerCase() === "content page") {
      this.router.navigate(["/bridge", item.taxonomyId, item.taxonomyId], { queryParams: { d: this.userService.getActiveDomain().join(',') } });
    }
    else if (item.mimeType == "video" || item.mimeType == "web" ||
      (item.docType && item.docType.trim().toLowerCase() == AlexDocumentType.ExternalUrl.trim().toLowerCase())) {
      if (item.url) {
        // phase 2 tracking code
        this.trackExternalURLClick(item.url, item.killDocId, item.docTitle, PAGE_IDENTIFIERS.homePage);
        window.open(item.url);
      }
      else {
        this.notificationService.show(Status.Failure, "No content found");
      }
    }
    else {
      if (this.domainService.getFileType(item.mimeType) == "file") {
        if (item.sectionId)//sectionId check
        {
          this.docService.hasKillDocID = false;
          this.docService.sectionId = item.sectionId;
          this.router.navigate(['document/lfc/find', item.sectionId]);
        }
        else {
          this.docService.hasKillDocID = true;
          if (this.item.parentId != null && this.item.parentId != "")
            this.router.navigate(['document/lfc', item.parentId, 'toc', item.killDocId], { queryParams: { tocref: item.tocRef } });//changed docId to killDocId
          else
            this.router.navigate(['document/lfc', item.killDocId, 'toc', item.killDocId], { queryParams: { tocref: item.tocRef } });//changed docId to killDocId
        }
      }
      else
        this.router.navigate(['/document/lfc', item.docId, 'toc', item.docId], { queryParams: { tocref: item.tocRef } });
    }
  }




  getImageBydeviceType() {
    if (this.deviceType == "mobile") {
      this.thubmnailImage = this.item.image.mobile;
    } else if (this.deviceType == "tablet") {
      this.thubmnailImage = this.item.image.tablet;
    } else {
      this.thubmnailImage = this.item.image.desktop;
    }
  }

  whenSavedToAlex() { }

  // phase 2 tracking code
  /**
   * Track external url click
   * @param url url to capture
   * @param documentKillDocId KILLDOCID of the url
   * @param documentTitle title of the document of the url
   * @param pageInfo page identifier of the url
   * @param searchKey search term used, if any
   */
  private trackExternalURLClick(url: string, documentKillDocId: string, documentTitle: string, pageInfo: string) {
    const searchKey = this.searchParamService.filterParam.q;
    const externalURLTrackingData = new ExternalUrlClickTrackingData(url, documentKillDocId, documentTitle, pageInfo, searchKey, '', '');
    const externalURLClickTrackingModel = new ExternalUrlClickTrackingModel(externalURLTrackingData, searchKey);
    this.trackingService.track(externalURLClickTrackingModel);
  }
}
