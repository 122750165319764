import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlexUrlResolverService, AlexUserService } from "app/core/services";
import { ICombinedSearchConfig } from "app/feature/search/interfaces";
import {
  asyncScheduler,
  BehaviorSubject,
  forkJoin,
  Observable,
  of,
  scheduled,
} from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  tap,
} from "rxjs/operators";
import {
  IManualsFiltersResponse,
  IManualsFiltersRequest,
  IManualsCountryFilterResponse,
} from "../interfaces";
import { AllSourcesService } from "./all-sources.service";

@Injectable({
  providedIn: "root",
})
export class CombinedSearchFiltersService {
  private config: ICombinedSearchConfig;

  private countries$: BehaviorSubject<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  > = new BehaviorSubject<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  >([]);
  private workflowTypes$: BehaviorSubject<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  > = new BehaviorSubject<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  >([]);
  private versions$: BehaviorSubject<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  > = new BehaviorSubject<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  >([]);
  private sources$: BehaviorSubject<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  > = new BehaviorSubject<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  >([]);
  private selectedSources$: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);
  private isManualsSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  private selectedSources: string[] = [];
  private selectedCountries: string[] = [];
  private selectedWorkflowTypes: string[] = [];
  private selectedVersions: string[] = [];

  private sources: {
    label: string;
    value: string;
    isChecked: boolean;
  }[] = [];
  private countries: {
    label: string;
    value: string;
    isChecked: boolean;
  }[] = [];
  private versions: {
    label: string;
    value: string;
    isChecked: boolean;
  }[] = [];
  private workflowTypes: {
    label: string;
    value: string;
    isChecked: boolean;
  }[] = [];
  private domains: string[] = [];

  private versionsByCountryMap: Map<string, number[]> = new Map<
    string,
    number[]
  >();

  constructor(
    private http: HttpClient,
    private appConfig: AlexUrlResolverService,
    private userService: AlexUserService,
    private allSourcesService: AllSourcesService
  ) {
    this.config = this.appConfig.getCombinedSearchConfig();
  }

  fetchKaegFilters(): Observable<IManualsFiltersResponse> {
    return this.userService.getUserData().pipe(
      map(({ domains }) => ({ domains })),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      tap(({ domains }) => (this.domains = domains)),
      switchMap((domains) =>
        forkJoin([
          this.fetchKaegFiltersByDomain(domains),
          this.allSourcesService.getAllSourcesTaxonomy(),
        ])
      ),
      map(([filters]) => filters),
      tap((filters) => this.populateKaegFilters(filters))
    );
  }

  getDomains(): string[] {
    return this.domains;
  }

  getCountries(): Observable<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  > {
    return this.countries$.asObservable();
  }

  setCountries(
    countries: {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  ): void {
    this.countries = countries;
    this.countries$.next(countries);
  }

  getWorkFlowTypes(): Observable<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  > {
    return this.workflowTypes$.asObservable();
  }

  setWorkflowTypes(
    workflowTypes: {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  ): void {
    this.workflowTypes = workflowTypes;
    this.workflowTypes$.next(workflowTypes);
  }

  getVersions(): Observable<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  > {
    return this.versions$.asObservable();
  }

  setVersions(
    versions: {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  ): void {
    const sortedVersions = versions.sort((v1, v2) => {
      const v1Num = Number(v1?.value);
      const v2Num = Number(v2?.value);
      return v1Num - v2Num;
    });
    this.versions = sortedVersions;
    this.versions$.next(this.versions);
  }

  getSources(): Observable<
    {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  > {
    return this.sources$.asObservable();
  }

  setSources(
    sources: {
      label: string;
      value: string;
      isChecked: boolean;
    }[]
  ): void {
    this.sources = sources;
    this.sources$.next(sources);
  }

  getIsManualsSelected(): Observable<boolean> {
    return this.isManualsSelected$.asObservable();
  }

  setIsManualsSelected(isManualsSelected: boolean): void {
    this.isManualsSelected$.next(isManualsSelected);
  }

  getSelectedSources(): string[] {
    return this.selectedSources;
  }

  getAllSources(): {
    label: string;
    value: string;
    isChecked: boolean;
  }[] {
    return this.sources;
  }

  getAllCountries(): {
    label: string;
    value: string;
    isChecked: boolean;
  }[] {
    return this.countries;
  }

  getAllWorkflowTypes(): {
    label: string;
    value: string;
    isChecked: boolean;
  }[] {
    return this.workflowTypes;
  }

  getAllVersions(): {
    label: string;
    value: string;
    isChecked: boolean;
  }[] {
    return this.versions;
  }

  getSelectedCountries(): string[] {
    return this.selectedCountries;
  }

  setSelectedCountries(countries: string[]): void {
    this.selectedCountries = countries;
  }

  getSelectedWorkflowTypes(): string[] {
    return this.selectedWorkflowTypes;
  }

  setSelectedWorkflowTypes(workflowTypes: string[]): void {
    this.selectedWorkflowTypes = workflowTypes;
  }

  getSelectedVersions(): string[] {
    return this.selectedVersions;
  }

  setSelectedVersions(versions: string[]): void {
    this.selectedVersions = versions;
  }

  getAvailableFilters(): {
    countries: string[];
    workflowTypes: string[];
    versions: string[];
  } {
    const countries = this.countries.map((c) => c.value);
    const versions = this.versions.map((v) => v.label);
    const workflowTypes = this.workflowTypes.map((wft) => wft.label);

    return { countries, versions, workflowTypes };
  }

  handleSourcesSelectionChange(sources: string[]): void {
    if (!Array.isArray(sources)) {
      return;
    }
    this.selectedSources$.next(sources);
    this.selectedSources = sources;

    this.setIsManualsSelected(this.isManualsSelected(sources));

    const selectedSourcesSet = new Set<string>(sources);
    const allSources = this.sources.map((s) => s.value);
    const isAllSelected =
      (sources.length === 1 && sources[0] === "All") ||
      allSources.every((s) => selectedSourcesSet.has(s));

    if (isAllSelected) {
      this.resetManualFiltersSelections();
    }

    const updatedSources = this.sources.map((s) => ({
      ...s,
      isChecked: isAllSelected || selectedSourcesSet.has(s.value),
    }));
    if(this.selectedSources.length>0){
      this.selectedSources.forEach(element => {        
      let objIndex = updatedSources.findIndex((obj => obj.value == element));
      (objIndex>-1)?(updatedSources[objIndex].isChecked=true):'';
      });
    }
    this.setSources(updatedSources);
  }

  handleCountriesSelectionChange(
    countries: string[],
    updateVersion: boolean = false
  ): void {
    if (!Array.isArray(countries)) {
      return;
    }

    const selectedCountriesSet = new Set<string>(countries);

    const isAllSelected =
      (countries.length === 1 && countries[0] === "All") ||
      this.countries.every((c) => selectedCountriesSet.has(c.value));

    this.setSelectedCountries(countries);

    const updatedCountries = this.countries.map((c) => ({
      ...c,
      isChecked: isAllSelected || selectedCountriesSet.has(c.value),
    }));
    this.setCountries(updatedCountries);

    const selectedCountries = isAllSelected
      ? this.countries.map((c) => c.value)
      : countries;

    const versions: {
      label: string;
      value: string;
      isChecked: boolean;
    }[] = this.getVersionsForCountries(selectedCountries);

    this.setVersions(versions);

    if (!updateVersion) {
      return;
    }

    this.handleVersionsSelectionChange(["All"]);
  }

  handleWorkflowTypesSelectionChange(workflowTypes: string[]): void {
    if (!Array.isArray(workflowTypes)) {
      return;
    }

    const selectedWorkflowTypesSet = new Set<string>(workflowTypes);

    const isAllSelected =
      (workflowTypes.length === 1 && workflowTypes[0] === "All") ||
      this.workflowTypes.every((wft) =>
        selectedWorkflowTypesSet.has(wft.value)
      );
    this.setSelectedWorkflowTypes(workflowTypes);

    const updatedWorkflowTypes = this.workflowTypes.map((wft) => ({
      ...wft,
      isChecked: isAllSelected || selectedWorkflowTypesSet.has(wft.value),
    }));
    this.setWorkflowTypes(updatedWorkflowTypes);
  }

  handleVersionsSelectionChange(versions: string[]): void {
    if (!Array.isArray(versions)) {
      return;
    }

    const selectedVersionsSet = new Set<string>(versions);

    const isAllSelected =
      (versions.length === 1 && versions[0] === "All") ||
      this.versions.every((v) => selectedVersionsSet.has(v.value));

    this.setSelectedVersions(versions);

    const updatedVersions = this.versions.map((v) => ({
      ...v,
      isChecked: isAllSelected || selectedVersionsSet.has(v.value),
    }));
    this.setVersions(updatedVersions);
  }

  handleResetClick(): void {
    this.handleSourcesSelectionChange(["All"]);
  }

  getVersionsForCountries(
    selectedCountries: string[]
  ): {
    label: string;
    value: string;
    isChecked: boolean;
  }[] {
    const versions: {
      label: string;
      value: string;
      isChecked: boolean;
    }[] = [];
    const versionsSet: Set<number> = new Set<number>();

    selectedCountries
      .map((c) =>
        this.versionsByCountryMap.has(c) ? this.versionsByCountryMap.get(c) : []
      )
      .forEach((vs) => {
        vs.forEach((v) => {
          if (!versionsSet.has(v)) {
            versions.push({
              label: v.toString(),
              value: v.toString(),
              isChecked: true,
            });
            versionsSet.add(v);
          }
        });
      });

    return versions;
  }

  private populateVersionsByCountryMap(
    countries: IManualsCountryFilterResponse[]
  ): void {
    this.versionsByCountryMap.clear();

    (countries || []).forEach((c) => {
      this.versionsByCountryMap.set(c.countryName, c.versions);
    });
  }

  private isManualsSelected(sources: string[]): boolean {
    if (
      Array.isArray(sources) &&
      sources.length === 1 &&
      sources[0] === "All"
    ) {
      return true;
    }
    const selectedSourcesSet = new Set(sources || []);
    const isManuals = selectedSourcesSet.has("0");
    return isManuals;
  }

  private fetchKaegFiltersByDomain(
    payload: IManualsFiltersRequest
  ): Observable<IManualsFiltersResponse> {
    const { countryWorkflowVersions } = this.config.api.endpoints.post;
    const url = this.appConfig.resolveUrlApiV1(countryWorkflowVersions);

    return this.http.post<IManualsFiltersResponse>(url, payload).pipe(
      catchError((error) => {
        return scheduled(
          of({
            manualsCountryModelList: [],
            manualsWorkFlowTypeModelList: [],
          }),
          asyncScheduler
        );
      }),
      take(1)
    );
  }

  private populateKaegFilters({
    manualsCountryModelList: countries,
    manualsWorkFlowTypeModelList: workflowTypes,
  }: IManualsFiltersResponse) {
    this.populateVersionsByCountryMap(countries);

    const countryDropdownItems = countries.map((c) => ({
      label: c.countryName,
      value: c.countryName,
      isChecked: true,
    }));
    this.setCountries(countryDropdownItems);
    this.setSelectedCountries(["All"]);

    const workflowTypeDropdownItems = workflowTypes.map((wf) => ({
      label: wf.workFlowTypeName,
      value: wf.workFlowTypeName,
      isChecked: true,
    }));
    this.setWorkflowTypes(workflowTypeDropdownItems);
    this.setSelectedWorkflowTypes(["All"]);

    const versions: {
      label: string;
      value: string;
      isChecked: boolean;
    }[] = this.getVersionsForCountries(countries.map((c) => c.countryName));
    this.setVersions(versions);
    this.setSelectedVersions(["All"]);
  }

  private resetManualFiltersSelections(): void {
    this.handleCountriesSelectionChange(["All"], true);
    this.handleWorkflowTypesSelectionChange(["All"]);
  }
}
