import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler } from "@angular/common/http";
import { Observable } from "rxjs";


@Injectable()
export class TokenHttpConfigInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authReqest = request.clone({
            setHeaders: {
                "ext_sessionid": this.getToken()
            }
        });
        return next.handle(authReqest)
    }
    getToken(): string {
        return localStorage.getItem("AlexSessionId" + window.location.host)||"";
    }
}
