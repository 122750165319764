import {
  of as observableOf,
  Observable,
  Subject,
  BehaviorSubject,
  throwError,
} from "rxjs";

import { catchError, filter, first, map } from "rxjs/operators";
import { Injectable, ViewChild, NgZone } from "@angular/core";
import { AlexUrlResolverService, Status } from "app/core/services";
import { TocModel } from "app/feature/long-form-content/toc/toc.model";
import { AlexDocumentModel } from "app/feature/long-form-content/document/document.model";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";
import { SearchItem } from "app/feature/search/model/search-item.model";
import { TocArrayModel } from "app/feature/long-form-content/toc/toc-Array.model";
import { TocItemModel } from "app/feature/long-form-content/toc/toc-item.model";
import { SpacesService } from "app/feature/spaces/spaces.service";
import { OperationModel } from "app/feature/spaces/model/OperationModel";
import { TOCSearchSectionModel } from "app/feature/long-form-content/toc-section-search/toc-search-section.model";
import { AlexContextualBarService } from "app/shared/components/contextual-bar/contextul-bar.service";
import {
  PrintInputEntity,
  PrintOutputEntity,
  PrintModelList,
} from "app/feature/long-form-content/print-selector/print-component.model";
import { AlexAppCacheService } from "app/core/services/cache/cache.service";
import { AlexCacheModel } from "app/core/services/cache/cache.model";
import { AlexLocationQueryModel } from "app/shared/components/location/alex-location-query.model";
import { SearchParamService } from "app/feature/search/services/search-param.service";
import { RecentDocument } from "app/feature/header/recent-search/recent-document.model";
import { AlexTaskTrackerService } from "app/core/services/task-tracker/task-tracker.service";
import {
  CONTEXT_LFC,
  TASK_FETCH_TOC_CONTENT,
  CONTEXT_TOC_SEARCH,
  CONTEXT_TOP,
  TASK_FETCH_LFC_DOCUMENT,
  TASK_FETCH_TOC_SEARCH_RESULT,
} from "app/feature/long-form-content/lfc.constants";
import { DocumentProcessorService } from "app/shared/components/document-viewer/document-processor.service";
import { AlexFileService } from "app/core/services/file/alex-file.service";
import { SpinnerService } from "app/shared/components/spinner/spinner.service";
import { Router } from "@angular/router";
import { ALEX_EDITION_IDENTIFIER } from "app/shared/constants";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AlexNotificationService } from "app/shared/components/notification/notification.service";

// import { browser } from "protractor/built";

@Injectable({ providedIn: "root" })
export class AlexDocumentService {
  private _uniqueUrlKey = 1;
  tocContentSubscription$: any;
  get UniqueUrlKey() {
    this._uniqueUrlKey++;
    return this._uniqueUrlKey % 2;
  }
  documentModel: AlexDocumentModel = new AlexDocumentModel();
  tocArray: Array<TocArrayModel>;
  tocMinSecOrdPos: number = 0;
  tocMaxSecOrdPos: number = 0;
  tocContent: TocModel;
  private tocName$: Subject<string>;
  item: SearchItem = new SearchItem();
  sectionId: string;
  documentCache: Array<AlexDocumentModel>;
  tocContentSubject: BehaviorSubject<TocModel>;
  lfcCurrentDocList: Array<AlexDocumentModel>;
  lfcCurrentDocListSubject: Subject<Array<AlexDocumentModel>>;
  tocCurrentDocument: AlexDocumentModel;
  tocCurrentDocumentSubject: BehaviorSubject<AlexDocumentModel>;
  glossaryCurrentDocument: AlexDocumentModel;
  glossaryCurrentDocumentSubject: Subject<AlexDocumentModel>;
  currentHighlightToc: TocArrayModel;
  currentHighlightTocSubject: Subject<TocArrayModel>;
  printOutputEntity: string;
  printOutputSubject: BehaviorSubject<string>;
  printOutputErrorMsg: Subject<string>;
  printErrorMsg: string;
  spandoc: string = "";
  printDocIdsArray = new Array<PrintInputEntity>();
  parentDocument: AlexDocumentModel;
  parentDocumentSubject: Subject<AlexDocumentModel>;
  searchTerm = "";
  ignoreFragmentChange = false;
  parentId: string;
  tocref: string;
  IsParentChanged = false;
  hitfoundtocTree: Subject<boolean>;
  hitFoundtocSection: Subject<boolean>;
  prevScrollPosition: number;
  eventProducer: Event;
  isScrollUp: boolean = false;
  hasKillDocID: boolean;
  checkIfSecIdPresent: boolean = false;
  recentDoc: RecentDocument;
  private currentContext: string;
  binariesPath: string;
  archiveBinariesPath: string;
  helpContent$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  //tocRef:string;
  // wordPrev:string="";
  // wordNext:string="";
  constructor(
    private http: HttpClient,
    private urlResolver: AlexUrlResolverService,
    private sanitizer: DomSanitizer,
    private spaceService: SpacesService,
    private searchParam: SearchParamService,
    private contextualBarService: AlexContextualBarService,
    private zone: NgZone,
    private appCacheSerivce: AlexAppCacheService,
    private documentProcessorService: DocumentProcessorService,
    private taskTracker: AlexTaskTrackerService,
    private fileService: AlexFileService,
    private spinnerService: SpinnerService,
    private notificationService: AlexNotificationService,
    private router: Router
  ) {
    this.documentCache = new Array<AlexDocumentModel>();
    this.tocArray = new Array<TocArrayModel>();
    this.lfcCurrentDocList = new Array<AlexDocumentModel>();
    this.lfcCurrentDocListSubject = new Subject<AlexDocumentModel[]>();
    this.tocCurrentDocumentSubject = new BehaviorSubject<AlexDocumentModel>(
      null
    );
    this.currentHighlightTocSubject = new Subject<TocArrayModel>();
    this.tocContentSubject = new BehaviorSubject<TocModel>(null);
    this.printOutputSubject = new BehaviorSubject<string>("");
    this.printOutputErrorMsg = new Subject<string>();
    this.hitFoundtocSection = new Subject<boolean>();
    this.hitfoundtocTree = new Subject<boolean>();
    this.glossaryCurrentDocumentSubject = new Subject<AlexDocumentModel>();
    this.binariesPath = this.urlResolver.getBinariesPath();
    this.archiveBinariesPath = this.urlResolver.getArchiveBinariesPath();
  }

  /**
   * Format content according to edition id present or not
   * @param contentRef contentRef to replace
   */
  replaceBinaryPathInContent(contentRef: string): string {
    if (!contentRef) {
      return contentRef;
    }
    const editionId = sessionStorage.getItem(ALEX_EDITION_IDENTIFIER);
    if (!editionId) {
      return contentRef;
    } else {
      const nonArchiveBinariesRegEx = new RegExp(this.binariesPath, "gi");
      const archiveBinariesPath = `${this.archiveBinariesPath}/${editionId}`;
      const newContentRef = contentRef.replace(
        nonArchiveBinariesRegEx,
        archiveBinariesPath
      );
      return newContentRef;
    }
  }
  // EXCEL MACRO ISSUE FIX START
  fetchMemStream(document) {
    return this.http.get(
      this.urlResolver.resolveUrlApiV1(
        "/search/GetFileStream?binaryPath=" + document.binaryPath
      )
    );
  }

  // EXCEL MACRO ISSUE FIX END
  fetchDocument(referenceId: string): Observable<AlexDocumentModel[]> {
    const cacheItem = this.documentCache.find(
      (doc) => doc.referenceId == referenceId
    );
    if (cacheItem) {
      return observableOf(this.documentCache);
    }
    return this.http
      .post(this.urlResolver.resolveUrlApiV1("/search/searchLfcData"), {
        referenceId: referenceId,
        searchTerm: this.searchParam.searchTerm,
      })
      .pipe(
        map(
          (response) => {
            const docModel = response as AlexDocumentModel;
            //this.documentModel = docModel;
            const contentRef = this.replaceBinaryPathInContent(
              (<any>docModel).contentRef
            );
            docModel.contentRef = contentRef;
            docModel.htmlContentRef = this.sanitizer.bypassSecurityTrustHtml(
              docModel.contentRef
            );
            docModel.tocRef = <any>docModel.tocRef;
            //this.documentCache.splice(0,0,docModel);
            this.documentCache.push(docModel);
            return this.documentCache;
          }
        ),
        catchError((err) => {
          if (err.status == 404) {
            setTimeout(() => {
              this.helpContent$.next("");
              localStorage.setItem('referenceID',(new Date().getTime()).toString());
              this.router.navigateByUrl('home');
            }, 5);
          }
          this.taskTracker.markTaskAsComplete(
            this.currentContext,
            TASK_FETCH_LFC_DOCUMENT
          );
          return throwError(err);
        })
      );
  }

  fetchSingleDocument(referenceId: string): Observable<AlexDocumentModel> {
    const cacheItem = this.documentCache.find(
      (doc) => doc.referenceId == referenceId
    );
    if (cacheItem) {
      return observableOf(cacheItem);
    }
    return this.http
      .post(this.urlResolver.resolveUrlApiV1("/search/searchLfcData"), {
        referenceId: referenceId,
        searchTerm: this.searchParam.searchTerm,
      })
      .pipe(
        map(
          (response) => {
          if ((<any>response).error) {
            return;
          }
          const docModel = response as AlexDocumentModel;
          //this.documentModel = docModel;
          const contentRef = this.replaceBinaryPathInContent(
            (<any>docModel).contentRef
          );
          docModel.contentRef = contentRef;
          docModel.htmlContentRef = this.sanitizer.bypassSecurityTrustHtml(
            docModel.contentRef
          );
          docModel.tocRef = <any>docModel.tocRef;
          this.documentCache.push(docModel);
          return docModel;
        }),
        catchError((err) => {
          if (err.status == 404) {
            setTimeout(() => {
              this.helpContent$.next("");
              localStorage.setItem('referenceID',(new Date().getTime()).toString());
              this.router.navigateByUrl('home');
            }, 5);
          }
          return throwError(err);
        })
      );
  }

  fetchSingleDocumentForSearch(
    referenceId: string
  ): Observable<AlexDocumentModel> {
    const cacheItem = this.documentCache.find(
      (doc) => doc.referenceId == referenceId
    );
    if (cacheItem) {
      // return Observable.of(Object.assign({},cacheItem));
      return observableOf(cacheItem);
    }
    return this.http
      .post(this.urlResolver.resolveUrlApiV1("/search/searchLfcData"), {
        referenceId: referenceId,
        searchTerm: this.searchParam.searchTerm,
      })
      .pipe(
        map((response) => {
          if ((<any>response).error) {
            return;
          }
          const docModel = response as AlexDocumentModel;
          //this.documentModel = docModel;
          const contentRef = this.replaceBinaryPathInContent(
            (<any>docModel).contentRef
          );
          docModel.contentRef = contentRef;
          docModel.htmlContentRef = this.sanitizer.bypassSecurityTrustHtml(
            docModel.contentRef
          );
          docModel.tocRef = <any>docModel.tocRef;
          return docModel;
        }),
        catchError((err) => {
          if (err.status == 404) {
            setTimeout(() => {
              this.helpContent$.next("");
              localStorage.setItem('referenceID',(new Date().getTime()).toString());
              this.router.navigateByUrl('home');
            }, 5);
          }
          return throwError(err);
        })
      );
  }

  fetchGlossaryDocument(referenceId: string): Observable<AlexDocumentModel> {
    const cacheItem = this.documentCache.find(
      (doc) => doc.referenceId == referenceId
    );
    if (cacheItem) {
      return observableOf(cacheItem);
    }
    return this.http
      .post(this.urlResolver.resolveUrlApiV1("/search/SearchLFCGlossaryData"), {
        referenceId: referenceId,
        searchTerm: this.searchParam.searchTerm,
      })
      .pipe(
        map((response) => {
          if ((<any>response).error) {
            return;
          }
          const docModel = response as AlexDocumentModel;
          //this.documentModel = docModel;
          const contentRef = this.replaceBinaryPathInContent(
            (<any>docModel).contentRef
          );
          docModel.contentRef = contentRef;
          docModel.htmlContentRef = this.sanitizer.bypassSecurityTrustHtml(
            docModel.contentRef
          );
          this.documentCache.push(docModel);
          return docModel;
        })
      );
  }

  fetchParentDocument(referenceId: string): Observable<AlexDocumentModel> {
    const cacheItem = this.documentCache.find(
      (doc) => doc.referenceId == referenceId
    );
    if (cacheItem) {
      return observableOf(cacheItem);
    }
    return this.http
      .post(this.urlResolver.resolveUrlApiV1("/search/SearchLFCParentData"), {
        referenceId: referenceId,
        searchTerm: this.searchParam.searchTerm,
      })
      .pipe(
        map((response) => {
          if ((<any>response).error) {
            return;
          }
          const docModel = response as AlexDocumentModel;
          //this.documentModel = docModel;
          const contentRef = this.replaceBinaryPathInContent(
            (<any>docModel).contentRef
          );
          docModel.contentRef = contentRef;
          docModel.htmlContentRef = this.sanitizer.bypassSecurityTrustHtml(
            docModel.contentRef
          );
          this.documentCache.push(docModel);
          return docModel;
        })
      );
  }

  fetchSingleDocumentWithoutCache(
    referenceId: string
  ): Observable<AlexDocumentModel> {
    return this.http
      .post(this.urlResolver.resolveUrlApiV1("/search/searchLfcData"), {
        referenceId: referenceId,
        searchTerm: this.searchParam.searchTerm,
      })
      .pipe(
        map((response) => {
          if ((<any>response).error) {
            return;
          }
          const docModel = response as AlexDocumentModel;
          const contentRef = this.replaceBinaryPathInContent(
            (<any>docModel).contentRef
          );
          docModel.contentRef = contentRef;
          docModel.htmlContentRef = this.sanitizer.bypassSecurityTrustHtml(
            docModel.contentRef
          );
          docModel.tocRef = <any>docModel.tocRef;
          return docModel;
        }),
        catchError((err) => {
          if (err.status == 404) {
            setTimeout(() => {
              this.helpContent$.next("");
              localStorage.setItem('referenceID',(new Date().getTime()).toString());
              this.router.navigateByUrl('home');
            }, 5);
          }
          return throwError(err);
        })
      );
  }
  fetchSingleDocumentWithSecOrPara(id: string): Observable<AlexDocumentModel> {
    return this.http
      .post(
        this.urlResolver.resolveUrlApiV1(
          "/search/searchLfcDataWithSectionOrParaId"
        ),
        {
          referenceId: id,
          searchTerm: this.searchParam.searchTerm,
        }
      )
      .pipe(
        map((response) => {
          if ((<any>response).error) {
            return;
          }
          const docModel = response as AlexDocumentModel;
          const contentRef = this.replaceBinaryPathInContent(
            (<any>docModel).contentRef
          );
          docModel.contentRef = contentRef;
          docModel.htmlContentRef = this.sanitizer.bypassSecurityTrustHtml(
            docModel.contentRef
          );
          docModel.tocRef = <any>docModel.tocRef;
          this.documentCache.push(docModel);
          return docModel;
        }),
        catchError((err) => {
          if (err.status == 404) {
            setTimeout(() => {
              this.helpContent$.next("");
              localStorage.setItem('referenceID',(new Date().getTime()).toString());
              this.router.navigateByUrl('home');
            }, 5);
          }
          return throwError(err);
        })
      );
  }

  getApiSingleDocument(referenceId: string): Observable<AlexDocumentModel> {
    return this.http
      .post(this.urlResolver.resolveUrlApiV1("/search/searchLfcData"), {
        referenceId: referenceId,
        searchTerm: this.searchParam.searchTerm,
      })
      .pipe(
        map(
          (response) => {
            if ((<any>response).error) {
              return;
            }
            const docModel = response as AlexDocumentModel;
            //this.documentModel = docModel;
            const contentRef = this.replaceBinaryPathInContent(
              (<any>docModel).contentRef
            );
            docModel.contentRef = contentRef;
            docModel.htmlContentRef = this.sanitizer.bypassSecurityTrustHtml(
              docModel.contentRef
            );
            docModel.tocRef = <any>docModel.tocRef;
            this.documentCache.push(docModel);
            return docModel;
          }
        ),
        catchError((err) => {
          if (err.status == 404) {
            setTimeout(() => {
              this.helpContent$.next("");
              localStorage.setItem('referenceID',(new Date().getTime()).toString());
              this.router.navigateByUrl('home');
            }, 5);
          }
          this.taskTracker.markTaskAsComplete(
            this.currentContext,
            TASK_FETCH_LFC_DOCUMENT
          );
          return throwError(err);
        })
      );
  }
  notify() {
    this.lfcCurrentDocListSubject.next(this.lfcCurrentDocList);
    this.tocCurrentDocumentSubject.next(this.tocCurrentDocument);
    this.currentHighlightTocSubject.next(this.currentHighlightToc);
    if (this.tocCurrentDocument) {
      // this.contextualBarService.updateBreadCrumbText(this.tocCurrentDocument.documentName);
      //this.contextualBarService.updateTooltip(this.tocCurrentDocument.documentName);
      if (this.tocContent && this.tocContent.title) {
        // this.getParentDocumentDetails(this.tocCurrentDocument.parentId ?
        //     this.tocCurrentDocument.parentId : this.tocCurrentDocument.referenceId);
        this.contextualBarService.updateLocationQueryDetails(
          this.getLFCLocationDetails(this.tocCurrentDocument)
        );
        this.contextualBarService.updateBreadCrumbText(
          this.tocCurrentDocument.parentTitle
        );
        this.contextualBarService.updateTooltip(
          this.tocCurrentDocument.parentTitle
        );
      } else {
        this.contextualBarService.updateLocationQueryDetails(
          this.getLFCLocationDetails(this.tocCurrentDocument)
        );
        this.contextualBarService.updateBreadCrumbText(
          this.tocCurrentDocument.documentName
        );
        this.contextualBarService.updateTooltip(
          this.tocCurrentDocument.documentName
        );
      }
      // this.getParentDocumentDetails(this.tocCurrentDocument.referenceId);
    }
  }
  getDocumentWithSecOrPara(secOrParaId: string) {
    this.fetchSingleDocumentWithSecOrPara(secOrParaId).subscribe(
      (doc) => {
        this.lfcCurrentDocList = new Array<AlexDocumentModel>();
        this.lfcCurrentDocList.push(doc);
        this.tocCurrentDocument = doc;
        this.documentModel = doc;
        this.parentId = doc.referenceId;
        this.tocref = doc.tocRef;
        this.IsParentChanged = true;
        this.sectionId = secOrParaId;
        this.tocContentSubject
          .pipe(
            filter((val) => Boolean(val)),
            first()
          )
          .subscribe((toc) => {
            if (this.tocContent && this.tocContent.title) {
              this.currentHighlightToc = this.findHighlightPath(
                doc.referenceId
              );
              this.currentHighlightTocSubject.next(this.currentHighlightToc);
              if (doc != null && doc.referenceId != null) {
                // this.getParentDocumentDetails(doc.parentId ? doc.parentId : doc.referenceId);
                this.contextualBarService.updateLocationQueryDetails(
                  this.getLFCLocationDetails(doc)
                );
                this.contextualBarService.updateBreadCrumbText(doc.parentTitle);
                this.contextualBarService.updateTooltip(doc.parentTitle);
              }
            } else {
              this.currentHighlightToc = null;
              if (doc != null && doc.referenceId != null) {
                this.contextualBarService.updateLocationQueryDetails(
                  this.getLFCLocationDetails(doc)
                );
                this.contextualBarService.updateBreadCrumbText(
                  doc.documentName
                );
                this.contextualBarService.updateTooltip(doc.documentName);
              }
              //this.getParentDocumentDetails(doc.referenceId);
            }
          });
        // });
        this.saveOpenSection(doc.documentName, this.sectionId).subscribe();
        this.fetchTOC(doc.tocRef);
        // if (doc.parentId)
        //     this.fetchTOC( doc.tocRef);
        // else
        //     this.fetchTOC( doc.tocRef);
        this.notify();
        const spinnerId = this.spinnerService.showSpinner();
        setTimeout(() => {
          // const collapsedParagraphEl = $("#" + secOrParaId).closest(
          //   ".ALexDisplayXSLParagraphStatusCollapsed"
          // );
          // collapsedParagraphEl.css({
          //   display: "block",
          //   visibility: "visible",
          // });
          // const expandCollapseIconImgEl = collapsedParagraphEl
          //   .closest(".ALexDisplayXSLKPMGExpandCollapse")
          //   .find("ALexDisplayXSLKPMGExpandCollapseMarker img");
          // if (expandCollapseIconImgEl) {
          //   expandCollapseIconImgEl.attr(
          //     "src",
          //     this.urlResolver.resolveFilePath(
          //       "/ALexDisplayXSLKPMGcollapse.gif"
          //     )
          //   );
          // }

          //todo
          const sectionEl = document.querySelector("#" + secOrParaId);
          if (sectionEl) {
            const closestParaEl = <HTMLElement>(
              sectionEl.closest(".ALexDisplayXSLParagraphStatusCollapsed")
            );
            if (closestParaEl) {
              closestParaEl.style.display = "block";
              closestParaEl.style.visibility = "visible";
            }
            const closestExpandIconContainerEl = <HTMLElement>(
              sectionEl.closest(".ALexDisplayXSLKPMGExpandCollapse")
            );
            if (
              closestExpandIconContainerEl &&
              closestExpandIconContainerEl.querySelector("img")
            ) {
              const expandIconEl = closestExpandIconContainerEl.querySelector(
                "img"
              );

              expandIconEl.setAttribute(
                "src",
                this.urlResolver.resolveFilePath(
                  "/ALexDisplayXSLKPMGcollapse.gif"
                )
              );
            }
          }
          window.location.hash = secOrParaId;
          this.spinnerService.hideSpinner(spinnerId);
        }, this.urlResolver.lfcFocusTimeout || 0);

        //     if (doc != null && doc.referenceId != null)
        //         this.getParentDocumentDetails(doc.parentId ? doc.parentId : doc.referenceId);
      },
      (error) => {
        this.taskTracker.markTaskAsComplete(
          this.currentContext,
          TASK_FETCH_LFC_DOCUMENT
        );
      }
    );
  }
  saveOpenSection(title: string, sectionId: string) {
    this.recentDoc = new RecentDocument();
    this.recentDoc.documentName = title;
    this.recentDoc.reference = "document/lfc/find/" + sectionId;
    return this.http.post(
      this.urlResolver.resolveUrlApiV1(`user/AddRecentDocument`),
      this.recentDoc
    );
  }
  getTocDocument(docId: string) {
    this.fetchSingleDocument(docId).subscribe(
      (doc) => {
        this.lfcCurrentDocList = new Array<AlexDocumentModel>();
        this.lfcCurrentDocList.push(doc);
        this.tocCurrentDocument = doc;
        this.documentModel = doc;
        //console.log('fetchDoc' + doc.tocRef);
        // if (doc.tocRef && this.tocref != doc.tocRef) {
        if (this.tocref != doc.tocRef) {
          this.IsParentChanged = true;

          this.tocContentSubscription$ = this.tocContentSubject.subscribe(
            (toc) => {
              if (this.tocContent && this.tocContent.title) {
                this.currentHighlightToc = this.findHighlightPath(
                  doc.referenceId
                );
                if (doc != null && doc.referenceId != null) {
                  //this.getParentDocumentDetails(doc.parentId ? doc.parentId : doc.referenceId);
                  this.contextualBarService.updateLocationQueryDetails(
                    this.getLFCLocationDetails(doc)
                  );
                  this.contextualBarService.updateBreadCrumbText(
                    doc.parentTitle
                  );
                  this.contextualBarService.updateTooltip(doc.parentTitle);
                }
              } else {
                this.currentHighlightToc = null;
                if (doc != null && doc.referenceId != null) {
                  this.contextualBarService.updateLocationQueryDetails(
                    this.getLFCLocationDetails(doc)
                  );
                  this.contextualBarService.updateBreadCrumbText(
                    doc.documentName
                  );
                  this.contextualBarService.updateTooltip(doc.documentName);
                }
                //this.getParentDocumentDetails(doc.referenceId);
              }
              this.tocContentSubscription$ &&
                this.tocContentSubscription$.unsubscribe();
              this.notify();
            }
          );
          if (doc.tocRef) this.fetchTOC(doc.tocRef);
          else this.fetchTOC("");
        } else {
          if (this.tocContent && this.tocContent.title) {
            this.currentHighlightToc = this.findHighlightPath(doc.referenceId);
            if (doc != null && doc.referenceId != null) {
              //this.getParentDocumentDetails(doc.parentId ? doc.parentId : doc.referenceId);
              this.contextualBarService.updateLocationQueryDetails(
                this.getLFCLocationDetails(doc)
              );
              this.contextualBarService.updateBreadCrumbText(doc.parentTitle);
              this.contextualBarService.updateTooltip(doc.parentTitle);
            }
          } else {
            this.currentHighlightToc = null;
            if (doc != null && doc.referenceId != null) {
              this.contextualBarService.updateLocationQueryDetails(
                this.getLFCLocationDetails(doc)
              );
              this.contextualBarService.updateBreadCrumbText(doc.documentName);
              this.contextualBarService.updateTooltip(doc.documentName);
            }
            //this.getParentDocumentDetails(doc.referenceId);
          }
          this.notify();
        }
        // if (doc != null && doc.referenceId != null)
        //     this.getParentDocumentDetails(doc.parentId ? doc.parentId : doc.referenceId);
      },
      (error) => {
        this.taskTracker.markTaskAsComplete(
          this.currentContext,
          TASK_FETCH_LFC_DOCUMENT
        );
      }
    );
  }
  getGlossaryDocument(docId: string) {
    this.fetchGlossaryDocument(docId).subscribe((doc) => {
      this.glossaryCurrentDocument = doc;
      this.glossaryCurrentDocumentSubject.next(doc);
    });
  }

  refreshTocDocument(docId: string) {
    this.getApiSingleDocument(docId).subscribe((doc) => {
      this.lfcCurrentDocList = new Array<AlexDocumentModel>();
      this.lfcCurrentDocList.push(doc);
      this.tocCurrentDocument = doc;
      this.documentModel = doc;
      this.lfcCurrentDocListSubject.next(this.lfcCurrentDocList);
      this.notify();
      setTimeout(function () {
        var myDiv = document.getElementById("lfcParentDiv");
        myDiv.scrollTop = 0;
      }, 0);
    });
  }
  getParentDocumentDetails(parentId: string) {
    if (this.parentDocument == null) {
      this.fetchParentDocument(parentId).subscribe(
        (doc) => {
          if (doc.documentName) {
            this.parentDocument = doc;
            this.contextualBarService.updateLocationQueryDetails(
              this.getLFCLocationDetails(doc)
            );
            this.contextualBarService.updateBreadCrumbText(
              this.parentDocument.documentName
            );
            this.contextualBarService.updateTooltip(
              this.parentDocument.documentName
            );
          } else {
            if (this.tocCurrentDocument) {
              this.contextualBarService.updateLocationQueryDetails(
                this.getLFCLocationDetails(this.tocCurrentDocument)
              );
              this.contextualBarService.updateBreadCrumbText(
                this.tocCurrentDocument.documentName
              );
              this.contextualBarService.updateTooltip(
                this.tocCurrentDocument.documentName
              );
            }
          }
        },
        (error) => {
          if (this.tocCurrentDocument) {
            this.contextualBarService.updateLocationQueryDetails(
              this.getLFCLocationDetails(this.tocCurrentDocument)
            );
            this.contextualBarService.updateBreadCrumbText(
              this.tocCurrentDocument.documentName
            );
            this.contextualBarService.updateTooltip(
              this.tocCurrentDocument.documentName
            );
            return;
          }
        }
      );
    }
  }

  getLFCLocationDetails(doc: AlexDocumentModel): AlexLocationQueryModel {
    let locationQueryDetails = new AlexLocationQueryModel();
    locationQueryDetails.docId = doc.referenceId ? doc.referenceId : "";
    locationQueryDetails.docName = doc.documentName;

    locationQueryDetails.docType = "LFC";
    locationQueryDetails.isDocument = true;
    locationQueryDetails.elementIds = doc.sourceTaxonomyIds;

    return locationQueryDetails;
  }

  // content="For example, we organization may inspect IT security administration records to obtain evidence that unauthorized access has not organization during the period";
  getTocSearchSectionDocumentHighlighted(
    docId: string,
    tocsectionArrray: Array<TOCSearchSectionModel>,
    fragment = "",
    nextIndex: number = 0
  ) {
    this.fetchSingleDocument(docId).subscribe((doc) => {
      this.lfcCurrentDocList = new Array<AlexDocumentModel>();
      var section = tocsectionArrray.find((doc) => doc.docId == docId);
      //var content="For example, we organization may inspect IT security administration records to obtain evidence that unauthorized access has not organization during the period"

      var i = 0;

      this.spandoc = doc.contentRef;
      section.searchExcerptModels.forEach((element) => {
        var updatedDoc = this.spandoc.substring(nextIndex, this.spandoc.length);
        var startindex = String(element.text).indexOf(
          "<span class='tocsearchhit'>"
        );
        var lenthspanstart = "<span class='tocsearchhit'>".length;
        var endindex = String(element.text).indexOf("</span>");
        var word =
          String(element.text).substring(
            startindex + lenthspanstart,
            endindex
          ) + " ";
        if (updatedDoc.includes(word)) {
          var spanword =
            "<span" +
            " id=" +
            i++ +
            " class='tocsearchhit'>" +
            word +
            "</span>" +
            " ";
          var wordIndexInDoc = String(updatedDoc).indexOf(word) + nextIndex;
          nextIndex = wordIndexInDoc + spanword.length;
          this.spandoc =
            this.spandoc.substring(0, wordIndexInDoc) +
            spanword +
            this.spandoc.substring(wordIndexInDoc + word.length);
          // console.log(this.spandoc);
        }
      });

      doc.htmlContentRef = this.sanitizer.bypassSecurityTrustHtml(this.spandoc);
      this.lfcCurrentDocList.push(doc);
      this.tocCurrentDocument = doc;
    });
  }
  getSectionDocument(docId: string) {
    this.fetchSingleDocumentWithoutCache(docId).subscribe((doc) => {
      this.lfcCurrentDocList = new Array<AlexDocumentModel>();
      this.lfcCurrentDocList.push(doc);
      this.tocCurrentDocument = doc;
      this.notify();
    });
  }
  searchFoundSection(
    docId: string,
    tocsectionArrray: Array<TOCSearchSectionModel>,
    componetName: string
  ) {
    //TODO: Duplicated method
    this.fetchSingleDocument(docId).subscribe((doc) => {
      let hitFoundStatus = false;
      this.lfcCurrentDocList = new Array<AlexDocumentModel>();
      var section = tocsectionArrray.find((doc) => doc.docId == docId);
      var i = 0;
      this.spandoc = doc.contentRef;
      var nextIndex = 0;
      let countExcerp = -1;
      var updatedDoc = this.spandoc.substring(nextIndex, this.spandoc.length);
      var nextElementIndex = 0;
      var tempdoc = "";

      if (section) {
        section.searchExcerptModels.forEach((element) => {
          countExcerp = countExcerp + 1;

          //**start* Get the count of words in seection excerp** */
          var count = 0;
          let arrayOfWords = new Array<string>();
          var index = String(element.text).indexOf(
            "<span class='tocsearchhit'>"
          );
          while (index >= 0) {
            index = String(element.text).indexOf(
              "<span class='tocsearchhit'>",
              index + 1
            );
            count++;
          }
          //**end* Get the count of words in seection excerp** */
          //* start*get all words in excerp of section */
          var nextElementIndex = 0;
          var updatedElement = String(element.text).substring(
            nextElementIndex,
            String(element.text).length
          );
          for (var x = 0; x < count; x++) {
            updatedElement = updatedElement.substring(
              nextElementIndex,
              updatedElement.length
            );
            var startindex = updatedElement.indexOf(
              "<span class='tocsearchhit'>"
            );
            var lenthspanstart = "<span class='tocsearchhit'>".length;
            var endindex = updatedElement.indexOf("</span>");
            var word = "";
            if (endindex > 0)
              word = updatedElement.substring(
                startindex + lenthspanstart,
                endindex
              );
            else {
              word = updatedElement.substring(startindex + lenthspanstart);
            }
            arrayOfWords.push(word + "");
            nextElementIndex =
              startindex + lenthspanstart + word.length + "</span>".length;
          }
          //* end*get all words in excerp of section */
          //Start**Adding highlight class by finding the excerpt word and adding to tempdoc */
          if (arrayOfWords.length > 0) {
            let docindex = 0;
            for (var y = 0; y < arrayOfWords.length; y++) {
              updatedDoc = updatedDoc.substring(nextIndex);
              var spanword =
                "<span" +
                " id=" +
                String(countExcerp) +
                " class='tocsearchhit'>" +
                arrayOfWords[y] +
                "</span>";
              var arrword = arrayOfWords[y]; //, '');
              //removing end  special charecter
              arrword = <any>this.removeLastSpecialCharecter(arrword);
              var wordIndexInDoc = <any>(
                this.findIndexWithoutSpecialchar(updatedDoc, arrword)
              );
              if (wordIndexInDoc >= 0) {
                hitFoundStatus = true;

                nextIndex = wordIndexInDoc + arrayOfWords[y].length;
                docindex = wordIndexInDoc + spanword.length;
                var temp = updatedDoc.substring(0, wordIndexInDoc);
                tempdoc = tempdoc + temp + spanword;
                //console.log(tempdoc);
              }
            }
          }
          //Start**Adding highlight class by finding the excerpt word */
        });
      }
      if (hitFoundStatus) {
        if (componetName == "AlexTocTreeMenuComponent") {
          this.hitfoundtocTree.next(true);
        } else if (componetName == "TocSectionSearchComponent") {
          this.hitFoundtocSection.next(true);
        }
      } else {
        if (componetName == "AlexTocTreeMenuComponent") {
          this.hitfoundtocTree.next(false);
        } else if (componetName == "TocSectionSearchComponent") {
          this.hitFoundtocSection.next(false);
        }
      }
    });
  }

  findIndexWithoutSpecialchar(str, match) {
    match = this.escape(match);
    const regexWB = new RegExp(`\\b${match}\\b`);
    let match1 = regexWB.exec(str);
    return match1 ? match1.index : -1;
  }
  escape = function (str) {
    return (str + "").replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&");
  };

  findIndex(str, match) {
    const regexWB = new RegExp(`\\b${match}\\s*\\b`);
    // const regexNWB = new RegExp(`\\b${match}\\s*\\B`);
    const regexNWB = new RegExp(`\\b${match}\\s\\B`);
    let matchIndex = -1;
    let match1 = regexWB.exec(str);
    let match2 = regexNWB.exec(str);
    if (match1 && match1.index >= 0) {
      matchIndex = match1.index;
    }
    if (match2 && match2.index >= 0 && match2.index < matchIndex) {
      matchIndex = match2.index;
    }

    return matchIndex;
  }
  removeLastSpecialCharecter(txt) {
    var result = txt;
    var l = txt.length; // length of the original string
    var lastChar = txt.substring(l - 1, l); // get the last char of the original string

    const d = lastChar.search(/[^\w\s]/gi);
    if (d >= 0) {
      result = txt.substring(0, l - 1);
    } else {
      // otherwise do nothing
      result = txt;
    }
    return result;
  }

  getNextSecOrdPos(secordpos: string): string {
    if (this.tocMaxSecOrdPos > 0 && +secordpos < this.tocMaxSecOrdPos) {
      const docData = this.tocArray.find(
        (l) => l.secOrdPos == secordpos && l.type.toLowerCase() != "part"
      );
      let index: number = this.tocArray.indexOf(docData);
      let i = index + 1;
      let nxtSecordPos;
      while (i <= this.tocArray.length) {
        if (this.tocArray[i].type.toLowerCase() != "part") {
          nxtSecordPos = this.tocArray[i].secOrdPos;
          break;
        } else i = i + 1;
      }
      return nxtSecordPos;
    }
  }

  getPrevSecOrdPos(secordpos: string): string {
    if (this.tocMinSecOrdPos > 0 && +secordpos > this.tocMinSecOrdPos) {
      const docData = this.tocArray.find(
        (l) => l.secOrdPos == secordpos && l.type.toLowerCase() != "part"
      );
      let index: number = this.tocArray.indexOf(docData);
      let i = index - 1;
      let prevSecordPos;
      while (i >= 0) {
        if (this.tocArray[i].type.toLowerCase() != "part") {
          prevSecordPos = this.tocArray[i].secOrdPos;
          break;
        } else i = i - 1;
      }
      return prevSecordPos;
    }
  }

  getDocOnScrollDown(secOrdPos: number, maxSecOrdPos: number) {
    while (maxSecOrdPos > 0 && secOrdPos < maxSecOrdPos) {
      let nxtSecordPos = this.getNextSecOrdPos(secOrdPos.toString());
      const document = this.tocArray.find(
        (l) =>
          l.secOrdPos == nxtSecordPos.toString() &&
          l.type.toLowerCase() != "part"
      );
      if (document) {
        this.spinnerService.noSpinner(true);
        const nextDocSubscription = this.fetchSingleDocument(
          document.referenceId
        ).subscribe((doc) => {
          this.spinnerService.noSpinner(false);
          if (
            !this.lfcCurrentDocList.find(
              (a) => a.referenceId == doc.referenceId
            )
          ) {
            this.lfcCurrentDocList.push(doc);
          }
          this.notify();
          nextDocSubscription && nextDocSubscription.unsubscribe();
        });
        break;
      }
    }
  }

  getDocOnScrollUp(secOrdPos: number, minSecOrdPos: number) {
    while (minSecOrdPos > 0 && secOrdPos > minSecOrdPos) {
      let prevSecordPos = this.getPrevSecOrdPos(secOrdPos.toString());
      const document = this.tocArray.find(
        (l) =>
          l.secOrdPos == prevSecordPos.toString() &&
          l.type.toLowerCase() != "part"
      );
      if (document) {
        this.spinnerService.noSpinner(true);
        const prevtDocSubscription = this.fetchSingleDocument(
          document.referenceId
        ).subscribe((doc) => {
          this.spinnerService.noSpinner(false);
          this.isScrollUp = true;
          if (
            !this.lfcCurrentDocList.find(
              (a) => a.referenceId == doc.referenceId
            )
          ) {
            let documents = this.lfcCurrentDocList;
            this.lfcCurrentDocList = new Array<AlexDocumentModel>();
            this.lfcCurrentDocList.push(doc);
            this.lfcCurrentDocList.splice(1, 0, ...documents);
          }
          this.notify();
          prevtDocSubscription && prevtDocSubscription.unsubscribe();
        });
        break;
      }
    }
  }

  fetchTOC(tocRef: string) {
    if (this.tocContent && !this.IsParentChanged) {
      this.tocContentSubject.next(this.tocContent);
      this.taskTracker.markTaskAsComplete(CONTEXT_LFC, TASK_FETCH_TOC_CONTENT);
      return;
    }

    this.http
      .post(this.urlResolver.resolveUrlApiV1("/search/TOCDocumentData"), {
        tocRef: tocRef,
        searchTerm: this.searchParam.searchTerm,
      })
      .subscribe(
        (response) => {
          const tocModel = response as TocModel;
          this.tocContent = tocModel;
          this.getTocArray();
          this.tocContentSubject.next(this.tocContent);
          this.IsParentChanged = false;
          this.taskTracker.markTaskAsComplete(
            CONTEXT_LFC,
            TASK_FETCH_TOC_CONTENT
          );
        },
        (error) => {
          this.taskTracker.markTaskAsComplete(
            CONTEXT_LFC,
            TASK_FETCH_TOC_CONTENT
          );
        }
      );
    this.tocref = tocRef;
  }

  fetchDocumentAfterTocSerialy(DocId) {
    this.tocContentSubject
      .pipe(
        filter((v) => Boolean(v)),
        first()
      )
      .subscribe((toc) => {
        this.getTocDocument(DocId);
      });
  }

  updateTocAfterPrintSElection(toc: TocModel) {
    this.tocContent = toc;
    this.tocContentSubject.next(this.tocContent);
  }

  getTocIdFromSecOrdPos(secOrdPos: string) {
    try {
      const referenceId = this.tocArray.find(
        (item) => item.secOrdPos == secOrdPos
      ).referenceId;
      return referenceId;
    } catch (error) {
      console.log("no match found for the secord pos you have entered");
    }
  }

  getTocArray() {
    if (
      this.tocContent &&
      this.tocContent.items != null &&
      this.tocContent.items.length > 0
    ) {
      let path = new Array<number>();
      this.tocArray = new Array<TocArrayModel>();
      this.findAndPutItemsInArray(this.tocContent.items, path);
    }
  }

  findAndPutItemsInArray(
    tocItems: Array<TocItemModel>,
    path: Array<number>
  ): void {
    tocItems.forEach((element) => {
      let toc: TocArrayModel;
      toc = new TocArrayModel();
      path.push(element.uniqueId);
      toc.referenceId = element.name;
      toc.uniqueId = element.uniqueId;
      toc.secOrdPos = element.secordPos;
      toc.type = element.type;
      //toc.path=new Array<number>();
      toc.path = path.slice();
      if (element.tocItems != null && element.tocItems.length > 0) {
        this.findAndPutItemsInArray(element.tocItems, path);
      }
      this.tocArray.push(toc);
      path.splice(path.length - 1, 1);
    });
    this.tocArray = this.tocArray.sort(function (a, b) {
      return Number(a.secOrdPos) - Number(b.secOrdPos);
    });
    var tocSecOrdPos: number[] = this.tocArray.map(function (x) {
      return parseInt(x.secOrdPos, 10);
    });
    this.tocMinSecOrdPos = Math.min.apply(null, tocSecOrdPos);
    this.tocMaxSecOrdPos = Math.max.apply(null, tocSecOrdPos);
  }

  findHighlightPath(referencrId: string): TocArrayModel {
    return this.tocArray.find((a) => a.referenceId == referencrId);
  }
  getCurrentDoc(referenceId: string) {
    this.currentHighlightToc = this.findHighlightPath(referenceId);
    this.tocCurrentDocument = this.lfcCurrentDocList.find(
      (a) => a.referenceId == referenceId
    );
    this.documentModel = this.tocCurrentDocument;
    this.tocCurrentDocumentSubject.next(this.tocCurrentDocument);
    this.currentHighlightTocSubject.next(this.currentHighlightToc);
    if (this.tocCurrentDocument) {
      // this.contextualBarService.updateBreadCrumbText(this.tocCurrentDocument.documentName);
      // this.contextualBarService.updateTooltip(this.tocCurrentDocument.documentName);
      if (this.tocContent && this.tocContent.title) {
        // this.getParentDocumentDetails(this.tocCurrentDocument.parentId ? this.tocCurrentDocument.parentId : this.tocCurrentDocument.referenceId);
        this.contextualBarService.updateLocationQueryDetails(
          this.getLFCLocationDetails(this.tocCurrentDocument)
        );
        this.contextualBarService.updateBreadCrumbText(
          this.tocCurrentDocument.parentTitle
        );
        this.contextualBarService.updateTooltip(
          this.tocCurrentDocument.parentTitle
        );
      } else {
        this.contextualBarService.updateLocationQueryDetails(
          this.getLFCLocationDetails(this.tocCurrentDocument)
        );
        this.contextualBarService.updateBreadCrumbText(
          this.tocCurrentDocument.documentName
        );
        this.contextualBarService.updateTooltip(
          this.tocCurrentDocument.documentName
        );
        //this.getParentDocumentDetails(this.tocCurrentDocument.referenceId);
      }
    }
  }

  fetchTopDocument(referenceId: string): Observable<AlexDocumentModel> {
    const cacheItem = this.documentCache.find(
      (doc) => doc.referenceId == referenceId
    );
    if (cacheItem) {
      this.documentModel = cacheItem;

      this.tocCurrentDocument = cacheItem;
      this.tocCurrentDocumentSubject.next(this.tocCurrentDocument);

      return observableOf(cacheItem);
    }
    return this.http
      .post(this.urlResolver.resolveUrlApiV1("/search/SearchTLPData"), {
        referenceId: referenceId,
        searchTerm: this.searchParam.searchTerm,
      })
      .pipe(
        map(
          (response) => {
            const docModel = response as AlexDocumentModel;
            const topicDefinition = this.replaceBinaryPathInContent(
              (docModel as any).topicDefinition
            );
            (docModel as any).topicDefinition = topicDefinition;
            this.documentModel = docModel;
            this.documentCache.push(docModel);
            this.tocCurrentDocument = docModel;
            this.tocCurrentDocumentSubject.next(this.tocCurrentDocument);
            return docModel;
          },
          (error) => {
            this.taskTracker.markTaskAsComplete(
              this.currentContext,
              TASK_FETCH_LFC_DOCUMENT
            );
          }
        )
      );
  }

  addDocumentToMasterSpace(newName): Observable<OperationModel> {
    this.item.docId = this.documentModel.referenceId;
    this.item.createdDate = this.documentModel.createdDate;
    this.item.mimeType = this.documentModel.mimeType;
    this.item.parentId = this.documentModel.parentId;

    if (
      this.item.mimeType == "application/top" ||
      this.item.mimeType == "application/topic" ||
      this.item.mimeType == "topic" ||
      this.item.mimeType == "top"
    ) {
      this.item.docType = "topic";
      this.item.topicTaxonomyIds = this.documentModel.topicTaxonomyIDs;
      this.item.tocRef = "";
    } else {
      this.item.docType = "documents";
      this.item.sourceTaxonomyIds = this.documentModel.sourceTaxonomyIds;
      this.item.tocRef = this.documentModel.tocRef;
    }
    this.item.parentId = this.documentModel.parentId;
    this.item.taxonomyIds = this.documentModel.taxonomyIds;

    let document;
    if (this.checkIfSecIdPresent) {
      this.hasKillDocID = false;
    } else {
      this.hasKillDocID = true;
    }

    if (!this.hasKillDocID) {
      this.item.docId = this.sectionId;
    }

    return this.spaceService.addDocumentToMasterSpace(
      this.item,
      newName,
      this.hasKillDocID
    );
  }

  getPrintData(
    printInputEntity: Array<PrintInputEntity>
  ): Observable<PrintOutputEntity> {
    let printModelList = new PrintModelList();
    printModelList.printModels = printInputEntity;
    this.printDocIdsArray = printInputEntity;
    return this.http
      .post(
        this.urlResolver.resolveUrlApiV1("/print/GetPrintPreviewData"),
        printModelList
      )
      .pipe(
        map((response) => {
          const printModel = response as PrintOutputEntity;
          this.printOutputEntity = this.replaceBinaryPathInContent(
            printModel.htmlContent
          );
          this.printErrorMsg = printModel.errroMessage;
          this.printOutputSubject.next(this.printOutputEntity);
          this.printOutputErrorMsg.next(this.printErrorMsg);
          return printModel;
        })
      );
  }

  // getPrintPdfData(
  //   printInputEntity: Array<PrintInputEntity>,
  //   modifiedHtmlContent: string,
  //   docId: string
  // ) {
  //   let printModelList = new PrintModelList();
  //   printModelList.printModels = printInputEntity;
  //   printModelList.htmlContent = modifiedHtmlContent;
  //   return this.http
  //     .post(
  //       this.urlResolver.resolveUrlApiV1("/print/GetPrintPdfData"),
  //       printModelList,
  //       { responseType: ResponseContentType.ArrayBuffer }
  //     )
  //     .map((res) => {
  //       const buffer = <ArrayBuffer>(<any>res)._body;
  //       var mimeType = "application/pdf";
  //       var fileName = docId + ".pdf";
  //       var isArryBuffer = true;
  //       this.fileService.downloadOrOpenFile(
  //         fileName,
  //         buffer,
  //         mimeType,
  //         isArryBuffer
  //       );
  //     });
  // }

  getPrintPdfData(
    printInputEntity: Array<PrintInputEntity>,
    modifiedHtmlContent: string,
    docId: string
  ) {
    let printModelList = new PrintModelList();
    printModelList.printModels = printInputEntity;
    printModelList.htmlContent = modifiedHtmlContent;
    return this.http
      .post(
        this.urlResolver.resolveUrlApiV1("/print/GetPrintPdfData"),
        printModelList,
        { responseType: "arraybuffer" }
      )
      .pipe(
        map((res) => {
          const buffer = <ArrayBuffer>(<any>res);
          var mimeType = "application/pdf";
          var fileName = docId + ".pdf";
          var isArryBuffer = true;
          this.fileService.downloadOrOpenFile(
            fileName,
            buffer,
            mimeType,
            isArryBuffer
          );
        })
      );
  }

  fetchStyleSheet(styleSheetName: string): Observable<string> {
    const styleSheet = this.appCacheSerivce.lookup(styleSheetName);
    if (styleSheet && styleSheet.value) {
      return observableOf(styleSheet.value);
    }
    // const headers = new Headers({ "Content-Type": "text" });
    // const options = new RequestOptions({ headers: headers });
    const header = new HttpHeaders({
      "Content-Type": "text",
    });
    return this.http
      .get(this.urlResolver.resolveStyleSheetUrl(styleSheetName), {
        responseType: "text",
      })
      .pipe(
        map((response) => {
          const appCacheModel = new AlexCacheModel();
          appCacheModel.key = styleSheetName;
          appCacheModel.value = response;
          return appCacheModel.value;
        })
      );
  }

  adjustScrollPosition(
    childElement: HTMLElement = null,
    parentElement: HTMLElement = null
  ) {
    if (!childElement || !parentElement) return;
    let parentClientRect = parentElement.getBoundingClientRect();
    let parentBottom = parentClientRect.bottom;
    let parentTop = parentClientRect.top;
    let childRect = childElement.getBoundingClientRect();
    let childBottom = childRect.bottom;
    let childTop = childRect.top;

    if (this.eventProducer && this.eventProducer.type == "click") {
      parentElement.scrollTop = this.prevScrollPosition;
    } else {
      if (childBottom > parentBottom) {
        parentElement.scrollTop =
          parentElement.scrollTop + (childBottom - parentBottom);
      } else if (childTop < parentTop) {
        parentElement.scrollTop =
          parentElement.scrollTop - (parentTop - childTop);
      }
    }
  }

  getBinaryPath(docId) {
    this.fetchSingleDocument(docId).subscribe((doc) => {
      if ((<any>doc).binaryPath) {
        window.open((<any>doc).binaryPath);
      }
    });
  }

  getCurrentContext(router: Router): string {
    if (router.url) {
      if (!!~router.url.toLowerCase().indexOf("/tocsearch/")) {
        this.currentContext = CONTEXT_TOC_SEARCH;
        return this.currentContext;
      }
      if (!!~router.url.toLowerCase().indexOf("/top/")) {
        this.currentContext = CONTEXT_TOP;
        return this.currentContext;
      }
    }
    this.currentContext = CONTEXT_LFC;
    return this.currentContext;
  }
  getTaskQueue(context: string) {
    switch (context) {
      case CONTEXT_TOP:
        return [TASK_FETCH_LFC_DOCUMENT];
      case CONTEXT_TOC_SEARCH:
        return [TASK_FETCH_LFC_DOCUMENT, TASK_FETCH_TOC_SEARCH_RESULT];
      default:
        return [TASK_FETCH_LFC_DOCUMENT, TASK_FETCH_TOC_CONTENT];
    }
  }
}
