import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "alex-multi-select",
  templateUrl: "./multi-select.component.html",
  styleUrls: ["./multi-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent {
  @Input()
  set items(listItems: { label: string; value: string; isChecked: boolean }[]) {
    this.listItems = [...listItems];
    this.updateListforAllItemsSelection();
  }
  @Input()
  disabled: boolean = false;
  @Input()
  allLabel: string = "All";
  @Input()
  partialLabel: string = "Partial";

  @Output()
  selectionChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  showDropdown: boolean = false;
  isAllSelected: boolean = false;
  listItems: { label: string; value: string; isChecked: boolean }[] = [];
  selectedItems: string[] = [];

  private hostEvent: Event;

  toggleList(): void {
    this.showDropdown = !this.showDropdown;
  }

  onItemSelectionChange(item: {
    label: string;
    value: string;
    isChecked: boolean;
  }): void {
    const updatedItem = { ...item, isChecked: !item.isChecked };

    this.listItems = this.listItems.map((li) =>
      li.value === item.value ? updatedItem : li
    );

    this.updateListforAllItemsSelection();

    this.emitSelectedItems();
  }

  onAllItemsSeletionToggle(): void {
    if (this.isAllSelected) {
      return;
    }
    this.isAllSelected = !this.isAllSelected;
    if (this.isAllSelected) {
      this.changeSelectionOfAllItems(false);
    }

    this.emitSelectedItems();
  }

  @HostListener("click", ["$event"])
  trackEvent(newHostEvent) {
    this.hostEvent = newHostEvent;
  }

  @HostListener("document:click", ["$event"])
  compareEvent(globalEvent) {
    if (!this.showDropdown) {
      return;
    }

    if (this.hostEvent !== globalEvent) {
      this.showDropdown = false;
    }
  }

  private updateListforAllItemsSelection(): void {
    const isAllSelected = (this.listItems || []).every(
      (item) => item.isChecked
    );
    const isNoneSelected = (this.listItems || []).every(
      (item) => !item.isChecked
    );
    if (isAllSelected) {
      this.changeSelectionOfAllItems(false);
    }
    this.isAllSelected = isAllSelected || isNoneSelected;
  }

  private changeSelectionOfAllItems(selectionState: boolean): void {
    this.listItems = this.listItems.map((li) => ({
      ...li,
      isChecked: selectionState,
    }));
  }

  private emitSelectedItems(): void {
    if (this.isAllSelected) {
      this.selectionChange.emit(["All"]);
    } else {
      const selectedItems = this.listItems
        .filter((li) => li.isChecked)
        .map((li) => li.value);
      this.selectionChange.emit(selectedItems);
    }
  }
}
