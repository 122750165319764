import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class EditionHttpConfigInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = this.addEditionId(request.url);
    const authReqest = request.clone({
      url: url,
    });
       
       
        return next.handle(authReqest).pipe(
          tap((event: HttpResponse<any>) => {
            
            if(event.headers){
              //console.log("event headers",event.headers.get('editionId'));
              const editionIdfromRes = event.headers.get('editionId');
              if(editionIdfromRes){
                this.setEditionId(editionIdfromRes);
              }
            }
          })
        );
    }
    addEditionId(url: string) {
            const editionId = sessionStorage.getItem('alex_edition');
            if (!editionId) {
              return url;
            }
            if (!~url.indexOf("?")) {
              return `${url}?editionId=${editionId}`;
            }
            return `${url}&editionId=${editionId}`;
          }
      
    setEditionId(ediId:string){
      const editionId = sessionStorage.getItem('alex_edition');
            if (!editionId) {
              sessionStorage.setItem('alex_edition',ediId);
            }
    }
}
