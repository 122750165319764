<!--tooltip section start-->
<!--<div class="flyoutTooltipTree Alextooltiptext treeToolTip" [ngClass]="{'displayFlyout':showPopUp}">
    <div class="toolTipTreeMenuList">
        <div class="accordion-heading treeMenuHeaderTxt">
            <a class="accordion-toggle" data-toggle="collapse" href="#toolTipfirstparentSource">
                                        <span class="float-left">
                                            <svg class="svgIm GreyColor1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      width="28px" height="1px" viewBox="0 0 22 1" xml:space="preserve">
      <g transform="scale(1.1)">
        <g transform="translate(-57 -110)" xmlns="http://www.w3.org/2000/svg" id="map-marker_2_">
          <path d="M63.972,106.585c0,0.881-0.183,1.838-0.727,2.701c-1.638,2.544-3.711,4.878-3.711,4.878s-2.383-2.253-3.906-4.924
                                        c-0.382-0.834-0.631-1.716-0.631-2.6c0-2.483,2.008-4.64,4.488-4.64C61.963,102,63.972,104.102,63.972,106.585z M62.208,106.404
                                        c0-1.484-1.202-2.683-2.683-2.683c-1.481,0-2.683,1.2-2.683,2.683c0,1.483,1.202,2.682,2.683,2.682
                                        C61.006,109.086,62.208,107.886,62.208,106.404z" />
        </g>
      </g>
    </svg>
                                            
                                        </span>
                                        Document Path
                                    </a>
        </div>

        <div class="accordion-body toolTipFirstChild collapse" id="toolTipfirstparentSource">
            <div class="accordion-inner">
                <div class="accordion" id="toolTipfirstchildSource">

                </div>
            </div>
        </div>
    </div>
</div>-->
<!--tooltip section end-->
<!--<div class="flyoutTooltipTree Alextooltiptext treeToolTip leftFlyout searchFlyout" [ngClass]="{'displayFlyout':showPopUp}">-->
    <div class="Alextooltiptext treeToolTip searchFlyout leftFlyout pathtooltip locationMob" [ngClass]="{'displayFlyout':showPopUp}">
  <div class="overflowAuto">
    <div class="treeStructure1" *ngIf="locationDetail!=null && locationDetail?.id!=null">
       

         <svg class="svgIm ceruleanColor treeLocationIcon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="1px" viewBox="0 0 20 1" xml:space="preserve">
                        <g transform="scale(1.9)">
                        <g transform="translate(-55 -108)" xmlns="http://www.w3.org/2000/svg" id="map-marker_2_">
                            <path d="M63.972,106.585c0,0.881-0.183,1.838-0.727,2.701c-1.638,2.544-3.711,4.878-3.711,4.878s-2.383-2.253-3.906-4.924
                        c-0.382-0.834-0.631-1.716-0.631-2.6c0-2.483,2.008-4.64,4.488-4.64C61.963,102,63.972,104.102,63.972,106.585z M62.208,106.404
                        c0-1.484-1.202-2.683-2.683-2.683c-1.481,0-2.683,1.2-2.683,2.683c0,1.483,1.202,2.682,2.683,2.682
                        C61.006,109.086,62.208,107.886,62.208,106.404z"></path>
                                </g>
                            </g>	
         </svg>
         <alex-location-tree-item [locationDetail]="locationDetail"></alex-location-tree-item>
    </div>
  </div>
</div>