export class PrintInputEntity {
    refernecId: string;
    secordPos: Array<string>;
    ISPageBreakNeeded: boolean = false;
}

export class PrintOutputEntity {
    htmlContent: string;
    pdfContent: string;
    errroMessage:string;
    Message?:string;
}

export class PrintModelList {
    printModels: Array<PrintInputEntity>;
    htmlContent: string;
}