
import {first} from 'rxjs/operators';
import { Injectable } from '@angular/core';
// import { AppInsights } from "applicationinsights-js";
import { v4 as uuid } from 'uuid';


// import { AlexUrlResolverService,AlexUserService } from '../../../core/services/user/user.service';
import { AlexUrlResolverService, AlexUserService } from "app/core/services";
import { User } from '../../../core/services/user/user.model';
import { TrackingMessageModel, TrackingPropertiesModel } from 'app/shared/models/tracking/';
import { TRACKING_SESSIOIN_ID_IDENTIFIER } from 'app/shared/constants/tracking';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable()
export class TrackingService {
  trackingProperties: TrackingPropertiesModel;
  sessionId: string;
  appInsights: ApplicationInsights;
  constructor(private urlResolverService: AlexUrlResolverService,private userService: AlexUserService) {
    this.setSessionId();
    this.userService.getUserDataAsObservable().pipe(
      first())
      .subscribe(
        (user: User) => {
          this.trackingProperties = new TrackingPropertiesModel(
            this.sessionId,
            user.userEmailId,
            user.domains.join(','),
            user.membershipDomain,
            user.IsAuthenticated,
            user.isExternalUser,
            user.groups
          );
        }
      );
      this.appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: this.urlResolverService.getAppInsightConfig().instrumentationKey,
            enableAutoRouteTracking: true // option to log all route changes
          }
        });
      this.appInsights.loadAppInsights();
  }

  /**
   * Method for tracking trace to AppInsight
   * @param trackMsgObj message object for tracking
   */
  track(trackMsgObj: TrackingMessageModel) {
    const trackingProperties = {
      ...this.trackingProperties,
      TRACE_TYPE: trackMsgObj.TRACE_TYPE,
      SEARCH_KEY: trackMsgObj.SEARCH_KEY || ''
    } as TrackingPropertiesModel;
    const messageObj = { data: trackMsgObj.data };
    // AppInsights.trackTrace(JSON.stringify(messageObj), { DIMENSIONS: JSON.stringify(trackingProperties) });
    // console.log("trackMsgObj",JSON.stringify(trackMsgObj),"trackingProperties",trackingProperties);
    this.appInsights.trackTrace({
      message:JSON.stringify(trackMsgObj),
      properties:  { DIMENSIONS: JSON.stringify(trackingProperties) }
  });
  }

  /**
   * set tracking session id
   */
  private setSessionId() {
    const sessionId = sessionStorage.getItem(TRACKING_SESSIOIN_ID_IDENTIFIER);
    if (sessionId) {
      this.sessionId = sessionId;
    } else {
      this.sessionId = uuid();
      sessionStorage.setItem(TRACKING_SESSIOIN_ID_IDENTIFIER, this.sessionId);
    }
  }

}
