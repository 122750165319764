import { AlexTaxonomyNodeModel } from "app/feature/search/model/filters/taxonomy-node.model";
import { AlexFilterBaseModel } from "app/feature/search/model/filters/filter-base.model";

export class AlexSourceFilterModel extends AlexFilterBaseModel {    
    //child nodes
    c?: Array<AlexSourceFilterModel>;
    constructor(taxId = "") {
        super();
        this.k = taxId;
    }
}