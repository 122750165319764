import {
  CombinedSearchFiltersService,
  TypeAhead,
} from "app/feature/header/services";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { AllSourcesService } from "app/feature/header/services/all-sources.service";
import { ParentTaxonomyIds } from "app/feature/search/model/taxonomy-model";
import { DateSelectionType } from "app/feature/search/model/filters/date-selction-type.enums";
import { AlexSearchItem } from "app/feature/search/model/content/search-item.model";
import { AlexSearchFilterModel } from "app/feature/search/model/filters/search-filter.model";
import { AlexSourceFilterModel } from "app/feature/search/model/filters/source-filter.model";
import { AlexDateFilterModel } from "app/feature/search/model/filters/date-filter.model";
import { AlexPaginationModel } from "app/shared/components/pagination/pagination.model";
import { AlexFilterTagModel } from "app/feature/search/search-result-tag/filter-tag.model";
import { AlexDropdownModel } from "app/shared/components/dropdown/dropdown.model";
import { AlexDropdownItemModel } from "app/shared/components/dropdown/dropdown-item.model";
import { AlexAdvanceFilteViewModel } from "app/feature/search/model/filters/advance-filter.viewmodel";
import { AlexCheckBoxModel } from "app/feature/search/model/filters/checkbox.model";
import { AlexTaxonomyNodeModel } from "app/feature/search/model/filters/taxonomy-node.model";

import * as moment from "moment";
import { AlexSearchViewModel } from "app/feature/search/model/search.viewmodel";
import { AlexSearchResult } from "app/feature/search/model/content/search-result.model";
import { AlexPaginationEventModel } from "app/shared/components/pagination/pagination.event.model";
import { AlexPaginationService } from "app/shared/components/pagination/pagination.service";
import { AlexSearchCacheService } from "app/feature/search/services/search-cache.service";
import { AlexSeeAllResultFilterModel } from "app/feature/search/model/filters/see-all-result-fiter.model";
import { AlexSourceFilterEventModel } from "app/feature/search/model/event/source-filter.event.model";
import {
  REMOVE_SOURCE_FILTER,
  REMOVE_TOPIC_FILTER,
  REMOVE_DATE_FILTER,
  REMOVE_FILE_FILTER,
  REMOVE_LANGUAGE_FILTER,
  REMOVE_ALL_TAGS,
  SOURCE_FILTER,
  TOPIC_FILTER,
  DATE_FILTER,
  FILE_FILTER,
  LANGUAGE_FILTER,
  REMOVE_PARENT_ID_FILTER,
} from "app/feature/search/ACTIONS/search-action-types";
import { AlexFilterBaseModel } from "app/feature/search/model/filters/filter-base.model";
import {
  SOURCE_FILTER_PARAM,
  TOPIC_FILTER_PARAM,
  DATE_FILTER_PARAM,
  FILE_FILTER_PARAM,
  LANGUAGE_FILTER_PARAM,
  SORT_ORDER_PARAM,
  MAX_FILTER_ORDER_PARAM,
  IS_ALL_SOURCE_SELECTED,
  VIEW_MODE_PARAM,
  OTHERCATEGORY_FILTER_PARAM,
  COUNTRY_FILTER_PARAM,
  APPLICABLE_COUNTRY_FILTER_PARAM,
  AUDIT_STANDARD_FILTER_PARAM,
  VERSION_FILTER_PARAM,
} from "app/feature/search/constants/search-url.constants";
import { SearchResultViewMode } from "app/feature/search/enums/alex-search-enums";
import { AlexUserService } from "app/core/services";
import { CombinedSearchQueryType } from "../types";

@Injectable()
export class SearchParamService {
  filterParam: AlexSearchFilterModel;
  private _uniqueUrlKey = 1;
  get UniqueUrlKey() {
    this._uniqueUrlKey++;
    return this._uniqueUrlKey % 2;
  }
  public recentSearch = false;
  public shouldLoadAllFilter = true;
  recentClick = false;
  filterOrderMaxValue = 0;
  reloadAllFilters = true;
  dateSelectionType: DateSelectionType;
  allSourcesChecked: boolean;
  taxonomyModelArray: any;
  searchTerm: string;
  searchFor: string;
  typedTerm: string;
  suggestionLength: number;
  refreshSourceFilter: boolean;
  alexSearchQueryParam$ = new Subject<string>();
  searchParamSource$: Subject<SearchParamService>;
  public searchViewModel$ = new Subject<AlexSearchViewModel>();
  tocTitleFromShowmore: string = "";
  needToFetchData: boolean = true;

  private _sortDropdownPristineValue = [
    { text: "Most relevant first", value: "Relevance", selected: true },
    { text: "Most recent first", value: "Date", selected: false },
    { text: "Title A to Z", value: "Title", selected: false },
  ];

  ///
  toDate: string;
  fromDate: string;
  loadDataWithAllFilters: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private paginationService: AlexPaginationService,
    private allSourcesService: AllSourcesService,
    private searchCahceService: AlexSearchCacheService,
    private userSerive: AlexUserService,
    private combinedSearchFiltersService: CombinedSearchFiltersService
  ) {
    this.filterParam = new AlexSearchFilterModel();
    this.filterParam.mxo = 0;
    this.filterParam.sort = this.getSortDropdownPristineValue()[0].value;
    this.filterParam.viewMode = SearchResultViewMode.Default;

    this.searchParamSource$ = new Subject<SearchParamService>();
    this.searchParamSource$.next(this || <any>{});
    this.dateSelectionType = DateSelectionType.None;
  }

  getSortDropdownPristineValue(): Array<AlexDropdownItemModel> {
    return this._sortDropdownPristineValue;
  }

  setAllSourceStatus(status: boolean) {
    if (this.filterParam.allSource !== status) {
      this.needToFetchData = true;
    }
    this.filterParam.allSource = status;
    if (status) {
      this.filterParam.isManuals = true;
    }
  }
  setUniqueKey(key: number): void {
    this._uniqueUrlKey = key;
  }
  setFilterParamMaxOrder(order: number) {
    if (this.filterParam.mxo !== order) {
      this.needToFetchData = true;
    }
    this.filterParam.mxo = order;
  }
  getFilterParamMaxOrder() {
    return this.filterParam.mxo;
  }

  getFilterData(): AlexSearchFilterModel {
    return this.filterParam;
  }
  // getCountryData(): Array<string>{
  //   return this.filterParam.countries &&this.filterParam.countries.map((country) => country.k);;
  // }

  clearAllFilter() {
    this.clearAdvanceFilter();
    this.reloadAllFilters = true;
    this.shouldLoadAllFilter = true;
    this.filterParam.sources = null;
    this.filterParam.mxo = 0;
    this.filterParam.allSource = true;
    this.tocTitleFromShowmore = null;
    this.allSourcesService.updateTaxonomySelection([]);
    this.combinedSearchFiltersService.handleResetClick();
  }

  clearAdvanceFilter(advance = false) {
    this.shouldLoadAllFilter = true;
    this.filterParam.date = null;
    this.filterParam.sort = "";
    this.filterParam.languages = null;
    //Added for combined search
    this.filterParam.applicableCountries = null;
    this.filterParam.workflowType = null;
    this.filterParam.version = null;
    this.filterParam.topics = null;
    this.filterParam.otherCategories = null;
    this.filterParam.countries = null;
    this.filterParam.fileTypes = null;
    if (this.filterParam.sources) {
      this.filterParam.sources = this.filterParam.sources.map((item) => {
        item.c = null;
        return item;
      });
    }
  }
  getRouteParam(): any {
    const routeParam = {};
    routeParam[IS_ALL_SOURCE_SELECTED] = this.filterParam.allSource ? 1 : 0;
    if (this.filterParam.sources && this.filterParam.sources.length) {
      routeParam[SOURCE_FILTER_PARAM] = JSON.stringify(
        this.filterParam.sources
      );
    }
    if (this.filterParam.topics && this.filterParam.topics.length) {
      routeParam[TOPIC_FILTER_PARAM] = JSON.stringify(this.filterParam.topics);
    }
    if (
      this.filterParam.otherCategories &&
      this.filterParam.otherCategories.length
    ) {
      routeParam[OTHERCATEGORY_FILTER_PARAM] = JSON.stringify(
        this.filterParam.otherCategories
      );
    }
    //Added for Country
    if (this.filterParam.countries && this.filterParam.countries.length) {
      routeParam[COUNTRY_FILTER_PARAM] = JSON.stringify(
        this.filterParam.countries
      );
    }

    if (this.filterParam.date) {
      routeParam[DATE_FILTER_PARAM] = JSON.stringify(this.filterParam.date);
    }
    if (this.filterParam.fileTypes && this.filterParam.fileTypes.length) {
      routeParam[FILE_FILTER_PARAM] = JSON.stringify(
        this.filterParam.fileTypes
      );
    }
    if (this.filterParam.languages && this.filterParam.languages.length) {
      routeParam[LANGUAGE_FILTER_PARAM] = JSON.stringify(
        this.filterParam.languages
      );
    }
    //Added for combined search start
    if (
      this.filterParam.applicableCountries &&
      this.filterParam.applicableCountries.length
    ) {
      routeParam[APPLICABLE_COUNTRY_FILTER_PARAM] = JSON.stringify(
        this.filterParam.applicableCountries
      );
    }
    if (this.filterParam.workflowType && this.filterParam.workflowType.length) {
      routeParam[AUDIT_STANDARD_FILTER_PARAM] = JSON.stringify(
        this.filterParam.workflowType
      );
    }
    if (this.filterParam.version && this.filterParam.version.length) {
      routeParam[VERSION_FILTER_PARAM] = JSON.stringify(
        this.filterParam.version
      );
    }
    //added for combined search end
    if (this.filterParam.sort) {
      routeParam[SORT_ORDER_PARAM] = this.filterParam.sort;
    }
    if (this.filterParam.mxo) {
      routeParam[MAX_FILTER_ORDER_PARAM] = this.filterParam.mxo;
    }
    routeParam[VIEW_MODE_PARAM] = this.filterParam.viewMode;

    return routeParam;
  }
  updateSearchTerm(term: string) {
    const searchTerm = term || "";
    if (this.filterParam.q !== searchTerm) {
      this.needToFetchData = true;
    }
    this.filterParam.q = searchTerm;
    this.alexSearchQueryParam$.next(term);
  }

  setSourcesFilter(sources: Array<AlexSourceFilterModel>) {
    if (JSON.stringify(sources) !== JSON.stringify(this.filterParam.sources)) {
      this.needToFetchData = true;
    }
    const { allSource: isAllSelected } = this.filterParam;
    this.filterParam.sources = sources;
    const selectedSources = isAllSelected
      ? ["All"]
      : (sources || []).map((s) => s.k);

    const selectedSourcesSet = new Set<string>((sources || []).map((s) => s.k));
    const isManuals = isAllSelected || selectedSourcesSet.has("0");
    this.filterParam.isManuals = isManuals;
    this.combinedSearchFiltersService.handleSourcesSelectionChange(
      selectedSources
    );
  }

  addToSourceFilter(sourceFilterEventModel: AlexSourceFilterEventModel) {
    this.filterParam.sources =
      this.filterParam.sources || new Array<AlexSourceFilterModel>();
    const nodePath = sourceFilterEventModel.additionData;

    if (Array.isArray(nodePath)) {
      this.filterParam.sources = this.updateSourceFilterTreeModel(
        nodePath,
        this.filterParam.sources
      );
    }
  }

  setCombinedSearchType(combinedSearchType: CombinedSearchQueryType): void {
    this.filterParam = { ...this.filterParam, combinedSearchType };
  }

  setTocCountry(tocCountry: string): void {
    this.filterParam = { ...this.filterParam, tocCountry };
  }

  setTocWorkflow(tocWorkflow: string): void {
    this.filterParam = { ...this.filterParam, tocWorkflow };
  }

  setTocVersion(tocVersion: string): void {
    this.filterParam = { ...this.filterParam, tocVersion };
  }

  private updateSourceFilterTreeModel(
    nodePath: Array<string>,
    treeNodeCollection: Array<AlexSourceFilterModel>
  ): Array<AlexSourceFilterModel> {
    treeNodeCollection =
      treeNodeCollection || new Array<AlexSourceFilterModel>();

    const node = nodePath[0];
    const nodeIndex = treeNodeCollection.findIndex(
      (source) => source.k == node
    );
    let currentNode =
      nodeIndex > -1
        ? treeNodeCollection[nodeIndex]
        : new AlexSourceFilterModel(node);

    const remainingPath = nodePath.slice(1);
    if (remainingPath.length) {
      currentNode.c = this.updateSourceFilterTreeModel(
        remainingPath,
        currentNode.c
      );
    }

    if (!remainingPath.length)
      currentNode.o = this.filterParam.mxo = this.filterParam.mxo + 1;

    if (nodeIndex == -1) {
      treeNodeCollection.push(currentNode);
    }

    return treeNodeCollection;
  }

  addToTopicFilter(topic: string) {
    this.filterParam.topics =
      this.filterParam.topics || new Array<AlexFilterBaseModel>();
    if (!this.filterParam.topics.map((topic) => topic.k).includes(topic)) {
      const topicFilter = new AlexFilterBaseModel();
      topicFilter.k = topic;
      topicFilter.o = this.filterParam.mxo = this.filterParam.mxo + 1;
      this.filterParam.topics.push(topicFilter);
    }
  }

  //Add for other category
  addToOtherFilter(other: string) {
    this.filterParam.otherCategories =
      this.filterParam.otherCategories || new Array<AlexFilterBaseModel>();
    if (
      !this.filterParam.otherCategories.map((other) => other.k).includes(other)
    ) {
      const otherFilter = new AlexFilterBaseModel();
      otherFilter.k = other;
      otherFilter.o = this.filterParam.mxo = this.filterParam.mxo + 1;
      this.filterParam.otherCategories.push(otherFilter);
    }
  }
  //Add for Country
  addToCountryFilter(country: string) {
    this.filterParam.countries =
      this.filterParam.countries || new Array<AlexFilterBaseModel>();
    if (
      !this.filterParam.countries.map((country) => country.k).includes(country)
    ) {
      const countryFilter = new AlexFilterBaseModel();
      countryFilter.k = country;
      countryFilter.o = this.filterParam.mxo = this.filterParam.mxo + 1;
      this.filterParam.countries.push(countryFilter);
    }
  }
  setTopicsFilter(topics: Array<AlexFilterBaseModel>) {
    if (JSON.stringify(topics) !== JSON.stringify(this.filterParam.topics)) {
      this.needToFetchData = true;
    }
    this.filterParam.topics = topics;
  }
  //Add for other category
  setOtherFilter(other: Array<AlexFilterBaseModel>) {
    if (
      JSON.stringify(other) !== JSON.stringify(this.filterParam.otherCategories)
    ) {
      this.needToFetchData = true;
    }
    this.filterParam.otherCategories = other;
  }
  //Add for Country
  setCountryFilter(country: Array<AlexFilterBaseModel>) {
    if (
      JSON.stringify(country) !== JSON.stringify(this.filterParam.countries)
    ) {
      this.needToFetchData = true;
    }
    this.filterParam.countries = country;
  }
  setDateFilter(dateModel: AlexDateFilterModel) {
    if (JSON.stringify(dateModel) !== JSON.stringify(this.filterParam.date)) {
      this.needToFetchData = true;
    }
    this.filterParam.date = dateModel;
  }
  addFileTypeFilter(fileType: string) {
    this.filterParam.fileTypes =
      this.filterParam.fileTypes || new Array<AlexFilterBaseModel>();
    const fileTypeFilter = new AlexFilterBaseModel();
    fileTypeFilter.k = fileType;
    fileTypeFilter.o = this.filterParam.mxo = this.filterParam.mxo + 1;
    this.filterParam.fileTypes[0] = fileTypeFilter;
  }
  setFileTypeFilter(fileTypes: Array<AlexFilterBaseModel>) {
    if (
      JSON.stringify(fileTypes) !== JSON.stringify(this.filterParam.fileTypes)
    ) {
      this.needToFetchData = true;
    }
    this.filterParam.fileTypes = fileTypes;
  }
  removeFileTypeFilter(fileType: string) {
    this.filterParam.fileTypes = [];
  }

  addLanguageTypeFilter(language: string) {
    this.filterParam.languages =
      this.filterParam.languages || new Array<AlexFilterBaseModel>();

    if (
      !this.filterParam.languages
        .map((language) => language.k)
        .includes(language)
    ) {
      const languageFilter = new AlexFilterBaseModel();
      languageFilter.k = language;
      languageFilter.o = this.filterParam.mxo = this.filterParam.mxo + 1;
      this.filterParam.languages.push(languageFilter);
    }
  }
  setLanguageFilter(languages: Array<AlexFilterBaseModel>) {
    if (
      JSON.stringify(languages) !== JSON.stringify(this.filterParam.languages)
    ) {
      this.needToFetchData = true;
    }
    this.filterParam.languages = languages;
  }
  removeLanguageTypeFilter(language: string) {
    if (
      this.filterParam.languages &&
      this.filterParam.languages.map((lang) => lang.k).includes(language)
    ) {
      this.filterParam.languages = this.filterParam.languages.filter(
        (item) => item.k != language
      );
    }
  }
  //Added for combined search start
  //Applicable country
  addApplicableCountryFilter(country: string) {
    this.filterParam.applicableCountries =
      this.filterParam.applicableCountries || new Array<AlexFilterBaseModel>();

    if (
      !this.filterParam.applicableCountries
        .map((country) => country.k)
        .includes(country)
    ) {
      const countryFilter = new AlexFilterBaseModel();
      countryFilter.k = country;
      countryFilter.o = this.filterParam.mxo = this.filterParam.mxo + 1;
      this.filterParam.applicableCountries.push(countryFilter);
    }
  }
  setApplicableCountryFilter(countries: Array<AlexFilterBaseModel>) {
    const selectedCountries =
      Array.isArray(countries) && countries.length > 0
        ? countries.map((s) => s.k)
        : [];

    const mappedCountries = selectedCountries.map((v, indx) => {
      const f = new AlexFilterBaseModel();
      f.k = v;
      f.o = indx;
      return f;
    });

    if (
      JSON.stringify(mappedCountries) !==
      JSON.stringify(this.filterParam.applicableCountries)
    ) {
      this.needToFetchData = true;
    }

    this.filterParam.applicableCountries = mappedCountries;

    if (selectedCountries.length === 0) {
      return;
    }

    this.combinedSearchFiltersService.handleCountriesSelectionChange(
      selectedCountries,
      false
    );
  }
  removeApplicableCountryFilter(country: string) {
    if (
      this.filterParam.applicableCountries &&
      this.filterParam.applicableCountries
        .map((country) => country.k)
        .includes(country)
    ) {
      this.filterParam.applicableCountries = this.filterParam.applicableCountries.filter(
        (item) => item.k != country
      );
    }
  }
  //workflow type
  addWorkflowTypeFilter(workflowType: string) {
    this.filterParam.workflowType =
      this.filterParam.workflowType || new Array<AlexFilterBaseModel>();

    if (
      !this.filterParam.workflowType
        .map((workflowType) => workflowType.k)
        .includes(workflowType)
    ) {
      const workflowTypeFilter = new AlexFilterBaseModel();
      workflowTypeFilter.k = workflowType;
      workflowTypeFilter.o = this.filterParam.mxo = this.filterParam.mxo + 1;
      this.filterParam.workflowType.push(workflowTypeFilter);
    }
  }
  setWorkflowTypeFilter(workflowTypes: Array<AlexFilterBaseModel>) {
    const selectedWorkflowTypes =
      Array.isArray(workflowTypes) && workflowTypes.length > 0
        ? workflowTypes.map((s) => s.k)
        : [];

    const mappedWorkflowTypes = selectedWorkflowTypes.map((v, indx) => {
      const f = new AlexFilterBaseModel();
      f.k = v;
      f.o = indx;
      return f;
    });

    if (
      JSON.stringify(mappedWorkflowTypes) !==
      JSON.stringify(this.filterParam.workflowType)
    ) {
      this.needToFetchData = true;
    }

    this.filterParam.workflowType = mappedWorkflowTypes;

    if (selectedWorkflowTypes.length === 0) {
      return;
    }

    this.combinedSearchFiltersService.handleWorkflowTypesSelectionChange(
      selectedWorkflowTypes
    );
  }
  removeWorkflowTypeFilter(workflowType: string) {
    if (
      this.filterParam.workflowType &&
      this.filterParam.workflowType
        .map((workflowType) => workflowType.k)
        .includes(workflowType)
    ) {
      this.filterParam.workflowType = this.filterParam.workflowType.filter(
        (item) => item.k != workflowType
      );
    }
  }

  //version
  addVersionFilter(version: string) {
    this.filterParam.version =
      this.filterParam.version || new Array<AlexFilterBaseModel>();

    if (
      !this.filterParam.version.map((version) => version.k).includes(version)
    ) {
      const versionFilter = new AlexFilterBaseModel();
      versionFilter.k = version;
      versionFilter.o = this.filterParam.mxo = this.filterParam.mxo + 1;
      this.filterParam.version.push(versionFilter);
    }
  }

  setVersionFilter(versions: Array<AlexFilterBaseModel>) {
    const selectedVersions =
      Array.isArray(versions) && versions.length > 0
        ? versions.map((s) => s.k)
        : [];

    const mappedVersions = selectedVersions.map((v, indx) => {
      const versionFilter = new AlexFilterBaseModel();
      versionFilter.k = v;
      versionFilter.o = indx;
      return versionFilter;
    });

    if (
      JSON.stringify(mappedVersions) !==
      JSON.stringify(this.filterParam.version)
    ) {
      this.needToFetchData = true;
    }

    this.filterParam.version = mappedVersions;

    if (selectedVersions.length === 0) {
      return;
    }

    this.combinedSearchFiltersService.handleVersionsSelectionChange(
      selectedVersions
    );
  }
  removeVersionFilter(version: string) {
    if (
      this.filterParam.version &&
      this.filterParam.version.map((version) => version.k).includes(version)
    ) {
      this.filterParam.version = this.filterParam.version.filter(
        (item) => item.k != version
      );
    }
  }
  //for combined search ends

  setSortFilter(key: string) {
    let selectedIndex = this.getSortDropdownPristineValue().findIndex(
      (item) => item.value == key
    );
    if (selectedIndex == -1) {
      selectedIndex = 0;
      console.log("invalid sort order. using default sort order");
    }
    if (
      this.getSortDropdownPristineValue()[selectedIndex].value !==
      this.filterParam.sort
    ) {
      this.needToFetchData = true;
    }
    this.filterParam.sort = this.getSortDropdownPristineValue()[
      selectedIndex
    ].value;
  }
  setViewMode(viewMode: SearchResultViewMode) {
    this.filterParam.viewMode = viewMode;
  }
  setSeeAllResultFilter(filter: AlexSeeAllResultFilterModel) {
    if (
      JSON.stringify(filter) !== JSON.stringify(this.filterParam.seeAllResult)
    ) {
      this.needToFetchData = true;
    }
    this.filterParam.seeAllResult = filter;
  }
  loadAllFilter(flag = true) {
    this.shouldLoadAllFilter = flag;
  }
  IsRecentSearch(flag: boolean) {
    this.recentSearch = flag;
  }
}
