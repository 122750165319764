       
<div class="mobMenuIcon" (click)="getallData($event)" title="Content menu">
    <svg class="svgIm oceanBlue burgerMenuWidth" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px"
         height="1px" viewBox="0 0 28 1"  xml:space="preserve">
    <g transform="scale(1.6)">
    <g transform="translate(-396,-792.5)" xmlns="http://www.w3.org/2000/svg" id="show-thumbnails-with-lines_2_">
        <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M420,799.5c0,0.275-0.225,0.5-0.5,0.5h-18c-0.275,0-0.5-0.225-0.5-0.5v-2
			c0-0.275,0.225-0.5,0.5-0.5h18c0.275,0,0.5,0.225,0.5,0.5V799.5z M399,799.5c0,0.275-0.225,0.5-0.5,0.5h-2
			c-0.275,0-0.5-0.225-0.5-0.5v-2c0-0.275,0.225-0.5,0.5-0.5h2c0.275,0,0.5,0.225,0.5,0.5V799.5z M420,793.5
			c0,0.275-0.225,0.5-0.5,0.5h-18c-0.275,0-0.5-0.225-0.5-0.5v-2c0-0.275,0.225-0.5,0.5-0.5h18c0.275,0,0.5,0.225,0.5,0.5V793.5z
			 M399,793.5c0,0.275-0.225,0.5-0.5,0.5h-2c-0.275,0-0.5-0.225-0.5-0.5v-2c0-0.275,0.225-0.5,0.5-0.5h2c0.275,0,0.5,0.225,0.5,0.5
			V793.5z M420,787.5c0,0.275-0.225,0.5-0.5,0.5h-18c-0.275,0-0.5-0.225-0.5-0.5v-2c0-0.275,0.225-0.5,0.5-0.5h18
			c0.275,0,0.5,0.225,0.5,0.5V787.5z M399,787.5c0,0.275-0.225,0.5-0.5,0.5h-2c-0.275,0-0.5-0.225-0.5-0.5v-2
			c0-0.275,0.225-0.5,0.5-0.5h2c0.275,0,0.5,0.225,0.5,0.5V787.5z"></path>
	                </g>
	            </g>	
     </svg>
    
</div>
<!-- hamburger menu section start-->
    <div *ngIf="(isVisible)" [style.max-height.px]="scrollHeight" class="col-lg-3 col-md-4 col-sm-6 col-12 hamburgerMenu DivWithScroll"  role="tablist" >
                <div  class="">
                    <ul class="hamBurgerMenuContent ">
                        <div *ngIf="bargarMenuData?.name!=''" class="hamburgerMenuHeader">
                            <div class="leftIconSection" (click)="moveBack($event)">
                                <span class="iconless ">
                                <svg class="svgIm menuNextIcon oceanBlue" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px"
                                 height="1px" viewBox="0 0 28 1"  xml:space="preserve">
                                 <g transform="scale(1.3)">
                                <g transform="translate(-246,-1127)" xmlns="http://www.w3.org/2000/svg" id="chevron-left_2_">
                                    <polygon points="255.808,1128 266.301,1117.507 269.973,1121.179 263.161,1128 269.977,1134.816 266.302,1138.492 		"></polygon>
                                    </g>
                                </g>
                                   </svg>
                                </span> 
                            </div>
                           <div class="subMenuHeading">
                             <p>
                              {{bargarMenuData?.name}}
                              </p>
                            </div>
                        </div>

                        <li class=""  *ngFor="let item of bargarMenuData?.data" >
                            <!--<ng-container *ngIf="bargarMenuData?.name!=''">-->
                           <div class="menulistSection">
                                <div  (click)="openBridgePage($event,item,bargarMenuData?.name)" class="menuListHeader">{{item.name}}</div>
                                <!--</ng-container>
                                <ng-container *ngIf="bargarMenuData?.name==''">
                                <div  style="cursor: default; " class="menuListHeader">{{item.name}}</div>
                                </ng-container>-->
                                <div  *ngIf="!item.isBottomLevel &&(item.nodes!= null) && (item.nodes.length>0) && bargarMenuData?.name!=''" class="menuBtn" (click)="getAllChilds($event,item)" >
                                    <span class="iconless " >
                                        <svg class="svgIm leftTriangleIcon oceanBlue" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px"
                                        height="1px" viewBox="0 0 32 1"  xml:space="preserve">
                                            <g transform="scale(1.8)">
                                                <g transform="translate(-237,-323)" xmlns="http://www.w3.org/2000/svg" id="triangle-right">
                                                    <path style="fill-rule:evenodd;clip-rule:evenodd;" 
                                                    d="M254.646,324.354l-4.939,4.939C249.318,329.682,249,329.55,249,329v-10
                                                        c0-0.55,0.318-0.682,0.707-0.293l4.939,4.939C254.841,323.841,254.841,324.159,254.646,324.354z"/>
                                                </g>	
                                            </g>
                                    </svg>
                                    </span>                                           
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="menuListFooter" style="display:none">
                          <span class=" ">
                                                 <svg class="svgIm menuFooterIcon darkishPurple" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px"
                                 height="1px" viewBox="0 0 28 1"  xml:space="preserve">
                            <g transform="scale(0.9)">
                            <g transform="translate(-295,-976.9)" xmlns="http://www.w3.org/2000/svg" id="info-sign_2_">
                                    <path d="M312,971.75c-6.765,0-12.25,5.484-12.25,12.25s5.485,12.25,12.25,12.25s12.25-5.484,12.25-12.25
                                        S318.765,971.75,312,971.75z M311,977.5c0-0.275,0.225-0.5,0.5-0.5h2c0.275,0,0.5,0.225,0.5,0.5v2c0,0.275-0.225,0.5-0.5,0.5h-2
                                        c-0.275,0-0.5-0.225-0.5-0.5V977.5z M316,990.5c0,0.275-0.225,0.5-0.5,0.5h-6c-0.275,0-0.5-0.225-0.5-0.5v-2
                                        c0-0.275,0.225-0.5,0.5-0.5h1.5v-4h-1.5c-0.275,0-0.5-0.225-0.5-0.5v-2c0-0.275,0.225-0.5,0.5-0.5h4c0.275,0,0.5,0.225,0.5,0.5
                                        v6.5h1.5c0.275,0,0.5,0.225,0.5,0.5V990.5z"/>
                                </g>
                            </g>	
                                   </svg>
                                       </span>  
                                <span class="menuListFooterTxt">Content Source help / FAQ</span>
                    </div>
                </div>
 </div>
    <!-- hamburger menu section end-->




