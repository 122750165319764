<div class="multi-select__wrapper">
  <button
    class="btn btn-default dropdown-toggle-btn"
    type="button"
    [disabled]="disabled"
    (click)="toggleList()"
  >
    {{ isAllSelected ? allLabel : partialLabel }}
    <span class="caret"></span>
  </button>
  <div class="multi-select__list-wrapper" *ngIf="showDropdown">
    <ul class="multi-select__list">
      <li *ngIf="listItems?.length > 0">
        <div class="multi-select__list-item">
          <input type="checkbox" [attr.checked]="isAllSelected" />
          <span
            class="checkboxspan float-left"
            (click)="onAllItemsSeletionToggle()"
          ></span>
          <label class="checkboxTxt" (click)="onAllItemsSeletionToggle()"
            >All</label
          >
        </div>
      </li>
      <li *ngFor="let item of listItems">
        <div class="multi-select__list-item">
          <input type="checkbox" [attr.checked]="item?.isChecked" />
          <span
            class="checkboxspan float-left"
            (click)="onItemSelectionChange(item)"
          ></span>
          <label class="checkboxTxt" (click)="onItemSelectionChange(item)">{{
            item?.label
          }}</label>
        </div>
      </li>
    </ul>
  </div>
</div>
