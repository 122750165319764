import { Injectable } from "@angular/core";

import { TOCSearchSectionModel } from "app/feature/long-form-content/toc-section-search/toc-search-section.model";
import { ProcessedNode } from "app/shared/components/document-viewer/document-processor.model";
import { AlexUrlResolverService } from "app/core/services";
import { ExcerptNavigationType } from "app/feature/long-form-content/toc-section-search/toc-search.enums";
import { TOCSearchExcerptModel } from "app/feature/long-form-content/toc-section-search/toc-search-excerpt.model";
import { ActivatedRoute } from "@angular/router";
import { ISpecialCharacter } from "app/core/factories/special-chaeacters/Ispecial-character";
import { ContentRef } from "ngx-bootstrap/component-loader";

@Injectable()
export class TocSearchDocumentSyncService {
  specialCharacters: ISpecialCharacter;
  private SPECIAL_CHARACTERS = "";
  private LANGUAGE_WITHOUT_WORD_BOUNDARY = "";
  private REGEX_LANGUAGE_WITHOUT_WORD_BOUNDARY: RegExp;
  constructor(private urlResolver: AlexUrlResolverService) { }
  setSpecialCharactersList(specialCharacters: ISpecialCharacter) {
    specialCharacters = specialCharacters || new ISpecialCharacter();
    this.SPECIAL_CHARACTERS = specialCharacters.wordBoundaries.join("|");
    if (Array.isArray(specialCharacters.langWithoutWordBoundaryUnicodes)) {
      this.LANGUAGE_WITHOUT_WORD_BOUNDARY = specialCharacters.langWithoutWordBoundaryUnicodes.join(
        "|"
      );
      this.REGEX_LANGUAGE_WITHOUT_WORD_BOUNDARY = new RegExp(
        `^([${this.LANGUAGE_WITHOUT_WORD_BOUNDARY}])*$`
      );
    }
  }
  highlightHits(
    section: TOCSearchSectionModel
  ): Promise<TOCSearchSectionModel> {
    return new Promise((resolve) => {
      if (section.isHitHighlighted) {
        resolve(section);
      } else {
        const distinctHits = this.getDistinct(
          this.getSearchHits(section.searchExcerptModels)
        );
        const html = this.updateSearchHits(
          distinctHits,
          section.document.contentRef
        );
        section.modifiedHtml = new DOMParser().parseFromString(
          html,
          "text/html"
        );
        section.isHitHighlighted = true;
        resolve(section);
      }
    });
  }

  syncExcerpts(
    searchSection: TOCSearchSectionModel,
    syncCount: number,
    navigationType = ExcerptNavigationType.Click,
    currentIndex = 0
  ): Document {

    navigationType = navigationType || ExcerptNavigationType.Click;
    const model = searchSection;
    const nodeList = searchSection.modifiedHtml.querySelectorAll(
      ".tocsearchhit,.unprocessed"
    );
    const excerptRange = this.getExcerptRange(
      searchSection,
      currentIndex,
      syncCount,
      navigationType
    );

    for (var index = 0; index < excerptRange.length; index++) {
      const searchExcerptModel = model.searchExcerptModels[excerptRange[index]];
      if (!searchExcerptModel.isSynced) {
        let excerptText = <any>searchExcerptModel.text;
        excerptText = excerptText
          .replace(new RegExp("<span class='tocsearchhit'>", "g"), "")
          .replace(new RegExp("</span>", "g"), "")
          .trim()
          .toLowerCase();
        if (
          this.findTextInNodeCollectionV2(
            nodeList,
            excerptText,
            searchExcerptModel
          )
        ) {
          searchExcerptModel.matchFound = true;
        }
        searchExcerptModel.isSynced = true;
        syncCount--;
      }
    }
    return searchSection.modifiedHtml;
  }

  getExcerptRange(
    section: TOCSearchSectionModel,
    currentIndex: number,
    syncCount: number,
    navigationType: ExcerptNavigationType
  ): Array<number> {

    let endIndex = currentIndex,
      startIndex = currentIndex;

    switch (navigationType) {
      case ExcerptNavigationType.Previous:
        startIndex -= syncCount;
        break;
      case ExcerptNavigationType.Click:
        startIndex -= Math.ceil(syncCount / 2);
        endIndex += Math.ceil(syncCount / 2);
        break;
      default:
        endIndex += syncCount;
        break;
    }
    if (startIndex < 0) {
      startIndex = 0;
    }
    if (endIndex >= section.searchExcerptModels.length) {
      endIndex = section.searchExcerptModels.length - 1;
    }
    const processedDuplicates = [];
    const range = [];
    for (var index = startIndex; index <= endIndex; index++) {
      if (!processedDuplicates.includes(index)) {
        // if (
        //   section.searchExcerptModels[index].duplicates &&
        //   section.searchExcerptModels[index].duplicates.length
        // ) {
        //   range.push(...section.searchExcerptModels[index].duplicates);
        // } else {
        range.push(index);
        //}
      }
    }
    return range;
  }

  highlightExcerptMatch(excerptId: any) {
    setTimeout(() => {

      const searchHighlightElNodeList = Array.from(
        document.querySelectorAll(".tocsearchhit,.tocsearchhighlight")
      );
      searchHighlightElNodeList.forEach((searchHighlightElement) => {
        const expandCollapseContainerEl = <HTMLElement>(
          searchHighlightElement.closest(
            ".ALexDisplayXSLParagraphStatusCollapsed"
          )
        );
        if (expandCollapseContainerEl) {
          expandCollapseContainerEl.style.display = "block";
          expandCollapseContainerEl.style.visibility = "visible";
          const expandCollapseImageContainerEl = <HTMLElement>(
            expandCollapseContainerEl.closest(
              ".ALexDisplayXSLKPMGExpandCollapse"
            )
          );

          const expandCollapseImageEl = expandCollapseImageContainerEl.querySelector(
            ".ALexDisplayXSLKPMGExpandCollapseMarker img"
          );

          if (expandCollapseImageEl) {
            expandCollapseImageEl.setAttribute(
              "src",
              this.urlResolver.resolveFilePath(
                "/ALexDisplayXSLKPMGcollapse.gif"
              )
            );
          }
        }
      });

      const previousHighlight = document.querySelector(".tocsearchhighlight");
      if (previousHighlight) {
        previousHighlight.classList.remove("tocsearchhighlight");
      }
      const currentHighlight = document.getElementById(excerptId);
      if (currentHighlight) {
        currentHighlight.classList.add("tocsearchhighlight");
        if (currentHighlight.scrollIntoView) {
          currentHighlight.scrollIntoView();
        } else {
          window.location.hash = excerptId;
        }
      }

      window.scrollTo(0, 0);
    }, 0);
  }

  private findTextInNodeCollectionV2(
    nodeList: NodeList,
    searchtext: string,
    excerpt: TOCSearchExcerptModel
  ): boolean {
    let bestMatchNode;
    for (let i = 0; i < nodeList.length; i++) {
      const processedNode = new ProcessedNode();
      processedNode.currentNode = processedNode.startNode = nodeList[i];
      processedNode.searchText = searchtext;
      processedNode.trimmedSearchText = this.replaceSpace(
        searchtext
      ).toLowerCase();
      if (
        !this.isAlreadyProcessed(<any>processedNode.currentNode) &&
        this.hasAnyHitMatch(<any>processedNode.currentNode, excerpt.hits)
      ) {
        this.findTextInNodeV2(processedNode, bestMatchNode);
        if (
          processedNode.matchFound &&
          !this.isAlreadyProcessed(<any>processedNode.currentNode)
        ) {
          if (
            !bestMatchNode ||
            processedNode.stepCount < bestMatchNode.stepCount
          ) {
            bestMatchNode = processedNode;
          }
          if (processedNode.stepCount == 1) break;
        }
      }
    }
    if (bestMatchNode) {
      bestMatchNode.matchExcerptCollection.push(excerpt.excerptId);
      (<Element>bestMatchNode.currentNode).classList.add(
        ...["matchedNode", excerpt.excerptId]
      );
      (<Element>bestMatchNode.startNode).classList.add(
        ...["matchedNode", excerpt.excerptId]
      );
      try {
        const elements = bestMatchNode.startNode.parentElement.querySelectorAll(
          ".unprocessed"
        );
        bestMatchNode.startNode.parentElement
          .querySelectorAll(".unprocessed")
          .forEach((element) => {
            element.classList.remove("unprocessed");
          });
      } catch (error) {
        (<Element>bestMatchNode.startNode).classList.remove("unprocessed");
      }
      this.updateExcerptIdInContentRef(bestMatchNode);
      return true;
    }

    return false;
  }

  private findTextInNodeV2(
    node: ProcessedNode,
    bestMatchNode: ProcessedNode
  ): ProcessedNode {
    if (node.currentNode.nodeName.toUpperCase() == "BODY") {
      node.messages.push("Reached body tag");
      return node;
    }

    let matchIndex = this.replaceSpace(node.currentNode.textContent)
      .toLowerCase()
      .indexOf(node.trimmedSearchText);

    if (matchIndex == -1) {
      if (node.currentNode.parentElement) {
        node.currentNode = node.currentNode.parentElement;
        node.stepCount++;
        if (!bestMatchNode || bestMatchNode.stepCount > node.stepCount) {
          this.findTextInNodeV2(node, bestMatchNode);
        }
      } else {
        node.messages.push("Parent Node not Found!");
      }
    } else {
      node.matchFound = true;
      node.matchStartIndex = matchIndex;
      node.matchEndIndex = matchIndex + node.trimmedSearchText.length;
    }
    return node;
  }

  private isAlreadyProcessed(element: Element): boolean {
    return element.classList && element.classList.contains("matchedNode");
  }
  private hasAnyHitMatch(element: Element, hits: string[]): boolean {
    return hits.some((hit) => {
      return (
        this.replaceSpace(hit).toLowerCase() ===
        this.replaceSpace(element.textContent).toLowerCase()
      );
    });
  }
  addWrapper(text: string, startIndex: number, endIndex: number) {
    return `${text.substring(
      0,
      startIndex
    )}<span class='tocsearchhit unprocessed'>${text.substring(
      startIndex,
      endIndex
    )}</span>${text.substring(endIndex)}`;
  }

  getSearchHits(
    searchExcerptModels: Array<TOCSearchExcerptModel>
  ): Array<string> {
    let hits = [];
    for (let excerpt of searchExcerptModels) {
      excerpt.hits = excerpt.hits || [];
      excerpt.text
        .split("<span class='tocsearchhit'>")
        .filter((value, index) => index > 0)
        .forEach((hit) => {
          let hitText = "";
          if (hit.includes("</span>")) {
            hitText = hit.substr(0, hit.indexOf("</span>")).trim();
          } else {
            hitText = hit.substr(0).trim();
          }
          excerpt.hits.push(hitText);
          hits.push(hitText);
        });
    }
    return hits;
  }
  private getDistinct(items: string[], removeEmpty = true): string[] {
    let distincts: Array<string>;
    if (items && items.length) {
      distincts = Array.from(new Set<string>(items)).filter(
        (item) => !removeEmpty || item.length
      );
    }
    return distincts;
  }

  private updateSearchHits(
    searchHits: Array<string>,
    contentRef: string
  ): string {
    //searchHits = searchHits.sort((a,b)=> b.length - a.length);
    searchHits &&
      searchHits.forEach((searchHit) => {
        if (this.isLangEnglish(searchHit)) {
          contentRef = this.updateSearchHitEnglish(searchHit, contentRef);
        } else {
          contentRef = this.updateSearchHitNonEnglish(searchHit, contentRef);
        }
      });
      return contentRef;
  }

  isLangEnglish(text: string): boolean {
    return /^[\x00-\x7F]*$/.test(text);
  }
  isLangWithoutWordBoundary(text: string): boolean {
    return (
      this.REGEX_LANGUAGE_WITHOUT_WORD_BOUNDARY &&
      this.REGEX_LANGUAGE_WITHOUT_WORD_BOUNDARY.test(text)
    );
  }
  updateSearchHitEnglish(searchHit: string, contentRef: string): string {
    //searchHit = this.removeLastSpecialCharecter(searchHit);
    const regexReplaceAllOccuranceOfWord = this.regexHelper.stringReplacementAllOccurance(
      searchHit
    );
    contentRef = contentRef.replace(
      regexReplaceAllOccuranceOfWord,
        this.addWrapper(searchHit, 0, searchHit.length)
     );
    return contentRef;
  }

  updateSearchHitNonEnglish(searchHit: string, contentRef: string): string {
    // let wordMatchRegex: RegExp;
    // if (this.isLangWithoutWordBoundary(searchHit)) {
    //   wordMatchRegex = new RegExp("()(" + searchHit + ")()", "gm");
    // } else {
    //   wordMatchRegex = new RegExp(
    //     "(^|[" +
    //     this.SPECIAL_CHARACTERS +
    //     "|\\s]?)(" +
    //     searchHit +
    //     ")($|[" +
    //     this.SPECIAL_CHARACTERS +
    //     "|\\s])",
    //     "gm"
    //   );
    // }
    // contentRef = contentRef.replace(
    //   wordMatchRegex,
    //   (
    //     matchedString,
    //     captureGroup1,
    //     captureGroup2,
    //     captureGroup3,
    //     offset,
    //     actualString
    //   ) => {
    //     const wrapperOpeningTag = "<span class='tocsearchhit unprocessed'>";
    //     const wrapperClosingTag = "</span>";
    //     let alreadyMatched =
    //       offset >= 39 &&
    //       actualString.substring(offset - 38, offset + 1).toLowerCase() ==
    //       wrapperOpeningTag;
    //     console.log("alreadyMatched",alreadyMatched);
    //     const wrapperString = `${wrapperOpeningTag}${captureGroup2}${wrapperClosingTag}`;
    //     console.log("wrapperString",wrapperString)
    //     return `${captureGroup1 || ""}${alreadyMatched ? captureGroup2 : wrapperString
    //       }${captureGroup3 || ""}`;

    //   }
    // );
    const regex = new RegExp(searchHit, 'gi');
    contentRef = contentRef.replace(
      regex,
      match => `<span class='tocsearchhit unprocessed'>${match}</span>`
    );
    
    return contentRef;
  }
  regexHelper = (function () {
    const escape = function (str) {
      return (str + "").replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&");
    };

    function stringReplacementAllOccurance(word: string) {
      word = escape(word);
      console.log("word",word)
      return new RegExp(`\\b${word}\\b`, "g");
    }

    return {
      stringReplacementAllOccurance: stringReplacementAllOccurance,
    };
  })();

  removeLastSpecialCharecter(txt) {
    var result = txt;
    var l = txt.length; // length of the original string
    var lastChar = txt.substring(l - 1, l); // get the last char of the original string
    const d = lastChar.search(/[^\w\s]/gi);
    if (d >= 0) {
      result = txt.substring(0, l - 1);
    } else {
      // otherwise do nothing
      result = txt;
    }
    return result;
  }

  private updateExcerptIdInContentRef(node: ProcessedNode) {
    if (node.matchExcerptCollection && node.matchExcerptCollection.length) {
      (<Element>node.currentNode).classList.add("matchParent");
      (<Element>node.startNode).id = node.matchExcerptCollection[0];
    }
  }

  private isTextOnlyNode(node: Node): boolean {
    return (
      node.nodeType === node.TEXT_NODE ||
      node.textContent === (<any>node).innerHTML
    );
  }

  private replaceSpace(text: string): string {
    return text
      .replace(new RegExp(" ", "g"), "")
      .replace(new RegExp(String.fromCharCode(160), "g"), "")
      .replace(new RegExp(String.fromCharCode(9), "g"), "")
      .replace(new RegExp(String.fromCharCode(10), "g"), "")
      .replace(new RegExp(String.fromCharCode(11), "g"), "")
      .replace(new RegExp(String.fromCharCode(12), "g"), "")
      .replace(new RegExp(String.fromCharCode(13), "g"), "");
  }
}
