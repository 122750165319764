import { Injectable } from "@angular/core";
@Injectable()
export class SpaceDocument {
    id: string;
    title: string;
    createdDate: string;
    mimeType: string;
    documentType:string;
    idolInfo:string;
    documentParentID:string;
    taxanomyIDList:[string];
    idolCreatedDate:string;
    isKillDocId:boolean;
    idolTitle:string;
    tocRef:string;
    isManual:boolean;
    country:[string];
    // phase 2 tracking code
    // documentId: string;
}


