import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, Output, EventEmitter } from '@angular/core';
import { AlexLocationModel } from "app/shared/components/location/alex-location.model";
import { AlexLocationService } from "app/shared/components/location/alex-location.service";
import { AlexLocationQueryModel } from "app/shared/components/location/alex-location-query.model";

@Component({
    selector: 'alex-location',
    templateUrl: './alex-location.component.html',
    styleUrls: ['./alex-location.component.scss'],
    host: {
        '(document:click)': 'onClick($event)',
    }
})

export class AlexLocationComponent implements OnInit {
    locationDetail: AlexLocationModel;
    @Input() showPopUp: boolean = false;
    @Input() initiator: any;
    @Output() closePopup: EventEmitter<any> = new EventEmitter<any>();

    locationSubscription: any;
    constructor(private locationService: AlexLocationService, private cdref: ChangeDetectorRef,
        private eref: ElementRef) {
        this.locationSubscription = this.locationService.locationDetail$.subscribe(location => {
            if (this.showPopUp) {
                this.locationDetail = location;
                this.cdref.detectChanges();

            }
        });
    }

    ngOnInit() {

    }
    ngOnDestroy() {
        this.locationSubscription.unsubscribe();
    }

    onClick(event: Event) {
        if (this.initiator) {
            if (!this.eref.nativeElement.contains(event.target) &&
                !this.initiator.elref.nativeElement.contains(event.target)) {
                this.showPopUp = false;
                this.closePopup.emit();
            }
        }
    }

    GetDetails(locationQueryDetails: AlexLocationQueryModel) {
        if (locationQueryDetails != null) {
            this.locationService.fetchLocationMid(locationQueryDetails);
        }
    }

}